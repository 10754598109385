import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Component, OnInit, Input, EventEmitter, Output, Injectable, OnChanges, AfterViewInit, ViewChild, ElementRef, Renderer, OnDestroy, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { SharedlistService } from 'src/app/services/sharedlist.service';
import { List } from 'src/app/models/list.model';
import { AppConfig } from 'src/app/app.config';
import { Subscription, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
// import { TemplateService } from 'src/app/services/template.service';
import { TemplateSharedQuestionCreateComponent } from '../template-shared-question-create/template-shared-question-create.component';
import { HttpParams, HttpErrorResponse } from '@angular/common/http';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ConfirmModalComponent } from 'src/app/modules/template/layout/confirm-modal';
import { AuthManager } from 'src/app/helpers/auth-manager';
import { map } from 'rxjs-compat/operator/map';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-template-sidenav',
  templateUrl: './template-sidenav.component.html',
  styleUrls: ['./template-sidenav.component.scss'],
 // providers: [templateservice]
})


@Injectable({
  providedIn: 'root'
})
export class TemplateSidenavComponent implements OnInit,AfterViewInit,OnChanges, OnDestroy,AfterViewChecked{
  @Input() templateInput;
  /* @Output() showMore: EventEmitter<any> = new EventEmitter<any>(); */
  //@Input() expandClass;
  templateId: any;
  appConstants = AppConfig.settings;
  checkServerURL: any;
  sharedQuestion:any[]=[];
  sharedChoice:any[]=[];
  SERVER_URL: any;
  sharedQuestionCreate:string;
  dialogRef: any;
  selectedIndex:any
  getSharedQuestionURL: any;
  expandClass : boolean ;
  collapseClass : boolean = false;
  subscription: Subscription;
  deleteSharedListURL:any;  
  templateSharedlistquestionUpdateURL:any;
  saveAsSubscription:Subscription;
  templateQuestionChoiceList:any = null;
  spinnerMTemplate:boolean=false;
  CopySharedListURL: any;
  spinnerSTemplate:boolean=false;
  sharedQuestionColumns: any;
  selectedData: any;
  templateSubscription:Subscription;
  SharedListDataSubscription: Subscription;
  reArrange: string;
  isEdit: boolean;
  isAdmin:boolean = true;
  templateQuesChoiceSubscription: Subscription;
  templateQuestionChoiceId: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
  //  private templateservice :TemplateService,
    private dialog:MatDialog,
    private sharedService:SharedService,
    private commonService: AwsCommonService,
    private toastorService: NotificationService,
    private cdr:ChangeDetectorRef,
    private authManager: AuthManager
    ) {
    this.checkServerURL = this.appConstants.checkServerURL;
    if (this.checkServerURL) {
      this.SERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.templateURL}`;
      this.templateSharedlistquestionUpdateURL = `${this.appConstants.serverUrl}/${this.appConstants.templateURL}/${this.appConstants.templateCopyURL}`;
      this.CopySharedListURL = `${this.appConstants.serverUrl}/${this.appConstants.templateURL}/${this.appConstants.copyURL}`; // ?templateId=3035&squestionId=11147`;
    } else {
      this.SERVER_URL = this.appConstants.getTemplateLocalURL;
    }
    this.sharedQuestionCreate = this.appConstants.SharedQuestionCreate;
    this.reArrange=this.appConstants.TemplateModel.rearrangeQuestions;
    this.deleteSharedListURL = `${this.appConstants.serverUrl}/${this.appConstants.templateURL}/${this.appConstants.deleteTemplateSharedQuestionURL}`;
    }
ngAfterViewInit(){
 this.subscription = this.sharedService.templateEditEvent$.subscribe((event)=>{
    if(event.sideNavReload===true && (event.type==="sharedList"|| event.type==="sharedListADD" || event.type==="sharedListDEL") ){
      if(event.type==="sharedListADD"){
        this.getSharedQuestion(false);
      }else {
        
        this.getSharedQuestion(true);
      }
    }
  }) 

  this.SharedListDataSubscription = this.sharedService.sharedListDataEmit$.subscribe((event) => {
    this.selectedData = event;
    //this.getSharedQuestion();s
    this.onSharedQuestionEmit(this.selectedData,'');
  })

  this.templateSubscription= this.sharedService.teplateSidenavToggle$.subscribe(event=>{
    this.expandClass =event;
  })
  this.templateQuesChoiceSubscription=this.sharedService.templateQuesChoiceEmit$.subscribe(event=>{
    this.templateQuestionChoiceId=event;
  })
}

ngAfterViewChecked(){
  this.cdr.detectChanges();
}
  ngOnInit() {
    if(this.authManager.userRole != 'ADMIN'){
      this.isAdmin = false;
    }
    /* 
    this.saveAsSubscription =this.sharedService.templateSaveAsEvent$.subscribe(data => {
      if(data.isSave==true){
        this.templateSaveAs(data.templateDescription);
      }
    }) */

  }

  ngOnChanges(){
    if(this.templateInput){
      this.templateId = this.templateInput.surveyTemplateId;
      if(!this.templateInput.id){
        this.getSharedQuestion(true);
      }else {
        this.selectedData = { id:this.templateInput.id, isGrid: this.templateInput.isGrid}
        this.getSharedQuestion();
      }
      this.getSharedChoice();
    } else {
      this.sharedService.templateId.subscribe( data => {
        this.templateId = data.surveyTemplateId;
        if(!data.id){
          this.getSharedQuestion(true);
        }else {
          this.selectedData = { id:data.id, isGrid: data.isGrid}
          this.getSharedQuestion();
        }
        this.getSharedChoice();
      });
    }
  }

/*
  templateSaveAs(templateDescription){
    this.spinnerSTemplate = true; 
    // this.sharedService.spinnerSideEvent(this.spinnerSTemplate);
      let inputData = {
          templateDescription: templateDescription,
          templateQuestionChoice: this.templateQuestionChoiceList
      }
      this.commonService.insertRequest(this.templateSharedlistquestionUpdateURL, 'POST', inputData).subscribe(
        (result) => {
        },
        error => {
          this.spinnerSTemplate=false;
          // this.sharedService.spinnerSideEvent(this.spinnerSTemplate);
          if(error.status==409){
            error.message = 'Template name already exsist';
          }
          this.toastorService.warn(error.message);
        },
        () => {
          this.spinnerSTemplate=false;
          // this.sharedService.spinnerSideEvent(this.spinnerSTemplate);
          this.toastorService.success('Template Created Successfully!');
          const editCancelData: any  = { isEdit: false, sideNavReload: true, type:'update', isNew: false }
          this.sharedService.templateEmitEvent(editCancelData);
        });

    } 
    templateSaveAs(templateDescription){
      this.spinnerSTemplate = true; 
      // this.sharedService.spinnerSideEvent(this.spinnerSTemplate);
        let inputData = {
            templateDescription: templateDescription,
            surveyTemplateId: this.templateId
        }
        this.commonService.insertRequest(this.templateSharedlistquestionUpdateURL, 'POST', inputData).subscribe(
          (result) => {
            console.log(result);
            this.spinnerSTemplate=false;
            // this.sharedService.spinnerSideEvent(this.spinnerSTemplate);
            this.toastorService.success('Template Created Successfully!');
           
            const editCancelData: any  = { reload:true, response: { surveyTemplateId: result}, type:'copy' }
            this.sharedService.templateDataEmit(editCancelData);
          },
          error => {
            this.spinnerSTemplate=false;
            // this.sharedService.spinnerSideEvent(this.spinnerSTemplate);
            if(error.status==409){
              error.message = 'Template name already exsist';
            }
            this.toastorService.warn(error.message);
          },
          () => {
         //   const editCancelData: any  = { isEdit: false, sideNavReload: true, type:'update', isNew: false }
         //   this.sharedService.templateEmitEvent(editCancelData); 
          });
  
      } */

      
  chunkArray(myArray, chunk_size){
      var results = [];
      while (myArray.length) {
          results.push(myArray.splice(0, chunk_size));
      }    
      return results;
  }
  
 

  getSharedQuestion(loadInit?:boolean){
    this.spinnerSTemplate=true;
    this.sharedQuestionColumns = [];
   // this.sharedService.spinnerSideEvent(this.spinnerSTemplate);
    if (this.checkServerURL) {
      this.getSharedQuestionURL = `${this.appConstants.serverUrl}/${this.appConstants.templateURL}/${this.templateId}/${this.appConstants.templateSharedQuestions}`;
    } else {
      this.getSharedQuestionURL = this.appConstants.getSharedQuestionLocalURL;
    }
    this.commonService.getRequest(this.getSharedQuestionURL, 'GET')
    .pipe(takeUntil(this.destroy$))
    .subscribe(data => {  
      this.spinnerSTemplate=false; 
      // this.sharedService.spinnerSideEvent(this.spinnerSTemplate);
      this.sharedQuestion = (data)?data: [];
    /* // Show more emit functionality
     let showmMoreCount = { sharedQuestionLength : (this.sharedQuestion) ? this.sharedQuestion.length : 0 } 
     this.showMore.emit(showmMoreCount); */
    if(this.sharedQuestion && this.sharedQuestion.length>0){
      /*  this.templateQuestionChoiceList = data.map(item =>{
          return {
            sharedQuestionChoice: {
              questionSharedList: {
                questionSharedListId: item.id
          }}}
        }); */
      //  this.sharedQuestionColumns = this.chunkArray(this.sharedQuestion, 15);
        if(loadInit){
        //  let firstData = this.sharedQuestionColumns[0];
       // let firstData = this.sharedQuestion[0];
          this.onSharedQuestionEmit(this.sharedQuestion[0], '')
        }else {
      /*sharedQuestionId: sharedQuestion.id,
      isGrid:sharedQuestion.isGrid */
     /* let selectedData = {
        id: 11497,
        isGrid: 'Simple'
      }*/
          this.onSharedQuestionEmit(this.selectedData,'');
        }
      }else {
        this.onSharedQuestionEmit('', 'noData')
      }
    },
    error => {
      this.spinnerSTemplate= false;
      // this.sharedService.spinnerSideEvent(this.spinnerSTemplate);
      this.toastorService.warn(error.message);
    }
    );
  }
  getSharedChoice(){
    this.sharedChoice = [];
   // this.templateservice.getSharedQuestion(`${this.appConstants.serverUrl}/${this.appConstants.templateURL}/${this.templateId}/${this.appConstants.templateSharedChoice}`).subscribe(data => {
    this.commonService.getRequest(`${this.appConstants.serverUrl}/${this.appConstants.templateURL}/${this.appConstants.templateSharedChoice}`, 'GET')
    .pipe(takeUntil(this.destroy$))
    .subscribe(data => {
      if(data) {
        this.sharedChoice = data;
      }
    }
    );
  }
  drop(event: CdkDragDrop<string[]>) {
    
   // console.log(event);
   // console.log(sharedQuesIndex);
   // console.log(this.sharedQuestionColumns);
   
const len=(this.sharedQuestion)? this.sharedQuestion.length: 0;

   moveItemInArray(
    this.sharedQuestion, 
      event.previousIndex, 
      event.currentIndex
   );
   for(let i=0;i<len;i++){
    this.sharedQuestion[i].displaySequenceNumber=i;
  }
  // console.log(this.sharedQuestion);
  
}
  dropTable(event: CdkDragDrop<{title: string, poster: string}[]>){
    moveItemInArray(this.sharedChoice, event.previousIndex, event.currentIndex);
  }

  onSharedQuestionEmit(sharedQuestion, dataType){
    let data;
    if(!dataType){
     data = {
      templateId: this.templateId,
      sharedQuestionId: sharedQuestion.id,
      isGrid:sharedQuestion.isGrid
    }

     this.selectedIndex=sharedQuestion.id
    } else {
      data = {
        templateId: this.templateId,
        sharedQuestionId: '',
        isGrid:''
      }
    }
    this.sharedService.templateData.emit(data); 
  }
  openCreateSharedQuestion(){
    this.dialogRef = this.dialog.open((TemplateSharedQuestionCreateComponent), { panelClass: 'newQuestion', disableClose: true,data: { templateId: this.templateId }});
    // this.dialogRef.afterClosed().subscribe(result=>{
    //   if(result=='error'){
    //     this.toastorService.warn('Selected Shared Question Already Exists  Template');
    //   }
    // })
  }
  
  

  // Confirmation Popup
  openconfirmModal(type, id): void {
    const dialogRef = this.dialog.open((ConfirmModalComponent), { data: { msg: `delete the ${type}` }, panelClass: 'confirmModal', disableClose: true });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
          this.deleteItem(id);
      }
    });
  }

  deleteItem(data){
    this.spinnerSTemplate = true; 
    // this.sharedService.spinnerSideEvent(this.spinnerSTemplate);
   // const options = data.id ? { params: new HttpParams().set('templateId', this.templateId).set('squestionId', data.id) } : {};
  // const options = data.id ? { params: new HttpParams().set('questionSharedListId', data.id).set('surveyTemplateId', this.templateId) } : {};
  const options = data.id ? { 'questionSharedListId':data.id,'templateQuestionChoiceId':this.templateQuestionChoiceId,'surveyTemplateId':this.templateId} : {};
  this.commonService.deleteRequest(this.deleteSharedListURL, 'DELETE', options, options)
  .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result:any) => {
          this.spinnerSTemplate = false; 
          // this.sharedService.spinnerSideEvent(this.spinnerSTemplate);
          this.getSharedQuestion(true);
          this.toastorService.success('Shared Question Deleted Successfully!');
        },
        (error) => {
          this.spinnerSTemplate = false; 
          // this.sharedService.spinnerSideEvent(this.spinnerSTemplate);
          this.toastorService.warn('Error' + error.message);
        }
      );
  
  }

  pasteItem(event, data){
    this.spinnerSTemplate = true;
    let inputparams =  {
      templateId: this.templateId,
      squestionId: data.id
    }
    // this.sharedService.spinnerSideEvent(this.spinnerSTemplate);
    this.commonService.postRequest(this.CopySharedListURL, 'POST', inputparams)
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (result) => {
        this.spinnerSTemplate = false;
        // this.sharedService.spinnerSideEvent(this.spinnerSTemplate);
        this.toastorService.success('Shared Question Copied Successfully!');
        this.getSharedQuestion();
        this.selectedData = {
          id: result,
        isGrid:data.isGrid
        }
        
      //  this.onSharedQuestionEmit(sharedListInput, '')
      },
      (error) => {
        this.spinnerSTemplate = false;
        // this.sharedService.spinnerSideEvent(this.spinnerSTemplate);
        this.toastorService.warn(error);
      },
      );
  }
  
  onReArrange(){
    this.spinnerSTemplate=true;
    let inputparams =  {
      templateId: this.templateId,
      sharedQuestion: this.sharedQuestion
    }
  console.log(this.sharedQuestion);

 this.commonService.putRequest(`${this.appConstants.serverUrl}/${this.appConstants.templateURL}/${this.templateId}/${this.reArrange}`,'PUT',this.sharedQuestion)
 .pipe(takeUntil(this.destroy$))
 .subscribe((data)=>{
  this.spinnerSTemplate = false;
  this.toastorService.success('Shared Questions Rearranged Successfully!');
 },
 (error)=>{
 this.spinnerSTemplate = false;
  // this.sharedService.spinnerSideEvent(this.spinnerSTemplate);
  this.toastorService.warn(error);
 })
  }
ngOnDestroy(){
  this.subscription.unsubscribe();
  this.destroy$.next(true);
  this.destroy$.unsubscribe();
  if(this.saveAsSubscription){
    this.saveAsSubscription.unsubscribe();
    this.templateSubscription.unsubscribe();
  }
  this.SharedListDataSubscription.unsubscribe();
  this.templateQuesChoiceSubscription.unsubscribe();
}
}



