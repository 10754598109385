import { Component, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { HeaderComponent } from './shared';
import { AuthManager } from './helpers/auth-manager';
import { Subject } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { CommonService } from './services/common.service';

export class User {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  token: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'primeauthortool';
  showHead: boolean;
  currentUser: any;
  userActivity;
  userInactive: Subject<any> = new Subject();
  appConstants = AppConfig.settings;

  @ViewChild(HeaderComponent, { static: false }) public headerComponent: HeaderComponent;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private authManger: AuthManager,
    private service: CommonService  
  ) {
    this.showHead = false;
    this.setTimeout();
    this.userInactive.subscribe(() => this.logout());
    // console.log('app-component')
    // this.authenticationService.currentUser.subscribe(x => { this.currentUser = x, this.showHead = true; });
    
  }

  ngAfterViewInit(){
    // debugger;
    
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), this.appConstants.idleTime * 60000);
  }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  @HostListener('document:mousemove', ['$event'])
  refreshUserState(event) {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  @HostListener("window:beforeunload",["$event"])
  clearLocalStorage(event) {
    localStorage.clear();
  }
  changeOfRoutes(){ 
   
   
    if(this.authManger.dynSSOId != '-1'){
      this.currentUser = true;
      this.showHead = true;
    }else{
      this.currentUser = false;
      this.showHead = false;
    }
    // this.headerComponent.onRouterChange();
  }

  logout() {
    // this.authenticationService.logout();
    // this.router.navigate(['/login']);
    this.authManger.logoutUser();
  }
}
