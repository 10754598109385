import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from '../app.config';

export class User {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  token: string;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  public username = 'Prime_Author';
  public password = 'pa1234';
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  protected appConstants = AppConfig.settings;
  constructor(private http: HttpClient, private appconfig: AppConfig) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    if (username === 'Prime_Author' && password === 'pa1234') {
    const apiURL = 'assets/json/logindata.json';
    return this.http.get<User>(apiURL) .pipe(map(user => {
    if (user ) {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
    }
    return user;
          }));
    }
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}