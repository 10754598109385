import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupName } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { AppConfig } from 'src/app/app.config';
import { SharedlistService } from 'src/app/services/sharedlist.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationService } from 'src/app/services/notification.service';
import { SharedQuestionEditViewComponent } from '../shared-question-edit-view/shared-question-edit-view.component';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { takeUntil } from 'rxjs/operators';
import {Subject} from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.scss']
})
export class AddQuestionComponent implements OnInit,OnDestroy {

  addSharedQuesForm: FormGroup;
  searchQuesModel:any;
  appConstants = AppConfig.settings;
  checkServerURL: any;
  SERVER_URL: any;
  questionCategoryList: any = [];
  getCategoryURL: any;
  getQuestion_URL: any;
  sharedQuestionList: any = [];
  addQuestionURL: any;
  validationMsg: any;
  isLoadingSave: any;
  // searchQuesModel:any;
  selectedIndex:any;
  isHidden: boolean = true;
  isCategorySelected: boolean;
  loadingQuestionList: boolean = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private commonService: CommonService,
    private sharedlistService: SharedlistService,
    private toastorService: NotificationService,
    private awsCommonService: AwsCommonService,
    private addQuestionRef: MatDialogRef<SharedQuestionEditViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.validationMsg = this.appConstants.validationMsg;
    this.checkServerURL = this.appConstants.checkServerURL;
    this.SERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedListParam}`;
    if (this.checkServerURL) {
      this.getCategoryURL = `${this.appConstants.serverUrl}/${this.appConstants.getQuestionCategoryParam}`;
      this.getQuestion_URL = `${this.SERVER_URL}`;
      this.addQuestionURL = `${this.appConstants.sharedListParam}/${this.appConstants.addQuestionURL}`;
      // this.getQuestionSharedListById_URL =  `${this.SERVER_URL}/${this.appConstants.getQuestionSharedListByIdParam}`;

    } else {
      this.getCategoryURL = this.appConstants.getQuestionCategoryLocalURL;
      this.getQuestion_URL = this.appConstants.getQuestionLocalURL;
    }
  }

  ngOnInit() {
    this.getCategory();
    this.addSharedQuesForm = new FormGroup({
      questionSharedListId: new FormControl(this.data.sharedListId),
      questionCategory: new FormControl('', Validators.compose([Validators.required])),
      //questionLibraryId: new FormControl(''),
      questionLongText: new FormControl('', Validators.compose([Validators.required,
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        validateEmptyField,
      Validators.maxLength(200)])),
      questionLibraryId: new FormControl('', Validators.compose([Validators.required]))
    });
    this.onChanges();
  }
  onChanges() {
    this.addSharedQuesForm.get('questionCategory').valueChanges.subscribe(data => {
      if (data == null || '') {
        this.addSharedQuesForm.controls['questionLibraryId'].setValidators(null);
        this.addSharedQuesForm.controls["questionLibraryId"].updateValueAndValidity();
        this.isCategorySelected = false;
      } else {
        this.addSharedQuesForm.controls['questionLibraryId'].setValidators([Validators.required]);
        this.addSharedQuesForm.controls["questionLibraryId"].updateValueAndValidity();
        this.isCategorySelected = true;
      }
    });
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.addSharedQuesForm.controls[controlName].hasError(errorName);
  }

  getCategory(): void {
    this.isLoadingSave = true;
    // this.commonService.getCategoryList(this.getCategoryURL)
    this.awsCommonService.getRequest(this.getCategoryURL,'GET').pipe(takeUntil(this.destroy$))
    .subscribe(data => {
      this.isLoadingSave = false;
      this.questionCategoryList = data;
    },
    error => {
      this.toastorService.warn(error.message);
      this.isLoadingSave = false;
    }
    );
  }

  onChangeQuesCat(event): void {
    let questionIds = [];
    questionIds = event.value;
    this.getQuestionList(questionIds);
  }

  getQuestionList(questionIds) {
    let sharedList = [];
    this.isLoadingSave = true;
    // this.sharedlistService.getQuestionList(this.getQuestion_URL, questionIds)
    this.awsCommonService.getRequest(`${this.getQuestion_URL}/${questionIds}/question`,'GET').pipe(takeUntil(this.destroy$))
    .subscribe(data => {
      this.isLoadingSave = false;
      data.forEach((item) => {
        if (item.status === 'Y') {
          sharedList.push(item);
        }
      })
      this.sharedQuestionList = sharedList;
      this.loadingQuestionList = false;
      //console.log('sharedlist', sharedList)
      //console.log('this.sharedQuestionList', this.sharedQuestionList);
    },
    error => {
      this.toastorService.warn(error.message);
      this.isLoadingSave = false;
    }
    );
  }

  public onSelect(target: any) {
    this.addSharedQuesForm.controls['questionLibraryId'].setValue(target);
  }

  addQuestion() {
    // this.addSharedQuesForm.removeControl('searchQuesModel');
    // this.addSharedQuesForm.removeControl('questionCategory');
    if (this.addSharedQuesForm.invalid) {
      this.isLoadingSave = false;
      return;
    } else {
      this.isLoadingSave = true;
      // //console.log(this.addSharedQuesForm.value); return false;
      // this.commonService.insert(this.addQuestionURL, this.addSharedQuesForm.value)
      this.awsCommonService.postRequest(`${this.appConstants.serverUrl}/${this.addQuestionURL}`, 'POST', this.addSharedQuesForm.value)
      .pipe(takeUntil(this.destroy$))
        .subscribe(
          (result) => {
            // this.addQuestionRef.close();
          },
          error => {
            this.toastorService.warn(error.message);
            this.isLoadingSave = false;
            this.addQuestionRef.close();
          },
          () => {
            this.isLoadingSave = false;
            this.addQuestionRef.close('success');
            this.toastorService.success('Question added successfully');
          }
        );

    }
  }

  openLink(event: MouseEvent): void {
    //console.log('cancel');
    this.addQuestionRef.close('cancel');
    event.preventDefault();
  }
  ngOnDestroy(): void {
    //throw new Error("Method not implemented.");
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
