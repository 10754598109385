import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthManager } from 'src/app/helpers/auth-manager';
import { SurveylayoutComponent } from './layout/surveylayout.component';
import { CreateSurveyComponent } from './components/create-survey/create-survey.component';
import { SurveyDashboardComponent } from './components/survey-dashboard/survey-dashboard.component';
import { SurveyStepComponent } from './components/survey-step/survey-step.component';
import { SurveyeditComponent } from './components/surveyedit/surveyedit.component';

const surveyRouting: Routes = [
  // { path: 'question', component: QuestionComponent  },
  // { path: '', redirectTo: 'question', pathMatch: 'full' }
  {
    path: '',
    component: SurveylayoutComponent,
    canActivate: [AuthManager],
    children: [
      {
        path: 'surveysteps',
        component: SurveyStepComponent,
      },
      { path: 'dashboard', component: SurveyDashboardComponent, pathMatch: 'full' },
      { path: 'surveyadd', component: SurveyStepComponent, pathMatch: 'full' },
      { path: 'surveyedit', component: SurveyeditComponent, pathMatch: 'full' },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
      }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(surveyRouting)],
  exports: [RouterModule]
})
export class SurveyroutingModule { }
