import { OthersEditComponent } from './components/others-edit/others-edit.component';
import { OthersCreateComponent } from './components/others-create/others-create.component';
import { OthersDashboardComponent } from './components/others-dashboard/others-dashboard.component';
import { InternetEditComponent } from './components/internet-edit/internet-edit.component';
import { InternetCreateComponent } from './components/internet-create/internet-create.component';
import { InternetDashboardComponent } from './components/internet-dashboard/internet-dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthManager } from 'src/app/helpers/auth-manager';
// import { AuthGuard } from 'src/app/guards/authgurad';

import { InputLayoutComponent } from './layout/input-layout.component';
import { PilotDashboardComponent } from './components/pilot-dashboard/pilot-dashboard.component';
import { PilotCreateComponent } from './components/pilot-create/pilot-create.component';
import { PilotEditComponent } from './components/pilot-edit/pilot-edit.component';
import { PromoDashboardComponent } from './components/promo-dashboard/promo-dashboard.component';
import { PromoCreateComponent } from './components/promo-create/promo-create.component';
import { PromoEditComponent } from './components/promo-edit/promo-edit.component';
import { PrintDashboardComponent } from './components/print-dashboard/print-dashboard.component';
import { PrintCreateComponent } from './components/print-create/print-create.component';
import { PrintEditComponent } from './components/print-edit/print-edit.component';


const inputsheetRouting: Routes = [
  {
    path: '',
    component: InputLayoutComponent,
    canActivate: [AuthManager],
    children: [
      { path: 'pilotedit', component: PilotEditComponent, pathMatch: 'full' },
      { path: 'pilotadd', component: PilotCreateComponent, pathMatch: 'full' },
      { path: 'pilot', component: PilotDashboardComponent, pathMatch: 'full' },
      { path: 'print', component: PrintDashboardComponent, pathMatch: 'full' },
      { path: 'printadd', component: PrintCreateComponent ,pathMatch: 'full' },
      { path: 'printedit', component: PrintEditComponent, pathMatch: 'full' },
      { path: 'promo', component: PromoDashboardComponent, pathMatch: 'full' },
      { path: 'promoadd', component: PromoCreateComponent, pathMatch: 'full' },
      { path: 'promoedit', component: PromoEditComponent, pathMatch: 'full' },
      { path: 'internet', component: InternetDashboardComponent, pathMatch: 'full'},
      { path: 'internetadd', component: InternetCreateComponent, pathMatch: 'full'},
      { path: 'internetedit', component: InternetEditComponent, pathMatch: 'full'},
      { path: 'others', component: OthersDashboardComponent, pathMatch: 'full'},
      { path: 'othersadd', component: OthersCreateComponent, pathMatch: 'full'},
      { path: 'othersedit', component: OthersEditComponent, pathMatch: 'full'},
      { path: '', redirectTo: 'pilot', pathMatch: 'full' },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
      }
      // { path: '', component: PilotDashboardComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(inputsheetRouting)],
  exports: [RouterModule]
})
export class InputsheetRoutingModule { }
