import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { AuthManager } from 'src/app/helpers/auth-manager';
import { CommonService } from 'src/app/services/common.service';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { MatTableDataSource } from '@angular/material';
import { NotificationService } from 'src/app/services/notification.service';
import { DashboardLayoutComponent } from 'src/app/modules/dashboard/layout/dashboard-layout.component';
import { ActivatedRoute, Router } from '@angular/router';
import { resource } from 'selenium-webdriver/http';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {
  // @ViewChild(DashboardLayoutComponent, { static: false }) private dashboard: DashboardLayoutComponent;
  // @Input() search = new EventEmitter;
  appConstants = AppConfig.settings;
  firstName: any;
  lastName: any;
  gridData = [];
  gridDataset = [];
  dataSource;
  data: any = false;
  searchData: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private authManager: AuthManager,
    private service: CommonService,
    private notificationService: NotificationService,
    private awsCommonService: AwsCommonService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    // ebugger;
    //console.log(this.firstName);
  }

  public onRouterChange() {
    // debugger;
    //console.log(this.firstName);
    //console.log(this.lastName);
  }
  ngAfterViewInit() {
    // debugger;
    this.service.dashboardValue.subscribe(
      result => this.data = result
    );
  }

  ngAfterContentChecked(){
    this.firstName = (this.authManager.dynFirstName) ? this.authManager.dynFirstName : '';
    this.lastName = (this.authManager.dynLastName) ? this.authManager.dynLastName : '';
  }

  ngOnInit() {

  }

  applyFilter(event) {
    this.searchData = event.target.value;

    if (this.searchData) {
      this.service.searchData.next(this.searchData);
    } else {
      // this.router.navigate([''], { relativeTo: this.route });
      this.service.searchData.next();
    }
    //  this.dashboard.getDashboardTable();
    // this.dashboard.getSearch(this.searchData);
    //  this.dashboard.getSearch(this.searchData);

    //   console.log(this.searchData); 
    //   const options = this.searchData ? { 'surveyCode' : this.searchData} : {};
    // this.awsCommonService.getApiRequest(`
    // ${this.appConstants.serverUrl}/${this.appConstants.chartDashboardParam}/${this.appConstants.surveyList}`,
    //   'GET', options).pipe(takeUntil(this.destroy$))
    //   .subscribe(
    //     dataSource => {

    //       this.gridDataset = dataSource;
    //       this.gridData = this.gridDataset;
    //      // this.dataSource = new MatTableDataSource(this.gridData);
    //      this.service.setFilter(this.gridData)
    //     },
    //     error => {
    //       this.notificationService.warn(error.message);

    //     }
    //   )
    // this.service.setFilter(this.searchData)
  }
  logoutUser() {
    this.authManager.logoutUser();
  }

}
