import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Inputsheettypes } from 'src/app/models/inputsheettypes';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { AppConfig } from 'src/app/app.config';

import { CommunicatingComponentService } from '../../service/communicating-component.service';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { ExcelService } from 'src/app/services/excel.service';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { PrintDashboardinterface, PrintDashboardObject } from 'src/app/models/inputsheetprintads';

import { DatePipe } from '@angular/common';
import { SatDatepicker } from 'saturn-datepicker';
import { MatSort, MatTableDataSource } from '@angular/material';
import { InputService } from 'src/app/services/inputsheet.service';
import { AuthManager } from 'src/app/helpers/auth-manager';

@Component({
  selector: 'app-print-dashboard',
  templateUrl: './print-dashboard.component.html',
  styleUrls: ['./print-dashboard.component.scss']
})

export class PrintDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  appConstants = AppConfig.settings;
  printDeclaring: PrintDashboardinterface;
  @ViewChild('picker', { static: true }) dateInput: SatDatepicker<any>;
  @Output() editEvent = new EventEmitter();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  inputSheetTypeControl = new FormControl();
  inputSheetType: Inputsheettypes[] = [];
  sheetType: number;
  subscription: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  pipe: DatePipe;
  isLoading: boolean;
  isAdmin:boolean = true;

  InputsheetprintData: any = [];
  InputsheetData: any = [];

  filterReturnData: any;
  objectDeclaration: any = [];
  matTableDataSource = new MatTableDataSource<PrintDashboardObject>();
  constructor(
    private communicatingComponentService: CommunicatingComponentService,
    private router: Router,
    private route: ActivatedRoute,
    private awsCommonService: AwsCommonService,
    private excelService: ExcelService,
    private inputService: InputService,
    private notificationService: NotificationService,
    private authManager: AuthManager
  ) {
    this.pipe = new DatePipe('en');
    this.printDeclaring = {
      inputSelecteName: '',
      dataShowTitle: '',
      printDashboardURL: '',
      gridTotalCount: '',
      tableErrorBlock: 'Loading Data...',
      tableNoDataFound: 'No Records Found',
      printDashboardGenresURL: '',
      printDashboardShowTitleURL: '',
      printTableFillterColumns: '',
      showTitleControl: new FormControl(''),
      surveyAnalystControl: new FormControl(''),
      genreDescControl: new FormControl(''),
      episodeControl: new FormControl(''),
      noOfPrintsControl: new FormControl(''),
      createdDtControl: new FormControl(''),
      lastUpdatedDtControl: new FormControl(''),
      inputSheetStatusControl: new FormControl(''),
      programmerNotesControl: new FormControl(''),
      inputSheetNameControl: new FormControl(''),
      printTableDisplayColumns: [
        'inputsheetName',
        'genreDesc',
        'showTitle', 'surveyAnalyst', 'episode', 'noOfPrints', 'programmerNotes', 'createdDt', 'lastUpdatedDt', 'inputSheetStatus',
        'action'],
      printTableHeaderColumns: [
        'input Sheet Name', 'genre', 'show title', 'Analyst', 'Episode #',
        'Number of PrintAds', 'Notes to Programmer', 'created date', 'modified date', 'status', 'action'],
      printTabelFooterColumns: ['filter_inputsheetName', 'filter_genreDesc',
        'filter_showTitle',
        'filter_surveyAnalyst',
        'filter_episode', 'filter_noOfPrints', 'filter_programmerNotes', 'filter_createdate', 'filter_modifieddate', 'filter_status',
        'filter_action'],
      pipe: new DatePipe('en'),
      tooltip: false,
      communication: '',
      placeholderHeight: 0,
      filterValues: {
        inputsheetName: '',
        showTitle: '',
        surveyAnalyst: '',
        genreDesc: '',
        episode: '',
        noOfPrints: '',
        programmerNotes: '',
        createdDt: '',
        lastUpdatedDt: '',
        inputSheetStatus: '',
        fromCreatedDate: '',
        toCreatedDate: '',
        fromUpdatedDate: '',
        toUpdatedDate: ''
      },
      showtitleData: [],
      genreData: [],
      inputsheetNameData: [],
      inputSheetStatusData: ['Draft', 'Submitted'],
      PrintTitleArr: [],
      showTitleArr: [],
      genreArr: [],
      showTitleArrd: [],
      genreArrd: [],
      noOfPrintsArr: null
    };
    this.printDeclaring.filterRougtCut = [
      {
        rougtcutid: '',
        rougtcutvalue: 'All'
      },
      {
        rougtcutid: 'Y',
        rougtcutvalue: 'Yes'
      },
      {
        rougtcutid: 'N',
        rougtcutvalue: 'No'
      }
    ];

    this.sheetType = 2;
    // this.printTableFillterColumns = 
    this.inputSheetType = [
      { value: 1, viewValue: 'Pilot' },
      { value: 2, viewValue: 'Print Ad' },
      { value: 3, viewValue: 'Promo' },
      {value: 4, viewValue: 'Internet'},
      {value: 5, viewValue: 'Others'}
    ];
    this.subscription = this.communicatingComponentService.getCommunication()
      .subscribe(message => { this.printDeclaring.communication = message; }
      );
    this.inputSheetTypeControl.setValue(this.inputSheetType[1].value);

    this.printDeclaring.inputSelecteName = this.inputSheetType[1].viewValue;
    this.inputSheetTypeControl.valueChanges
      .subscribe(
        inputSheetType => {
          this.printDeclaring.inputSelecteName = '';
          if (inputSheetType === 1) {
            this.router.navigate(['../pilot'], { relativeTo: this.route });
          } else if (inputSheetType === 2) {
            this.router.navigate(['../print'], { relativeTo: this.route });
          } else if (inputSheetType === 3) {
            this.router.navigate(['../promo'], { relativeTo: this.route });
          }
          else if(inputSheetType === 4){
            this.router.navigate(['../internet'], { relativeTo: this.route });
          }
          else if(inputSheetType === 5){
            this.router.navigate(['../others'], { relativeTo: this.route });
          }
          this.sheetType = inputSheetType;
          inputSheetType--;
          this.printDeclaring.inputSelecteName = this.inputSheetType[inputSheetType].viewValue;
        }
      );

    if (this.appConstants.checkServerURL) {
      this.printDeclaring.printDashboardURL =
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetPrintAdsDashboard}`;
      this.printDeclaring.printDashboardGenresURL =
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetDashboardFilterGenres}`;
      this.printDeclaring.printDashboardShowTitleURL =
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetDashboardFilterShowTitle}`;

    } else {
      this.printDeclaring.printDashboardURL = `${this.appConstants.inputSheetprintDashboardLocalURL}`;
      this.printDeclaring.printDashboardGenresURL = `${this.appConstants.inputsheetprintGenreLocalURL}`;
      this.printDeclaring.printDashboardShowTitleURL = `${this.appConstants.inputsheetprintShowTitleLocalURL}`;
    }

    this.getprintDashboard();

  }
  ngOnInit() {
    this.matTableDataSource.sort = this.sort;
    if(this.authManager.userRole != 'ADMIN'){
      this.isAdmin = false;
    }
  }

  ngAfterViewInit() {
    this.matTableDataSource.sort = this.sort;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    this.destroy$.next(true);
  }
  sendMessage() {
    this.communicatingComponentService.sendCommunication(true);
  }
  getprintDashboard() {

    this.isLoading = true;
    this.getMultipleFilter();
    const data: PrintDashboardObject[] = [];
    const showtitle: any[] = [];
    // const localURL = 'assets/json/testPrint.json';
    let PrintTitleArr = [];
    this.isLoading = true;

    this.awsCommonService.getRequest(this.printDeclaring.printDashboardURL, 'GET')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        dataSource => {
          this.isLoading = false;
          this.InputsheetprintData = dataSource,
            this.printDeclaring.showtitleData = [];
          this.printDeclaring.genreData = [];
          this.printDeclaring.inputsheetNameData = [];
          this.InputsheetprintData = this.InputsheetprintData.map((item, index) => {
            PrintTitleArr = item.printInfoDto;
            if (item.printInfoDto) {
              PrintTitleArr.map((itemdata) => {
                this.printDeclaring.showTitleArr = itemdata.showTitle;
                this.printDeclaring.genreArr = itemdata.genreDesc;
              });
              this.printDeclaring.showTitleArrd = PrintTitleArr.map((itemdata) => {
                return itemdata.showTitle.trim();
              });
              this.printDeclaring.genreArrd = PrintTitleArr.map((itemdata) => {
                return itemdata.genreDesc;
              });
              this.printDeclaring.noOfPrintsArr = PrintTitleArr.reduce((total, itemnoOfPrints) => {
                return total + itemnoOfPrints.noOfPrints;
              }, 0);
            }
            data.push({
              inputsheetId: item.inputSheetId,
              inputsheetName: item.inputSheetName ? item.inputSheetName : 'No Data',
              showTitle: (this.printDeclaring.showTitleArrd.length > 0) ? this.printDeclaring.showTitleArrd : 'No Data',
              surveyAnalyst: item.surveyAnalyst ? item.surveyAnalyst : 'No Data',
              genreDesc: (this.printDeclaring.genreArrd.length > 0) ? this.printDeclaring.genreArrd : 'No Data',
              episode:
                item.printInfoDto ? (item.printInfoDto[0]) ?
                  (item.printInfoDto[0].episodeNumber) ? (item.printInfoDto[0].episodeNumber) : 'No Data' : 'No Data' : 'No Data',
              titleVersionId: '',
              noOfPrints: (this.printDeclaring.noOfPrintsArr != null) ? this.printDeclaring.noOfPrintsArr : 'No Data',
              createdDt: item.createdDt ? item.createdDt : '01/01/0001',
              lastUpdatedDt: item.lastUpdatedDt ? item.lastUpdatedDt : '01/01/0001',
              inputSheetStatus: item.inputSheetStatus ? item.inputSheetStatus : 'No Data',
              programmerNotes: item.programmerNotes ? item.programmerNotes : 'No Data'
            });

            if (this.printDeclaring.showTitleArr && this.printDeclaring.showTitleArr.length > 0) {
              this.printDeclaring.showtitleData.push({
                showTitle: this.printDeclaring.showTitleArr
              });
            }
            if (this.printDeclaring.genreArr && this.printDeclaring.genreArr.length > 0) {
              this.printDeclaring.genreData.push({
                genreDesc: this.printDeclaring.genreArr
              });
            }
            if (typeof (item.inputSheetName) !== 'undefined' && item.inputSheetName != null) {
              this.printDeclaring.inputsheetNameData.push({
                inputsheetName: item.inputSheetName
              });
            }
          });

          this.printDeclaring.showtitleData =
            [...new Set(Array.from(new Set(this.printDeclaring.showtitleData)).map((itemInArray) => {
              if (typeof (itemInArray) !== 'undefined' && itemInArray !== null) {
                return itemInArray.showTitle.trim();
              }
            }))];
          this.printDeclaring.genreData =
            [...new Set(Array.from(new Set(this.printDeclaring.genreData)).map((itemInArray) => {
              if (typeof (itemInArray) !== 'undefined' && itemInArray !== null) {
                return itemInArray.genreDesc.trim();
              }
            }))];
          this.printDeclaring.inputsheetNameData =
            [...new Set(Array.from(new Set(this.printDeclaring.inputsheetNameData)).map((itemInArray) => {
              if (typeof (itemInArray) !== 'undefined' && itemInArray !== null) {
                return itemInArray.inputsheetName.trim();
              }
            }))];
          this.printDeclaring.showtitleData = this.printDeclaring.showtitleData.sort();
          this.printDeclaring.genreData = this.printDeclaring.genreData.sort();
          this.printDeclaring.inputsheetNameData = this.printDeclaring.inputsheetNameData.sort();
          this.matTableDataSource.data = data;
          setTimeout(() => {
            this.matTableDataSource.sort = this.sort;
          });
          this.matTableDataSource.filterPredicate = this.customFilterPredicate();
          this.printDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
        }
      );
  }

  clearGridTable() {
    this.printDeclaring.noOfPrintsControl.setValue('');
    this.printDeclaring.episodeControl.setValue('');
    this.printDeclaring.genreDescControl.setValue('');
    this.printDeclaring.surveyAnalystControl.setValue('');
    this.printDeclaring.showTitleControl.setValue('');
    this.printDeclaring.inputSheetStatusControl.setValue('');
    this.printDeclaring.inputSheetNameControl.setValue('');
    this.printDeclaring.filterValues.fromCreatedDate = '';
    this.printDeclaring.filterValues.toCreatedDate = '';
    this.printDeclaring.filterValues.fromUpdatedDate = '';
    this.printDeclaring.filterValues.toUpdatedDate = '';
    this.printDeclaring.lastdate = '';
    this.printDeclaring.createdate = '';
    this.awsCommonService.clearDate.emit(true);
    this.matTableDataSource.filter = JSON.stringify(this.printDeclaring.filterValues);

    this.printDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
  }
  getMultipleFilter() {
    this.printDeclaring.inputSheetNameControl.valueChanges
      .subscribe(
        inputsheetName => {
          this.printDeclaring.filterValues.inputsheetName = inputsheetName;
          this.matTableDataSource.filter = JSON.stringify(this.printDeclaring.filterValues);
          this.printDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.printDeclaring.tableErrorBlock =
            (this.printDeclaring.gridTotalCount === 0) ? this.printDeclaring.tableNoDataFound : this.printDeclaring.tableErrorBlock;
        },
        error => { }
      );
    this.printDeclaring.surveyAnalystControl.valueChanges
      .subscribe(
        surveyAnalyst => {
          this.printDeclaring.filterValues.surveyAnalyst = surveyAnalyst;
          this.matTableDataSource.filter = JSON.stringify(this.printDeclaring.filterValues);
          this.printDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.printDeclaring.tableErrorBlock =
            (this.printDeclaring.gridTotalCount === 0) ? this.printDeclaring.tableNoDataFound : this.printDeclaring.tableErrorBlock;
        },
        error => { }
      );
    this.printDeclaring.showTitleControl.valueChanges
      .subscribe(
        showTitle => {
          this.printDeclaring.filterValues.showTitle = showTitle;
          this.matTableDataSource.filter = JSON.stringify(this.printDeclaring.filterValues);
          this.printDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.printDeclaring.tableErrorBlock =
            (this.printDeclaring.gridTotalCount === 0) ? this.printDeclaring.tableNoDataFound : this.printDeclaring.tableErrorBlock;
        },
        error => { }
      );
    this.printDeclaring.genreDescControl.valueChanges
      .subscribe(
        genreDesc => {
          this.printDeclaring.filterValues.genreDesc = genreDesc;
          this.matTableDataSource.filter = JSON.stringify(this.printDeclaring.filterValues);
          this.printDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.printDeclaring.tableErrorBlock =
            (this.printDeclaring.gridTotalCount === 0) ? this.printDeclaring.tableNoDataFound : this.printDeclaring.tableErrorBlock;
        },
        error => { }
      );
    this.printDeclaring.episodeControl.valueChanges
      .subscribe(
        episode => {
          this.printDeclaring.filterValues.episode = episode;
          this.matTableDataSource.filter = JSON.stringify(this.printDeclaring.filterValues);
          this.printDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.printDeclaring.tableErrorBlock =
            (this.printDeclaring.gridTotalCount === 0) ? this.printDeclaring.tableNoDataFound : this.printDeclaring.tableErrorBlock;
        },
        error => { }
      );
    this.printDeclaring.noOfPrintsControl.valueChanges
      .subscribe(
        noOfPrints => {
          this.printDeclaring.filterValues.noOfPrints = noOfPrints;
          this.matTableDataSource.filter = JSON.stringify(this.printDeclaring.filterValues);
          this.printDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.printDeclaring.tableErrorBlock =
            (this.printDeclaring.gridTotalCount === 0) ? this.printDeclaring.tableNoDataFound : this.printDeclaring.tableErrorBlock;
        },
        error => { }
      );
    this.printDeclaring.inputSheetStatusControl.valueChanges
      .subscribe(
        inputSheetStatus => {
          this.printDeclaring.filterValues.inputSheetStatus = inputSheetStatus;
          this.matTableDataSource.filter = JSON.stringify(this.printDeclaring.filterValues);
          this.printDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.printDeclaring.tableErrorBlock =
            (this.printDeclaring.gridTotalCount === 0) ? this.printDeclaring.tableNoDataFound : this.printDeclaring.tableErrorBlock;
        },
        error => { }
      );
    this.printDeclaring.programmerNotesControl.valueChanges
      .subscribe(
        programmerNotes => {
          this.printDeclaring.filterValues.programmerNotes = programmerNotes;
          this.matTableDataSource.filter = JSON.stringify(this.printDeclaring.filterValues);
          this.printDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.printDeclaring.tableErrorBlock =
            (this.printDeclaring.gridTotalCount === 0) ? this.printDeclaring.tableNoDataFound : this.printDeclaring.tableErrorBlock;
        },
        error => { }
      );

  }
  customFilterPredicate() {

    return (data: PrintDashboardObject, filter: string): boolean => {
      const searchString = JSON.parse(filter);

      const inputsheetName =
        data.inputsheetName.toString().trim().toLowerCase().indexOf(searchString.inputsheetName.toLowerCase()) !== -1;

      const surveyAnalyst =
        data.surveyAnalyst.toString().trim().toLowerCase().indexOf(searchString.surveyAnalyst.toLowerCase()) !== -1;
      const showTitle = data.showTitle.toString().trim().indexOf(searchString.showTitle) !== -1;
      const genreDesc = data.genreDesc.toString().trim().indexOf(searchString.genreDesc) !== -1;
      const episode = data.episode.toString().trim().toLowerCase().indexOf(searchString.episode.toLowerCase()) !== -1;
      const noOfPrints =
        data.noOfPrints.toString().trim().toLowerCase().indexOf(searchString.noOfPrints.toLowerCase()) !== -1;
      const inputSheetStatus =
        data.inputSheetStatus.toString().trim().toLowerCase().indexOf(searchString.inputSheetStatus.toLowerCase()) !== -1;
      const programmerNotes =
        data.programmerNotes.toString().trim().toLowerCase().indexOf(searchString.programmerNotes.toLowerCase()) !== -1;
      const tempCreDt = this.pipe.transform(data.createdDt, 'yyyy-MM-dd');
      const createdDt = (
        (tempCreDt.toString() >= (searchString.fromCreatedDate).toString())
        &&
        (tempCreDt.toString() <= (searchString.toCreatedDate).toString())
      );
      const tempUpdDt = this.pipe.transform(data.lastUpdatedDt, 'yyyy-MM-dd');
      const lastUpdatedDt = (
        (tempUpdDt.toString() >= (searchString.fromUpdatedDate).toString())
        &&
        (tempUpdDt.toString() <= (searchString.toUpdatedDate).toString())
      );

      if (!(searchString.fromCreatedDate) && !(searchString.fromUpdatedDate)) {
        this.filterReturnData =
          inputsheetName && surveyAnalyst && showTitle && genreDesc && episode && noOfPrints && inputSheetStatus && programmerNotes;
      } else if (searchString.fromCreatedDate && !(searchString.fromUpdatedDate)) {
        this.filterReturnData =
          inputsheetName &&
          surveyAnalyst && showTitle && genreDesc && episode && noOfPrints && inputSheetStatus && programmerNotes && createdDt;
      } else if (!(searchString.fromCreatedDate) && searchString.fromUpdatedDate) {
        this.filterReturnData =
          inputsheetName &&
          surveyAnalyst && showTitle && genreDesc && episode && noOfPrints && inputSheetStatus && programmerNotes && lastUpdatedDt;
      } else {
        this.filterReturnData =
          surveyAnalyst &&
          inputsheetName &&
          showTitle && genreDesc && episode && noOfPrints && inputSheetStatus && programmerNotes && createdDt && lastUpdatedDt;
      }

      return this.filterReturnData;
    };
  }
  edit(data) {

    this.awsCommonService.editPrintData = data;
    this.router.navigate(['../printedit'], { relativeTo: this.route });

  }
  getDates(range: any, column: any) {
    const fromDate = this.pipe.transform(range.start, 'yyyy-MM-dd');
    const toDate = this.pipe.transform(range.end, 'yyyy-MM-dd');
    if (column === 'createdDt') {
      this.printDeclaring.filterValues.fromCreatedDate = fromDate;
      this.printDeclaring.filterValues.toCreatedDate = toDate;
      this.printDeclaring.createdate = this.pipe.transform(fromDate, 'MM/dd/yyyy') + '  to  ' + this.pipe.transform(toDate, 'MM/dd/yyyy');
    } else {
      this.printDeclaring.filterValues.fromUpdatedDate = fromDate;
      this.printDeclaring.filterValues.toUpdatedDate = toDate;
      this.printDeclaring.lastdate = this.pipe.transform(fromDate, 'MM/dd/yyyy') + '  to  ' + this.pipe.transform(toDate, 'MM/dd/yyyy');
    }

    this.matTableDataSource.filter = JSON.stringify(this.printDeclaring.filterValues);
    this.matTableDataSource.filter = JSON.stringify(this.printDeclaring.filterValues);
    this.printDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
  }

  onCopy(element) {
    this.isLoading = true;
    //console.log(element);
    const inputSheetIdData = element.inputsheetId;
    //console.log(inputSheetIdData);
    const inputSheetIdParam = { inputSheetId: inputSheetIdData };
    this.awsCommonService.postRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/copyprint`,
      'POST', inputSheetIdParam)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.notificationService.success('InputSheet Print Copied successfully');
        this.clearGridTable();
        this.getprintDashboard();
      }, err => {
        this.notificationService.warn(err);
      });
  }
  exportExcel() {
    //console.log(this.matTableDataSource.filteredData);
    //console.log(this.filteredData);
    this.notificationService.success('InputSheet Printad Excel generated successfully');
    this.excelService.inputsheetPrintExport(this.matTableDataSource.filteredData);
  }
}
