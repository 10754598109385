
import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { SharedlistService } from 'src/app/services/sharedlist.service';
import { FormGroup, FormControl } from '@angular/forms';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-shared-variable-view',
  templateUrl: './shared-variable-view.component.html',
  styleUrls: ['./shared-variable-view.component.scss']
})
export class SharedVariableViewComponent implements OnInit, OnChanges,OnDestroy {
 
  @Input() childInput;
  @Input() childEdit;
  sharedListName: any;
  spinnerSharedList: boolean;
  statements: string = "Number of Statements";
  questionGroup: string = "Question Group";
  sharedVariableForm: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  appConstants = AppConfig.settings;
  checkServerURL: any;
  SERVER_URL: any;

  constructor(
    private sharedservie: SharedlistService,
    private awsCommonService: AwsCommonService
  ) {
    this.sharedservie.tooltip.emit(true);
  //  this.spinnerSharedList=true;
    this.checkServerURL = this.appConstants.checkServerURL;
    if (this.checkServerURL) {
      this.SERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedListParam}`;
    }
  }

  ngOnInit() {
    this.sharedVariableForm = new FormGroup({
      sharedListName: new FormControl('', []),
      sharedListDesc: new FormControl('')
     
    });
  
  }
  ngOnChanges(): void {
  //  this.spinnerSharedList=true;
    this.sharedservie.tooltip.emit(true);
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.getElemenetbyId();
  }
  ngOnDestroy(): void {
   // throw new Error("Method not implemented.");
   this.destroy$.next(true);
   this.destroy$.unsubscribe();
  }

  getElemenetbyId() {
  this.spinnerSharedList = true;
    const url = 'variables/' + this.childInput.id;
    // this.sharedservie.getSharedVariablebyId(url)
    this.awsCommonService.getRequest(`${this.SERVER_URL}/${url}`, 'GET').pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        //console.log(res);
    this.sharedservie.tooltip.emit(false);
    this.spinnerSharedList=false;
   this.sharedListName = res;
   
   this.sharedVariableForm.patchValue(this.sharedListName);
  
    // this.getQuestionSharedListById_URL = `${this.getQuestionSharedListById_URL}/${childInput.id}`;

  

  
   
  })  
}
  
}
