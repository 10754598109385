import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
@Input() searchModel;
@Output() searchChangeEmit: EventEmitter<any>  =  new EventEmitter();
  constructor() { }

  ngOnInit() {
    
  }

  updateSearchModel(value){
    this.searchModel = value;
    //console.log('test', this.searchModel);
    this.searchChangeEmit.emit(this.searchModel);
  }

}
