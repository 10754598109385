import { Component, OnInit, ViewChild } from '@angular/core';
import { CreatequestionComponent } from '../components/create-question/create-question.component';
import { EditQuestionComponent } from '../components/edit-question/edit-question.component';
import { MatDialog } from '@angular/material/dialog';
import { QuestiondashboardComponent } from '../components/question-dashboard/question-dashboard.component';
import { AppConfig } from '../../../app.config';
import { CommonService } from 'src/app/services/common.service';
import { NotificationService } from 'src/app/services/notification.service';
import { QuestionService } from 'src/app/services/question.service';
import { AuthManager } from 'src/app/helpers/auth-manager';
import { ExcelService } from 'src/app/services/excel.service';
@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {

  @ViewChild(QuestiondashboardComponent, { static: false }) private dashboard: QuestiondashboardComponent;

  appConstants = AppConfig.settings;
  dashboardTitle: string;
  createText: string;
  dialogRef: any;
  checkServerURL: any;
  exportExcelURL: any;
  tooltip: boolean;
  isAdmin:boolean = true;
  constructor(
    public dialog: MatDialog,
    private appconfig: AppConfig,
    private commonService: CommonService,
    private toastorService: NotificationService,
    private quesService: QuestionService,
    private authManager: AuthManager,
    private excelService: ExcelService,
    
  ) {
    this.quesService.tooltip
      .subscribe(data => {
        if (data === true) {
          this.tooltip = true;
        } else {
          this.tooltip = false;
        }
      });
    this.checkServerURL = this.appConstants.checkServerURL;
    if (this.checkServerURL) {
      this.exportExcelURL = `${this.appConstants.questionDashboardExportExcelParam}`;
      // this.dashboardSurvey_URL = `${this.appConstants.serverUrl}/${this.appConstants.questionDashboardSurveyParam}`;
    }

    this.dashboardTitle = this.appConstants.questiontitle;
    this.createText = this.appConstants.questionCreateText;
  }

  ngOnInit() {
    
    //console.log('12')

    if(this.authManager.userRole != 'ADMIN'){
      this.isAdmin = false;
    }
  }

  // Open Dialog for Create Questiions
  openCreateQuestion(): void {
    this.dialogRef = this.dialog.open((CreatequestionComponent), { panelClass: 'newQuestion', disableClose: true });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'success') {
        this.dashboard.getquestion();
      }
    });
  }

  // Open Dialog for Edit Questiions
  editForm(data: any) {
    this.dialogRef = this.dialog.open(EditQuestionComponent, { data, panelClass: 'newQuestion', disableClose: true });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'success') {
        this.dashboard.getquestion();
      }
    });
  }

  // Export Excel
  exportExcel() {
    //console.log(this.dashboard.dataTableGrid.filteredData)
    this.toastorService.inProgress('Exporting data please wait...');
    if (this.checkServerURL) {

      // this.commonService.exportExcel(this.exportExcelURL, 'Prime Survey Question Library', this.dashboard.dataTableGrid.filteredData).subscribe(
      //   (res) => {
      //     //console.log(res)
      //     this.downLoadFile(res);
      //     this.toastorService.success(res.filename + ' excel generated  successfully');
      //   },
      //   error => {
      //     this.toastorService.warn('Error' + error.message);
      //   }
      // );
      this.excelService.generateExcelQuestions(this.dashboard.dataTableGrid.filteredData);

    } else {
      this.toastorService.warn('Server Not Connected. Please contact to Server Team');
    }

  }

  // Download files 
  // downLoadFile(data) {
  //   const element = document.createElement('a');
  //   element.href = URL.createObjectURL(data.fileContent);
  //   element.download = data.filename;
  //   document.body.appendChild(element);
  //   element.click();
  // }


}
