import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SharedListComponent } from './layout/shared-list.component';
const sharedlistRouting: Routes = [
  { path: '', component: SharedListComponent, pathMatch: 'full' }
];
@NgModule({
  imports: [RouterModule.forChild(sharedlistRouting)],
  exports: [RouterModule]
})
export class SharedlistRoutingModule { }
