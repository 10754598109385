import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {


  transform(list: any[], filterText: string): any {
    //console.log('filter', filterText);
    //console.log('list', list);
    if(filterText && (list[0].choiceLibraryDesc || list[0].choiceLibraryDesc == "")){
      //console.log('filter');
     // list =  list ? list.filter(item => item.choiceLibraryDesc.search(new RegExp(filterText, 'i')) > -1) : [];
     list =  list ? list.filter(item => item.choiceLibraryDesc.toLowerCase().
                indexOf(filterText.toLowerCase()) > -1) : [];
     // return list;
    } 
    else if(filterText && list[0].sharedListName){
     // list =  list ? list.filter(item => item.sharedListName.search(new RegExp(filterText, 'i')) > -1) : [];
     list =  list ? list.filter(item => item.sharedListName.toLowerCase().
                indexOf(filterText.toLowerCase()) > -1) : [];
    }
    else if(filterText && list[0].inputSheetName){
      // list =  list ? list.filter(item => item.sharedListName.search(new RegExp(filterText, 'i')) > -1) : [];
      list =  list ? list.filter(item => item.inputSheetName.toLowerCase().
                 indexOf(filterText.toLowerCase()) > -1) : [];
     }
     else if(filterText && list[0].templateDescription){
      // list =  list ? list.filter(item => item.sharedListName.search(new RegExp(filterText, 'i')) > -1) : [];
      list =  list ? list.filter(item => item.templateDescription.toLowerCase().
                 indexOf(filterText.toLowerCase()) > -1) : [];
     }
    else if(filterText && list[0].name){
     // list =  list ? list.filter(item => item.name.search(new RegExp(filterText, 'i')) > -1) : [];
     list =  list ? list.filter(item => item.name.toLowerCase().
                indexOf(filterText.toLowerCase()) > -1) : [];
    }
    else if(filterText && list[0].value){
     // list =  list ? list.filter(item => item.name.search(new RegExp(filterText, 'i')) > -1) : [];
     list =  list ? list.filter(item => item.value.toLowerCase().
                indexOf(filterText.toLowerCase()) > -1) : [];
    }
    
    else{
      //console.log('else', list);
      return list;

    }

    return list;
  }
  
  
}
