import { Injectable, EventEmitter } from '@angular/core';
import { HandleError, HttpErrorHandlerService } from '../shared/services/httperrorhandler.service';
import { AppConfig } from '../app.config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, retry, retryWhen, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChoicedashboardDatatable } from '../models/choicedashboard-datatable';
import { SurveyTable } from '../models/survey.model';
@Injectable({
  providedIn: 'root'
})
export class ChoicedashboardDatatableService {

  public handleError: HandleError;
  protected appConstants = AppConfig.settings;
  clearDate = new EventEmitter();
  constructor(
    private http: HttpClient,
    private httpErrorHandler: HttpErrorHandlerService,
    private appconfig: AppConfig) {

    // this.handleError = httpErrorHandler.createHandleError('choiceDashboardService');
  }
  httpOptions = {
    headers: new HttpHeaders().
    set('content-type', 'application/json').
    set('Accept', 'application/json').
    set('Access-Control-Allow-Origin', '*')
  };
  getChoiceDashboard(apiURL: any): Observable<ChoicedashboardDatatable[]> {
    return this.http.get<ChoicedashboardDatatable[]>(apiURL, this.httpOptions)
      .pipe(
        retry(2)
      );
  }
  getSurveyTable(apiSurveyURL: any): Observable<SurveyTable[]> {
    return this.http.get<SurveyTable[]>(apiSurveyURL, this.httpOptions)
      .pipe(
        retry(2)
      );
  }
}
