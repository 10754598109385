import { AbstractControl } from '@angular/forms';

export function RequiredMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (selection) {
    if (typeof selection === 'string' || selection.questionCategoryId == null) {
        return { incorrect: true };
    }
}
    return null;
}

export function RequiredSharedListQuestionMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (selection) {
    if (typeof selection === 'string' || selection.questionSharedListId == null) {
        return { incorrect: true };
    }
}
    return null;
}

export function RequiredSharedListChoiceMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (selection) {
    if (typeof selection === 'string' || selection.choicesharedListId == null) {
        return { incorrect: true };
    }
}
    return null;
}
