import { TemplateComponent } from './layout/template.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const templateRouting: Routes = [
  { path: '', component: TemplateComponent, pathMatch: 'full' }
];
@NgModule({
  imports: [RouterModule.forChild(templateRouting)],
  exports: [RouterModule]
})
export class TemplateRoutingModule { }