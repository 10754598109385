import { Subscription, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
// import { TemplateService } from 'src/app/services/template.service';
import { AppConfig } from 'src/app/app.config';
import { CommonService } from 'src/app/services/common.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TemplateCreateComponent } from '../components/template-create/template-create.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ConfirmModalComponent } from '../layout/confirm-modal';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { TemplateSaveasComponent } from '../components/template-saveas/template-saveas.component';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { AuthManager } from 'src/app/helpers/auth-manager';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit, OnDestroy ,AfterViewInit{
 
 
  dashboardTitle: string;
  templateList:any = [];
  templateListControl = new FormControl();
  appConstants = AppConfig.settings;
  templateTitle: string;
  SERVER_URL:any;
  templateCreate: any;
  templateId: any;
  dialogRef:any;
  isEdit: boolean = false;
  isCancel: boolean = false;
  editTemplateForm: FormGroup;
  editTemplateForm1: FormGroup;
  spinnerMTemplate: boolean = false;
  subscription : Subscription;
  templatelistSubscription: Subscription;
  viewTemplateSubscription: Subscription;
  selectedTemplateID: any;
  eventTemp: { surveyTemplateId: any, id: any, isGrid: any };
  checkServerURL: any;
  expandClass : boolean = false;
  collapseClass : boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  showMoreCount: number = 0;
  templateSharedlistquestionUpdateURL:any;
  sharedQuestionData: any;
  value: any;
  isAdmin: boolean = true;
  filteredList: any;
 
  constructor( 
  //  public templateService: TemplateService,
  private service: CommonService,
    private commonService: AwsCommonService,
    private toastorService: NotificationService,
    private dialog:MatDialog,
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private authManager: AuthManager
    
    ) { 

    this.templateCreate = this.appConstants.templateCreate;
    this.editTemplateForm = this.formBuilder.group({
      templateDescription: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        validateEmptyField,
        Validators.maxLength(128)
      ]))
    
    })
    this.checkServerURL = this.appConstants.checkServerURL;
    if (this.checkServerURL) {
      this.SERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.templateURL}`;
      this.templateSharedlistquestionUpdateURL = `${this.appConstants.serverUrl}/${this.appConstants.templateURL}/${this.appConstants.templateCopyURL}`;
    } else {
      this.SERVER_URL = this.appConstants.getTemplateLocalURL;
    }
  }

  ngOnInit() {
    if(this.authManager.userRole != 'ADMIN'){
      this.isAdmin = false;
    }

    const loadInit=true;
    this.getTemplateList(loadInit);
    
    this.templatelistSubscription=this.sharedService.templateDataEmit$.subscribe(event=>{
      // consio.log('template create', event);
      this.sharedQuestionData = event.response;
      if(event.reload){
        this.eventTemp ={surveyTemplateId :event.response.surveyTemplateId, id:null, isGrid: null}
        if(event.type=="copy"){
          this.getTemplateList(loadInit);
        } else {
          this.getTemplateList();
        }
      }
      if(event.isEdit===false){
        this.isEdit = event.isEdit;
     /*   if(event.sideNavReload===true && event.type==='update'){
           this.getTemplateList(loadInit);
         }
         this.cdr.detectChanges();*/
      }
    
    });

    this.viewTemplateSubscription = this.sharedService.templateEditEvent$.subscribe(event => {
      if(event){
        this.isEdit = event.isEdit;
      }
    })

   }

   ngAfterViewInit() {
   // throw new Error("Method not implemented.");
   this.templatelistSubscription=this.sharedService.templateDataEmit$.subscribe(event=>{
    console.log('template create afterviewInit', event);
    if(event.reload){
      this.eventTemp ={surveyTemplateId:event.response.surveyTemplateId, id:null, isGrid:null}
      this.getTemplateList();
    }
    if(event.isEdit===false){
      this.isEdit = event.isEdit;
   /*   if(event.sideNavReload===true && event.type==='update'){
         this.getTemplateList(loadInit);
       }
       this.cdr.detectChanges();*/
    }
  
  })

  }
  openCreateTemplate(){
    this.dialogRef = this.dialog.open((TemplateCreateComponent), { panelClass: 'newQuestion', disableClose: true });
  }

  getTemplateList(loadInit?:boolean) {
    this.spinnerMTemplate= true;
    this.commonService.getRequest(`${this.SERVER_URL}`, 'GET')
    .pipe(takeUntil(this.destroy$))
    .subscribe(data => {
    this.spinnerMTemplate= false;
     
    this.templateList = data;
    this.filteredList = this.templateList.slice();
    if(data.length>0){
    this.templateListControl.setValue(this.templateList[0].surveyTemplateId);
      if(loadInit){
        this.dashboardTitle = data[0].templateDescription;
        this.templateId = {surveyTemplateId: data[0].surveyTemplateId }
        this.editTemplateForm.controls['templateDescription'].setValue(data[0].templateDescription);
       
        const event={surveyTemplateId:this.templateList[0].surveyTemplateId, id:null, isGrid: null}
        this.onSelectTemplate(event);
      }
     else{
       this.templateListControl.setValue(this.eventTemp.surveyTemplateId);
      this.templateList = data;  
      this.onSelectTemplate(this.sharedQuestionData);
     }
     
    }
  },
  error => {
    this.spinnerMTemplate= false;
    this.toastorService.warn(error.message);
  }
    );
  }


  
  onSelectTemplate(event){
   // if(this.sharedQuestionData){}
    this.sharedService.templateId.emit(event);
    this.templateId = event;
    // consio.log('Template ID',this.templateId);
   this.cdr.detectChanges();
   const index =this.templateList.findIndex(el=>el.surveyTemplateId===event.surveyTemplateId);
   // consio.log(index);
   if(index> -1){
    this.dashboardTitle=this.templateList[index].templateDescription;
    this.editTemplateForm.patchValue({templateDescription:this.dashboardTitle});
    this.templateListControl.patchValue(this.templateList[index].surveyTemplateId);
   }
  }

  onEdit(){
    let editCancelData;
    this.isEdit = true;
    editCancelData = {isEdit: this.isEdit }
    this.sharedService.templateEmitEvent(editCancelData);
  }

// Confirmation PopUP
openconfirmModal(type: any, id: any): void {
  const dialogRef = this.dialog.open(ConfirmModalComponent, { panelClass: 'confirmModal', disableClose: true, data: { msg: (type==='edit')? 'cancel the changes' : 'delete the Template'} });
  dialogRef.afterClosed().subscribe(dialogResult => {
    if (dialogResult === true) {
      if (type === 'edit') {
        this.isEdit = false;
        const index =this.templateList.findIndex(el=>el.surveyTemplateId===this.templateId.surveyTemplateId);
        if(index> -1){
         this.dashboardTitle=this.templateList[index].templateDescription;
         this.editTemplateForm.patchValue({templateDescription:this.dashboardTitle});
        }
        const editCancelData: any = { isEdit: this.isEdit, isCancel: true};
        this.sharedService.templateEmitEvent(editCancelData);
      } else if(type==='template'){
          this.deleteTemplate(id);
      }
    }
  });
}


deleteTemplate(templateId){
  this.spinnerMTemplate = true;
  // const options = templateId? { params: new HttpParams().set('templateId', templateId)} : {};
  const options = templateId ? {'templateId':templateId } : {};
  this.commonService.deleteRequest(this.SERVER_URL, 'DELETE', options, options)
  .pipe(takeUntil(this.destroy$))
    .subscribe((result) => {
      this.isEdit = false;
      this.spinnerMTemplate = false;
      this.toastorService.success('Template Deleted Successfully!'); 
      const editCancelData: any  = { isEdit: false}
      this.sharedService.templateEmitEvent(editCancelData);
      this.getTemplateList(true);
      },
      error => {
        this.spinnerMTemplate = false;
        this.toastorService.warn('Error' + error.message);
      }
    );
}

sharedListQuestionSave(){
  if(this.editTemplateForm.invalid){
    return false;
  } else {
    let isSave = true;
    this.isEdit = true;
    const editClickedData: any = { isSave: isSave,  templateDescription: this.editTemplateForm.controls['templateDescription'].value };
    this.sharedService.templateEmitSave(editClickedData);
  }
}

//Open Saveas Name confirm
openSaveAsNameModal():void{
  const dialogRef = this.dialog.open(TemplateSaveasComponent, { panelClass:'confirmModal', disableClose:true, data : {name:''} });
  dialogRef.afterClosed().subscribe(dialogResult => {
    if(dialogResult.status == true){
      this.templateSharedListQuestionSaveAs(dialogResult.templateName);
    }
  })
}

templateSharedListQuestionSaveAs(templateDescription){
  if(this.editTemplateForm.invalid){
    return false;
  } else {
    let isSave = true;
    this.isEdit = false;
    this.templateSaveAs(templateDescription);
  /*  const editClickedData: any = { isSave: isSave, isEdit: this.isEdit, templateDescription: templateDescription };
    this.sharedService.templateEmitSaveAs(editClickedData);*/
  }
}


public hasError = (controlName: string, errorName: string) => {
  return this.editTemplateForm.controls[controlName].hasError(errorName);
}
ngOnDestroy(): void {
  this.templatelistSubscription.unsubscribe();
  this.viewTemplateSubscription.unsubscribe();
  this.destroy$.next(true);
  this.destroy$.unsubscribe();
}

/* templateSideNav_Expand(event){
  this.expandClass = true;
  //  this.templateId;
  this.sharedService.templateToggler(this.expandClass)
}

templateSideNav_Collapse(event){
this.expandClass = false;
this.sharedService.templateToggler(this.expandClass);
// this.templateId=null;
}*/

showMoreDisplay(event){
  this.showMoreCount = event.sharedQuestionLength;
  // consio.log('showmore count', event );
} 

templateSaveAs(templateDescription){
  this.spinnerMTemplate = true; 
  // this.sharedService.spinnerSideEvent(this.spinnerSTemplate);
    let inputData = {
        templateDescription: templateDescription,
        surveyTemplateId: this.templateId.surveyTemplateId
    }
    this.commonService.postRequest(this.templateSharedlistquestionUpdateURL, 'POST', inputData)
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (result) => {
        // consio.log(result);
        this.spinnerMTemplate=false;
        // this.sharedService.spinnerSideEvent(this.spinnerSTemplate);
        this.toastorService.success('Template Copied Successfully!');
        this.sharedQuestionData = {surveyTemplateId:result};
        this.eventTemp ={surveyTemplateId :result, id:null, isGrid: null}
       this.getTemplateList();
   //    this.eventTemp ={surveyTemplateId:result, id:null, isGrid:null}
      /*  const editCancelData: any  = { reload:true, response: { surveyTemplateId: result}, type:'copy' }
        this.sharedService.templateDataEmit(editCancelData); */
      },
      error => {
        this.spinnerMTemplate=false;
        // this.sharedService.spinnerSideEvent(this.spinnerSTemplate);
        if(error.status==409){
          error.message = 'Template name already exists';
        }
        this.toastorService.warn(error.message);
      },
      () => {
     /*    const editCancelData: any  = { isEdit: false, sideNavReload: true, type:'update', isNew: false }
        this.sharedService.templateEmitEvent(editCancelData); */
      });

  } 

}
