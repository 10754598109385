import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class SharedService {
  templateId = new EventEmitter();
  templateData = new EventEmitter();

  // Observable string sources
  private eventAnnouncedSource = new Subject<any>();
  private eventConfirmedSource = new Subject<any>();
  private editEvent=new Subject<any>();
  private templateEditEventSource =  new Subject<any>();
  private templateSaveEventSource = new Subject<any>();
  private templateSidenavToggleSource = new Subject<any>();
  private sharedListDataEmitSource =  new Subject<any>();
  private templateQuesChoiceEmitSource =new Subject<any>();
  private templateDataEmitSource = new Subject<any>();
  // Observable string streams
  eventAnnounced$ = this.eventAnnouncedSource.asObservable();
  eventConfirmed$ = this.eventConfirmedSource.asObservable();
  editEvent$ = this.editEvent.asObservable();
  templateEditEvent$ = this.templateEditEventSource.asObservable();
  templateSaveEvent$ = this.templateSaveEventSource.asObservable();
  teplateSidenavToggle$=this.templateSidenavToggleSource.asObservable();
  sharedListDataEmit$ = this.sharedListDataEmitSource.asObservable();
  templateDataEmit$ = this.templateDataEmitSource.asObservable();
  
  templateQuesChoiceEmit$ =this.templateQuesChoiceEmitSource.asObservable();
  
  // Service message commands
  announceEvent(event: any) {
    this.eventAnnouncedSource.next(event);
  }

  confirmEvent(astronaut: any) {
    this.eventConfirmedSource.next(astronaut);
  }
  emitEvent(event:any){
   this.editEvent.next(event);
  }

  templateEmitEvent(event:any) {
    this.templateEditEventSource.next(event);
  }

  templateEmitSave(event:any) {
    this.templateSaveEventSource.next(event);
  }
  templateToggler(event:any){
    this.templateSidenavToggleSource.next(event);
  }

  sharedListDataEmit(event:any){
    this.sharedListDataEmitSource.next(event);
  }

  templateDataEmit(event:any) {
    this.templateDataEmitSource.next(event);
  }
  templateQuesChoiceEmit(event:any){
    this.templateQuesChoiceEmitSource.next(event)
}
}
