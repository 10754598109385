import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import * as AppConst from '../../../../app.config';
import { AppConfig } from '../../../../app.config';
import { Observable } from 'rxjs';
import { List, categoryList, sharedList, choiceList } from 'src/app/models/list.model';
import { MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteTrigger } from '@angular/material';

import { QuestiondashboardComponent } from '../question-dashboard/question-dashboard.component';
import { RequiredMatch, RequiredSharedListQuestionMatch } from 'src/app/shared/requiredMatch';
import { startWith, map, debounceTime } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { CommonService } from 'src/app/services/common.service';
import { QuestionsModel } from 'src/app/models/questions.model';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { QuestiondashboardDatatableService } from 'src/app/services/questiondashboard-datatable.service';
import { HttpParams } from '@angular/common/http';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-edit-question',
  templateUrl: './edit-question.component.html',
  styleUrls: ['./edit-question.component.scss']
})
export class EditQuestionComponent implements OnInit {
  appConstants = AppConfig.settings;
  label: any;
  settings: any;
  editQuesForm: FormGroup;
  public globalResponse: any;
  toppings = new FormControl();
  toppingList: string[] = [' FUTURE ACTIVITY', 'FAVORITE SHOWS', 'HOST ADJECTIVES', 'CLUSTER', 'ANCHOR', 'FIRST FAVORITE'];
  public url = 'questions';
  public validationMsg: any;
  posNegFlag = [
    { id: 1, value: 'POSITIVE', data: 'Y', color: 'green' },
    { id: 2, value: 'NEGATIVE', data: 'N', color: 'red' },
    { id: 3, value: 'NEUTRAL', data: 'U', color: '' }
  ];

  chosenItem = this.posNegFlag[0].data;
  editData: any;
  editLiveData: any;
  filteredCategory: Observable<List[]>;
  filteredSharedList;
  filteredChoice: Observable<List[]>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  categoryList: categoryList[] = [];
  sharedList = [];
  choiceList: choiceList[] = [
    { choiceListId: 1, choiceListDesc: 'Frequently' },
    { choiceListId: 2, choiceListDesc: 'Rarely' }
  ];
  isLoadingCategory = false;
  isLoadingSharedQuestion = false;
  isLoadingSave = false;
  @ViewChild(MatAutocompleteTrigger, { static: true }) auto: MatAutocompleteTrigger;

  constructor(
    private appconfig: AppConfig,
    private editQuestionRef: MatDialogRef<QuestiondashboardComponent>,
    
    private toastorService: NotificationService,
    private awsCommonService: AwsCommonService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: QuestionsModel, private questionDashboardService: QuestiondashboardDatatableService,
    private formbuilder: FormBuilder) {

    this.label = this.appConstants.question;
    this.settings = this.appConstants.validationMsg;
    this.validationMsg = this.appConstants.validationMsg;
    this.editData = data;
    
  }
  getQuestionEdit() {
    this.isLoadingSave = true;
    // const options = this.editData.questionLibraryId ?
      // { params: new HttpParams().set('id', this.editData.questionLibraryId) } : {};
    const options = this.editData.questionLibraryId ?
      { 'id' : this.editData.questionLibraryId } : {};
    // this.questionDashboardService.getQuestionedit(`${this.appConstants.serverUrl}/questions/editQuestion`, options)
    this.awsCommonService.getApiRequest(`${this.appConstants.serverUrl}/questions/editQuestion`,'GET',options).pipe(takeUntil(this.destroy$))
    .subscribe(
      data => {
        //console.log(data),
        this.editLiveData = data;
        this.isLoadingSave = false;
        this.editQuesForm.patchValue({
          questionLibraryId: this.editLiveData.questionLibraryId,
          questionCategory:
          {questionCategoryId: this.editLiveData.questionCategoryId , questionCategoryDesc: this.editLiveData.questionCategoryDesc},
          genericText: this.editLiveData.genericText,
          questionPosNegFlag: this.editData.questionPosNegFlag,
          percentileYn: this.editData.percentileYn,
          summRptYn: this.editData.summRptYn,
          activeYn: this.editData.activeYn,
          questionSharedList: (this.editLiveData.questionSharedListId) ?
          {questionSharedListId: this.editLiveData.questionSharedListId , sharedListName: this.editLiveData.sharedListName } : '',
          questionLongText: this.editLiveData.questionLongText,
          questionSharedListId: this.editLiveData.questionSharedListId,
          questionCategoryId: this.editLiveData.questionCategoryId,
          questionCategoryDesc: this.editLiveData.questionCategoryDesc
        });
      }
    );
  }

  _filterValue(val: any, ArrList: any, Type: any) {
    if (Type === 'categoryList') {
      const name = val.questionCategoryDesc || val; // val can be List or string
      const list = ArrList.filter(
        option => option.questionCategoryDesc.toLowerCase().includes(name.toLowerCase()));
      return (list.length > 0 ? list : [{ questionCategoryId: null, questionCategoryDesc: 'No record found' } as any]);
    } else if (Type === 'sharedList') {
      const name = val.sharedListName || val; // val can be List or string
      //console.log(name);
      const listsh = ArrList.filter(
        option => option.sharedListName.toLowerCase().includes(name.toLowerCase()));
      return (listsh.length > 0 ? listsh : [{ questionSharedListId: null, sharedListName: 'No record found' } as any]);
  
    } else if (Type === 'choiceList') {
      const name = val.choiceListDesc || val; // val can be List or string
      const list = ArrList.filter(
        option => option.choiceListDesc.toLowerCase().indexOf(name.toLowerCase()) === 0);
      return list;
    }

  }
  categoryDisplayFn(filterBy): string {
    return filterBy ? filterBy.questionCategoryDesc : filterBy;
  }
  sharedDisplayFn(filterBy): string {
    return filterBy ? filterBy.sharedListName : filterBy;
  }
  choiceDisplayFn(filterBy): string {
    return filterBy ? filterBy.choiceListDesc : filterBy;
  }
  openLink(event: MouseEvent): void {
    this.editQuestionRef.close('cancel');
    event.preventDefault();
  }
  ngOnInit() {
    this.getCategory();
    this.getSharedList();
    // this.getChoiceList();
    //console.log(this.data.questionCategory);

    this.editQuesForm = this.formbuilder.group({
      questionLibraryId: new FormControl(),
      questionCategory: new FormControl('', [Validators.required, RequiredMatch]),
      questionCategoryId: new FormControl(''),
      questionCategoryDesc: new FormControl(''),
      genericText: new FormControl('',
        Validators.compose([
          Validators.required,
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
          Validators.maxLength(255),
          validateEmptyField
        ])
      ),
      questionPosNegFlag: new FormControl(this.posNegFlag[0].data, [Validators.required]),
      percentileYn: new FormControl(''),
      summRptYn: new FormControl(''),
      activeYn: new FormControl(''),
      questionSharedList: new FormControl(''),
      questionLongText: new FormControl(''),
      questionSharedListId: new FormControl('')
    });
    this.getQuestionEdit();
  }

  public trimFunction(val) {
    val.target.value = val.target.value.trim();
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.editQuesForm.controls[controlName].hasError(errorName);
  }
  onChange(controlName: string, e) {
    if (e.checked === true) {
      this.editQuesForm.controls[controlName].setValue('Y');
    } else {
      this.editQuesForm.controls[controlName].setValue('N');
    }
  }
  onClose() {
    this.editQuesForm.reset();
   //  this.initializeFormGroup();
  }
  // Update Question
  updateQuestion() {
    // this.isLoadingSave = true;
    if (this.editQuesForm.invalid) {
      return;
    }
    else{
      //console.log('isLoadingSave');
      this.isLoadingSave = true;
      // this.editQuesForm.removeControl('questionSharedList');
      // this.editQuesForm.removeControl('choiceList');
      this.editQuesForm.patchValue({
        questionLibraryId: this.editQuesForm.controls.questionLibraryId.value,
        questionCategory: this.editQuesForm.controls.questionCategory.value,
        questionCategoryId: this.editQuesForm.controls.questionCategory.value.questionCategoryId,
        questionCategoryDesc: this.editQuesForm.controls.questionCategory.value.questionCategoryDesc,
        genericText: this.editQuesForm.controls.genericText.value,
        questionPosNegFlag: this.editQuesForm.controls.questionPosNegFlag.value,
        percentileYn: this.editQuesForm.controls.percentileYn.value,
        summRptYn: this.editQuesForm.controls.summRptYn.value,
        activeYn: this.editQuesForm.controls.activeYn.value,
        questionSharedListId:
        (this.editQuesForm.controls.questionSharedList.value) ?
        this.editQuesForm.controls.questionSharedList.value.questionSharedListId : '' ,
        questionLongText: this.editQuesForm.controls.questionLongText.value,
        questionSharedList:
          (this.editQuesForm.controls.questionSharedList.value) ? this.editQuesForm.controls.questionSharedList.value :
          { questionSharedListId: '', sharedListName: '' }

      });
      // this.editQuesForm.removeControl('questionCategory');
      // this.editQuesForm.removeControl('questionSharedList');
     // this.isLoadingSave = true;
      // this.commonService.update(this.url, this.editQuesForm.value)
      this.awsCommonService.postRequest(`${this.appConstants.serverUrl}/${this.url}`, 'PUT', this.editQuesForm.value)
      .subscribe((result) => {
        this.globalResponse = result;
        this.isLoadingSave = false;
        // this.editQuestionRef.close();
      },
        error => {
          // this.toastorService.warn('Error' + error.message);
          if (error.status === 409) {
            this.isLoadingSave = false;
            this.editQuesForm.controls.genericText.setErrors({
              notUnique: true
            });
            // this.createQuesForm.controls.genericText.setValue('');
            // this.toastorService.warn('already exists in our database');
          } else {
            this.isLoadingSave = false;
            this.toastorService.warn('Oops!!! The Server was unable to complete your request');
            this.editQuestionRef.close('cancel');
          }
        },
        () => {
          this.isLoadingSave = false;
          this.editQuestionRef.close('success');
          this.toastorService.success('Updated Successfully');
        });
    }
    // this.isLoadingSave = false;
  }

  // Auto Populate for each dropdown
  // Category API
  getCategory(): void {
    this.isLoadingCategory = true;
    // this.quesService.getCategoryList()
    this.awsCommonService.getRequest(`${this.appConstants.serverUrl}/categories?page=1`, 'GET').pipe(takeUntil(this.destroy$))
    .subscribe(data => {
      this.categoryList = data;
      this.auto.closePanel(),
        this.filteredCategory = this.editQuesForm.controls.questionCategory.valueChanges
          .pipe(
            debounceTime(300),
            startWith(''),
            map(category => category ? this._filterValue(category, this.categoryList, 'categoryList') : this.categoryList.slice())
          );
    },
      error => {
        this.isLoadingCategory = false,
          //console.log(error),
          this.toastorService.warn('Oops!!! The Server was unable to complete your request');
      },
      () => { this.isLoadingCategory = false; }
    );
  }

  // Shared List API
  getSharedList(): void {
    this.isLoadingSharedQuestion = true;
    // this.quesService.getSharedList()
    this.awsCommonService.getRequest(`${this.appConstants.serverUrl}/questions/question`, 'GET').pipe(takeUntil(this.destroy$))
    .subscribe(data => {
      this.sharedList = data;
      //console.log(data);
      this.filteredSharedList = this.editQuesForm.controls.questionSharedList.valueChanges
        .pipe(
          startWith(''),
          map(dataShared =>
            dataShared ? this._filterValue(dataShared, this.sharedList, 'sharedList') : this.sharedList.slice()
          )
        );

    },
      error => {
        this.isLoadingSharedQuestion = false,
          //console.log(error),
          this.toastorService.warn('Oops!!! The Server was unable to complete your request');
      },
      () => { this.isLoadingSharedQuestion = false; }
    );
  }
  onChangeSharedList(sharedValue) {
    // this.quesService.getChoiceList()
    this.awsCommonService.getRequest(`${this.appConstants.serverUrl}/sharedlist/choices`,'GET').pipe(takeUntil(this.destroy$))
    .subscribe(data => {
      this.filteredChoice = this.editQuesForm.controls.choiceList.valueChanges.pipe(
        startWith(''),
        map(category => category ? this._filterValue(category, this.choiceList, 'choiceList') : this.choiceList.slice())
      );
    });
  }
}
