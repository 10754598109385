import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardLayoutComponent } from './layout/dashboard-layout.component';
import { DashboardRoutingModule } from './dashboard.routing.module';
import { ChartsModule } from 'ng2-charts';
import { MatTableModule } from '@angular/material';



@NgModule({
  declarations: [
    DashboardLayoutComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ChartsModule,
    MatTableModule
  ]
})
export class DashboardModule { }
