
import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, ElementRef, QueryList, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AppConfig } from 'src/app/app.config';
import { MatDialogRef, MAT_DIALOG_DATA, MatSelectionList, MatListOption } from '@angular/material';
import { CommonService } from 'src/app/services/common.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SelectionModel } from '@angular/cdk/collections';
import { TemplateDashboardComponent } from '../template-dashboard/template-dashboard.component';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-choice',
  templateUrl: './add-choice.component.html',
  styleUrls: ['./add-choice.component.scss']
})
export class AddChoiceComponent implements OnInit ,OnDestroy{
 
  appConstants = AppConfig.settings;
  public addChoiceForm: FormGroup;
  sharedChoiceList;
  isLoadingSave: any;
  selectedChoices = [];
  choiceLibraryIds: any = [];
  SHAREDCHOICESERVER_URL: any;
  ADDCHOICE_URL: any;
  checkServerURL: any;
  filteredChoices: any[];
  validationMsg: any;
  searchChoiModel: any;
  @ViewChild('multiSelect', { static: false }) private selectionList: MatSelectionList;
  selectedId:any
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private addChoiceRef: MatDialogRef<TemplateDashboardComponent>,
    private commonService: AwsCommonService,
    private toastorService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.validationMsg = this.appConstants.validationMsg;
    this.checkServerURL = this.appConstants.checkServerURL;
    if (this.checkServerURL) {
      this.SHAREDCHOICESERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedChoiceListParam}`;
      this.ADDCHOICE_URL = `${this.appConstants.serverUrl}/${this.appConstants.templateAddParam}/${this.appConstants.templateAddChoice}`
    } else {
      this.SHAREDCHOICESERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedChoiceListParam}`;
    }
  }

  ngOnInit() {
    this.getSharedChoiceList();
    this.addChoiceForm = new FormGroup({
      surveyTemplateId: new FormControl(this.data.surveyTemplateId),
      sharedChoiceId: new FormControl('', Validators.required),
      templateQuestionChoiceId:new FormControl(this.data.templateQuestionChoiceId),
      choiceSharedListId: new FormControl(this.data.choicesharedListId),
      sharedListId: new FormControl(this.data.sharedListId)
    });
  }

  getSharedChoiceList() {
    this.isLoadingSave = true;
    this.commonService.getRequest(this.SHAREDCHOICESERVER_URL, 'GET')
    .pipe(takeUntil(this.destroy$))
    .subscribe(data => {
    if (data) {
      this.isLoadingSave = false;
      this.sharedChoiceList = data;
      this.sharedChoiceList.forEach(element => {
        element.isselected = false;
      });
    }
  },
  (error) => {
    this.isLoadingSave = false;
    this.toastorService.warn('Oops!!! The Server was unable to complete your request');
  });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.addChoiceForm.controls[controlName].hasError(errorName);
  }

  addSharedChoice() {
    if (this.addChoiceForm.invalid) {
      this.isLoadingSave = false;
    } else {
      this.isLoadingSave = true;
      this.commonService.postRequest(this.ADDCHOICE_URL, 'POST', this.addChoiceForm.value)
      .pipe(takeUntil(this.destroy$))
        .subscribe(

          (res) => {
            this.isLoadingSave = false;
            this.toastorService.success('Choice Added Successfully!');
            this.addChoiceRef.close(res);
            },
          (error) => {
            this.toastorService.warn('Error' + error.message);
            this.isLoadingSave = false;
            this.addChoiceRef.close();
          },
          () => {
         

          }
        );

      event.preventDefault();
    }
  }

 
  onAreaListControlChanged(sharedChoices, option) {
    this.selectionList.selectedOptions = new SelectionModel<MatListOption>(true);
    const found = this.selectedChoices.some(el => el.choiceSharedListId === option.choiceSharedListId);
    if (!found) {
      this.selectedChoices.push(option);
      this.choiceLibraryIds.push(option.choiceSharedListId);
      this.addChoiceForm.patchValue({ sharedChoiceId: this.choiceLibraryIds });
    } else {
      this.onRemove(option);
    }
  
    return this.sharedChoiceList;

  }
  getSomeClass(option){
    const found=this.selectedChoices.some(el => el.choiceSharedListId === option.choiceSharedListId);
 if(found){
  return {createQuesselected:found};
 }
 else{
   return{removeBg:!found};
 }
    
  }
  onRemove(item) {
  let index = this.selectedChoices.findIndex(x=> x.choiceSharedListId===item.choiceSharedListId);
    this.selectedChoices.splice(index,1);
let ChoiceIds=this.selectedChoices.map(x=>x.choiceSharedListId);
this.choiceLibraryIds =ChoiceIds;
    this.addChoiceForm.patchValue({ sharedChoiceId: ChoiceIds });
  }

  openLink(event: MouseEvent): void {
    this.addChoiceRef.close();
    event.preventDefault();
  }
  ngOnDestroy(): void {
   // throw new Error("Method not implemented.");
   this.destroy$.next(true);
   this.destroy$.unsubscribe();
  }

}
