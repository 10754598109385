import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-add-choice',
  template: `
  <div class="confirm-modal">
  <p> Are you sure you want to delete the {{data}}? </p>
  <button mat-raised-button color="primary" (click)="onConfirm()">Yes</button>
  <button type="button" mat-raised-button color="warn" mat-dialog-close>Cancel</button>
</div>`
})
export class ChoiceConfirmModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ChoiceConfirmModalComponent>, 
    @Inject(MAT_DIALOG_DATA) public data:any ) { 
     // console.log('test model', data);
    }

  ngOnInit() {
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
