import { Component, OnInit, Directive, Input, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppConfig } from 'src/app/app.config';
import { Subject } from 'rxjs';
import { MatAccordion, MatDialog, MatTableDataSource, MatRadioChange } from '@angular/material';
import { InputSheetPilotCreate, InputSheetPilotUpdate, InputSheetPilotAttrSection, InputSheetPilotCharSection, InputSheetPilotActSection, InputSheetPilotRelSection, InputSheetPilotStatSection, InputSheetPilotStoSection, InputSheetPilotViewSection, InputsheetPilotQuesSection, PilotHeader } from 'src/app/models/inputsheetpilot';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { CommunicatingComponentService } from 'src/app/modules/survey/service/communicating-component.service';
import { InputService } from 'src/app/services/inputsheet.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { NotificationService } from 'src/app/services/notification.service';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { takeUntil } from 'rxjs/operators';
import { ConfirmModalComponent } from '../../layout/confirm-modal.component';
import data from '../../../../../assets/json/vendorData';

@Directive({
  selector: '[editable]'
})
export class EditableDirectiveOthers {
  @Input() keysban: number[];

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    // check banned key
    if (this.keysban) {
      if (this.keysban.indexOf(event.keyCode) > -1) {
        event.preventDefault();
      }
    }
    //console.log(event.keyCode);
  }

  constructor() {

   }

}

@Component({
  selector: 'app-others-create',
  templateUrl: './others-create.component.html',
  styleUrls: ['./others-create.component.scss'],
  providers: [DatePipe]
})
export class OthersCreateComponent implements OnInit, OnDestroy {
  appConstants = AppConfig.settings;
  destroy$: Subject<boolean> = new Subject<boolean>();
  createTitle: any;
  array = ['Male', 'Female'];
  // notSaved: boolean = true;
  // showSelected: boolean = false;
  generated: boolean = false
  spinnerMTemplate: boolean = false;
  // isReadonly = true;

  @ViewChild('accordion', {static: true}) Accordion: MatAccordion;
  openall: boolean = false;
  panelOpenState = false;

  toggle: boolean = true;
  initialSelection = [];
  allowMultiSelect = true;
  checkedbox: boolean = true;

  createInputPilot: InputSheetPilotCreate;
  updateInputPilot: InputSheetPilotUpdate;
  inputSheetId: any;

  attributeList: any = [];
  attributeSelection = new SelectionModel<InputSheetPilotAttrSection>(this.allowMultiSelect, this.initialSelection);
  charactersList: InputSheetPilotCharSection[] = [{ rowid: null, poschar: null, name: '', description: '', gender: '', lead: null, noOpenEnd: null, upDateFlag: true }];
  actorNameList: InputSheetPilotActSection[] = [{ rowid: null, posact: 1, actorname: '', gender: '', upDateFlag: true }];
  relationshipsList: InputSheetPilotRelSection[] = [{ rowid: null, posrel: 1, relationships: '', upDateFlag: true }];
  statementList: InputSheetPilotStatSection[] = [];
  storyelemList: InputSheetPilotStoSection[] = [{ rowid: null, posstoryelem: 1, storyelements: '', upDateFlag: true }];
  viewershipList: InputSheetPilotViewSection[] = [{ rowid: null, posview: 1, viewShowTitle: '', upDateFlag: true }];
  questionstimList: InputsheetPilotQuesSection[] = [{ rowid: null, posques: 1, quesnstim: '', upDateFlag: true }];

  attrSave: boolean = false;
  charSave: boolean = false;
  actSave: boolean = false;
  relSave: boolean = false;
  statementSave: boolean = false;
  stosSave: boolean = false;
  viewsSave: boolean = false;
  quessSave: boolean = false;

  submittedForm1: boolean = false;
  submittedForm2: boolean = false;
  submittedForm3: boolean = false;
  submittedForm4: boolean = false;
  submittedForm5: boolean = false;
  submittedForm6: boolean = false;
  submittedForm7: boolean = false;
  submittedForm8: boolean = false;

  //this.createHeaderPilot.inputSheetStatus = {};
  //dataSoruceHeader = new MatTableDataSource<Header>(HeaderElement);
  //displayedColumnsHeader: string[] = ['showtitle', 'analyst', 'genre', 'roughcut', 'version', 'createddate'];
  pilotDateVal: string;
  programmerNotes: FormControl = new FormControl(null, [
    Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]);
    testDate = new FormControl();
    vendor =new FormControl('',Validators.required)
  headerCreateForm: FormGroup;
  headerCreateList: PilotHeader[] = [{
    genre: '',
    showTitle: '',
    episodeNumber: null,
    analyst: '',
    roughCut: '',
    version: ''
  }]

  pilotAddHeader: ['genre', 'showTitle', 'analyst', 'roughCut', 'version']

  formErrors: any;
  dataSourceHeaderCreate;
  showTitleList: any = [];
  genreList: any = [];
  versionList: any = [];
  displayedColumnsHeaderCreate: string[] = ['genre', 'showTitle', 'episodeNumber', 'analyst', 'roughCut', 'version'];

//   public vendorData:any = [
//     { vendorId:11374,vendorName:'Brilliant Orlando FL'},
//     { vendorId:20931,vendorName:'Diamond group'},
//     { vendorId:18020,vendorName:'E - POLL hello'},
//     { vendorId:17688,vendorName:'MRC'},
//     { vendorId:19062,vendorName:'Q-Score'},
//     { vendorId:20934,vendorName:'NRG'}

// ];
    
// public vendorData:any = data;
//vendor=new FormControl('',Validators.required);
  section1Form: FormGroup;
  dataSource1;
  displayedColumns1: string[] = ['posattr', 'attributes', 'select'];
  selection = new SelectionModel<InputSheetPilotAttrSection>(true, []);

  section2Form: FormGroup;
  dataSource2;
  displayedColumns2: string[] = ['poschar', 'name', 'description', 'gender', 'lead', 'noOpenEnd', 'columndelete'];

  section3Form: FormGroup;
  dataSource3;
  displayedColumns3: string[] = ['posact', 'actorname', 'gender', 'columndelete'];

  section4Form: FormGroup;
  dataSource4;
  displayedColumns4: string[] = ['posrel', 'relationships', 'columndelete'];

  section6Form: FormGroup;
  dataSource6;
  displayedColumns6: string[] = ['posstoryelem', 'storyelements', 'columndelete'];

  section5Form: FormGroup;
  dataSource5;
  displayedColumns5: string[] = ['posstat', 'statements', 'columndelete'];

  section7Form: FormGroup;
  dataSource7;
  displayedColumns7: string[] = ['posview', 'viewShowTitle', 'columndelete'];

  section8Form: FormGroup;
  dataSource8;
  displayedColumns8: string[] = ['posques', 'quesnstim', 'columndelete'];
  selectedVendorVendor: any;
  vendorSelected: any;
  vendorData: { "vendorId": number; "vendorName": string; }[];
  selectedVendor: any;

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource1.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    //console.log('12')
    if (this.isAllSelected()) {
      this.selection.clear();
      this.dataSource1.data.forEach(
        row => {
          row.select = false;
          row.upDateFlag = true;
        }
      );
    } else {
      this.dataSource1.data.forEach(row => {
        this.selection.select(row);
        row.select = true;
        row.upDateFlag = true;
      }
      );
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: InputSheetPilotAttrSection): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.posattr + 1}`;
  }

  isCheckSelected(event, row) {
    //console.log(event);
    //console.log(row)
    this.selection.toggle(row);
    if (event.checked) {
      row.select = true;
      row.upDateFlag = true;
    } else {
      row.select = false;
      row.upDateFlag = true;
    }
    this.refreshSelectedLength();
  }

  refreshSelectedLength() {
    let selectedLength = 0;
    this.attributeList.map(item => {
    item.select == true ? selectedLength += 1 : selectedLength = selectedLength;
    })
    this.selection.selected.length = selectedLength;
  }

  constructor(
    private communicatingComponentService: CommunicatingComponentService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private inputService: InputService,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: AwsCommonService,
    private toastorService: NotificationService,
    private datePipe: DatePipe

  ) {
     
    this.createTitle = this.appConstants.inputsheetHeader.createTitle;

    this.headerCreateForm = this.fb.group({
      genre: new FormControl(null,  Validators.compose([
        Validators.required        
      ])),
      showTitle: new FormControl(null,  Validators.compose([
        Validators.required
            ])),
      episodeNumber: new FormControl(null,  Validators.compose([
        Validators.required        
      ])),
      analyst: new FormControl(null,  Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField
      ])),
      roughCut: new FormControl(null,  Validators.compose([
        Validators.required,
      ])),
      version: new FormControl(null,  Validators.compose([
        Validators.required,
      ])),
      vendor :new FormControl(null,Validators.compose([
        Validators.required,
      ]))
      
    })

    this.formErrors = {
      genre: {},
      showTitle: {},
      analyst: {},
      roughCut: {},
      version: {}
    }

    // this.showSelected = false;

    this.section1Form = this.fb.group({
      section1Array: this.fb.array([])
    });

    this.section2Form = this.fb.group({
      section2Array: this.fb.array([
        this.fb.group({
          name: new FormControl('', [
            Validators.required, 
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ]),
          description: new FormControl('', [
            Validators.required, 
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ]),
          gender: new FormControl('', [Validators.required]),
        })
      ]),
      lead: new FormControl(null),
      onOpenEnd: new FormControl(null)
    });

    this.section3Form = this.fb.group({
      section3Array: this.fb.array([this.fb.group({
        actorname: new FormControl('', [
          Validators.required, 
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
          validateEmptyField
        ]),
        gender: new FormControl('', [Validators.required]) 
      })]),
      
    });

    this.section4Form = this.fb.group({
      section4Array: this.fb.array([this.fb.group({
        relationships: new FormControl('', [
          Validators.required, 
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
          validateEmptyField
        ]) 
      })])
    });

    this.section5Form = this.fb.group({
      section5Array: this.fb.array([])
    });

    this.section6Form = this.fb.group({
      section6Array: this.fb.array([this.fb.group({
        storyelements: new FormControl('', [
          Validators.required, 
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
          validateEmptyField
        ]) 
      })])
    });

    this.section7Form = this.fb.group({
      section7Array: this.fb.array([this.fb.group({
        viewShowTitle: new FormControl('', [
          Validators.required, 
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
          validateEmptyField
        ]) 
      })])
    });

    this.section8Form = this.fb.group({
      section8Array: this.fb.array([this.fb.group({
        quesnstim: new FormControl('', [
          Validators.required, 
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
          validateEmptyField
        ]) 
      })])
    });

    this.sendMessage();
  }
  sendMessage() {
    this.communicatingComponentService.sendCommunication(true);
  }
  clearMessage() {
    this.section1Form.reset();
    this.section2Form.reset();
    this.section3Form.reset();
    this.section4Form.reset();
    this.section5Form.reset();
    this.section6Form.reset();
    this.section7Form.reset();
    this.section8Form.reset();
    this.communicatingComponentService.sendCommunication(false);
    this.router.navigate(['../others'], { relativeTo: this.route });
  }



  onFormValuesChanged() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      // Clear previous errors
      this.formErrors[field] = {};
      // Get the control
      const control = this.headerCreateForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }

  ngOnInit() {
    this.dataSourceHeaderCreate = new MatTableDataSource(this.headerCreateList);
    this.dataSource1 = new MatTableDataSource<InputSheetPilotAttrSection>(this.attributeList);
    this.dataSource2 = new MatTableDataSource<InputSheetPilotCharSection>(this.charactersList);
    this.dataSource3 = new MatTableDataSource<InputSheetPilotActSection>(this.actorNameList);
    this.dataSource4 = new MatTableDataSource<InputSheetPilotRelSection>(this.relationshipsList);
    this.dataSource6 = new MatTableDataSource<InputSheetPilotStoSection>(this.storyelemList);
    this.dataSource5 = new MatTableDataSource<InputSheetPilotStatSection>(this.statementList);
    this.dataSource7 = new MatTableDataSource<InputSheetPilotViewSection>(this.viewershipList);
    this.dataSource8 = new MatTableDataSource<InputsheetPilotQuesSection>(this.questionstimList);
   this.spinnerMTemplate=true;
   this.vendorData =data;
   console.log(data);
    this.commonService.getRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/showprojectgenres`,
      'GET')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          //console.log(data);
          this.genreList = data;
          this.spinnerMTemplate=false;
        },
        error => {
          //console.log('show project genres');
          this.toastorService.warn(error);
          this.spinnerMTemplate=false;
        }
      );

    this.commonService.getRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/titles`,
      'GET')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          //console.log(data);
          this.versionList = data;
          this.spinnerMTemplate=false;
        },
        error => {
          //console.log('show title version');
          this.toastorService.warn(error);
          this.spinnerMTemplate=false;
        }
      );

    this.headerCreateForm.valueChanges.subscribe(() => {
      this.onFormValuesChanged();
    });

  }

  // public hasError = (controlName: string, errorName: string) => {
    
  //   return this.section1Form.controls[controlName].hasError(errorName);
  // }
  onSelectedVendor(e){
   
    this.selectedVendor=e.value;
  }
  onSelectedGenre(e) {
    this.spinnerMTemplate = true;
    //console.log(e);
    let selectedGenre
    this.genreList.forEach(el => {
      if (el.projectTypeId == e.value) {
        //console.log(el.projectTypeId);
        selectedGenre = el.projectType;
      }
    })
    //console.log(selectedGenre);
    this.commonService.getRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/showtitles/?genretype=${selectedGenre}`,
      'GET')
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        //console.log(data);
        this.showTitleList = data;
        this.spinnerMTemplate = false;
      }, error => {
        this.toastorService.warn(error)
      this.spinnerMTemplate = false;
      } 
      );
  }

  // ToggleButton() {
  //   this.showSelected = !this.showSelected;
  // }

  getAttributes() {
    this.spinnerMTemplate=true;
    let attributes = [];
    let data = [];
    // const getAttr = {
    //   params: new HttpParams()
    //     .set('inputSheetId', this.inputSheetId)
    //     .append('sectionName', 'ATTRIBUTE LIST')
    //     .append('sectiontype', 'PILOT')
    //   } 
    const getAttr = { 'inputSheetId' : this.inputSheetId, 'sectionName':'ATTRIBUTE LIST', 'sectiontype': 'PILOT'} ;
    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/sections`,
      'GET', getAttr)
      .pipe(takeUntil(this.destroy$))
      .subscribe(chardata => {
        //console.log(chardata);
        this.spinnerMTemplate=false;
        attributes = chardata;
        attributes = attributes.map(mapdata => {
          let check;
          let metaFlag;
          mapdata.attributeCheck == 'Y' ? check = true : check = false;
          mapdata.createdBy == 'METADATA' ? metaFlag = false : metaFlag = true
          data.push({
            rowid: mapdata.inputSheetUserSectionId,
            posattr: mapdata.sheetQuestion.questionSequenceNum,
            attributes: mapdata.sheetQuestion.questionLongText,
            select: check,
            sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
            metaDataFlag: metaFlag,
            upDateFlag: mapdata.upDateFlag
          });
          const ArrayData = this.fb.group({
            attributes: new FormControl(mapdata.sheetQuestion.questionLongText, [
              Validators.required, 
              Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
              validateEmptyField
            ])
         });
         (this.section1Form.get('section1Array') as FormArray).push(ArrayData);
         
        })
        //console.log(this.section1Form.value);
        this.attributeList = data;
        this.selection.selected.length = this.attributeList.length;
        this.refreshSelectedLength();
        //console.log(this.attributeList);
        this.dataSource1 = new MatTableDataSource(this.attributeList);
        this.spinnerMTemplate=false;
      }, error => {
        this.toastorService.warn(error.message);
        this.spinnerMTemplate=false;
      })
  }

  getStatements() {
    this.spinnerMTemplate=true;
    let statements = [];
    let data = [];
    // const getStat = {
    //   params: new HttpParams()
    //     .set('inputSheetId', this.inputSheetId)
    //     .append('sectionName', 'STATEMENT')
    //     .append('sectiontype', 'PILOT')
    //   } 
      const getStat = { 'inputSheetId' : this.inputSheetId, 'sectionName':'STATEMENT', 'sectiontype': 'PILOT'} ;
    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/sections`,
      'GET', getStat)
      .pipe(takeUntil(this.destroy$))
      .subscribe(chardata => {
        this.spinnerMTemplate=false;
        //console.log(chardata);
        statements = chardata;
        statements = statements.map(mapdata => {
          let metaFlag;
          mapdata.createdBy == 'METADATA' ? metaFlag = false : metaFlag = true;
          data.push({
            rowid: mapdata.inputSheetuserSectionId,
            posstat: mapdata.sheetQuestion.questionSequenceNum,
            statements: mapdata.sheetQuestion.questionLongText,
            sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
            metaDataFlag: metaFlag
          });
          const ArrayData = this.fb.group({
            statements: new FormControl(mapdata.sheetQuestion.questionLongText, [
              Validators.required, 
              Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
              validateEmptyField
            ])
         });
         (this.section5Form.get('section5Array') as FormArray).push(ArrayData);
         //console.log(this.section5Form.value);
        })

        this.statementList = data;
        //console.log(this.statementList);
        this.dataSource5 = new MatTableDataSource<InputSheetPilotStatSection>(this.statementList);
        this.spinnerMTemplate=false;
      }, error => {
        this.toastorService.warn(error.message);
        this.spinnerMTemplate=false;
      })
  }


  getInitAttributes() {
    this.spinnerMTemplate = true;
    let attributes = [];
    let data = [];
    
    // const getInitAttr = {
    //   params: new HttpParams()
    //     .set('sectionName', 'ATTRIBUTE LIST')
    //   } 
    const getInitAttr =  { 'sectionName' : 'ATTRIBUTE LIST'};
    // this.spinnerMTemplate= true;
    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/questions`,
      'GET', getInitAttr)
      .pipe(takeUntil(this.destroy$))
      .subscribe(attrdata => {
        //console.log(attrdata);
        attributes = attrdata;
        attributes = attributes.map(mapdata => {
          data.push({
            sheetQuestionId: mapdata.inputSheetQuestionsId,
            posattr: mapdata.questionSequenceNum,
            attributes: mapdata.questionLongText,
            select: true
          });
          const ArrayData = this.fb.group({
            attributes: new FormControl(mapdata.questionLongText, [
              Validators.required, 
              Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
              validateEmptyField
            ])
         });
         (this.section1Form.get('section1Array') as FormArray).push(ArrayData);
        })
        this.attributeList = data;
        // this.attributeList = this.isReadonly = true;
        //console.log(this.attributeList);
        //console.log(this.attributeList.length);
        this.selection.selected.length = this.attributeList.length;
        this.dataSource1 = new MatTableDataSource(this.attributeList);
        this.spinnerMTemplate = false;
      }, error => {
        this.toastorService.warn(error)
        this.spinnerMTemplate = false;
      }
      );

  }

  getInitStatements() {
    this.spinnerMTemplate = true;
    let statement = [];
    let data = [];
    
    // const getInitStat = {
    //   params: new HttpParams()
    //     .set('sectionName', 'STATEMENT')
    //   } 
    const getInitStat =  { 'sectionName' : 'STATEMENT'};
    // this.spinnerMTemplate= true;
    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/questions`,
      'GET', getInitStat)
      .pipe(takeUntil(this.destroy$))
      .subscribe(attrdata => {
        //console.log(attrdata);
        statement = attrdata;
        statement = statement.map(mapdata => {
          data.push({
            sheetQuestionId: mapdata.inputSheetQuestionsId,
            posstat: mapdata.questionSequenceNum,
            statements: mapdata.questionLongText,
            select: true
          });
          const ArrayData = this.fb.group({
            statements: new FormControl(mapdata.questionLongText, [
              Validators.required, 
              Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
              validateEmptyField
            ])
         });
         (this.section5Form.get('section5Array') as FormArray).push(ArrayData);
        })
        this.statementList = data;
        //console.log(this.statementList);
        this.dataSource5 = new MatTableDataSource<InputSheetPilotStatSection>(this.statementList);
        this.spinnerMTemplate = false;
      }, error => {
        this.toastorService.warn(error);
        this.spinnerMTemplate = false;
      });
  }

  createNewPilot(pilotStatus) {
    if (!this.headerCreateForm.valid ) {
      return;
    } else {
      this.spinnerMTemplate = true;
      let inputSheetId;
      let newPilot;
      let status;
      this.inputSheetId ? inputSheetId = this.inputSheetId : inputSheetId = null
      pilotStatus == 'DRAFT' ? status = 'DRAFT' : status = 'SUBMITTED'
      this.pilotDateVal = this.datePipe.transform(this.testDate.value, 'yyyy/MM/dd');
      
      newPilot = {
        inputSheetId: inputSheetId,
        inputSheetType: {
          inputSheetType: "Others",
        },
        surveyAnalyst: this.headerCreateForm.controls.analyst.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"'),
        programmerNotes: this.programmerNotes.value ? this.programmerNotes.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"') : this.programmerNotes.value,
        testDate: this.pilotDateVal,
        vendor:this.selectedVendor,
        inputSheetStatus: {
          inputSheetStatus: status
        },
        inputSheetPilot: [{
          primeShowTitle: {
            primeShowTitleId: this.headerCreateForm.controls.showTitle.value,
            projectType: {
              c2027ProjectTypeId: this.headerCreateForm.controls.genre.value
            },
          },
          inputSheetType: {
            inputSheetType: "Others",
          },
          roughCut: this.headerCreateForm.controls.roughCut.value,
          episode: this.headerCreateForm.controls.episodeNumber.value,
          titleVersion: {
            titleVersionId: this.headerCreateForm.controls.version.value
          },
        }],      
      }
      if (!this.generated) {
        //console.log('create pilot');
        this.createInputPilot = newPilot      
        //console.log(this.createInputPilot);
        this.savePilot();
      } else {
        this.updateInputPilot = newPilot
        //console.log(this.updateInputPilot);
        this.updatePilot(status);
      }
    }
  }

  savePilot() {
    this.commonService.postRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}`, 'POST', this.createInputPilot)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        //console.log(result);
        this.inputSheetId = result;
        this.toastorService.success('Generated Successfully');
        this.spinnerMTemplate = false;
        //console.log(this.inputSheetId);
        this.generated = true;
        this.getAttributes();
        this.getStatements();
        // this.getInitAttributes();
        // this.getInitStatements();
      }, error => {
        this.spinnerMTemplate = false;
        this.toastorService.warn(error.message);
      });
  }

  updatePilot(status) {
    this.spinnerMTemplate=true;
    //console.log(status);
    this.commonService.putRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}`, 'PUT', this.updateInputPilot)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        //console.log(result);
        if(status == 'DRAFT') {
          this.toastorService.success('Generated Successfully');
        } else {
          this.toastorService.success('Submitted Successfully');
          this.router.navigate(['../others'], { relativeTo: this.route });
        }
        this.spinnerMTemplate = false;
      }, error => {
        this.toastorService.warn(error.message);
        this.spinnerMTemplate = false;
      });
  }

  //Confirmation PopUP

  openconfirmModal(type: any, id: any): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, { panelClass: 'confirmModal', disableClose: true, data: { msg: (type === 'cancel') ? 'cancel the changes' : (type=='Submit')?'submit? All the unsaved changes will be lost':'' } });
    dialogRef.afterClosed().subscribe(dialogResult => {
      //console.log(dialogResult);
      if (dialogResult === 'edit') {
        this.router.navigate(['../others'], { relativeTo: this.route });
      }
      if(dialogResult==='Submit'){
        this.createNewPilot(type);
      }
      // else {
      //   //console.log(this.clearMessage)
      // }
    });
  }


  /** Section 1 - Add, Delete and Save method **/

  onAddattrRow() {
    let newRow = {
      rowid: null, posattr: (this.attributeList[this.attributeList.length - 1].posattr + 1),
      attributes: '', select: true, metaDataFlag: true, upDateFlag: true
    };
    const row = this.fb.group({
      attributes: new FormControl(null, [Validators.required, 
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField])
    });
    (this.section1Form.get('section1Array') as FormArray).push(row);

    this.attributeList.push(newRow);
    this.refreshSelectedLength();
    this.dataSource1 = new MatTableDataSource(this.attributeList);
    //console.log(this.attributeList);
    this.dataSource1._updateChangeSubscription();
  }

  updateAttrVal(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.attributes = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');;
    // ele.attributes.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  attributeSave(formValue) {
    if(formValue.invalid) {
      this.submittedForm1 = true;
      return false;
    } else {
      this.spinnerMTemplate = true;
      //console.log(this.attributeList);
      let attributesSave;
      //console.log(this.attributeList);
  
      attributesSave = this.attributeList.map(item => {
        let select;
        let createdBy;
        // let rowId;
        item.select == true ? select = 'Y' : select = 'N';
        item.metaDataFlag == false ? createdBy = 'METADATA' : createdBy = null;
        // item.rowid != null || '' ? rowId = item.rowid : rowId = null
        return {
          inputSheetUserSectionId: item.rowid,
          attributeCheck: select,
          sequence: item.posattr,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 601,
            sequenceNumber: 1,
            sectionName: 'ATTRIBUTE LIST',
            sectionGroup: ""
          },
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          sheetQuestion: {
            inputSheetQuestionsId: item.sheetQuestionId,
            questionLongText: item.attributes,
            questionSequenceNum: item.posattr
          },
          createdBy: createdBy,
          upDateFlag: item.upDateFlag
        }
      })
  
      // if (!this.attrSave) {
      //   //console.log(attributesSave);
      //   this.commonService.postRequest(`
      //   ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/promosection`, 'POST', attributesSave)
      //   .pipe(takeUntil(this.destroy$))  
      //   .subscribe(result => {
      //       //console.log(result);
      //       this.spinnerMTemplate=false;
      //       this.attrSave = true;
      //       this.loadAttributes(result);
      //       //this.getAttributes();
      //       this.toastorService.success('Saved Successfully');
      //     }, error => {
      //       this.toastorService.warn(error.message);
      //       this.spinnerMTemplate = false;
      //     });
      // } else {
        // console.log(attributesSave);
        this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', attributesSave)
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            //console.log(result);
            this.loadAttributes(result);
            this.spinnerMTemplate = false;
            this.toastorService.success('Saved Successfully');
           // this.getAttributes();
          }, error => {
            this.spinnerMTemplate = false;
            this.toastorService.warn(error.message);
          });
      }
    // }   
  }

  loadAttributes(attributes) {
    if(attributes == null) {
      this.onAddattrRow();
    } else {
      let data = [];
      // console.log(attributes);
      (this.section1Form.controls.section1Array as FormArray).clear();
      attributes = attributes.map(mapdata => {
        let check;
        let metaFlag;
        mapdata.attributeCheck == 'Y' ? check = true : check = false;
        mapdata.createdBy == 'METADATA' ? metaFlag = false : metaFlag = true
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          posattr: mapdata.sheetQuestion.questionSequenceNum,
          attributes: mapdata.sheetQuestion.questionLongText,
          select: check,
          sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
          metaDataFlag: metaFlag,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          attributes: new FormControl(mapdata.sheetQuestion.questionLongText, [
            Validators.required, 
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
       });
       (this.section1Form.get('section1Array') as FormArray).push(ArrayData);
       
      })
      //console.log(this.section1Form.value);
      this.attributeList = data;
      this.selection.selected.length = this.attributeList.length;
      this.refreshSelectedLength();
      //console.log(this.attributeList);
      this.dataSource1 = new MatTableDataSource(this.attributeList);
    }
    
  }

  /** Section 2 - Add, Delete and Save Method **/

  onAddcharRow() {
    let newRow = { rowid: null, poschar: (this.charactersList.length + 1), name: '', description: '', gender: '', lead: false, noOpenEnd: false,
      upDateFlag: true };
    this.charactersList.push(newRow);
    const row = this.fb.group({
      name: new FormControl(null, [Validators.required, 
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField]),
      description: new FormControl(null, [Validators.required, 
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
          validateEmptyField]),
      gender: new FormControl(null, [Validators.required])
    });
    (this.section2Form.get('section2Array') as FormArray).push(row);
    this.dataSource2 = new MatTableDataSource(this.charactersList);
    //console.log(this.charactersList);
    this.dataSource2._updateChangeSubscription();

  }

  deleteChar(elm, index) {
    // this.spinnerMTemplate = true;
    //console.log(elm);
    //console.log(this.charactersList);
    // elm.rowid == (null || '') ? this.spinnerMTemplate = false : this.spinnerMTemplate = true;
    this.charactersList.splice(index, 1);
    (this.section2Form.get('section2Array') as FormArray).removeAt(index);
    this.dataSource2 = new MatTableDataSource(this.charactersList);
    //console.log(this.charactersList)

    if(elm.rowid != null || '') {
      this.spinnerMTemplate = true;
      // const delChar = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      //   }
      const delChar = { 'inputSheetuserSectionId' : elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delChar)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
           this.spinnerMTemplate = false;
          this.toastorService.success('Deleted Successfully');
       //   this.getCharacters();
        }, error => {
          this.toastorService.warn(error.message);
          this.spinnerMTemplate = false;
        })
    }
  }

  updateCharName(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.name = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');;
    // ele.name.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  updateCharDes(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.description = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');;
    // ele.description.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  characterSave(formValue) {
    if(formValue.invalid) {
      this.submittedForm2 = true;
      return false;
    } else {
    this.spinnerMTemplate = true;
    //console.log(this.charactersList);
    //console.log(this.charactersList)
    let charactersSave = [];
    //console.log(this.charactersList);

    charactersSave = this.charactersList.map(item => {
      let maplead: any = false;
      let mapopenEnd: any = false;
      item.lead == true ? maplead = 'Y' : maplead = 'N';
      item.noOpenEnd == true ? mapopenEnd = 'Y' : mapopenEnd = 'N';

      return {
        inputSheetUserSectionId: item.rowid,
        name: item.name,
        description: item.description,
        gender: item.gender,
        lead: maplead,
        openEnd: mapopenEnd,
        sequence: item.poschar,
        sheetSectionMaster: {
          inputSheetSectionMasterId: 610,
          sequenceNumber: 2,
          sectionName: 'CHARECTERS',
          sectionGroup: ""
        },
        inputSheet: {
          inputSheetId: this.inputSheetId
        },
        upDateFlag: item.upDateFlag
      }
    })
    //console.log(charactersSave);

    if (!this.charSave) {
      this.commonService.postRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/promosection`, 'POST', charactersSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.charSave = true;
          this.loadCharacters(result);
          this.spinnerMTemplate = false;
          this.toastorService.success('Saved Successfully');
       //   this.getCharacters();
        }, error => {
          this.toastorService.warn(error.message);
          this.spinnerMTemplate = false;
        });
    } else {
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', charactersSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.loadCharacters(result);
          this.spinnerMTemplate = false;
          this.toastorService.success('Saved Successfully');
        //  this.getCharacters();
        }, error => {
          this.toastorService.warn(error.message);
          this.spinnerMTemplate = false;
        });
    }
    }
  }

  loadCharacters(characters) {
    if(characters == null) {
      this.onAddcharRow();
    } else {
      let data = [];
      (this.section2Form.controls.section2Array as FormArray).clear();
      characters = characters.map(mapdata => {
        let select;
        let mapnoOpenEnd;
        // let mapgender;
        mapdata.lead == 'Y' ? select = true : select = false;
        mapdata.openEnd == 'Y' ? mapnoOpenEnd = true : mapnoOpenEnd = false;
        // mapdata.gender == 'Male' ? mapgender = 'M' : mapgender = 'F';
  
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          name: mapdata.name,
          description: mapdata.description,
          gender: mapdata.gender,
          lead: select,
          noOpenEnd: mapnoOpenEnd,
          poschar: mapdata.sequence,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          name: new FormControl(mapdata.name, [
            Validators.required, 
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ]),
          description: new FormControl(mapdata.description, [
            Validators.required, 
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ]),
          gender: new FormControl(mapdata.gender, [Validators.required, validateEmptyField])
       });
       (this.section2Form.get('section2Array') as FormArray).push(ArrayData);
       //console.log(this.section2Form.value);
      })
  
      this.charactersList = data;
      //console.log(this.charactersList);
      this.dataSource2 = new MatTableDataSource<InputSheetPilotCharSection>(this.charactersList);
    }
    
  }  

  radioChange(ele, event: MatRadioChange) {
    //console.log(event);
    //console.log(event.value);
    //console.log(event.source);
    ele.gender = event.value;
    ele.upDateFlag = true;
  }

  onChange(ele, event) {
    //console.log(ele)
    //console.log(event)
    ele.lead = event.checked;
    ele.upDateFlag = true;
  }

  onChangeOpenend(ele, event) {
    //console.log(ele)
    //console.log(event)
    ele.noOpenEnd = event.checked;
    ele.upDateFlag = true;
  }

  /** Section 3 - Add, Delete and Save method **/

  onAddactRow() {
    let newRow = { rowid: null, posact: (this.actorNameList.length + 1), actorname: '', gender: '',
      upDateFlag: true };
    this.actorNameList.push(newRow);
    const row = this.fb.group({
      actorname: new FormControl(null, [Validators.required, 
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField]),
      gender: new FormControl(null, [Validators.required])
    });
    (this.section3Form.get('section3Array') as FormArray).push(row);
    this.dataSource3 = new MatTableDataSource(this.actorNameList);
    //console.log(this.actorNameList);
    this.dataSource3._updateChangeSubscription();
  }

  deleteAct(elm, index) {
    //  this.spinnerMTemplate = true;
    //console.log(elm);
    //console.log(this.actorNameList);
    // elm.rowid == (null || '') ? this.spinnerMTemplate = false : this.spinnerMTemplate = true
    this.actorNameList.splice(index, 1);
    (this.section3Form.get('section3Array') as FormArray).removeAt(index);
    this.dataSource3 = new MatTableDataSource(this.actorNameList);

    if(elm.rowid != null || '') {
      this.spinnerMTemplate = true;
      // const delAct = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      //   }
      const delAct = { 'inputSheetuserSectionId' : elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delAct)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.spinnerMTemplate = false;
          this.toastorService.success('Deleted Successfully');
        //  this.getActor();
        }, error => {
          this.toastorService.warn(error.message);
          this.spinnerMTemplate = false;
        })
    }
    //console.log(this.actorNameList)
    
  }

  updateActName(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.actorname = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');;
    // ele.actorname.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  actorSave(formValue) {
    if(formValue.invalid) {
      this.submittedForm3 = true;
      return false;
    } else {
    this.spinnerMTemplate = true;
    //console.log(this.actorNameList);
    //console.log(this.actorNameList)
    let actorsSave = [];
    //console.log(this.actorNameList);
    
    actorsSave = this.actorNameList.map(item => {
      // let rowId;
      // item.rowid != null || '' ? rowId = item.rowid : rowId = null
      return {
        inputSheetUserSectionId: item.rowid,
        name: item.actorname,
        gender: item.gender,
        sequence: item.posact,
        sheetSectionMaster: {
          inputSheetSectionMasterId: 611,
          sequenceNumber: 3,
          sectionName: 'ACTOR FAMILIARITY',
          sectionGroup: ""
        },
        inputSheet: {
          inputSheetId: this.inputSheetId 
        },
        upDateFlag: item.upDateFlag
      }
    })
    
    if (!this.actSave) {
      //console.log(actorsSave);
      this.commonService.postRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/promosection`, 'POST', actorsSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.actSave = true;
          this.loadActor(result);
          this.spinnerMTemplate = false;
          this.toastorService.success('Saved Successfully');
         // this.getActor();
        }, error => {
          this.toastorService.warn(error.message);
        });
    } else {
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', actorsSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.loadActor(result);
          this.spinnerMTemplate = false;
          this.toastorService.success('Saved Successfully');
        //  this.getActor();
        }, error => {
          this.toastorService.warn(error.message);
          this.spinnerMTemplate = false;
        });
    }
    }
  }

  loadActor(actors) {
    if(actors == null) {
      this.onAddactRow();
    } else {
      let data = [];
      (this.section3Form.controls.section3Array as FormArray).clear();
      actors = actors.map(mapdata => {
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          actorname: mapdata.name,
          gender: mapdata.gender,
          posact: mapdata.sequence,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          actorname: new FormControl(mapdata.name, [
            Validators.required, 
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ]),
          gender: new FormControl(mapdata.gender, [Validators.required, validateEmptyField])
       });
       (this.section3Form.get('section3Array') as FormArray).push(ArrayData);
       //console.log(this.section3Form.value);
      });
      this.actorNameList = data;
      //console.log(this.actorNameList);
      this.dataSource3 = new MatTableDataSource<InputSheetPilotActSection>(this.actorNameList);    
    }
    
  }

  radioChangeact(ele, event: MatRadioChange) {
    //console.log(event);
    //console.log(event.value);
    //console.log(event.source);
    ele.gender = event.value;
    ele.upDateFlag = true
  }

  /** Section 4 - Add, Delete and Save method **/

  onAddrelRow() {
    let newRow = { rowid: null, posrel: (this.relationshipsList.length + 1), relationships: null,
     upDateFlag: true };
    this.relationshipsList.push(newRow);
    const row = this.fb.group({
      relationships: new FormControl(null, [Validators.required, 
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField]),
    });
    (this.section4Form.get('section4Array') as FormArray).push(row);
    this.dataSource4 = new MatTableDataSource(this.relationshipsList);
    //console.log(this.relationshipsList);
    this.dataSource4._updateChangeSubscription();
  }

  deleteRel(elm, index) {
    //console.log(elm);
    //  this.spinnerMTemplate = true;
    //console.log(this.relationshipsList);
    // elm.rowid == (null || '') ? this.spinnerMTemplate = false : this.spinnerMTemplate = true;
    this.relationshipsList.splice(index, 1);
    (this.section4Form.get('section4Array') as FormArray).removeAt(index);
    this.dataSource4 = new MatTableDataSource(this.relationshipsList);

    if(elm.rowid != null || '') {
      this.spinnerMTemplate = true;
      // const delRel = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      //   } 
        const delRel = { 'inputSheetuserSectionId' : elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delRel)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
       //   this.getRelations();
          this.spinnerMTemplate = false;
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.toastorService.warn(error.message);
           this.spinnerMTemplate = false;
        })
    }
  }

  updateRel(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.relationships = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');;
    // ele.relationships.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  relationshipSave(formValue) {
    if(formValue.invalid) {
      this.submittedForm4 = true;
      return false;
    } else {
    //console.log(this.relationshipsList);
    //console.log(this.relationshipsList)
    let relsSave = [];
    this.spinnerMTemplate = true;
    //console.log(this.relationshipsList);
    relsSave = this.relationshipsList.map(item => {
      return {
        inputSheetUserSectionId: item.rowid,
        description: item.relationships,
        sequence: item.posrel,
        sheetSectionMaster: {
          inputSheetSectionMasterId: 612,
          sequenceNumber: 4,
          sectionName: 'RELATIONSHIPS',
          sectionGroup: ""
        },
        inputSheet: {
          inputSheetId: this.inputSheetId,
        },
        upDateFlag: item.upDateFlag
      }
    })

    if (!this.relSave) {
      //console.log(relsSave);
      this.commonService.postRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/promosection`, 'POST', relsSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.relSave = true;
          this.loadRelations(result);
          this.spinnerMTemplate = false;
          //this.getRelations();
          this.toastorService.success('Saved Successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.spinnerMTemplate = false;
        });
    } else {
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', relsSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.loadRelations(result);
       //   this.getRelations();
          this.spinnerMTemplate = false;
          this.toastorService.success('Saved Successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.spinnerMTemplate = false;
        });
    }
    }
  }

  loadRelations(relationships) {
    if(relationships == null) {
      this.onAddrelRow();
    } else {
      let data = [];
      (this.section4Form.controls.section4Array as FormArray).clear();
      relationships = relationships.map(mapdata => {
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          relationships: mapdata.description,
          posrel: mapdata.sequence,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          relationships: new FormControl(mapdata.description, [
            Validators.required, 
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
       });
       (this.section4Form.get('section4Array') as FormArray).push(ArrayData);
       //console.log(this.section4Form.value);
      });
      this.relationshipsList = data;
      //console.log(this.relationshipsList);
      this.dataSource4 = new MatTableDataSource<InputSheetPilotRelSection>(this.relationshipsList);
    }
    
  }

  /** Section 5 - Add, Delete and Save method **/

  onAddStatRow() {
    let newRow = { rowid: null, posstat: (this.statementList.length + 1), statements: null, 
        sheetQuestionId: null, metaDataFlag: true, upDateFlag: true };
    this.statementList.push(newRow);
    const row = this.fb.group({
      statements: new FormControl(null, [Validators.required, 
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField]),
    });
    (this.section5Form.get('section5Array') as FormArray).push(row);
    this.dataSource5 = new MatTableDataSource(this.statementList);
    //console.log(this.statementList);
    this.dataSource5._updateChangeSubscription();
  }

  deleteStat(elm, index) {
    // this.spinnerMTemplate = true;
    //console.log(elm);
    //console.log(this.statementList);
    // elm.rowid == (null || '') ? this.spinnerMTemplate = false : this.spinnerMTemplate = true;
    this.statementList.splice(index, 1);
    (this.section5Form.get('section5Array') as FormArray).removeAt(index);
    this.dataSource5 = new MatTableDataSource(this.statementList);

    if(elm.rowid != null || '') {
      this.spinnerMTemplate = true;
      // const delStat = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      //   }
        const delStat = { 'inputSheetuserSectionId' : elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delStat)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
       //   this.getStatements();
          this.spinnerMTemplate = false;
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.spinnerMTemplate = false;
        })
    }
  }

  updateStat(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.statements = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');;
    // ele.statements.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  statSave(formValue) {
    if(formValue.invalid) {
      this.submittedForm5 = true;
      return false;
    } else {
    //console.log(this.statementList);
    //console.log(this.statementList);
    let statsSave = [];
    //console.log(this.statementList);
    this.spinnerMTemplate = true;
    //console.log(statsSave);
    statsSave = this.statementList.map(item => {
      let createdBy;
      item.metaDataFlag == false ? createdBy = 'METADATA' : createdBy = null;
      return {
        inputSheetUserSectionId: item.rowid,
        description: item.statements,
        sequence: item.posstat,
        sheetSectionMaster: {
          inputSheetSectionMasterId: 602,
          sequenceNumber: 5,
          sectionName: 'STATEMENT',
          sectionGroup: ""
        },
        inputSheet: {
          inputSheetId: this.inputSheetId
        },
        sheetQuestion: {
          inputSheetQuestionsId: item.sheetQuestionId,
          questionLongText: item.statements,
          questionSequenceNum: item.posstat
        },
        createdBy: createdBy,
        upDateFlag: item.upDateFlag
      }
    })

    // if (!this.statementSave) {
    //   this.commonService.postRequest(`
    //     ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/promosection`, 'POST', statsSave)
    //     .pipe(takeUntil(this.destroy$))
    //     .subscribe(result => {
    //       //console.log(result);
    //       this.loadStatements(result);
    //       this.statementSave = true;
    //       this.spinnerMTemplate = false;
    //      // this.getStatements();
    //       this.toastorService.success('Saved Successfully');
    //     }, error => {
    //       this.toastorService.warn(error.message);
    //       this.spinnerMTemplate = false;
    //     });
    // } else {
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', statsSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.loadStatements(result);
          // this.getStatements();
          this.spinnerMTemplate = false;
          this.toastorService.success('Saved Successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.spinnerMTemplate = false;
        });
    }
    // }
  }

  loadStatements(statements) {
    if(statements == null) {
      this.onAddStatRow();
    } else {
      let data =[];
      (this.section5Form.controls.section5Array as FormArray).clear();
      statements = statements.map(mapdata => {
        let metaFlag;
        mapdata.createdBy == 'METADATA' ? metaFlag = false : metaFlag = true;
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          posstat: mapdata.sheetQuestion.questionSequenceNum,
          statements: mapdata.sheetQuestion.questionLongText,
          sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
          metaDataFlag: metaFlag,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          statements: new FormControl(mapdata.sheetQuestion.questionLongText, [
            Validators.required, 
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
       });
       (this.section5Form.get('section5Array') as FormArray).push(ArrayData);
       //console.log(this.section5Form.value);
      })
  
      this.statementList = data;
      //console.log(this.statementList);
      this.dataSource5 = new MatTableDataSource<InputSheetPilotStatSection>(this.statementList);
    }
    
  }

  /** Section 6 - Add, Delete and Save method **/

  onAddstoryelemRow() {
    let newRow = { rowid: null, posstoryelem: (this.storyelemList.length + 1), storyelements: null,
      upDateFlag: true };
    this.storyelemList.push(newRow);
    const row = this.fb.group({
      storyelements: new FormControl(null, [Validators.required, 
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField]),
    });
    (this.section6Form.get('section6Array') as FormArray).push(row);
    this.dataSource6 = new MatTableDataSource(this.storyelemList);
    //console.log(this.storyelemList);
    this.dataSource6._updateChangeSubscription();
  }

  deleteSto(elm, index) {
    //  this.spinnerMTemplate = true;
    //console.log(elm);
    //console.log(this.storyelemList);
    // elm.rowid == (null || '') ? this.spinnerMTemplate = false : this.spinnerMTemplate = true;
    this.storyelemList.splice(index, 1);
    (this.section6Form.get('section6Array') as FormArray).removeAt(index);
    this.dataSource6 = new MatTableDataSource(this.storyelemList);

    if(elm.rowid != null || '') {
      this.spinnerMTemplate = true;
      // const delSto = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      //   }
        const delSto = { 'inputSheetuserSectionId' : elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delSto)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
           this.spinnerMTemplate = false;
          //console.log(result);
        //  this.getStoryElem();
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.spinnerMTemplate = false;
        })
    }
  }

  updateSto(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.storyelements = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');;
    // ele.storyelements.replace(/[����]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  stoSave(formValue) {
    if(formValue.invalid) {
      this.submittedForm6 = true;
      return false;
    } else {
    //console.log(this.storyelemList);
    let stoelsSave = [];
    //console.log(this.storyelemList);
    this.spinnerMTemplate = true;
    //console.log(stoelsSave);
    stoelsSave = this.storyelemList.map(item => {
      return {
        inputSheetUserSectionId: item.rowid,
        description: item.storyelements,
        sequence: item.posstoryelem,
        sheetSectionMaster: {
          inputSheetSectionMasterId: 613,
          sequenceNumber: 6,
          sectionName: 'STORY ELEMENTS',
          sectionGroup: ""
        },
        inputSheet: {
          inputSheetId: this.inputSheetId
        },
        upDateFlag: item.upDateFlag
      }
    })

    if (!this.stosSave) {
      this.commonService.postRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/promosection`, 'POST', stoelsSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.stosSave = true;
          this.loadStoryElem(result);
    //      this.getStoryElem();
          this.toastorService.success('Saved Successfully');
          this.spinnerMTemplate = false;
        }, error => {
          this.toastorService.warn(error.message);
          this.spinnerMTemplate = false;
        });
    } else {
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', stoelsSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.loadStoryElem(result);
         // this.getStoryElem();
          this.spinnerMTemplate = false;
          this.toastorService.success('Saved Successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.spinnerMTemplate = false;
        });
    }
  }
  }

  loadStoryElem(storyElem) {
    if(storyElem == null) {
      this.onAddstoryelemRow();
    } else {
      let data = [];
      (this.section6Form.controls.section6Array as FormArray).clear();
      storyElem = storyElem.map(mapdata => {
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          posstoryelem: mapdata.sequence,
          storyelements: mapdata.description,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          storyelements: new FormControl(mapdata.description, [
            Validators.required, 
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
       });
       (this.section6Form.get('section6Array') as FormArray).push(ArrayData);
       //console.log(this.section6Form.value);
      })
  
      this.storyelemList = data;
      //console.log(this.storyelemList);
      this.dataSource6 = new MatTableDataSource<InputSheetPilotStoSection>(this.storyelemList);
    }
    
  }

  /** Section 7 - Add, Delete and Save method **/

  onAddViewRow() {
    let newRow = { rowid: null, posview: (this.viewershipList.length + 1), viewShowTitle: null,
      upDateFlag: true };
    this.viewershipList.push(newRow);
    const row = this.fb.group({
      viewShowTitle: new FormControl(null, [Validators.required, 
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField]),
    });
    (this.section7Form.get('section7Array') as FormArray).push(row);

    this.dataSource7 = new MatTableDataSource(this.viewershipList);
    //console.log(this.viewershipList);
    this.dataSource7._updateChangeSubscription();
  }

  deleteShow(elm, index) {
    //console.log(elm);
    // this.spinnerMTemplate = true;
    //console.log(this.viewershipList);
    // elm.rowid == null || '' ? this.spinnerMTemplate = false : this.spinnerMTemplate = true;
    this.viewershipList.splice(index, 1);
    (this.section7Form.get('section7Array') as FormArray).removeAt(index);
    this.dataSource7 = new MatTableDataSource(this.viewershipList);

    if(elm.rowid != null || '') {
      this.spinnerMTemplate = true;
      // const delView = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      //   }
        const delView = { 'inputSheetuserSectionId' : elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delView)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
       //   this.getViewership();
          this.spinnerMTemplate = false;
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.spinnerMTemplate = false;
        })
    }
  }

  updateShow(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.viewShowTitle = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');;
    // ele.viewShowTitle.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  viewSave(formValue) {
    if(formValue.invalid) {
      this.submittedForm7 = true;
      return false;
    } else {
    //console.log(this.viewershipList);
    //console.log(this.viewershipList)
    let viewsSave = [];
    this.spinnerMTemplate = true;
    //console.log(this.viewershipList);
    viewsSave = this.viewershipList.map(item => {
      return {
        inputSheetUserSectionId: item.rowid,
        description: item.viewShowTitle,
        sequence: item.posview,
        sheetSectionMaster: {
          inputSheetSectionMasterId: 614,
          sequenceNumber: 7,
          sectionName: 'VIEWERSHIP',
          sectionGroup: ""
        },
        inputSheet: {
          inputSheetId: this.inputSheetId
        },
        upDateFlag: item.upDateFlag
      }
    })

    if (!this.viewsSave) {
      //console.log(viewsSave);
      this.commonService.postRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/promosection`, 'POST', viewsSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.loadViewership(result);
          this.viewsSave = true;
          this.spinnerMTemplate = false;
        //  this.getViewership();
          this.toastorService.success('Saved Successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.spinnerMTemplate = false;
        });
    } else {
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', viewsSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.loadViewership(result);
          this.spinnerMTemplate = false;
     //     this.getViewership();
          this.toastorService.success('Saved Successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.spinnerMTemplate = false;
        });
    }
    }
  }

  loadViewership(viewership) {
    if(viewership == null) {
      this.onAddViewRow();
    } else {
      let data = [];
      (this.section7Form.controls.section7Array as FormArray).clear();
      viewership = viewership.map(mapdata => {
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          viewShowTitle: mapdata.description,
          posview: mapdata.sequence,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          viewShowTitle: new FormControl(mapdata.description, [
            Validators.required, 
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
       });
       (this.section7Form.get('section7Array') as FormArray).push(ArrayData);
       //console.log(this.section7Form.value);
      })
  
      this.viewershipList = data;
      //console.log(this.viewershipList);
      this.dataSource7 = new MatTableDataSource<InputSheetPilotViewSection>(this.viewershipList);
    }
    
  }

  /** Section 8 - Add, Delete and Save method **/

  onAddQuesRow() {
    let newRow = { rowid: null, posques: (this.questionstimList.length + 1), quesnstim: null, upDateFlag: true };
    this.questionstimList.push(newRow);
    const row = this.fb.group({
      quesnstim: new FormControl(null, [Validators.required, 
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField]),
    });
    (this.section8Form.get('section8Array') as FormArray).push(row);

    this.dataSource8 = new MatTableDataSource(this.questionstimList);
    //console.log(this.questionstimList);
    this.dataSource8._updateChangeSubscription();
  }

  deleteQues(elm, index) {
    //console.log(elm);
    //console.log(this.questionstimList);
    //  this.spinnerMTemplate = true;
    // elm.rowid == (null || '') ? this.spinnerMTemplate = false : this.spinnerMTemplate = true;
    this.questionstimList.splice(index, 1);
    (this.section8Form.get('section8Array') as FormArray).removeAt(index);
    this.dataSource8 = new MatTableDataSource(this.questionstimList);
    //console.log(this.questionstimList);

    if(elm.rowid != null || '') {
      this.spinnerMTemplate = true;
      // const delQues = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      //   }
      const delQues = { 'inputSheetuserSectionId' : elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delQues)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
         // this.getQuestionStim();
          this.spinnerMTemplate = false;
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.spinnerMTemplate = false;
          this.toastorService.warn(error.message);
        })
    }
  }

  updateQues(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.quesnstim = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');;
    // ele.quesnstim.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  quesSave(formValue) {
    if(formValue.invalid) {
      this.submittedForm8 = true;
      return false;
    } else {
    //console.log(this.questionstimList);
    //console.log(this.questionstimList)
    let quessSave = [];
    this.spinnerMTemplate = true;
    //console.log(this.questionstimList);
    quessSave = this.questionstimList.map(item => {
      return {
        inputSheetUserSectionId: item.rowid,
        description: item.quesnstim,
      sequence: item.posques,
      sheetSectionMaster: {
        inputSheetSectionMasterId: 615,
        sequenceNumber: 8,
        sectionName: 'QUESTION AND STIM CHANGES',
        sectionGroup: ""
      },
      sheetQuestion: null,
      inputSheet: {
        inputSheetId: this.inputSheetId
      },
      upDateFlag: item.upDateFlag
    }
      
    })
    
    if (!this.quessSave) {
      //console.log(this.section8Form.value);
      //console.log(quessSave);
      this.commonService.postRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/promosection`, 'POST', quessSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.loadQuestStim(result);
          this.spinnerMTemplate = false;
          this.quessSave = true;
       //   this.getQuestionStim();
          this.toastorService.success('Saved Successfully');
        }, error => {
          this.spinnerMTemplate = false;
          this.toastorService.warn(error.message);
        });
    } else {
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', quessSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.loadQuestStim(result);
        //  this.getQuestionStim();
          this.spinnerMTemplate = false;
          this.toastorService.success('Saved Successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.spinnerMTemplate = false;
        });
    }
    }
  }

  loadQuestStim(questionstim) {
    if(questionstim == null) {
      this.onAddQuesRow();
    } else {
      let data = [];
      (this.section8Form.controls.section8Array as FormArray).clear();
          questionstim = questionstim.map(mapdata => {
            data.push({
              rowid: mapdata.inputSheetUserSectionId,
              quesnstim: mapdata.description,
              posques: mapdata.sequence,
              upDateFlag: mapdata.upDateFlag
            });
            //console.log(this.section8Form.value);
            const ArrayData = this.fb.group({
              quesnstim: new FormControl(mapdata.description, [
                Validators.required, 
                Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
                validateEmptyField
              ])
           });
           (this.section8Form.get('section8Array') as FormArray).push(ArrayData);
           //console.log(this.section8Form.value);
          })
          
          this.questionstimList = data;
          //console.log(this.questionstimList);
          this.dataSource8 = new MatTableDataSource<InputsheetPilotQuesSection>(this.questionstimList);
    }
    
  }
  
  toggleAll(selection) {
    if (selection) {
      //console.log(this.Accordion);
      this.Accordion.multi = true;
      this.Accordion.openAll();
      this.toggle = false;
    } else {
      this.Accordion.closeAll();
      this.Accordion.multi = false;
      this.toggle = true;
    }
  }
  public hasErrorsection1Form = (index, errorName: string) => {
    return ((this.section1Form.controls.section1Array as FormArray).controls[index] as FormGroup).controls.attributes.hasError(errorName);
  }
  public hasErrorsection2Form  = (index, controlName , errorName: string) => {
    return ((this.section2Form.controls.section2Array as FormArray).controls[index] as FormGroup).controls[controlName].hasError(errorName);
  }
  public hasErrorsection3Form  = (index, controlName , errorName: string) => {
    return ((this.section3Form.controls.section3Array as FormArray).controls[index] as FormGroup).controls[controlName].hasError(errorName);
  }
  public hasErrorsection4Form  = (index, controlName , errorName: string) => {
    return ((this.section4Form.controls.section4Array as FormArray).controls[index] as FormGroup).controls[controlName].hasError(errorName);
  }
  public hasErrorsection5Form  = (index, controlName , errorName: string) => {
    return ((this.section5Form.controls.section5Array as FormArray).controls[index] as FormGroup).controls[controlName].hasError(errorName);
  }
  public hasErrorsection6Form  = (index, controlName , errorName: string) => {
    return ((this.section6Form.controls.section6Array as FormArray).controls[index] as FormGroup).controls[controlName].hasError(errorName);
  }
  public hasErrorsection7Form  = (index, controlName , errorName: string) => {
    return ((this.section7Form.controls.section7Array as FormArray).controls[index] as FormGroup).controls[controlName].hasError(errorName);
  }
  public hasErrorsection8Form  = (index, controlName , errorName: string) => {
    return ((this.section8Form.controls.section8Array as FormArray).controls[index] as FormGroup).controls[controlName].hasError(errorName);
  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.destroy$.next(true);
  }
  
}