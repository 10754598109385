import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputsheetRoutingModule } from './inputsheet.routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/app.material.module';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from 'src/app/shared/shared.module';

import { PilotCreateComponent } from './components/pilot-create/pilot-create.component';
import { InputLayoutComponent } from './layout/input-layout.component';
import { PilotEditComponent } from './components/pilot-edit/pilot-edit.component';
import { PilotDashboardComponent } from './components/pilot-dashboard/pilot-dashboard.component';
import { PrintDashboardComponent } from './components/print-dashboard/print-dashboard.component';
import { PromoDashboardComponent } from './components/promo-dashboard/promo-dashboard.component';
import { PromoCreateComponent } from './components/promo-create/promo-create.component';
import { PromoEditComponent } from './components/promo-edit/promo-edit.component';
import { PrintCreateComponent } from './components/print-create/print-create.component';
import { PrintEditComponent } from './components/print-edit/print-edit.component';
import { ConfirmModalComponent } from './layout/confirm-modal.component';
import {EditableDirective} from './components/pilot-create/pilot-create.component';
import {EditableDirectivePrint} from './components/print-create/print-create.component';
import {EditableDirectiveInternet} from './components/internet-create/internet-create.component';
import {EditableDirectiveOthers} from './components/others-create/others-create.component';
// import { MatVsTableModule } from 'mat-vs-table';
import { MatVsTableModule } from 'mat-vs-table';
import { PilotDatepickerComponent } from './components/pilot-dashboard/pilot-datepicker.component';
import { ServerErrordialogComponent } from 'src/app/shared/components/server-errordialog/server-errordialog.component';
import { InternetCreateComponent } from './components/internet-create/internet-create.component';
import { InternetDashboardComponent } from './components/internet-dashboard/internet-dashboard.component';
import { InternetEditComponent } from './components/internet-edit/internet-edit.component';
import { OthersEditComponent } from './components/others-edit/others-edit.component';
import { OthersCreateComponent } from './components/others-create/others-create.component';
import { OthersDashboardComponent } from './components/others-dashboard/others-dashboard.component';
@NgModule({
  declarations: [
    PilotEditComponent,
    PilotCreateComponent,
    InputLayoutComponent, 
    PilotEditComponent,
    PilotDashboardComponent,
    PrintDashboardComponent,
    PrintCreateComponent,
    PrintEditComponent,
    PromoDashboardComponent,
    PromoCreateComponent,
    PromoEditComponent,
    ConfirmModalComponent,
    PilotDatepickerComponent,
    ServerErrordialogComponent,
    EditableDirective,
    EditableDirectivePrint,
	EditableDirectiveInternet,
	EditableDirectiveOthers,
    InternetCreateComponent,
    InternetDashboardComponent,
    InternetEditComponent,
    OthersEditComponent,
    OthersCreateComponent,
    OthersDashboardComponent
  ],
  imports: [
    CommonModule,
    InputsheetRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatInputModule,
    MatExpansionModule,
    MatTableModule,
    SharedModule,
    MatSelectModule,
    MatVsTableModule
  ],
  exports: [
    
  ],
  entryComponents: [ConfirmModalComponent]
})
export class InputsheetModule { }
