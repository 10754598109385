import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, ElementRef, QueryList, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { SharedlistService } from 'src/app/services/sharedlist.service';
import { AppConfig } from 'src/app/app.config';
import { MatDialogRef, MAT_DIALOG_DATA, MatSelectionList, MatListOption } from '@angular/material';
import { SharedListDashboardComponent } from '../shared-list-dashboard/shared-list-dashboard.component';
import { CommonService } from 'src/app/services/common.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SelectionModel } from '@angular/cdk/collections';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { takeUntil } from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-add-choice',
  templateUrl: './add-choice.component.html',
  styleUrls: ['./add-choice.component.scss']
})
export class AddChoiceComponent implements OnInit,OnDestroy {
  
  appConstants = AppConfig.settings;
  public addChoiceForm: FormGroup;
  sharedChoiceList;
  isLoadingSave: any;
  selectedChoices = [];
  choiceLibraryIds: any = [];
  SHAREDCHOICESERVER_URL: any;
  ADDCHOICE_URL: any;
  checkServerURL: any;
  filteredChoices: any[];
  validationMsg: any;
  spinnerChoice: boolean;
  searchChoiModel: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  //@ViewChild('multiSelect', { static: false }) private multiSelect: ElementRef;
  @ViewChild('multiSelect', { static: false }) private selectionList: MatSelectionList;
  selectedId:any
  constructor(private sharedlistService: SharedlistService,
    private addChoiceRef: MatDialogRef<SharedListDashboardComponent>,
    private commonservice: CommonService,
    private toastorService: NotificationService,
    private awsCommonService: AwsCommonService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    //console.log('data', data);
    this.validationMsg = this.appConstants.validationMsg;
    this.checkServerURL = this.appConstants.checkServerURL;
    if (this.checkServerURL) {
      this.SHAREDCHOICESERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedChoiceListParam}`;
      this.ADDCHOICE_URL = `${this.appConstants.sharedAddChoiceParam}`
    } else {
      this.SHAREDCHOICESERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedChoiceListParam}`;
    }
  }

  ngOnInit() {
    this.getSharedChoiceList();
    //console.log(this.data.choicesharedListId, 'this.data.sharedListId', this.data.sharedListId);
    this.addChoiceForm = new FormGroup({
      sharedChoiceId: new FormControl('', Validators.required),
      choiceSharedListId: new FormControl(this.data.choicesharedListId),
      sharedListId: new FormControl(this.data.sharedListId)
    });
  }

  getSharedChoiceList() {
    //  let url = `${this.appConstants.serverUrl}`;
    // this.sharedlistService.getSharedChoice(this.SHAREDCHOICESERVER_URL)
    this.awsCommonService.getRequest(this.SHAREDCHOICESERVER_URL,'GET').pipe(takeUntil(this.destroy$))
    .subscribe(data => {
      if (data) {
        this.sharedChoiceList = data;
        this.sharedChoiceList.forEach(element => {
          element.isselected = false;
        });
      }
      
      //console.log('sharedlist',this.sharedChoiceList);
    }
    );
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.addChoiceForm.controls[controlName].hasError(errorName);
  }

  addSharedChoice() {
    if (this.addChoiceForm.invalid) {
      //console.log(this.addChoiceForm);
      this.isLoadingSave = false;
    } else {
      this.isLoadingSave = true;
      // this.spinnerChoice=true;
      // //console.log(this.addChoiceForm); return false;
      // debugger;
      // this.commonservice.insert(this.ADDCHOICE_URL, this.addChoiceForm.value)
      this.awsCommonService.postRequest(`${this.appConstants.serverUrl}/${this.ADDCHOICE_URL}`, 'POST', this.addChoiceForm.value)
      .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {  },
          (error) => {
            //console.log('error', error.message);
            this.toastorService.warn('Error' + error.message);
            this.isLoadingSave = false;
            this.addChoiceRef.close();
          },
          () => {
            this.isLoadingSave = false;
            this.toastorService.success('Choice Added Successfully');
            this.spinnerChoice = false;
            this.addChoiceRef.close('success');
          }
        );
      event.preventDefault();
    }
  }

 
  onAreaListControlChanged(option) {
    //console.log(this.selectionList);
    this.selectionList.selectedOptions = new SelectionModel<MatListOption>(true);
    //console.log(this.selectionList.selectedOptions);
    const found = this.selectedChoices.some(el => el.choiceSharedListId === option.choiceSharedListId);
    if (!found) {
      //option.isSelected = true;
      this.selectedChoices.push(option);
      this.choiceLibraryIds.push(option.choiceSharedListId);
      this.addChoiceForm.patchValue({ sharedChoiceId: this.choiceLibraryIds });
    } else {
     // option.isSelected = false;
      //const index = this.selectedChoices.findIndex(el => el.choiceSharedListId === option.choiceSharedListId);
      this.onRemove(option);
    }
    //console.log(this.choiceLibraryIds);
  
    return this.sharedChoiceList;

  }
  getSomeClass(option){
    //console.log(option);
    const found=this.selectedChoices.some(el => el.choiceSharedListId === option.choiceSharedListId);
    if(found){
      return {createQuesselected:found};
    }
    else{
      return{removeBg:!found};
    }
  }
  onRemove(item) {

    //console.log(item);
    //console.log(this.addChoiceForm);
   // this.selectedChoices.splice(index, 1);
    //console.log(this.selectedChoices);
    //console.log(this.choiceLibraryIds);
   
  let index = this.selectedChoices.findIndex(x=> x.choiceSharedListId===item.choiceSharedListId);
    this.selectedChoices.splice(index,1);
   // this.selectedChoices=this.filteredChoices;
      //console.log(this.selectedChoices);
    let ChoiceIds=this.selectedChoices.map(x=>x.choiceSharedListId);
    this.choiceLibraryIds =ChoiceIds;
    this.addChoiceForm.patchValue({ sharedChoiceId: ChoiceIds });
  }

  openLink(event: MouseEvent): void {
    //console.log('cancel');
    this.addChoiceRef.close('cancel');
    event.preventDefault();
  }
  ngOnDestroy(): void {
   // throw new Error("Method not implemented.");
   this.destroy$.next(true);
   this.destroy$.unsubscribe();
  }

}
