import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { SatDatepicker } from 'saturn-datepicker';
import { QuestiondashboardDatatableService } from 'src/app/services/questiondashboard-datatable.service';
import { FormControl } from '@angular/forms';
import { ChoicedashboardDatatableService } from 'src/app/services/choicedashboard-datatable.service';
import { AwsCommonService } from 'src/app/services/aws-common.service';
@Component({
  selector: 'app-daterange-picker-component',
  // templateUrl: './daterange-picker.component.html',
  styleUrls: ['./daterange-picker.component.scss'],
  template: `
  <mat-form-field appearance="outline">
    <input matInput
      placeholder="Select Date"
      [satDatepicker]="picker" 
      (dateChange)="emitDates()" readonly 
      [formControl]="date"
      >
    <sat-datepicker #picker [rangeMode]="true">
    </sat-datepicker>
    <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
  </mat-form-field>
  `
})
export class DaterangePickerComponent implements OnInit {
  @ViewChild('picker', { static: true }) dateInput: SatDatepicker<any>;
  @Output() emitRange: EventEmitter<any> = new EventEmitter<any>();
  now: Date = new Date();
  date = new FormControl('');
  delay;

  constructor(
    private questionservice: QuestiondashboardDatatableService,
    private choiceservice: ChoicedashboardDatatableService,
    private awsCommonService: AwsCommonService) { }

  ngOnInit() {
    this.questionservice.clearDate
      .subscribe(data =>
        this.date.setValue(''));

    this.choiceservice.clearDate
      .subscribe(data =>
        this.date.setValue(''));

    this.awsCommonService.clearDate.subscribe(clearDate => this.date.setValue(''));
  }

  emitDates() {
    const start = this.dateInput.beginDate;
    const end = this.dateInput.endDate;
    const daterange = { 'start': start, 'end': end };

    this.emitRange.emit(daterange);
  }

}
