import { Injectable } from '@angular/core';
import { HttpRequest } from 'aws-sdk';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import * as aws4 from "ngx-aws4";
import {Buffer} from 'buffer';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class AwsSignonService {
  secretAccessKey:any;
  accessKeyId:any;
  sessionToken:any;
  openIdToken:any;
  appConstants = AppConfig.settings;
  public apiUrl = this.appConstants.serverUrl;
  public hostname:any;
  valid:boolean = false;
  constructor(
    private http:HttpClient
  ) {
   
    this.secretAccessKey = sessionStorage.getItem('secretAccessKey');
    this.accessKeyId = sessionStorage.getItem('accessKeyId');
    this.sessionToken = sessionStorage.getItem('sessionToken');
    this.openIdToken = sessionStorage.getItem('id_token');
    // this.secretAccessKey = this.authManager.secretAccessKey;
    // this.accessKeyId = this.authManager.accessKeyId;
    // this.sessionToken = this.authManager.sessionToken;
  }

  apiGetRequest(apiURL: any, method:any, params?,options? ){
    //console.log(params)
    this.secretAccessKey = sessionStorage.getItem('secretAccessKey');
    this.accessKeyId = sessionStorage.getItem('accessKeyId');
    this.sessionToken = sessionStorage.getItem('sessionToken');
    this.openIdToken = sessionStorage.getItem('id_token');
    // console.log(this.secretAccessKey)
    this.hostname = 'https://'+this.appConstants.hostname;
	
	let queryParams = '?';
	
	console.log('Paremeters start')
	for (let key in params) {
	//console.log(key, params[key]);
	queryParams += key +'='+ params[key] + '&'
	}
	console.log('Paremeters end')
    var path = apiURL.split(this.hostname)[1];
    	
	if(params !== null && params !== ''  && params!==undefined) {
		queryParams = queryParams.substring(0, queryParams.length - 1)
		//console.log('queryParams after remove:'+queryParams)
		path= path + queryParams
	}
    
    if(sessionStorage.getItem('sessionToken') != null){
      // console.log(apiURL)
      let request = {
        hostname: this.appConstants.hostname,
        service: this.appConstants.service,
        region: this.appConstants.region,
        method: method,
		url: apiURL, // this field is not recommended in the document.
		path: path,
		//path: '/',
        headers: {
          'Content-Type': 'application/json',
	  'cache-control': 'no-cache',
          'openIdToken': this.openIdToken
        
        },
       params: params
      }
          
      let signedRequest = aws4.sign(request, {
          secretAccessKey: this.secretAccessKey,
          accessKeyId: this.accessKeyId,
          sessionToken: this.sessionToken
      });
      delete signedRequest.headers['Host'];
      if(method == 'GET'){
        return this.http.get(signedRequest.url, signedRequest);
      }else if(method == 'POST'){
        //console.log(signedRequest.body)
        return this.http.post(signedRequest.url, signedRequest.body, signedRequest);
      }else if(method == 'PUT'){
        return this.http.put(signedRequest.url, signedRequest.body, signedRequest);
      }else if(method == 'DELETE'){
        return this.http.delete(signedRequest.url, signedRequest);
      }
    }else{
      this.logoutUser();
    }
  }

  apiRequest(apiURL: any, method:any, options? ){
    console.log('apiRequest ')
   
    this.secretAccessKey = sessionStorage.getItem('secretAccessKey');
    this.accessKeyId = sessionStorage.getItem('accessKeyId');
    this.sessionToken = sessionStorage.getItem('sessionToken');
    this.openIdToken = sessionStorage.getItem('id_token');
    this.hostname = 'https://'+this.appConstants.hostname;
    let path = apiURL.split(this.hostname)[1];

    
    if(sessionStorage.getItem('sessionToken') != null){
      let request = {
        hostname: this.appConstants.hostname,
        service: this.appConstants.service,
        region: this.appConstants.region,
        method: method,
        url: apiURL, // this field is not recommended in the document.
		 path: path,
        headers: {
          'Content-Type': 'application/json',
          'openIdToken': this.openIdToken,
	   'cache-control': 'no-cache'//,
         
        },
        body: JSON.stringify(options),
        //path: path
      }
          
      let signedRequest = aws4.sign(request, {
          secretAccessKey: this.secretAccessKey,
          accessKeyId: this.accessKeyId,
          sessionToken: this.sessionToken
      });
      delete signedRequest.headers['Host'];
      // console.log(signedRequest)
      if(method == 'GET'){
        return this.http.get(signedRequest.url, signedRequest);
      }else if(method == 'POST'){
        //console.log(signedRequest.body)
        return this.http.post(signedRequest.url, signedRequest.body, signedRequest)
      }else if(method == 'PUT'){
        return this.http.put(signedRequest.url, signedRequest.body, signedRequest);
      }else if(method == 'DELETE'){
        return this.http.delete(signedRequest.url, signedRequest.body);
      }
    }else{
      this.logoutUser();
    }
  }

  public logoutUser(){
    //console.log('123')

    // let userType = sessionStorage.getItem('userType');      

    sessionStorage.clear();
    // localStorage.clear();

    // window.location.href = AppConfig.settings.appUrl;
    window.location.href = AppConfig.settings.logoutUri;

    // sessionStorage.setItem('userType', userType);
    // userType = sessionStorage.getItem('userType');

    // if (userType === 'E') {
    //     sessionStorage.removeItem('userType');
    //     window.location.href = environment.appurl + '/#/Login';
    // } else {
    //      sessionStorage.removeItem('userType');
    //     window.location.href = environment.logoutUri + environment.appurl + '%26client_id=' + environment.clientId;
    // }

    // sessionStorage.clear();
    // localStorage.clear();
  }
  
}
