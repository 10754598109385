import { Component, OnInit, OnDestroy } from '@angular/core';
import { AwsUploadService } from 'src/app/services/aws-upload.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-import-survey',
  templateUrl: './import-survey.component.html',
  styles: []
})
export class ImportSurveyComponent implements OnInit, OnDestroy {

  selectedFiles: FileList;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private uploadService: AwsUploadService) { }

  ngOnInit() {
  }

  upload() {
    const file = this.selectedFiles.item(0);
    this.uploadService.uploadFile(file);
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    // this.subscription.unsubscribe();
  }
}
