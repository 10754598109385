import { Injectable, EventEmitter } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  appConstants = AppConfig.settings;
  public apiUrl = this.appConstants.serverUrl;
  tooltip = new EventEmitter();
  httpOptions = {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
  };

  constructor() { }
}
