import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter, Input, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { MatDialog } from '@angular/material/dialog';
import { map, startWith, tap, debounceTime, switchMap, finalize, takeUntil } from 'rxjs/operators';
import { Subscription, from, Subject, Observable, BehaviorSubject } from 'rxjs';
import { MatSort, MatTableDataSource } from '@angular/material';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';

/* Service Section */
import { AwsCommonService } from 'src/app/services/aws-common.service';
// import { AwsCommonSprint3Service } from 'src/app/services/aws-commonsprint3.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CommunicatingComponentService } from 'src/app/modules/survey/service/communicating-component.service';
import { ExcelService } from 'src/app/services/excel.service';
/* Interface Section */
import { SurveyModules, SurveydashboardObject } from 'src/app/models/surveymodules';
/* DialogModel Section */
import { ConfirmdialogComponent, ConfirmDialogModel } from 'src/app/shared/components/confirmdialog/confirmdialog.component';


/*  Component  */
import { CreateSurveyComponent } from '../create-survey/create-survey.component';
import { ImportSurveyComponent } from 'src/app/modules/survey/components/import-survey/import-survey.component';
import { AuthManager } from 'src/app/helpers/auth-manager';


@Component({
  selector: 'app-survey-dashboard',
  templateUrl: './survey-dashboard.component.html',
  styleUrls: ['./survey-dashboard.component.scss']
})

export class SurveyDashboardComponent implements OnInit, OnDestroy, AfterViewInit {

  appConstants = AppConfig.settings;
  surveyDec: SurveyModules;
  destroy$: Subject<boolean> = new Subject<boolean>();
  subscription: Subscription;
  matTableDataSource = new MatTableDataSource<SurveydashboardObject>();
  isAdmin:boolean = true;
  // panelOpenState = false;
  // tableObjectData = [];
  pipe: DatePipe;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private awsCommonService: AwsCommonService,
    private notificationService: NotificationService,
    private communicatingService: CommunicatingComponentService,
    private router: Router,
    private route: ActivatedRoute,
    private excelService: ExcelService,
    private authManager: AuthManager,
    private cdr:ChangeDetectorRef
    
  ) {

    this.pipe = new DatePipe('en');
    this.surveyDec = {
      surveyTitle: this.appConstants.surveyHeader.surveyTitle,
      newSurvey: this.appConstants.surveyHeader.newSurvey,
      localurl: 'assets/json/survey_dashboard.json',
      gridTotalCount: '',
      dialogRef: '',
      tableErrorBlock: 'Loading Data...',
      tableNoDataFound: 'No Records Found',
      surveyCodeControl: new FormControl(''),
      showtitleControl: new FormControl(''),
      genreControl: new FormControl(''),
      episodeControl: new FormControl(''),
      surveytypeControl: new FormControl(''),
      versionControl: new FormControl(''),
      inputsheetControl: new FormControl(''),
      surveystatusControl: new FormControl(''),
      tableDisplayColumns: [
        'surveyCode', 'showTitle', 'showGenre', 'episodeNumber', 'testDt', 'surveyTypeDesc', 'titleVersion', 'inputsheet',
        'LastUpdatedDt', 'status', 'action'],
      tableHeaderColumns: [
        'survey Code', 'Show Title', 'show genre', 'Episode #', 'Test Date',
        'survey type', 'version', 'inputsheet', 'modified date', 'status', 'action'],
      tableFillterColumns: [
        'filter_surveycode',
        'filter_showtitle',
        'filter_genre',
        'filter_episode',
        'filter_testdate',
        'filter_surveytype', 'filter_version', 'filter_inputsheet', 'filter_modifieddate', 'filter_surveystatus', 'filter_action'],
      filterValues: {
        surveyCode: '', showTitle: '', showGenre: '', episodeNumber: '', testDt: '', surveyTypeDesc: '', titleVersion: '', inputsheet: '',
        LastUpdatedDt: '', status: '', fromCreatedDate: '', toCreatedDate: '', fromUpdatedDate: '', toUpdatedDate: ''
      },

      surveyTypeData: [],
      showGenreData: [],
      surveyStatusData: ['Draft', 'Submitted'],
      titleVersionData: [],
      result: '',
      isLoading: true
    };
    if (this.appConstants.checkServerURL) {
      this.surveyDec.DashboardURL =
        `${this.appConstants.serverUrl}/${this.appConstants.surveyURL}/${this.appConstants.surveyDashboardParamsURL}`;
    } else {
      this.surveyDec.DashboardURL = `${this.appConstants.surveyDashboardLocalURL}`;
    }

    this.getMultipleFilter();
    this.fetchDashboard();


  }
  fetchDashboard() {
    // this.clearGridTable();

    this.surveyDec.isLoading = true;
    this.awsCommonService.getRequest(`${this.surveyDec.DashboardURL}`, 'GET')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        dataSource => {
          const data = [];
          this.surveyDec.surveyTypeData = [];
          this.surveyDec.showGenreData = [];
          this.surveyDec.titleVersionData = [];
          dataSource.map(item => {
            data.push({
              surveyCode: item.c2301SurveyCode ? item.c2301SurveyCode : 'No Data',
              showTitle: item.showTitle ? item.showTitle : 'No Data',
              showGenre: item.c2027ProjectTypeDesc ? item.c2027ProjectTypeDesc : 'No Data',
              episodeNumber: (item.episodeNumber) ?
                (item.episodeNumber) ? item.episodeNumber : 'No Data' : 'No Data',
              testDt: (item.c2301TestDt) ? item.c2301TestDt : '01/01/0001',
              surveyTypeDesc: (item.surveyType) ? item.surveyType : 'No Data',
              titleVersion: (item.titleVersion) ? item.titleVersion : 'No Data',
              inputsheet: (item.inputSheetName) ? item.inputSheetName : 'No Data',
              LastUpdatedDt: (item.c2301LastUpdatedDt) ? item.c2301LastUpdatedDt : '01/01/0001',
              status: (item.surveyStatus) ? item.surveyStatus : 'No Data',
              surveyId: item.c2301SurveyId
            });
            if (typeof (item.surveyType) !== 'undefined' && item.surveyType !== null && item.surveyType !== '') {
              this.surveyDec.surveyTypeData.push({
                surveyTypeDesc: item.surveyType
              });
            }
            if (typeof (item.c2027ProjectTypeDesc) !== 'undefined'
              && item.c2027ProjectTypeDesc !== null && item.c2027ProjectTypeDesc !== '') {
              this.surveyDec.showGenreData.push({
                showGenre: item.c2027ProjectTypeDesc
              });
            }
            if (typeof (item.titleVersion) !== 'undefined' && item.titleVersion !== null && item.titleVersion !== '') {
              this.surveyDec.titleVersionData.push({
                titleVersion: (item.titleVersion) ? item.titleVersion : 'No Data'
              });
            }
            // this.surveyDec.surveyStatusData.push({
            //   status: (item.surveyStatus) ? item.surveyStatus : 'No Data'
            // });
          });

          this.surveyDec.surveyTypeData =
            [...new Set(Array.from(new Set(this.surveyDec.surveyTypeData)).map((itemInArray) => {
              if (typeof (itemInArray) !== 'undefined' && itemInArray !== null) {
                return itemInArray.surveyTypeDesc.trim();
              }
            }))];

          this.surveyDec.showGenreData =
            [...new Set(Array.from(new Set(this.surveyDec.showGenreData)).map((itemInArray) => {
              if (typeof (itemInArray) !== 'undefined' && itemInArray !== null) {
                return itemInArray.showGenre.trim();
              }
            }))];

          // this.surveyDec.surveyStatusData =
          //   [...new Set(Array.from(new Set(this.surveyDec.surveyStatusData)).map((itemInArray) => itemInArray.status))];


          this.surveyDec.titleVersionData =
            [...new Set(Array.from(new Set(this.surveyDec.titleVersionData)).map((itemInArray) => {
              if (typeof (itemInArray) !== 'undefined' && itemInArray !== null) {
                return itemInArray.titleVersion.trim();
              }
            }
            ))];

          this.surveyDec.showGenreData = this.surveyDec.showGenreData.sort();
          this.surveyDec.titleVersionData = this.surveyDec.titleVersionData.sort();
          this.surveyDec.surveyTypeData = this.surveyDec.surveyTypeData.sort();

          this.matTableDataSource.data = data;
          this.surveyDec.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.communicatingService.storageTotalCount = {
            dashboardcount: this.matTableDataSource.filteredData.length
          };
          this.matTableDataSource.filterPredicate = this.customFilterPredicate();
        },
        error => {
          this.notificationService.warn('Wait...!');
        },
        () => {
          this.surveyDec.isLoading = false;
        }
      );
  }

  ngOnInit() {
    if(this.authManager.userRole != 'ADMIN'){
      this.isAdmin = false;
    }
  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    // this.subscription.unsubscribe();
    this.destroy$.next(true);
  }
  ngAfterViewInit() {
    this.matTableDataSource.sort = this.sort;
  }


  confirmDialog(data: any): void {
    const message = `Are you sure you want to copy the survey details`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmdialogComponent, {
      data: dialogData,
      panelClass: 'confirmModal',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.surveyDec.result = dialogResult;

      // console.log(this.surveyDec.result);
      if (this.surveyDec.result) {
        this.surveyDec.isLoading = true;
        // console.log(data);
        const copySurveyData = { c2301SurveyId: data };
        this.awsCommonService.postRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/copy`,
          'POST', copySurveyData)
          .subscribe(
            (resultsaveSummaryQuestion) => {
              this.surveyDec.isLoading = true;
              this.fetchDashboard();
              this.clearGridTable();
              this.notificationService.success('Copied Successfully');
            },
            err => {
              this.surveyDec.isLoading = false;
              this.notificationService.warn('Oops!!! The Server was unable to complete your request');
            }
          );
      } else {
        this.surveyDec.isLoading = false;
      }

    });
  }

  clearGridTable() {
    this.surveyDec.surveyCodeControl.setValue('');
    this.surveyDec.showtitleControl.setValue('');
    this.surveyDec.genreControl.setValue('');
    this.surveyDec.episodeControl.setValue('');
    this.surveyDec.surveytypeControl.setValue('');
    this.surveyDec.versionControl.setValue('');
    this.surveyDec.inputsheetControl.setValue('');
    this.surveyDec.surveystatusControl.setValue('');
    this.surveyDec.filterValues.fromCreatedDate = '';
    this.surveyDec.filterValues.toCreatedDate = '';
    this.surveyDec.filterValues.fromUpdatedDate = '';
    this.surveyDec.filterValues.toUpdatedDate = '';
    this.surveyDec.lastdate = '';
    this.surveyDec.createdate = '';
    this.awsCommonService.clearDate.emit(true);
    this.matTableDataSource.filter = JSON.stringify(this.surveyDec.filterValues);
    this.matTableDataSource.filter = JSON.stringify(this.surveyDec.filterValues);
    this.surveyDec.gridTotalCount = this.matTableDataSource.filteredData.length;

  }

  getMultipleFilter() {
    // inputSheetName
    this.surveyDec.surveyCodeControl.valueChanges
      .subscribe(
        surveyCode => {
          this.surveyDec.filterValues.surveyCode = surveyCode;
          this.matTableDataSource.filter = JSON.stringify(this.surveyDec.filterValues);
          this.surveyDec.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.surveyDec.tableErrorBlock =
            (this.surveyDec.gridTotalCount === 0) ? this.surveyDec.tableNoDataFound : this.surveyDec.tableErrorBlock;
        },
        error => {
          console.log(error);
        }
      );

    this.surveyDec.showtitleControl.valueChanges
      .subscribe(
        showTitle => {
          this.surveyDec.filterValues.showTitle = showTitle;
          this.matTableDataSource.filter = JSON.stringify(this.surveyDec.filterValues);
          this.surveyDec.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.surveyDec.tableErrorBlock =
            (this.surveyDec.gridTotalCount === 0) ? this.surveyDec.tableNoDataFound : this.surveyDec.tableErrorBlock;
        },
        error => {
          console.log(error);
        }
      );
    this.surveyDec.genreControl.valueChanges
      .subscribe(
        showGenre => {
          this.surveyDec.filterValues.showGenre = showGenre;
          this.matTableDataSource.filter = JSON.stringify(this.surveyDec.filterValues);
          this.surveyDec.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.surveyDec.tableErrorBlock =
            (this.surveyDec.gridTotalCount === 0) ? this.surveyDec.tableNoDataFound : this.surveyDec.tableErrorBlock;
        },
        error => {
          console.log(error);
        }
      );

    this.surveyDec.episodeControl.valueChanges
      .subscribe(
        episodeNumber => {
          this.surveyDec.filterValues.episodeNumber = episodeNumber;
          this.matTableDataSource.filter = JSON.stringify(this.surveyDec.filterValues);
          this.surveyDec.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.surveyDec.tableErrorBlock =
            (this.surveyDec.gridTotalCount === 0) ? this.surveyDec.tableNoDataFound : this.surveyDec.tableErrorBlock;
        },
        error => {
          console.log(error);
        }
      );

    this.surveyDec.surveytypeControl.valueChanges
      .subscribe(
        surveyTypeDesc => {
          this.surveyDec.filterValues.surveyTypeDesc = surveyTypeDesc;
          this.matTableDataSource.filter = JSON.stringify(this.surveyDec.filterValues);
          this.surveyDec.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.surveyDec.tableErrorBlock =
            (this.surveyDec.gridTotalCount === 0) ? this.surveyDec.tableNoDataFound : this.surveyDec.tableErrorBlock;
        },
        error => {
          console.log(error);
        }
      );
    this.surveyDec.versionControl.valueChanges
      .subscribe(
        titleVersion => {
          this.surveyDec.filterValues.titleVersion = titleVersion;
          this.matTableDataSource.filter = JSON.stringify(this.surveyDec.filterValues);
          this.surveyDec.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.surveyDec.tableErrorBlock =
            (this.surveyDec.gridTotalCount === 0) ? this.surveyDec.tableNoDataFound : this.surveyDec.tableErrorBlock;
        },
        error => {
          console.log(error);
        }
      );
    this.surveyDec.inputsheetControl.valueChanges
      .subscribe(
        inputsheet => {
          this.surveyDec.filterValues.inputsheet = inputsheet;
          this.matTableDataSource.filter = JSON.stringify(this.surveyDec.filterValues);
          this.surveyDec.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.surveyDec.tableErrorBlock =
            (this.surveyDec.gridTotalCount === 0) ? this.surveyDec.tableNoDataFound : this.surveyDec.tableErrorBlock;
        },
        error => {
          console.log(error);
        }
      );
    this.surveyDec.surveystatusControl.valueChanges
      .subscribe(
        status => {
          this.surveyDec.filterValues.status = status;
          this.matTableDataSource.filter = JSON.stringify(this.surveyDec.filterValues);
          this.surveyDec.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.surveyDec.tableErrorBlock =
            (this.surveyDec.gridTotalCount === 0) ? this.surveyDec.tableNoDataFound : this.surveyDec.tableErrorBlock;
        },
        error => {
          console.log(error);
        }
      );
  }

  customFilterPredicate() {
    return (data: SurveydashboardObject, filter: string): boolean => {
      const searchString = JSON.parse(filter);
      const surveyCode =
        data.surveyCode.toString().trim().toLowerCase().indexOf(searchString.surveyCode.toLowerCase()) !== -1;
      const showTitle =
        data.showTitle.toString().trim().toLowerCase().indexOf(searchString.showTitle.toLowerCase()) !== -1;
      const showGenre = data.showGenre.toString().indexOf(searchString.showGenre) !== -1;
      const episodeNumber = data.episodeNumber.toString().trim().indexOf(searchString.episodeNumber) !== -1;
      const surveyTypeDesc = data.surveyTypeDesc.toString().trim().toLowerCase().indexOf(searchString.surveyTypeDesc.toLowerCase()) !== -1;
      const titleVersion =
        data.titleVersion.toString().trim().toLowerCase().indexOf(searchString.titleVersion.toLowerCase()) !== -1;
      const inputsheet =
        data.inputsheet.toString().trim().toLowerCase().indexOf(searchString.inputsheet.toLowerCase()) !== -1;
      const status =
        data.status.toString().trim().toLowerCase().indexOf(searchString.status.toLowerCase()) !== -1;
      const temptestDt = this.pipe.transform(data.testDt, 'yyyy-MM-dd');
      const testDt = (
        (temptestDt.toString() >= (searchString.fromCreatedDate).toString())
        &&
        (temptestDt.toString() <= (searchString.toCreatedDate).toString())
      );
      const tempUpdDt = this.pipe.transform(data.LastUpdatedDt, 'yyyy-MM-dd');
      const lastUpdatedDt = (
        (tempUpdDt.toString() >= (searchString.fromUpdatedDate).toString())
        &&
        (tempUpdDt.toString() <= (searchString.toUpdatedDate).toString())
      );

      if (!(searchString.fromCreatedDate) && !(searchString.fromUpdatedDate)) {
        this.surveyDec.filterReturnData =
          surveyCode && showTitle && showGenre && episodeNumber && surveyTypeDesc && titleVersion && inputsheet && status;
      } else if (searchString.fromCreatedDate && !(searchString.fromUpdatedDate)) {
        this.surveyDec.filterReturnData =
          surveyCode && showTitle && showGenre && episodeNumber && surveyTypeDesc && titleVersion && inputsheet && status && testDt;

      } else if (!(searchString.fromCreatedDate) && searchString.fromUpdatedDate) {
        this.surveyDec.filterReturnData =
          surveyCode &&
          showTitle && showGenre && episodeNumber && surveyTypeDesc && titleVersion && inputsheet && status && lastUpdatedDt;

      } else {
        this.surveyDec.filterReturnData =
          surveyCode &&
          showTitle && showGenre && episodeNumber && surveyTypeDesc && titleVersion && inputsheet && status && testDt && lastUpdatedDt;
      }
      return this.surveyDec.filterReturnData;
    };
  }
  getDates(range: any, column: any) {
    const fromDate = this.pipe.transform(range.start, 'yyyy-MM-dd');
    const toDate = this.pipe.transform(range.end, 'yyyy-MM-dd');
    if (column === 'createdDt') {
      this.surveyDec.filterValues.fromCreatedDate = fromDate;
      this.surveyDec.filterValues.toCreatedDate = toDate;
      this.surveyDec.createdate = this.pipe.transform(fromDate, 'MM/dd/yyyy') + '  to  ' + this.pipe.transform(toDate, 'MM/dd/yyyy');
    } else {
      this.surveyDec.filterValues.fromUpdatedDate = fromDate;
      this.surveyDec.filterValues.toUpdatedDate = toDate;
      this.surveyDec.lastdate = this.pipe.transform(fromDate, 'MM/dd/yyyy') + '  to  ' + this.pipe.transform(toDate, 'MM/dd/yyyy');
    }
    this.matTableDataSource.filter = JSON.stringify(this.surveyDec.filterValues);
    this.matTableDataSource.filter = JSON.stringify(this.surveyDec.filterValues);
    this.surveyDec.gridTotalCount = this.matTableDataSource.filteredData.length;
  }
  createsurvey() {
    this.cdr.detach();
    this.surveyDec.dialogRef = this.dialog.open((CreateSurveyComponent), { panelClass: 'newQuestion', disableClose: true });
    this.surveyDec.dialogRef.afterClosed().subscribe(result => {
      this.cdr.reattach();
      if (result) {
        this.communicatingService.storage = {
          inputSheetName: result.InputSheetName.inputSheetName,
          inputSheetId: result.InputSheetName.inputSheetId,
          surveyTemplateId: result.TemplateName.surveyTemplateId,
          templateDescription: result.TemplateName.templateDescription,
          surveyDescription:result.SurveyDesc
        };
        this.router.navigate(['../surveyadd'], { relativeTo: this.route });
      } else {
        this.router.navigate(['../dashboard'], { relativeTo: this.route });
      }

    });
  }
  surveyEdit(editData) {
    console.log(editData);
    if (editData) {
      this.communicatingService.editstorage = {
        surveyId: editData.surveyId,
        surveycode: editData.surveyCode,
        status: (editData.status === 'Submitted') ? true : false
      };
      this.router.navigate(['../surveyedit'], { relativeTo: this.route });
    } else {
      this.router.navigate(['../dashboard'], { relativeTo: this.route });
    }
  }
  exportExcel() {
    this.notificationService.success('Excel Generated Successfully');
    this.excelService.surveyToExcel(this.matTableDataSource.filteredData);
  }
  uploadExcel() {
    this.surveyDec.dialogRef = this.dialog.open((ImportSurveyComponent), { panelClass: 'newQuestion', disableClose: true });
    this.surveyDec.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['../dashboard'], { relativeTo: this.route });
      } else {
        this.router.navigate(['../dashboard'], { relativeTo: this.route });
      }

    });
  }

}
