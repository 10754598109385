import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppConfig } from 'src/app/app.config';
import { SurveyTable } from 'src/app/models/survey.model';
import { MatTableDataSource } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ChoicedashboardDatatableService } from 'src/app/services/choicedashboard-datatable.service';
import { from } from 'rxjs';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { CommunicatingComponentService } from 'src/app/modules/survey/service/communicating-component.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-question-surveydialog',
  templateUrl: './choice-surveydialog.component.html',
  styles: [`
  .cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-dialog-container .question-dialog-container {
    /* right: 0; */
    /* top: 65px; */
    /* height: 100%; */
    position: absolute !important;
    width: 31%;
}`]
})
export class ChoiceSurveydialogComponent implements OnInit {
  appConstants = AppConfig.settings;
  surveyHeader: any;
  surveyColumns: any;
  surveyTableSource: any;
  dataTableErrorBlock: any;
  dataTableNoDataFound: any;
  spinnerSurveyChoice: boolean;
  checkServerURL: any;
  dashboardSurveyURL: string;
  SERVER_URL: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    public dialogRef: MatDialogRef<ChoiceSurveydialogComponent>,
    private choiceservice: ChoicedashboardDatatableService,
    private awsCommonService: AwsCommonService,
    private communicatingService: CommunicatingComponentService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService:NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dataTableErrorBlock = 'Loading Data...';
    this.dataTableNoDataFound = 'No Records Found';
    this.surveyHeader = this.appConstants.surveyPopHeader;
    this.surveyColumns = Object.values(this.appConstants.surveyPopColums);
    this.surveyTableSource = new MatTableDataSource<SurveyTable>(this.data);
    this.spinnerSurveyChoice = true;
    this.checkServerURL = this.appConstants.checkServerURL;
    // this.dataTableErrorBlock = (this.surveyTableSource.filteredData.length === 0) ? this.dataTableNoDataFound : '';
    if (this.checkServerURL) {
      this.SERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.choiceDashboardParam}`;
      this.dashboardSurveyURL = `${this.appConstants.serverUrl}/${this.appConstants.choiceDashboardSurveyParam}`;
    } else {
      this.SERVER_URL = this.appConstants.choiceDashboardLocalURL;
    }
    // //console.log(this.surveyTableSource.filteredData.length);
    /** //console.log(this.surveyTableSource); */
  }

  ngOnInit() {
    /** //console.log('Dialog got', this.data); */
    this.choiceservice
    // .getSurveyTable(`${this.appConstants.serverUrl}/${this.appConstants.choiceDashboardSurveyParam}/${this.dialogRef.id}`)
    // .pipe(takeUntil(this.destroy$))
    this.awsCommonService.getRequest(`${this.appConstants.serverUrl}/${this.appConstants.choiceDashboardSurveyParam}/${this.dialogRef.id}`,'GET').pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.spinnerSurveyChoice = false;
        this.surveyTableSource = new MatTableDataSource<SurveyTable>(res);
       this.dataTableErrorBlock = (this.surveyTableSource.filteredData.length === 0) ? this.dataTableNoDataFound : '';
      },
      error => {
        this.spinnerSurveyChoice = false;
        this.notificationService.warn('Oops!!! The Server was unable to complete your request');
        this.dataTableErrorBlock =  this.dataTableNoDataFound;
      }

      );

  }
  surveyEdit(editData) {
    debugger;
    // console.log(editData);
    this.dialogRef.close();
    if (editData) {
      this.communicatingService.editstorage = {
        surveyId: editData.surveyId,
        surveycode: editData.surveyCode,
        status: (editData.status === 'Submitted') ? true : false
      };
      this.router.navigate(['../survey/surveyedit'], { relativeTo: this.route });
    } else {
      this.router.navigate(['../dashboard'], { relativeTo: this.route });
    }
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
