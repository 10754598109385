import { SharedService } from 'src/app/shared/services/shared.service';
import { Component, OnInit, Output, EventEmitter, ViewChildren, QueryList, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatSelectionList, MatListOption } from '@angular/material';
import { SharedListDashboardComponent } from '../shared-list-dashboard/shared-list-dashboard.component';
import { AppConfig } from 'src/app/app.config';
import { SharedlistService } from 'src/app/services/sharedlist.service';
import { SharedChoiceModel } from 'src/app/models/sharedchoice.model';
import { CommonService } from 'src/app/services/common.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SharedListSidenavComponent } from '../shared-list-sidenav/shared-list-sidenav.component';
import { map } from 'rxjs/operators';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { SelectionModel } from '@angular/cdk/collections';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-create-shared-choice',
  templateUrl: './create-shared-choice.component.html',
  styleUrls: ['./create-shared-choice.component.scss'],
  providers: [SharedlistService, SharedListSidenavComponent]
})

export class CreateSharedChoiceComponent implements OnInit ,OnDestroy{

  shareQuestionList: any[] = [];
  isLoadingSave: any
  appConstants = AppConfig.settings;
  SHAREDCHOICESERVER_URL: any;
  // tslint:disable-next-line: variable-name
  checkServerURL: any;
  SHAREDCHOICELIST_URL: any;
  createSharedChoiceForm: FormGroup;
  public globalResponse: any;
  sharedChoiceCreateLabel: any;
  validationMsg: any;
  searchQModel: string;
  public url = 'sharedQuestion';
  public searchChoiModel: string;
  selectedOptions = [];
  selected = [];
  selectedOption;
  sharedChoiceList;
  selectedChoices: any = [];
  choiceLibraryIds: any = [];
  filteredChoices;
  shareditem;
  sideNavReload;
  loading: boolean = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Output() public select: EventEmitter<{}> = new EventEmitter();
  //@ViewChildren('sharedChoices') selectedchoice: QueryList<ElementRef>;
  @ViewChild('sharedChoices', { static: false }) private selectionList: MatSelectionList;
  constructor(private createChoiceRef: MatDialogRef<SharedListDashboardComponent>,
    private toastorService: NotificationService, private commonservice: CommonService,
    private sharedlistService: SharedlistService,
    private sharedService: SharedService,
    private awsCommonService: AwsCommonService,
    private sidenav: SharedListSidenavComponent) {
    this.sharedChoiceCreateLabel = this.appConstants.SharedChoiceModel;
    this.validationMsg = this.appConstants.validationMsg;

    this.checkServerURL = this.appConstants.checkServerURL;
    if (this.checkServerURL) {
      this.SHAREDCHOICESERVER_URL = `${this.appConstants.sharedChoiceListParam}`;
      this.SHAREDCHOICELIST_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedListChoice}`;
    } else {
      this.SHAREDCHOICESERVER_URL = this.appConstants.questionDashboardLocalURL;
    }
  }

  ngOnInit() {
    this.getChoiceList();
    this.createSharedChoiceForm = new FormGroup({
      sharedListName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        validateEmptyField
      ])),
      sharedListDesc: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        validateEmptyField
        // Validators.pattern('^[a-zA-Z ]+$')
      ])),
      choiceLibraryIds: new FormControl(null)
    });
  }
  data = [];
  createChoice() {
    this.isLoadingSave = true;
    if (this.createSharedChoiceForm.invalid) {
      this.isLoadingSave = false;
      //console.log('invalid');
    } else {

      // //console.log(this.createSharedChoiceForm.value); return false;
      // this.commonservice.insert(this.SHAREDCHOICESERVER_URL, this.createSharedChoiceForm.value)
      this.awsCommonService.postRequest(`${this.appConstants.serverUrl}/${this.SHAREDCHOICESERVER_URL}`, 'POST', this.createSharedChoiceForm.value)
      .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          this.isLoadingSave = false;
          this.globalResponse = result;
          //console.log('choice', this.globalResponse);
          this.sideNavReload = {sideNavReload:true,createdChoice:true, response:this.globalResponse };
          this.sharedService.announceEvent(this.sideNavReload);
        },
          (error) => {
            //console.log('error', error.message);
            if (error.status === 409) {
              this.isLoadingSave = false;
              this.createSharedChoiceForm.controls.sharedListName.setErrors({
                notUnique: true
              });
            }
            else {
              this.isLoadingSave = false;
              this.createChoiceRef.close();
              this.toastorService.warn('Error' + error.message);
            }
          },
          () => {
            this.toastorService.success('New Shared Choice Created Successfully!');
            this.createChoiceRef.close();
            this.sidenav.getSharedChoiceList();
          }
        );
      event.preventDefault();
    }
  }
  onRemove(item) {
    // this.selectedChoices.splice(index, 1);
    //console.log(this.selectedChoices);
    //console.log(item);
    let index = this.selectedChoices.findIndex(x => x.choiceLibraryId === item.choiceLibraryId);
    this.selectedChoices.splice(index, 1);
    let ChoiceIds = this.selectedChoices.map(x => x.choiceLibraryId);
    this.choiceLibraryIds = ChoiceIds;
    this.createSharedChoiceForm.patchValue({ choiceLibraryIds: ChoiceIds });
  }

  public trimFunction(val) {
    val.target.value = val.target.value.trim();
  }

  getChoiceList() {
    this.isLoadingSave = true;
    // this.sharedlistService.getSharedChoiceList(this.SHAREDCHOICELIST_URL)
    this.awsCommonService.getRequest(this.SHAREDCHOICELIST_URL, 'GET').pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.isLoadingSave = false;
        //this.sharedChoiceList=data
        //console.log(data);
        this.shareditem = data;
        let sharedList = [];
        this.shareditem.forEach((item) => {
            sharedList.push(item);          
        })
        this.sharedChoiceList = sharedList;
        this.loading = false;
        //console.log('sharedList', sharedList)
        //console.log('shared choice', this.sharedChoiceList);
      }
      );
  }
  onAreaListControlChanged(sharedChoices, option) {
    ////console.log(this.createSharedChoiceForm.value.choiceLibraryIds);
    //console.log(this.sharedChoiceList);
    //console.log(option);
    this.selectionList.selectedOptions = new SelectionModel<MatListOption>(true);

    const found = this.selectedChoices.some(el => el.choiceLibraryId === option.choiceLibraryId);
    if (!found) {
      this.selectedChoices.push(option);
      this.choiceLibraryIds.push(option.choiceLibraryId);
      this.createSharedChoiceForm.patchValue({ choiceLibraryIds: this.choiceLibraryIds });
    } else {
      //const index = this.selectedChoices.findIndex(el => el.choiceLibraryId === option.choiceLibraryId);
      this.onRemove(option);
    }

    return this.sharedChoiceList;
  }
  getSomeClass(option) {
    ////console.log(option);
    const found = this.selectedChoices.some(el => el.choiceLibraryId === option.choiceLibraryId);
    if (found) {
      return { createQuesselected: found };
    }
    else {
      return { removeBg: !found };
    }

  }

  public searchInput(input: any, type: string): void {
    if (type === 'sharedQuestions') {

      this.searchChoiModel = input;
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.createSharedChoiceForm.controls[controlName].hasError(errorName);
  }
  onNgModelChange($event) {
    //console.log($event);
    this.selectedOption = $event;

  }

  close(event: MouseEvent): void {
    this.createChoiceRef.close();
    event.preventDefault();
  }
  ngOnDestroy(): void {
   // throw new Error("Method not implemented.");
   this.destroy$.next(true);
   this.destroy$.unsubscribe();
  }
}
