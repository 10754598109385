import { HttpHeaders, HttpClient, HttpResponse, HttpRequest, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/operator/map';
import { AppConfig } from '../app.config';
import { environment } from 'src/environments/environment';
import * as jwt_decode from 'jwt-decode';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authUrl: string;
  private access_token: string;

  appConstants = AppConfig.settings;
  public apiUrl = this.appConstants.serverUrl;

  constructor(
    private http: HttpClient
  ) { }

  getDecodedAccessToken(token: string): any {
    let token_decode: any;
    try {
      token_decode = jwt_decode(token);
    }
    catch (Error) {
      token_decode = null;
    }
    return token_decode;
  }

  generateUUID() {
    let uid = uuid();
    //console.log(uid)
    uid = uid.replace(/[^\w\s]/gi, '')
    return uid;
  }

  login(ssoId: string) {
    // debugger;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers.set('Access-Control-Allow-Origin', '*');
    let options = {
      headers: headers
    }
    // this.appConstants.serverUrl = 'http://10.219.46.229:8080/'
    return this.http.get(`${this.appConstants.serverUrl}/auth/login?SsoId=${ssoId}`, options);
  }
  userDetails(token: string) {
    // debugger;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers.set('Access-Control-Allow-Origin', '*');
    let options = {
      headers: headers
    }
    return this.http.post('http://10.219.46.229:8080/auth/user?token=' + token + '', options);
  }

  // getToken(authorization_code:string){
  //   let headers = new HttpHeaders({'Content-Type': 'application/json'});
  //   // headers.set('Access-Control-Allow-Origin', '*');
  //   let options = {
  //     headers: headers
  //   }
  //   let params = new HttpParams();
  //   params.set("code",authorization_code);
  //   //console.log(authorization_code);
  //   return this.http.post(AppConfig.settings.appUrl + 'auth/oidc/', params, options);
  //   //response -> response.json
  // }
  // getUserDetails(token:string){
  //   debugger;
  //   let headers = new HttpHeaders({'Content-Type': 'appliapplication/x-www-form-urlencodedcation/json'});
  //   headers.set('Access-Control-Allow-Origin', '*');
  //   headers.set('Authorization', 'Basic UHJpbWVTdXJ2ZXlUb29sOmtxY2p6eWF2OTFYcHdxS21NVVZWUU1BMWVQR3FxQzBtZEN4WmhOemhwSUtZSGJjOXM0VGd6TERtOGllWXM2S2g=')

  //   let options = {
  //     headers: headers
  //   }
  //   let params = new HttpParams();
  //   params.set("grant_type","urn:pingidentity.com:oauth2:grant_type:validate_bearer");
  //   params.set("token",token);
  //   return this.http.post('https://fss.stg.inbcu.com/fss/as/token.oauth2', params, options);
  // }

  // method to validate user type
  getUserRoleID(SSO: any) {
    return this.http.get(AppConfig.settings.authUrl + `api/UserRoleID/` + SSO);
  }
}

function handleError(error: any) {
  // log error
  // could be something more sofisticated
  let errorMsg = error.message || ``;
  console.error(errorMsg);

  // throw an application level error
  return Observable.throw(errorMsg);
}
