import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { Subject } from 'rxjs';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { PilotHeader, InputSheetPilotCharSection, InputSheetPilotActSection, InputSheetPilotRelSection, InputSheetPilotStatSection, InputSheetPilotStoSection, InputSheetPilotViewSection, InputsheetPilotQuesSection, InputSheetPilotAttrSection } from 'src/app/models/inputsheetpilot';
import { MatAccordion, MatTable, MatDialog, MatTableDataSource, MatRadioChange } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { CommunicatingComponentService } from 'src/app/modules/survey/service/communicating-component.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DatePipe } from '@angular/common';
import { AuthManager } from 'src/app/helpers/auth-manager';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { takeUntil } from 'rxjs/operators';
import { ConfirmModalComponent } from '../../layout/confirm-modal.component';
import data from '../../../../../assets/json/vendorData';
@Component({
  selector: 'app-others-edit',
  templateUrl: './others-edit.component.html',
  styleUrls: ['./others-edit.component.scss']
})
export class OthersEditComponent implements OnInit, OnDestroy,AfterViewInit {
  appConstants = AppConfig.settings;
  destroy$: Subject<boolean> = new Subject<boolean>();
  editTitle: any;
  pilotDateVal: string;
  programmerNotes: FormControl = new FormControl(null, [
    Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]);
  testDate = new FormControl();
  vendor=new FormControl();
  headerEditForm: FormGroup;
  headerEditList: PilotHeader[] = [];
  formErrors: any;
  genre = new FormControl('', Validators.required);
  attributeList: any = [];
  charactersList: InputSheetPilotCharSection[] = [];
  actorNameList: InputSheetPilotActSection[] = [];
  relationshipsList: InputSheetPilotRelSection[] = [];
  statementList: InputSheetPilotStatSection[] = [];
  storyelemList: InputSheetPilotStoSection[] = [];
  viewershipList: InputSheetPilotViewSection[] = [];
  questionstimList: InputsheetPilotQuesSection[] = [];
  inputSheetId: any;
  updateInputPilot: any;
  notSaved: boolean;
  submittedForm1: boolean = false;
  submittedForm2: boolean = false;
  submittedForm3: boolean = false;
  submittedForm4: boolean = false;
  submittedForm5: boolean = false;
  submittedForm6: boolean = false;
  submittedForm7: boolean = false;
  submittedForm8: boolean = false;
  isAdmin:boolean = true;

  readonlyFlag: boolean = false;
  disableSelect: boolean = false;

  selectedGender: string;
  array = ['Male', 'Female'];
  vendorData: { "vendorId": number; "vendorName": string; }[];

  @ViewChild('accordion', { static: true }) Accordion: MatAccordion;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>
  openall: boolean = false;
  panelOpenState: boolean = false;
  editData;
  checkedbox: boolean = true;
  toggle: boolean = true;
  selectbox: boolean = true;
  myModel: boolean = true;
  initialSelection = [];
  allowMultiSelect: boolean = true;
  attributeSelection = new SelectionModel<InputSheetPilotAttrSection>(this.allowMultiSelect, this.initialSelection);
  selectedGenre = 'Select Genre';
  selectedTitle = 'Select Title';
  selectedVersion = 'Select Version';
  selectedGenreId;
  selectedShowTitleId;
  selectedVersionId;
  dataSourceHeaderEdit;
  header: PilotHeader[] = [];
  ViewSource = [];
  showTitleList = [];
  genreList = [];
  versionList = [];

  attributeData = [];
  charectersData = [];
  actorFamiliarityData = [];
  relationshipsData = [];
  statementsData = [];
  storyElementsData = [];
  viewershipData = [];
  questionStimChangesData = [];

  displayedColumnsHeaderEdit: string[] = ['genre', 'showTitle', 'episodeNumber', 'analyst', 'roughCut', 'version'];
  disButtons: boolean;
  isLoading: boolean;
  selectedVendor: any;
  vendorSelected: any;
  vendorValue: any;

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource1.data.length;
    //console.log(this.selection.selected.length)
    //console.log(this.dataSource1.data)
    //console.log(numSelected)
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // this.isAllSelected() ?
    //     this.selection.clear() :
    //     this.dataSource1.data.forEach(row => this.selection.select(row));
    //console.log('12')
    if (this.isAllSelected()) {
      this.selection.clear();
      this.dataSource1.data.forEach(
        row => {
          row.select = false;
          row.upDateFlag = true;
        }
      );
    } else {
      this.dataSource1.data.forEach(row => {
        this.selection.select(row);
        row.select = true;
        row.upDateFlag = true;
      }
      );
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: InputSheetPilotAttrSection): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.posattr + 1}`;
  }

  isCheckSelected(event, row) {
    //console.log(event);
    //console.log(row)
    this.selection.toggle(row);
    if (event.checked) {
      row.select = true;
      row.upDateFlag = true;
    } else {
      row.select = false;
      row.upDateFlag = true;
    }
    this.refreshSelectedLength();
  }

  refreshSelectedLength() {
    let selectedLength = 0;
    this.attributeList.map(item => {
      item.select == true ? selectedLength += 1 : selectedLength = selectedLength;
    })
    this.selection.selected.length = selectedLength;
  }

  // selectrow(row ?: Attributes): string {
  //   if (!row){
  //     return `${this.isAllSelected() ? 'select' : '' }`;
  //   }
  //   return `${this.selection.isSelected(row)}`;
  // }
  section1Form: FormGroup;
  displayedColumns1: string[] = ['posattr', 'attributes', 'select'];
  selection = new SelectionModel<InputSheetPilotAttrSection>(true, []);
  dataSource1;

  section2Form: FormGroup;
  dataSource2;
  displayedColumns2: string[] = ['poschar', 'name', 'description', 'gender', 'lead', 'noOpenEnd', 'columndelete'];
  // dataSource2 = new MatTableDataSource<InputSheetPilotCharSection>(this.charactersList);

  section3Form: FormGroup;
  dataSource3;
  displayedColumns3: string[] = ['posact', 'actorname', 'gender', 'columndelete'];
  // dataSource3 = new MatTableDataSource<InputSheetPilotActSection>(this.actorNameList);

  section4Form: FormGroup;
  dataSource4;
  displayedColumns4: string[] = ['posrel', 'relationships', 'columndelete'];
  // dataSource4 = new MatTableDataSource<InputSheetPilotRelSection>(this.relationshipsList);

  section5Form: FormGroup;
  dataSource5;
  displayedColumns5: string[] = ['posstat', 'statements', 'columndelete'];
  // dataSource5 = new MatTableDataSource<InputSheetPilotStatSection>(this.statementList);

  section6Form: FormGroup;
  dataSource6;
  displayedColumns6: string[] = ['posstoryelem', 'storyelements', 'columndelete'];
  // dataSource6 = new MatTableDataSource<InputSheetPilotStoSection>(this.storyelemList);

  section7Form: FormGroup;
  dataSource7;
  displayedColumns7: string[] = ['posview', 'viewshowtitle', 'columndelete'];
  // dataSource7 = new MatTableDataSource<InputSheetPilotViewSection>(this.viewershipList);

  section8Form: FormGroup;
  dataSource8;
  displayedColumns8: string[] = ['posques', 'quesnstim', 'columndelete'];
  projectType: FormControl;
  constructor(
    private fb: FormBuilder,
    private communicatingComponentService: CommunicatingComponentService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: AwsCommonService,
    private toastorService: NotificationService,
    private datePipe: DatePipe,
    private authManager: AuthManager
  ) {

    this.editTitle = this.appConstants.inputsheetHeader.editTitle;

    this.formErrors = {
      genre: {},
      showTitle: {},
      analyst: {},
      roughCut: {},
      version: {}
    }

    this.headerEditForm = this.fb.group({
      genre: new FormControl('', [Validators.required]),
      showTitle: new FormControl('', [Validators.required]),
      episodeNumber: new FormControl('', [Validators.required]),
      analyst: new FormControl('', [Validators.required, validateEmptyField,
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]),
      roughCut: new FormControl('', [Validators.required]),
      version: new FormControl('', [Validators.required])
    })

    this.section1Form = this.fb.group({
      section1Array: this.fb.array([])
    });

    this.section2Form = this.fb.group({
      section2Array: this.fb.array([]),
      lead: new FormControl(null),
      onOpenEnd: new FormControl(null)
    });

    this.section3Form = this.fb.group({
      section3Array: this.fb.array([]),
    });

    this.section4Form = this.fb.group({
      section4Array: this.fb.array([])
    });

    this.section5Form = this.fb.group({
      section5Array: this.fb.array([])
    });

    this.section6Form = this.fb.group({
      section6Array: this.fb.array([])
    });

    this.section7Form = this.fb.group({
      section7Array: this.fb.array([])
    });

    this.section8Form = this.fb.group({
      section8Array: this.fb.array([])
    });

    this.sendMessage();
  }
  sendMessage() {
    this.communicatingComponentService.sendCommunication(true);
  }
  clearMessage() {
    this.section1Form.reset();
    this.section2Form.reset();
    this.section3Form.reset();
    this.section4Form.reset();
    this.section5Form.reset();
    this.section6Form.reset();
    this.section7Form.reset();
    this.section8Form.reset();
    this.communicatingComponentService.sendCommunication(false);
    this.router.navigate(['../others'], { relativeTo: this.route });
  }

  onFormValuesChanged() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      // Clear previous errors
      this.formErrors[field] = {};
      // Get the control
      const control = this.headerEditForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }

  ngOnInit() {
    this.dataSourceHeaderEdit = new MatTableDataSource(this.header);
    this.vendorData= data;
    this.commonService.getRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/showprojectgenres`,
      'GET')
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        //console.log(data);
        this.genreList = data;
      });
    
    if (this.commonService.editPilotData) {
      this.editData = this.commonService.editPilotData;
      if(this.authManager.userRole != 'ADMIN'){
        this.isAdmin = false;
        this.disButtons = true;
        this.readonlyFlag = true;
        this.disableSelect = true;
      }
    } else {
      this.router.navigate(['../others'], { relativeTo: this.route });
    }


    //console.log(this.editData);
    this.isLoading = true;
    this.inputSheetId = this.commonService.editPilotData.inputSheetId;
    let editData = [];
    // const getEdit = {
    //   params: new HttpParams()
    //     .set('id', this.inputSheetId)
    //   }
    const getEdit = this.inputSheetId ? { 'id': this.inputSheetId } : {};
    this.commonService.getApiRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/pilotby`,
      'GET', getEdit)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          console.log(data);
          // console.log(data[0].status);
          if (data[0].status === 'SUBMITTED') {
            //console.log('submitted pilot');
            this.disButtons = true;
            this.readonlyFlag = true;
            this.disableSelect = true;
          }
          // console.log(this.readonlyFlag)
          editData = data;
          this.selectedGenreId = data[0].primeShowTitle.projectType.c2027ProjectTypeId;
          this.selectedGenre = data[0].primeShowTitle.projectType.c2027ProjectTypeDesc;
          this.selectedTitle = data[0].primeShowTitle.showTitle;
          this.selectedShowTitleId = data[0].primeShowTitle.primeShowTitleId;
          this.selectedVersion = data[0].titleVersion.titleVersionName;
          this.selectedVersionId = data[0].titleVersion.titleVersionId;
          this.headerEditForm.controls.genre.setValue(this.selectedGenreId);
          this.onSelectedGenre(this.selectedGenreId);
          this.headerEditForm.controls.showTitle.setValue(this.selectedShowTitleId);
          this.headerEditForm.controls.analyst.setValue(data[0].analyst);
          this.headerEditForm.controls.roughCut.setValue(data[0].roughCut);
          this.headerEditForm.controls.episodeNumber.setValue(data[0].episodenumber);
          this.headerEditForm.controls.version.setValue(this.selectedVersionId);
          this.programmerNotes.setValue(data[0].programmer);
          // console.log(data)
          this.testDate.setValue(data[0].testDate);
          if(data){
            const index=this.vendorData.findIndex(item=>item.vendorId===data[0].vendorId);
           
            if(index>-1){
              this.vendorSelected=this.vendorData[index].vendorName;
            
              this.vendor.setValue(this.vendorSelected);
            }
          }
          this.header.push(this.headerEditForm.value);
          //console.log(this.header);          
          this.dataSourceHeaderEdit = new MatTableDataSource(this.header);
        }
      )

    this.commonService.getRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/titles`,
      'GET')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          //console.log(data);
          this.versionList = data;
        },
        error => {
          //console.log('show title version');
          this.toastorService.warn(error);
        }
      );



    this.dataSource1 = new MatTableDataSource(this.attributeList);
    this.dataSource2 = new MatTableDataSource<InputSheetPilotCharSection>(this.charactersList);
    this.dataSource3 = new MatTableDataSource<InputSheetPilotActSection>(this.actorNameList);
    this.dataSource4 = new MatTableDataSource<InputSheetPilotRelSection>(this.relationshipsList);
    this.dataSource5 = new MatTableDataSource<InputSheetPilotStatSection>(this.statementList);
    this.dataSource6 = new MatTableDataSource<InputSheetPilotStoSection>(this.storyelemList);
    this.dataSource7 = new MatTableDataSource<InputSheetPilotViewSection>(this.viewershipList);
    this.dataSource8 = new MatTableDataSource<InputsheetPilotQuesSection>(this.questionstimList);

    // const inputSheetId = this.inputSheetId ?
    //   {
    //     params: new HttpParams()
    //       .set('inputSheetId', this.inputSheetId)
    //   } : {};
    const inputSheetId = this.inputSheetId ? { 'inputSheetId': this.inputSheetId } : {};
    this.commonService.getApiRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/getAllPilotSections`,
      'GET', inputSheetId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        //console.log(result);
        this.isLoading = false;
        this.attributeData = result.Section_Data.attributesList;
        this.charectersData = result.Section_Data.characters;
        this.actorFamiliarityData = result.Section_Data.actorFamiliarity;
        this.relationshipsData = result.Section_Data.relationShips;
        this.statementsData = result.Section_Data.statements;
        this.storyElementsData = result.Section_Data.storyElements;
        this.viewershipData = result.Section_Data.viewerShip;
        this.questionStimChangesData = result.Section_Data.questionStimChanges;
        this.loadAttributes(this.attributeData);
        this.loadCharacters(this.charectersData);
        this.loadActor(this.actorFamiliarityData);
        this.loadRelations(this.relationshipsData);
        this.loadStatements(this.statementsData);
        this.loadStoryElem(this.storyElementsData);
        this.loadViewership(this.viewershipData);
        this.loadQuestStim(this.questionStimChangesData);

      })

    // this.getAttributes();
    // this.getCharacters();
    // this.getActor();
    // this.getRelations();
    // this.getViewership();
    // this.getStoryElem();
    // this.getStatements();
    // this.getQuestionStim();
  }
   ngAfterViewInit(){
 this.selectedVendor=this.vendorSelected
 console.log(this.selectedVendor);
   }
  onSelectedVendor(e){
    console.log(e);
    if(typeof e !==undefined){
      this.selectedVendor=e.value;
    }   
    else{
      this.selectedVendor=this.vendorSelected;
    }
    console.log(this.selectedVendor)
    
  }

  onSelectedGenre(genre) {
    //console.log(genre);
    let selectedGenre = genre;
    //console.log(selectedGenre);
    this.commonService.getRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/showtitles/?genretype=${selectedGenre}`,
      'GET')
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        //console.log(data);
        this.showTitleList = data;
        this.dataSourceHeaderEdit._updateChangeSubscription();
      }, error => this.toastorService.warn(error)
      );
  }


  changeVal(value) {
    this.checkedbox = !value;
  }

  updateNewPilot(pilotStatus) {
    if (!this.headerEditForm.valid) {
      //console.log('invalid');
      return false;
    } else {
      //console.log(this.headerEditForm);
      
      pilotStatus == 'DRAFT' ? status = 'DRAFT' : status = 'SUBMITTED';
      this.pilotDateVal = this.datePipe.transform(this.testDate.value, 'yyyy/MM/dd');
     this.vendorValue=this.vendor.value;
      this.updateInputPilot = {
        inputSheetId: this.inputSheetId,
        vendor:this.vendorValue,
        inputSheetType: {
          inputSheetType: "Others",
        },
        programmerNotes: this.programmerNotes.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"'),
        testDate: this.pilotDateVal,
      
        surveyAnalyst: this.headerEditForm.controls.analyst.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"'),
        inputSheetStatus: {
          inputSheetStatus: status
        },
        inputSheetPilot: [{
          primeShowTitle: {
            primeShowTitleId: this.headerEditForm.controls.showTitle.value,
            projectType: {
              c2027ProjectTypeId: this.headerEditForm.controls.genre.value
            },
          },
          inputSheetType: {
            inputSheetType: "Others",
          },
          roughCut: this.headerEditForm.controls.roughCut.value,
          episode: this.headerEditForm.controls.episodeNumber.value,
          titleVersion: {
            // titleVersionDesc: this.headerCreateForm.controls.version.value 
            titleVersionId: this.headerEditForm.controls.version.value
          }
        }],

      }

      this.updatePilot(status);
    }
    //console.log(this.updateInputPilot);
    // this.commonService.putRequest(`
    // ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}`, 'PUT', this.updateInputPilot)
    // .pipe(takeUntil(this.destroy$))
    // .subscribe(result => {
    //     //console.log(result);
    //     this.toastorService.success('Saved Successfully');
    //     // this.router.navigate(['../pilot'], { relativeTo: this.route });

    //   }, error => {
    //     this.toastorService.warn(error.message);
    //   });
    // }

  }

  // submitNewPilot() {
  //   //console.log(this.headerEditForm);
  //   // if (!this.headerEditForm.valid) {
  //   //   this.onFormValuesChanged();
  //   //   return;
  //   // } else {
  //     this.isLoading=true;
  //     let genreid = this.editData.genreId;
  //     let showTitleid = this.editData.showTitleid;
  //     let roughCut;

  //     this.updateInputPilot = {
  //       inputSheetId: this.inputSheetId,
  //       inputSheetType: {
  //         inputSheetType: "Pilot",
  //       },
  //       programmerNotes: this.programmerNotes.value,
  //       surveyAnalyst: this.headerEditForm.controls.analyst.value,
  //       inputSheetStatus: {
  //         inputSheetStatus: 'Submitted'
  //       },
  //       inputSheetPilot: [{
  //         primeShowTitle: {
  //           primeShowTitleId: showTitleid,
  //           projectType: {
  //             c2027ProjectTypeId: genreid
  //           },
  //         },
  //         inputSheetType: {
  //           inputSheetType: "Pilot",
  //         },
  //         roughCut: this.headerEditForm.controls.roughCut.value,
  //         episode: this.headerEditForm.controls.episodeNumber.value,
  //         titleVersion: {
  //           // titleVersionDesc: this.headerCreateForm.controls.version.value 
  //           titleVersionId: this.headerEditForm.controls.version.value
  //         }
  //       }],

  //     }
  //     //console.log(this.updateInputPilot);
  //     this.commonService.putRequest(`
  //     ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}`, 'PUT', this.updateInputPilot)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(result => {
  //       this.isLoading=false;
  //         //console.log(result);
  //         this.toastorService.success('Submitted Successfully');
  //         this.router.navigate(['../pilot'], { relativeTo: this.route });

  //       }, error => {
  //         this.toastorService.warn(error.message);
  //         this.isLoading=false;
  //       });
  //   // }

  // }

  updatePilot(status) {
    this.isLoading = true;
    //console.log(status);
    this.commonService.putRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}`, 'PUT', this.updateInputPilot)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        //console.log(result);
        if (status == 'DRAFT') {
          this.toastorService.success('Generated Successfully');
        } else {
          this.toastorService.success('Submitted Successfully');
          this.router.navigate(['../others'], { relativeTo: this.route });
        }
        this.isLoading = false;
      }, error => {
        this.toastorService.warn(error.message);
        this.isLoading = false;
      });
  }

  getAttributes() {
    this.isLoading = true;
    let attributes = [];
    let data = [];
    // const getAttr = {
    //   params: new HttpParams()
    //     .set('inputSheetId', this.inputSheetId)
    //     .append('sectionName', 'ATTRIBUTE LIST')
    //     .append('sectiontype', 'PILOT')
    //   } 
    const getAttr = { 'inputSheetId': this.inputSheetId, 'sectionName': 'ATTRIBUTE LIST', 'sectiontype': 'PILOT' };
    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/sections`,
      'GET', getAttr)
      .pipe(takeUntil(this.destroy$))
      .subscribe(chardata => {
        //console.log(chardata);
        this.isLoading = false;
        attributes = chardata;
        attributes = attributes.map(mapdata => {
          let check;
          mapdata.attributeCheck == 'Y' ? check = true : check = false;
          data.push({
            rowid: mapdata.inputSheetUserSectionId,
            posattr: mapdata.sheetQuestion.questionSequenceNum,
            attributes: mapdata.sheetQuestion.questionLongText,
            select: check,
            sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
            upDateFlag: mapdata.upDateFlag
          });
          const ArrayData = this.fb.group({
            attributes: new FormControl(mapdata.sheetQuestion.questionLongText, [
              Validators.required,
              Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
              validateEmptyField
            ])
          });
          (this.section1Form.get('section1Array') as FormArray).push(ArrayData);
          //  //console.log(this.section1Form.value);
        })

        this.attributeList = data;
        this.refreshSelectedLength();
        //console.log(this.attributeList);
        this.dataSource1 = new MatTableDataSource(this.attributeList);
      }, error => {
        this.toastorService.warn(error.message);
        this.isLoading = false;
      })
  }

  getCharacters() {
    this.isLoading = true;
    let characters = [];
    let data = [];
    // const getChar = {
    //   params: new HttpParams()
    //     .set('inputSheetId', this.inputSheetId)
    //     .append('sectionName', 'CHARECTERS')
    //     .append('sectiontype', 'PILOT')
    //   } 
    const getChar = { 'inputSheetId': this.inputSheetId, 'sectionName': 'CHARECTERS', 'sectiontype': 'PILOT' };
    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/sections`,
      'GET', getChar)
      .pipe(takeUntil(this.destroy$))
      .subscribe(chardata => {
        characters = chardata;
        this.isLoading = false;
        //console.log(characters);
        characters = characters.map(mapdata => {
          let maplead;
          let mapnoOpenEnd;
          mapdata.lead == 'Y' ? maplead = true : maplead = false;
          mapdata.openEnd == 'Y' ? mapnoOpenEnd = true : mapnoOpenEnd = false;
          data.push({
            rowid: mapdata.inputSheetuserSectionId,
            poschar: mapdata.sequence,
            name: mapdata.name,
            description: mapdata.description,
            gender: mapdata.gender,
            lead: maplead,
            noOpenEnd: mapnoOpenEnd,
            upDateFlag: mapdata.upDateFlag
          });
          const ArrayData = this.fb.group({
            name: new FormControl(mapdata.name, [
              Validators.required,
              Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
              validateEmptyField
            ]),
            description: new FormControl(mapdata.description, [
              Validators.required,
              Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
              validateEmptyField
            ]),
            gender: new FormControl(mapdata.gender, [Validators.required, validateEmptyField])
          });
          (this.section2Form.get('section2Array') as FormArray).push(ArrayData);
          //  //console.log(this.section2Form.value);
        });
        this.charactersList = data;
        //console.log(this.charactersList);
        this.dataSource2 = new MatTableDataSource<InputSheetPilotCharSection>(this.charactersList);
      }, error => {
        this.toastorService.warn(error.message);
        this.isLoading = false;
      });
  }

  getActor() {
    let actors = [];
    let data = [];
    this.isLoading = true;
    // const getActor = {
    //   params: new HttpParams()
    //     .set('inputSheetId', this.inputSheetId)
    //     .append('sectionName', 'ACTOR FAMILIARITY')
    //     .append('sectiontype', 'PILOT')
    //   } 
    const getActor = { 'inputSheetId': this.inputSheetId, 'sectionName': 'ACTOR FAMILIARITY', 'sectiontype': 'PILOT' };
    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/sections`,
      'GET', getActor)
      .pipe(takeUntil(this.destroy$))
      .subscribe(

        chardata => {
          this.isLoading = false;
          //console.log(chardata);
          actors = chardata;
          actors = actors.map(mapdata => {
            data.push({
              rowid: mapdata.inputSheetuserSectionId,
              posact: mapdata.sequence,
              actorname: mapdata.name,
              gender: mapdata.gender,
              upDateFlag: mapdata.upDateFlag
            });
            const ArrayData = this.fb.group({
              actorname: new FormControl(mapdata.name, [
                Validators.required,
                Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
                validateEmptyField
              ]),
              gender: new FormControl(mapdata.gender, [Validators.required, validateEmptyField])
            });
            (this.section3Form.get('section3Array') as FormArray).push(ArrayData);
            //  //console.log(this.section3Form.value);
          });
          this.actorNameList = data;
          //console.log(this.actorNameList);
          this.dataSource3 = new MatTableDataSource<InputSheetPilotActSection>(this.actorNameList);
        },
        error => {
          this.toastorService.warn(error.message);
          this.isLoading = false;
        }
      );
  }

  getRelations() {
    this.isLoading = true;
    let relationships = [];
    let data = [];
    // const getRel = {
    //   params: new HttpParams()
    //     .set('inputSheetId', this.inputSheetId)
    //     .append('sectionName', 'RELATIONSHIPS')
    //     .append('sectiontype', 'PILOT')
    //   } 
    const getRel = { 'inputSheetId': this.inputSheetId, 'sectionName': 'RELATIONSHIPS', 'sectiontype': 'PILOT' };
    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/sections`,
      'GET', getRel)
      .pipe(takeUntil(this.destroy$))
      .subscribe(chardata => {
        //console.log(chardata);
        this.isLoading = false;
        relationships = chardata;
        relationships = relationships.map(mapdata => {
          data.push({
            rowid: mapdata.inputSheetuserSectionId,
            posrel: mapdata.sequence,
            relationships: mapdata.description,
            upDateFlag: mapdata.upDateFlag
          });
          const ArrayData = this.fb.group({
            relationships: new FormControl(mapdata.description, [
              Validators.required,
              Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
              validateEmptyField
            ])
          });
          (this.section4Form.get('section4Array') as FormArray).push(ArrayData);
          //  //console.log(this.section4Form.value);
        })

        this.relationshipsList = data;
        //console.log(this.relationshipsList);
        this.dataSource4 = new MatTableDataSource<InputSheetPilotRelSection>(this.relationshipsList);
      }, error => {
        this.toastorService.warn(error.message);
        this.isLoading = false;
      })
  }

  getStatements() {
    this.isLoading = true;
    let statements = [];
    let data = [];
    // const getStat = {
    //   params: new HttpParams()
    //     .set('inputSheetId', this.inputSheetId)
    //     .append('sectionName', 'STATEMENT')
    //     .append('sectiontype', 'PILOT')
    //   } 
    const getStat = { 'inputSheetId': this.inputSheetId, 'sectionName': 'STATEMENT', 'sectiontype': 'PILOT' };
    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/sections`,
      'GET', getStat)
      .pipe(takeUntil(this.destroy$))
      .subscribe(chardata => {
        this.isLoading = false;
        //console.log(chardata);
        statements = chardata;
        statements = statements.map(mapdata => {
          data.push({
            rowid: mapdata.inputSheetuserSectionId,
            posstat: mapdata.sheetQuestion.questionSequenceNum,
            statements: mapdata.sheetQuestion.questionLongText,
            sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
            upDateFlag: mapdata.upDateFlag
          });
          const ArrayData = this.fb.group({
            statements: new FormControl(mapdata.sheetQuestion.questionLongText, [
              Validators.required,
              Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
              validateEmptyField
            ])
          });
          (this.section5Form.get('section5Array') as FormArray).push(ArrayData);
          //  //console.log(this.section5Form.value);
        })

        this.statementList = data;
        //console.log(this.statementList);
        this.dataSource5 = new MatTableDataSource<InputSheetPilotStatSection>(this.statementList);
      }, error => {
        this.toastorService.warn(error.message);
        this.isLoading = false;
      })
  }

  getStoryElem() {
    let storyElem = [];
    let data = [];
    this.isLoading = true;
    // const getSto = {
    //   params: new HttpParams()
    //     .set('inputSheetId', this.inputSheetId)
    //     .append('sectionName', 'STORY ELEMENTS')
    //     .append('sectiontype', 'PILOT')
    //   } 
    const getSto = { 'inputSheetId': this.inputSheetId, 'sectionName': 'STORY ELEMENTS', 'sectiontype': 'PILOT' };
    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/sections`,
      'GET', getSto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(chardata => {
        this.isLoading = false;
        //console.log(chardata);
        storyElem = chardata;
        storyElem = storyElem.map(mapdata => {
          data.push({
            rowid: mapdata.inputSheetuserSectionId,
            posques: mapdata.sequence,
            storyelements: mapdata.description,
            upDateFlag: mapdata.upDateFlag
          });
          const ArrayData = this.fb.group({
            storyelements: new FormControl(mapdata.description, [
              Validators.required,
              Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
              validateEmptyField
            ])
          });
          (this.section6Form.get('section6Array') as FormArray).push(ArrayData);
          //  //console.log(this.section6Form.value);
        })

        this.storyelemList = data;
        //console.log(this.storyelemList);
        this.dataSource6 = new MatTableDataSource<InputSheetPilotStoSection>(this.storyelemList);
      }, error => {
        this.toastorService.warn(error.message);
        this.isLoading = false;
      })
  }

  getViewership() {
    this.isLoading = true;
    let viewership = [];
    let data = [];
    // const getView = {
    //   params: new HttpParams()
    //     .set('inputSheetId', this.inputSheetId)
    //     .append('sectionName', 'VIEWERSHIP')
    //     .append('sectiontype', 'PILOT')
    //   } 
    const getView = { 'inputSheetId': this.inputSheetId, 'sectionName': 'VIEWERSHIP', 'sectiontype': 'PILOT' };
    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/sections`,
      'GET', getView)
      .pipe(takeUntil(this.destroy$))
      .subscribe(chardata => {
        //console.log(chardata);
        this.isLoading = false;
        viewership = chardata;
        viewership = viewership.map(mapdata => {
          data.push({
            rowid: mapdata.inputSheetuserSectionId,
            posview: mapdata.sequence,
            viewShowTitle: mapdata.description,
            upDateFlag: mapdata.upDateFlag
          });
          const ArrayData = this.fb.group({
            viewShowTitle: new FormControl(mapdata.description, [
              Validators.required,
              Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
              validateEmptyField
            ])
          });
          (this.section7Form.get('section7Array') as FormArray).push(ArrayData);
          //  //console.log(this.section7Form.value);
        })

        this.viewershipList = data;
        //console.log(this.viewershipList);
        this.dataSource7 = new MatTableDataSource<InputSheetPilotViewSection>(this.viewershipList);

      }, error => {
        this.toastorService.warn(error.message);
        this.isLoading = false;
      })
  }

  getQuestionStim() {
    let questionstim = [];
    let data = [];
    this.isLoading = true;
    // const getQues = {
    //   params: new HttpParams()
    //     .set('inputSheetId', this.inputSheetId)
    //     .append('sectionName', 'QUESTION AND STIM CHANGES')
    //     .append('sectiontype', 'PILOT')
    //   } 
    const getQues = { 'inputSheetId': this.inputSheetId, 'sectionName': 'QUESTION AND STIM CHANGES', 'sectiontype': 'PILOT' };
    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/sections`,
      'GET', getQues)
      .pipe(takeUntil(this.destroy$))
      .subscribe(chardata => {
        this.isLoading = false;
        //console.log(chardata);
        questionstim = chardata;
        questionstim = questionstim.map(mapdata => {
          data.push({
            rowid: mapdata.inputSheetUserSectionId,
            posques: mapdata.sequence,
            quesnstim: mapdata.description,
            upDateFlag: mapdata.upDateFlag
          });
          const ArrayData = this.fb.group({
            quesnstim: new FormControl(mapdata.description, [
              Validators.required,
              Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
              validateEmptyField
            ])
          });
          (this.section8Form.get('section8Array') as FormArray).push(ArrayData);
          //  //console.log(this.section8Form.value);
        })

        this.questionstimList = data;
        //console.log(this.questionstimList);
        this.dataSource8 = new MatTableDataSource<InputsheetPilotQuesSection>(this.questionstimList);

      }, error => {
        this.toastorService.warn(error.message);
        this.isLoading = false;
      })
  }

  change(element, $event) {
    //console.log(element)
    //console.log(event)
  }

  toggleAll(selection) {
    if (selection) {
      this.Accordion.multi = true;
      this.Accordion.openAll();
      this.toggle = false;
    } else {
      this.Accordion.closeAll();
      this.Accordion.multi = false;
      this.toggle = true;
    }
  }

  //Confirmation PopUP

  openconfirmModal(type: any, id: any): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, { panelClass: 'confirmModal', disableClose: true, data: { msg: (type === 'cancel') ? 'cancel the changes' : (type == 'Submit') ? 'submit? All the unsaved changes will be lost' : '' } });
    dialogRef.afterClosed().subscribe(dialogResult => {
      //console.log(dialogResult);
      if (dialogResult === 'edit') {
        this.router.navigate(['../others'], { relativeTo: this.route });
      }
      if (dialogResult === 'Submit') {
        this.updateNewPilot(type);
      }
      // else {
      //   //console.log(this.clearMessage)
      // }
    });
  }

  /** Section 1 - Add, Delete and Save method **/

  updateVal(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.attributes = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    // ele.attributes.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
    //console.log(this.section1Form.valid)
  }

  onChangeselect(ele, event) {
    //console.log(ele)
    // ele.select = ele.selectbox;
  }

  onAddattrRow() {
    let newRow = {
      posattr: (this.attributeList.length + 1), attributes: '', select: true,
      sheetQuestionId: null, metaDataFlag: true, upDateFlag: true
    };
    //console.log(this.attributeList);
    const row = this.fb.group({
      attributes: new FormControl('', [Validators.required,
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField])
    });
    (this.section1Form.get('section1Array') as FormArray).push(row);

    this.attributeList.push(newRow);
    // console.log(this.attributeList.length)
    // console.log(this.section1Form.controls.section1Array);
    // console.log((this.section1Form.get('section1Array') as FormArray).controls[26]);
    // console.log((this.section1Form.get('section1Array') as FormArray).controls[26].controls.attributes);
    this.refreshSelectedLength();
    this.dataSource1 = new MatTableDataSource(this.attributeList);
    //console.log(this.attributeList);
    this.dataSource1._updateChangeSubscription();
  }
  
  attributeSave(formValue) {
    if (formValue.invalid) {
      this.submittedForm1 = true;
      return false;
    } else {
      // console.log(this.attributeList)
      let attrsSave = [];
      // console.log(this.attributeList);
      this.isLoading = true;

      attrsSave = this.attributeList.map(item => {
        let select;
        let createdBy;
        item.select === true ? select = 'Y' : select = 'N';
        item.metaDataFlag === false ? createdBy = 'METADATA' : createdBy = null;
        return {
          inputSheetUserSectionId: item.rowid,
          attributeCheck: select,
          name: item.attributes,
          sequence: item.posattr,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 601,
            sequenceNumber: 1,
            sectionName: 'ATTRIBUTE LIST',
            sectionGroup: ""
          },
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          sheetQuestion: {
            inputSheetQuestionsId: item.sheetQuestionId,
            questionLongText: item.attributes,
            questionSequenceNum: item.posattr
          },
          createdBy: createdBy,
          upDateFlag: item.upDateFlag
        }
      })
      //console.log(attrsSave);
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', attrsSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.loadAttributes(result);
          this.isLoading = false;
          //console.log(result);
          // this.getAttributes();
          this.toastorService.success('Saved successfully');
        }, error => {
          //console.log(error);
          this.toastorService.warn('Error');
          this.isLoading = false;
        });
    }
  }

  loadAttributes(attributes) {
    if (attributes == null) {
      this.onAddattrRow();
    } else {
      let data = [];
      // console.log(attributes);
      (this.section1Form.controls.section1Array as FormArray).clear();
      attributes = attributes.map(mapdata => {
        let check;
        let metaFlag;
        mapdata.attributeCheck == 'Y' ? check = true : check = false;
        mapdata.createdBy == 'METADATA' ? metaFlag = false : metaFlag = true
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          posattr: mapdata.sheetQuestion.questionSequenceNum,
          attributes: mapdata.sheetQuestion.questionLongText,
          select: check,
          sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
          metaDataFlag: metaFlag,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          attributes: new FormControl(mapdata.sheetQuestion.questionLongText, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section1Form.get('section1Array') as FormArray).push(ArrayData);
        //console.log(this.section1Form.value);
      })

      this.attributeList = data;
      this.refreshSelectedLength();
      //console.log(this.attributeList);
      this.dataSource1 = new MatTableDataSource(this.attributeList);
    }

  }


  /** Section 2 - Add, Delete and Save method **/

  updateValdesc(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.description = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    // ele.description.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  radioChange(ele, event: MatRadioChange) {
    //console.log(event);
    //console.log(event.value);
    //console.log(event.source);
    ele.gender = event.value;
    ele.upDateFlag = true;
  }

  onChange(ele, event) {
    //console.log(ele)
    //console.log(event)
    ele.lead = event.checked;
    ele.upDateFlag = true;
  }

  onChangeOpenend(ele, event) {
    //console.log(ele)
    //console.log(event)
    ele.noOpenEnd = event.checked;
    ele.upDateFlag = true
  }

  onAddcharRow() {
    let newRow = {
      rowid: null, poschar: (this.charactersList.length + 1), name: '', description: '', gender: '', lead: false, noOpenEnd: false,
      upDateFlag: true
    };
    //console.log(this.charactersList);
    const row = this.fb.group({
      name: new FormControl(null, [Validators.required,
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField]),
      description: new FormControl(null, [Validators.required,
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField]),
      gender: new FormControl(null, [Validators.required])
    });
    (this.section2Form.get('section2Array') as FormArray).push(row);

    this.charactersList.push(newRow);
    this.dataSource2 = new MatTableDataSource(this.charactersList);
    //console.log(this.charactersList);
    this.dataSource2._updateChangeSubscription();
  }

  deletechar(elm, index) {
    //  this.isLoading=true;
    //console.log(elm);
    //console.log(this.charactersList);
    //console.log(elm.rowid);
    // elm.rowid == (null || '') ? this.isLoading = false : this.isLoading = true;
    this.charactersList.splice(index, 1);
    (this.section2Form.get('section2Array') as FormArray).removeAt(index);
    this.dataSource2 = new MatTableDataSource(this.charactersList);

    if (elm.rowid != null || '') {
      this.isLoading = true;
      // const delChar = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      //   }
      const delChar = { 'inputSheetuserSectionId': elm.rowid };

      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delChar)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.isLoading = false;
          this.toastorService.success('Deleted Successfully');
          //   this.getCharacters();
        }, error => {
          this.toastorService.warn(error.message);
          this.isLoading = false;
        })
    }
  }

  updateValname(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.name = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    // ele.name.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  characterSave(formValue) {
    if (formValue.invalid) {
      this.submittedForm2 = true;
      return false;
    } else {
      //console.log(this.charactersList)
      let charactersSave = [];
      let lead;
      //console.log(this.charactersList);
      this.isLoading = true;
      charactersSave = this.charactersList.map(item => {
        let maplead: any = false;
        let mapopenEnd: any = false;
        item.lead == true ? maplead = 'Y' : maplead = 'N';
        item.noOpenEnd == true ? mapopenEnd = 'Y' : mapopenEnd = 'N';
        return {
          inputSheetUserSectionId: item.rowid,
          name: item.name,
          description: item.description,
          gender: item.gender,
          lead: maplead,
          openEnd: mapopenEnd,
          sequence: item.poschar,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 610,
            sequenceNumber: 2,
            sectionName: 'CHARECTERS',
            sectionGroup: ""
          },
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          sheetQuestion: null,
          upDateFlag: item.upDateFlag
        }
      })
      //console.log(charactersSave);
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', charactersSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.loadCharacters(result);
          this.isLoading = false;
          //console.log(result);
          // this.getCharacters();
          this.toastorService.success('Saved successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.isLoading = false;
        });
    }
  }

  loadCharacters(characters) {
    if (characters == null) {
      this.onAddcharRow();
    } else {
      let data = [];
      (this.section2Form.controls.section2Array as FormArray).clear();
      characters = characters.map(mapdata => {
        let select;
        let mapnoOpenEnd;
        // let mapgender;
        mapdata.lead == 'Y' ? select = true : select = false;
        mapdata.openEnd == 'Y' ? mapnoOpenEnd = true : mapnoOpenEnd = false;
        // mapdata.gender == 'Male' ? mapgender = 'M' : mapgender = 'F';

        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          name: mapdata.name,
          description: mapdata.description,
          gender: mapdata.gender,
          lead: select,
          noOpenEnd: mapnoOpenEnd,
          poschar: mapdata.sequence,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          name: new FormControl(mapdata.name, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ]),
          description: new FormControl(mapdata.description, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ]),
          gender: new FormControl(mapdata.gender, [Validators.required, validateEmptyField])
        });
        (this.section2Form.get('section2Array') as FormArray).push(ArrayData);
        //console.log(this.section2Form.value);
      })

      this.charactersList = data;
      //console.log(this.charactersList);
      this.dataSource2 = new MatTableDataSource<InputSheetPilotCharSection>(this.charactersList);
    }

  }


  /** Section 3 - Add, Delete and Save method **/

  onAddactRow() {
    let newRow = {
      rowid: null, posact: (this.actorNameList.length + 1), actorname: '', gender: '',
      upDateFlag: true
    };
    //console.log(this.actorNameList);
    const row = this.fb.group({
      actorname: new FormControl(null, [Validators.required,
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField]),
      gender: new FormControl(null, [Validators.required])
    });
    (this.section3Form.get('section3Array') as FormArray).push(row);

    this.actorNameList.push(newRow);
    this.dataSource3 = new MatTableDataSource(this.actorNameList);
    //console.log(this.actorNameList);
    this.dataSource3._updateChangeSubscription();
  }

  deleteAct(elm, index) {
    //console.log(elm);
    //console.log(this.actorNameList)
    // this.isLoading=true;
    // elm.rowid == (null || '') ? this.isLoading = false : this.isLoading = true
    this.actorNameList.splice(index, 1);
    (this.section3Form.get('section3Array') as FormArray).removeAt(index);
    this.dataSource3 = new MatTableDataSource(this.actorNameList);

    if (elm.rowid != null || '') {
      this.isLoading = true;
      // const delAct = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      //   }
      const delAct = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delAct)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.isLoading = false;
          this.toastorService.success('Deleted Successfully');
          //  this.getActor();
        }, error => {
          this.toastorService.warn(error.message);
          this.isLoading = false;
        })
    }
  }

  updateValActname(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.actorname = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    // ele.actorname.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  actsecSave(formValue) {
    if (formValue.invalid) {
      this.submittedForm3 = true;
      return false
    } else {
      //console.log(this.actorNameList)
      let actorsSave = [];
      //console.log(this.actorNameList);
      this.isLoading = true;
      actorsSave = this.actorNameList.map(item => {
        return {
          inputSheetUserSectionId: item.rowid,
          name: item.actorname,
          gender: item.gender,
          sequence: item.posact,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 611,
            sequenceNumber: 3,
            sectionName: 'ACTOR FAMILIARITY',
            sectionGroup: ""
          },
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          sheetQuestion: null,
          upDateFlag: item.upDateFlag
        }
      })
      //console.log(actorsSave);
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', actorsSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.loadActor(result);
          this.isLoading = false;
          //console.log(result);
          // this.getActor();
          this.toastorService.success('Saved successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.isLoading = false;
        });
    }
  }

  loadActor(actors) {
    if (actors == null) {
      this.onAddactRow();
    } else {
      let data = [];
      (this.section3Form.controls.section3Array as FormArray).clear();
      actors = actors.map(mapdata => {
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          actorname: mapdata.name,
          gender: mapdata.gender,
          posact: mapdata.sequence,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          actorname: new FormControl(mapdata.name, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ]),
          gender: new FormControl(mapdata.gender, [Validators.required, validateEmptyField])
        });
        (this.section3Form.get('section3Array') as FormArray).push(ArrayData);
        //console.log(this.section3Form.value);
      });
      this.actorNameList = data;
      //console.log(this.actorNameList);
      this.dataSource3 = new MatTableDataSource<InputSheetPilotActSection>(this.actorNameList);
    }

  }

  radioChangeact(ele, event: MatRadioChange) {
    //console.log(event);
    //console.log(event.value);
    //console.log(event.source);
    ele.gender = event.value;
    ele.upDateFlag = true;
  }

  /** Section 4 - Add, Delete and Save method **/

  onAddrelRow() {
    let newRow = {
      rowid: null, posrel: (this.relationshipsList.length + 1), relationships: null,
      upDateFlag: true
    };
    //console.log(this.relationshipsList);
    const row = this.fb.group({
      relationships: new FormControl(null, [Validators.required,
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField]),
    });
    (this.section4Form.get('section4Array') as FormArray).push(row);

    this.relationshipsList.push(newRow);
    this.dataSource4 = new MatTableDataSource(this.relationshipsList);
    //console.log(this.relationshipsList);
    this.dataSource4._updateChangeSubscription();
  }

  deleteRel(elm, index) {
    //console.log(elm);
    //console.log(this.relationshipsList)
    // this.isLoading=true;
    // elm.rowid == (null || '') ? this.isLoading = false : this.isLoading = true;
    this.relationshipsList.splice(index, 1);
    (this.section4Form.get('section4Array') as FormArray).removeAt(index);
    this.dataSource4 = new MatTableDataSource(this.relationshipsList);

    if (elm.rowid != null || '') {
      this.isLoading = true;
      // const delRel = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      //   } 
      const delRel = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delRel)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          //   this.getRelations();
          this.isLoading = false;
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.isLoading = false;
        })
    }
  }

  updateValRel(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.relationships = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    // ele.relationships.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  relsecSave(formValue) {
    if (formValue.invalid) {
      this.submittedForm4 = true;
      return false;
    } else {
      //console.log(this.relationshipsList);
      let relsSave = [];
      //console.log(this.relationshipsList);
      this.isLoading = true;
      relsSave = this.relationshipsList.map(item => {
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.relationships,
          sequence: item.posrel,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 612,
            sequenceNumber: 4,
            sectionName: 'RELATIONSHIPS',
            sectionGroup: ""
          },
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          sheetQuestion: null,
          upDateFlag: item.upDateFlag
        }
      })
      //console.log(relsSave);
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', relsSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.loadRelations(result);
          this.isLoading = false;
          //console.log(result);
          // this.getRelations();
          this.toastorService.success('Saved successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.isLoading = false;
        });
    }
  }

  loadRelations(relationships) {
    if (relationships == null) {
      this.onAddrelRow();
    } else {
      let data = [];
      (this.section4Form.controls.section4Array as FormArray).clear();
      relationships = relationships.map(mapdata => {
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          relationships: mapdata.description,
          posrel: mapdata.sequence,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          relationships: new FormControl(mapdata.description, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section4Form.get('section4Array') as FormArray).push(ArrayData);
        //console.log(this.section4Form.value);
      });
      this.relationshipsList = data;
      //console.log(this.relationshipsList);
      this.dataSource4 = new MatTableDataSource<InputSheetPilotRelSection>(this.relationshipsList);
    }

  }

  /** Section 5 - Add, Delete and Save method **/

  onAddStatRow() {
    let newRow = {
      rowid: null, posstat: (this.statementList.length + 1), statements: null,
      sheetQuestionId: null, metaDataFlag: true, upDateFlag: true
    };
    //console.log(this.statementList);
    const row = this.fb.group({
      statements: new FormControl(null, [Validators.required,
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField]),
    });
    (this.section5Form.get('section5Array') as FormArray).push(row);

    this.statementList.push(newRow);
    this.dataSource5 = new MatTableDataSource(this.statementList);
    //console.log(this.statementList);
    this.dataSource5._updateChangeSubscription();
  }

  deleteStat(elm, index) {
    // this.isLoading=true;
    //console.log(elm);
    //console.log(this.statementList);
    // elm.rowid == (null || '') ? this.isLoading = false : this.isLoading = true;
    this.statementList.splice(index, 1);
    (this.section5Form.get('section5Array') as FormArray).removeAt(index);
    this.dataSource5 = new MatTableDataSource(this.statementList);

    if (elm.rowid != null || '') {
      this.isLoading = true;
      // const delStat = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      //   }
      const delStat = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delStat)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          //   this.getStatements();
          this.isLoading = false;
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.isLoading = false;
        })
    }
  }

  updateValstat(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.statements = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    // ele.statements.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  statsecSave(formValue) {
    if (formValue.invalid) {
      this.submittedForm5 = true;
      return false;
    } else {
      //console.log(this.statementList);
      let statsSave = [];
      //console.log(this.statementList);
      this.isLoading = true;
      statsSave = this.statementList.map(item => {
        let createdBy;
        item.metaDataFlag == false ? createdBy = 'METADATA' : createdBy = null;
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.statements,
          sequence: item.posstat,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 602,
            sequenceNumber: 5,
            sectionName: 'STATEMENT',
            sectionGroup: ""
          },
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          sheetQuestion: {
            inputSheetQuestionsId: item.sheetQuestionId,
            questionLongText: item.statements,
            questionSequenceNum: item.posstat
          },
          createdBy: createdBy,
          upDateFlag: item.upDateFlag
        }
      })
      //console.log(statsSave);
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', statsSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.loadStatements(result);
          this.isLoading = false;
          // this.getStatements();
          this.toastorService.success('Saved successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.isLoading = false;
        });
    }

  }

  loadStatements(statements) {
    if (statements == null) {
      this.onAddStatRow();
    } else {
      let data = [];
      (this.section5Form.controls.section5Array as FormArray).clear();
      statements = statements.map(mapdata => {
        let metaFlag;
        mapdata.createdBy == 'METADATA' ? metaFlag = false : metaFlag = true;
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          posstat: mapdata.sheetQuestion.questionSequenceNum,
          statements: mapdata.sheetQuestion.questionLongText,
          sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
          metaDataFlag: metaFlag,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          statements: new FormControl(mapdata.sheetQuestion.questionLongText, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section5Form.get('section5Array') as FormArray).push(ArrayData);
        //console.log(this.section5Form.value);
      })

      this.statementList = data;
      //console.log(this.statementList);
      this.dataSource5 = new MatTableDataSource<InputSheetPilotStatSection>(this.statementList);
    }

  }


  /** Section 6 - Add, Delete and Save method **/

  onAddstoryelemRow() {
    let newRow = {
      rowid: null, posstoryelem: (this.storyelemList.length + 1), storyelements: null,
      upDateFlag: true
    };
    //console.log(this.storyelemList);
    const row = this.fb.group({
      storyelements: new FormControl(null, [Validators.required,
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField]),
    });
    (this.section6Form.get('section6Array') as FormArray).push(row);

    this.storyelemList.push(newRow);
    this.dataSource6 = new MatTableDataSource(this.storyelemList);
    //console.log(this.storyelemList);
    this.dataSource6._updateChangeSubscription();
  }

  deleteStoryElem(elm, index) {
    // this.isLoading=true;
    //console.log(elm);
    //console.log(this.storyelemList);
    // elm.rowid == (null || '') ? this.isLoading = false : this.isLoading = true;
    this.storyelemList.splice(index, 1);
    (this.section6Form.get('section6Array') as FormArray).removeAt(index);
    this.dataSource6 = new MatTableDataSource(this.storyelemList);

    if (elm.rowid != null || '') {
      this.isLoading = true;
      // const delSto = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      //   }
      const delSto = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delSto)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.isLoading = false;
          //console.log(result);
          //  this.getStoryElem();
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);

        })
    }
  }

  updateValstory(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.storyelements = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    // ele.storyelements.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  storysecSave(formValue) {
    if (formValue.invalid) {
      this.submittedForm6 = true;
      return false;
    } else {
      //console.log(this.storyelemList);
      let stoelsSave = [];
      //console.log(this.storyelemList);
      this.isLoading = true;

      stoelsSave = this.storyelemList.map(item => {
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.storyelements,
          sequence: item.posstoryelem,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 613,
            sequenceNumber: 6,
            sectionName: 'STORY ELEMENTS',
            sectionGroup: ""
          },
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          sheetQuestion: null,
          upDateFlag: item.upDateFlag
        }
      })
      //console.log(stoelsSave);
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', stoelsSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.loadStoryElem(result);
          this.isLoading = false;
          //console.log(result);
          // this.getStoryElem();
          this.toastorService.success('Saved successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.isLoading = false;
        });
    }

  }

  loadStoryElem(storyElem) {
    if (storyElem == null) {
      this.onAddstoryelemRow();
    } else {
      let data = [];
      (this.section6Form.controls.section6Array as FormArray).clear();
      storyElem = storyElem.map(mapdata => {
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          posstoryelem: mapdata.sequence,
          storyelements: mapdata.description,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          storyelements: new FormControl(mapdata.description, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section6Form.get('section6Array') as FormArray).push(ArrayData);
        //console.log(this.section6Form.value);
      })

      this.storyelemList = data;
      //console.log(this.storyelemList);
      this.dataSource6 = new MatTableDataSource<InputSheetPilotStoSection>(this.storyelemList);
    }

  }

  /** Section 7 - Add, Delete, Save method **/

  onAddViewRow() {
    let newRow = {
      rowid: null, posview: (this.viewershipList.length + 1), viewShowTitle: null,
      upDateFlag: true
    };
    //console.log(this.viewershipList);
    const row = this.fb.group({
      viewShowTitle: new FormControl(null, [Validators.required,
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField]),
    });
    (this.section7Form.get('section7Array') as FormArray).push(row);

    this.viewershipList.push(newRow);
    this.dataSource7 = new MatTableDataSource(this.viewershipList);
    //console.log(this.viewershipList);
    this.dataSource7._updateChangeSubscription();
  }

  deleteView(elm, index) {
    //console.log(elm);
    //console.log(this.viewershipList)
    // this.isLoading=true;
    // elm.rowid == null || '' ? this.isLoading = false : this.isLoading = true;
    this.viewershipList.splice(index, 1);
    (this.section7Form.get('section7Array') as FormArray).removeAt(index);
    this.dataSource7 = new MatTableDataSource(this.viewershipList);

    if (elm.rowid != null || '') {
      this.isLoading = true;
      // const delView = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      //   }
      const delView = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delView)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.getViewership();
          this.isLoading = false;
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.isLoading = false;
        })
    }
  }

  updateValtitle(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.viewShowTitle = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    // ele.viewShowTitle.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  viewsecSave(formValue) {
    if (formValue.invalid) {
      //console.log(formValue);
      this.submittedForm7 = true;
      return false;
    } else {
      //console.log(this.viewershipList);
      let viewsSave = [];
      this.isLoading = true;
      viewsSave = this.viewershipList.map(item => {
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.viewShowTitle,
          sequence: item.posview,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 614,
            sequenceNumber: 7,
            sectionName: 'VIEWERSHIP',
            sectionGroup: ""
          },
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          sheetQuestion: null,
          upDateFlag: item.upDateFlag
        }
      })
      //console.log(viewsSave);
      this.commonService.putRequest(`
          ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', viewsSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.loadViewership(result);
          this.isLoading = false;
          //console.log(result);
          // this.getViewership();
          this.toastorService.success('Saved successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.isLoading = false;
        });
    }

  }

  loadViewership(viewership) {
    if (viewership == null) {
      this.onAddViewRow();
    } else {
      let data = [];
      (this.section7Form.controls.section7Array as FormArray).clear();
      viewership = viewership.map(mapdata => {
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          viewShowTitle: mapdata.description,
          posview: mapdata.sequence,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          viewShowTitle: new FormControl(mapdata.description, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section7Form.get('section7Array') as FormArray).push(ArrayData);
        //console.log(this.section7Form.value);
      })

      this.viewershipList = data;
      //console.log(this.viewershipList);
      this.dataSource7 = new MatTableDataSource<InputSheetPilotViewSection>(this.viewershipList);
    }

  }

  /** Section 8 - Add, Delete and Save method **/

  onAddQuesRow() {
    let newRow = {
      rowid: null, posques: (this.questionstimList.length + 1), quesnstim: null,
      upDateFlag: true
    };
    const row = this.fb.group({
      quesnstim: new FormControl(null, [Validators.required,
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField]),
    });
    (this.section8Form.get('section8Array') as FormArray).push(row);

    this.questionstimList.push(newRow);
    this.dataSource8 = new MatTableDataSource(this.questionstimList);
    //console.log(this.questionstimList);
    this.dataSource8._updateChangeSubscription();
  }

  deleteQues(elm, index) {
    //console.log(elm);
    //console.log(this.questionstimList);
    //  this.isLoading=true;
    // elm.rowid == (null || '') ? this.isLoading = false : this.isLoading = true;
    this.questionstimList.splice(index, 1);
    (this.section8Form.get('section8Array') as FormArray).removeAt(index);
    this.dataSource8 = new MatTableDataSource(this.questionstimList);
    //console.log(this.questionstimList);

    if (elm.rowid != null || '') {
      this.isLoading = true;
      // const delQues = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      //   }
      const delQues = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delQues)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          // this.getQuestionStim();
          this.isLoading = false;
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.toastorService.warn(error.message);
          // this.isLoading = false;
        })
    }
  }

  updateQues(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.quesnstim = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    // ele.quesnstim.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  quesSave(formValue) {
    if (formValue.invalid) {
      this.submittedForm8 = true;
      return false;
    } else {
      //console.log(this.questionstimList);
      let quesSave = [];
      this.isLoading = true;
      quesSave = this.questionstimList.map(item => {
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.quesnstim,
          sequence: item.posques,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 615,
            sequenceNumber: 8,
            sectionName: 'QUESTION AND STIM CHANGES',
            sectionGroup: ""
          },
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          sheetQuestion: null,
          upDateFlag: item.upDateFlag
        }
      })
      //console.log(quesSave);
      this.commonService.putRequest(`
          ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', quesSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.loadQuestStim(result);
          this.isLoading = false;
          //console.log(result);
          // this.getQuestionStim();
          this.toastorService.success('Saved successfully');
        }, error => {
          this.toastorService.warn(error.message);
          this.isLoading = false;
        });
    }

  }

  loadQuestStim(questionstim) {
    if (questionstim == null) {
      this.onAddQuesRow();
    } else {
      let data = [];
      (this.section8Form.controls.section8Array as FormArray).clear();
      questionstim = questionstim.map(mapdata => {
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          quesnstim: mapdata.description,
          posques: mapdata.sequence,
          upDateFlag: mapdata.upDateFlag
        });
        //console.log(this.section8Form.value);
        const ArrayData = this.fb.group({
          quesnstim: new FormControl(mapdata.description, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section8Form.get('section8Array') as FormArray).push(ArrayData);
        //console.log(this.section8Form.value);
      })

      this.questionstimList = data;
      //console.log(this.questionstimList);
      this.dataSource8 = new MatTableDataSource<InputsheetPilotQuesSection>(this.questionstimList);
    }

  }
  public hasErrorsection1Form = (index, errorName: string) => {
    return ((this.section1Form.controls.section1Array as FormArray).controls[index] as FormGroup).controls.attributes.hasError(errorName);
  }
  public hasErrorsection2Form  = (index, controlName , errorName: string) => {
    return ((this.section2Form.controls.section2Array as FormArray).controls[index] as FormGroup).controls[controlName].hasError(errorName);
  }
  public hasErrorsection3Form  = (index, controlName , errorName: string) => {
    return ((this.section3Form.controls.section3Array as FormArray).controls[index] as FormGroup).controls[controlName].hasError(errorName);
  }
  public hasErrorsection4Form  = (index, controlName , errorName: string) => {
    return ((this.section4Form.controls.section4Array as FormArray).controls[index] as FormGroup).controls[controlName].hasError(errorName);
  }
  public hasErrorsection5Form  = (index, controlName , errorName: string) => {
    return ((this.section5Form.controls.section5Array as FormArray).controls[index] as FormGroup).controls[controlName].hasError(errorName);
  }
  public hasErrorsection6Form  = (index, controlName , errorName: string) => {
    return ((this.section6Form.controls.section6Array as FormArray).controls[index] as FormGroup).controls[controlName].hasError(errorName);
  }
  public hasErrorsection7Form  = (index, controlName , errorName: string) => {
    return ((this.section7Form.controls.section7Array as FormArray).controls[index] as FormGroup).controls[controlName].hasError(errorName);
  }
  public hasErrorsection8Form  = (index, controlName , errorName: string) => {
    return ((this.section8Form.controls.section8Array as FormArray).controls[index] as FormGroup).controls[controlName].hasError(errorName);
  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.destroy$.next(true);
  }

}
