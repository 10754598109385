import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestiondashboardComponent } from './components/question-dashboard/question-dashboard.component';
import { QuestionRoutingModule } from './question.routing.module';
import { QuestionComponent } from './layout/question.component';
import { CreatequestionComponent } from './components/create-question/create-question.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/app.material.module';
import { EditQuestionComponent } from './components/edit-question/edit-question.component';
import { QuestionSurveydialogComponent } from './components/question-dashboard/question-surveydialog.component';
import { SharedModule } from 'src/app/shared';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { SurveyeditComponent } from '../survey/components/surveyedit/surveyedit.component';
import { SurveyModule } from '../survey/survey.module';
import { MatVsTableModule } from 'mat-vs-table';
@NgModule({
  declarations: [
    QuestiondashboardComponent,
    QuestionComponent,
    CreatequestionComponent,
    EditQuestionComponent,
    QuestionSurveydialogComponent,
   
  ],
  imports: [
    CommonModule,
    QuestionRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    SatDatepickerModule,
    SatNativeDateModule,
    SurveyModule,
    MatVsTableModule
  ],
  providers: [QuestiondashboardComponent],
  entryComponents: [
    QuestiondashboardComponent,
    QuestionComponent,
    CreatequestionComponent,
    EditQuestionComponent,
    QuestionSurveydialogComponent
  ]
})
export class QuestionModule { }
