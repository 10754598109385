import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-input-layout',
  templateUrl: './input-layout.component.html',
  styleUrls: ['./input-layout.component.scss']
})
export class InputLayoutComponent implements OnInit {
  
  constructor(
    private service:CommonService
   ) {
   
  }

  ngOnInit() {
    // debugger;

  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks

  }



}
