import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { LoginComponent } from './login/login.component';
// import { AuthGuard } from './guards/authgurad';
import { LandingComponent } from './landing.component';
import { AuthManager } from './helpers/auth-manager';

const routes: Routes = [
  {
    path: 'question',
    loadChildren: () => import('./modules/question/question.module').then(m => m.QuestionModule), canActivate: [AuthManager]
  },
  {
    path: 'inputsheet',
    loadChildren: () => import('./modules/inputsheet/inputsheet.module').then(m => m.InputsheetModule), canActivate: [AuthManager]
  },
  {
    path: 'survey',
    loadChildren: () => import('./modules/survey/survey.module').then(m => m.SurveyModule), canActivate: [AuthManager]
  },
  {
    path: 'template',
    loadChildren: () => import('./modules/template/template.module').then(m => m.TemplateModule)
  },
  {
    path: 'choice',
    loadChildren: () => import('./modules/choice/choice.module').then(m => m.ChoiceModule), canActivate: [AuthManager]
  },
  {
    path: 'sharedList',
    loadChildren: () => import('./modules/sharedlist/sharedlist.module').then(m => m.SharedlistModule), canActivate: [AuthManager]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthManager]
  },
  // {
  //   path: 'login',component: LoginComponent
  // },
  {
    path:'', component: LandingComponent, canActivate: [AuthManager]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
