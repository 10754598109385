import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { AppConfig } from 'src/app/app.config';
@Injectable({
  providedIn: 'root'
})
export class AwsUploadService {
  appConstants = AppConfig.settings;
  FOLDER: string;
  constructor() {
    this.FOLDER = this.appConstants.s3FolderName;
  }

  uploadFile(file) {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: sessionStorage.getItem('accessKeyId'),
        secretAccessKey: sessionStorage.getItem('secretAccessKey'),
        region: this.appConstants.region
      }
    );
    const params = {
      Bucket: this.appConstants.s3bucketName,
      Key: this.FOLDER + file.name,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    bucket.upload(params, function (err, data) {
      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }
      console.log('Successfully uploaded file.', data);
      return true;
    });
    // for upload progress   
    /*bucket.upload(params).on('httpUploadProgress', function (evt) {
              console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
          }).send(function (err, data) {
              if (err) {
                  console.log('There was an error uploading your file: ', err);
                  return false;
              }
              console.log('Successfully uploaded file.', data);
              return true;
          });*/
  }
}
