import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
// import { TemplateService } from 'src/app/services/template.service';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-template-dashboard',
  templateUrl: './template-dashboard.component.html',
  styleUrls: ['./template-dashboard.component.scss']
})
export class TemplateDashboardComponent implements OnInit {
//  @Input() templateInput;
templateInput: any;
  constructor(
  //  private templateService: TemplateService,
    private sharedService: SharedService,
    private cdr:ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.sharedService.templateData.subscribe( data => {
      // console.log('tempalte-dashboard', data);
      this.templateInput = Object.assign({}, data);
      this.cdr.detectChanges();
    })
  }
  
}
