import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray, NgForm } from '@angular/forms';
import { Observable, from, Subscription, Subject } from 'rxjs';
import { map, startWith, tap, debounceTime, switchMap, finalize, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SelectionModel } from '@angular/cdk/collections';
import { Router, ActivatedRoute } from '@angular/router';

/* Component Section   */
import { CreateSurveyComponent } from '../create-survey/create-survey.component';
import { SurveyaddchoiceComponent } from '../surveyaddchoice/surveyaddchoice.component';
import { SurveyaddquestionComponent } from '../surveyaddquestion/surveyaddquestion.component';
import { ConfirmdialogComponent, ConfirmDialogModel } from 'src/app/shared/components/confirmdialog/confirmdialog.component';
/*  Service Section  */
import { NotificationService } from 'src/app/services/notification.service';
import { AwsCommonService } from 'src/app/services/aws-common.service';
// import { AwsCommonSprint3Service } from 'src/app/services/aws-commonsprint3.service';
import { CommunicatingComponentService } from 'src/app/modules/survey/service/communicating-component.service';

/*  Interface Section  */
import { SurveyEdit, SurveyCreateInputSheet, SurveyCreateTemplate } from 'src/app/models/surveycreate';
import { HttpParams } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material';
import { AuthManager } from 'src/app/helpers/auth-manager';
import { ExcelService } from 'src/app/services/excel.service';
@Component({
  selector: 'app-surveyedit',
  templateUrl: './surveyedit.component.html',
  styleUrls: ['./surveyedit.component.scss']
})
export class SurveyeditComponent implements OnInit, OnDestroy {
  appConstants = AppConfig.settings;
  surveyDec: SurveyEdit;
  subscription: Subscription;
  subscriptionData: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  selection = new SelectionModel<SurveyCreateInputSheet>(true, []);
  isAdmin: boolean = true;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private awscommonservice: AwsCommonService,
    private notification: NotificationService,
    private communicationservice: CommunicatingComponentService,
    private authManager: AuthManager,
    private excelService: ExcelService,
  ) {
    this.surveyDec = {
      SelectedInputSheet: '',
      SelectedTemplate: '',
      StatementList: '',
      isLinear: false,
      dialogRef: '',
      submitCreateSurvey: FormGroup,
      secondFormGroup: FormGroup,
      summaryFormGroup: FormGroup,
      SelectedInputSheetValue: '',
      SelectedTemplateValue: '',
      selectedInputSheetId: '',
      SelectedTemplateId: '',
      inputTemplateData: [],
      inputSheetData: [],
      isEdit: false,
      selectedAll: true,
      tselectedAll: true,
      totalSelected: '',
      checkedInputquestion: '',
      checkedTemplateQuestion: '',
      dragdropData: [],
      summarChoiceLocalURL: 'assets/json/summarySurveyQuestionChoiceList.json',
      dataSuveyQuestionChoiceData: [],
      qChoiceDataReorder: [],
      choiceColumns: ['choiceLibraryDesc', 'choicePunchCode'],
      dataSource: [],
      summaryquestionEdit: false,
      communicationData: false,
      summaryQuestionName: new FormControl(''),
      summaryQuestionChoicePunchCode: new FormControl(''),

      isLoading: true,
      summaryData: [],
      displayedColumnsInputSheet: ['questionText', 'includeAllFlag'],
      rowsInputSheetTemaplate: [],
      surveyId: false,
      notSaved: false,
      surveyCode: '',
      surveyStatus: ''


    };
    this.surveyDec.dragdropData = [];
    this.surveyDec.SelectedInputSheet = this.appConstants.surveysteps.selectedInputSheet;
    this.surveyDec.SelectedTemplate = this.appConstants.surveysteps.selectedTemplate;
    this.surveyDec.StatementList = this.appConstants.surveysteps.statementList;
    this.surveyDec.firstFormGroup = this.fb.group({
      inputSheetId: [this.surveyDec.selectedInputSheetId, Validators.required],
      templateId: [this.surveyDec.SelectedTemplateId, Validators.required],
      status: 'Draft',
      sharedListQustionids: this.fb.array([this.createItem()])
    });
  }

  ngOnInit() {
    this.surveyDec.secondFormGroup = this.fb.group({
      secondCtrl: ['', Validators.required]
    });
    this.surveyDec.summaryFormGroup = this.fb.group({
      summaryCtrl: ['', Validators.required]
    });
    this.surveyDec.rowsInputSheetTemaplate = this.surveyDec.firstFormGroup.get('sharedListQustionids') as FormArray;
    if (this.communicationservice.editstorage) {
      const editstorage = this.communicationservice.editstorage;
      this.surveyDec.surveyCode = editstorage.surveycode;
      this.getData(this.communicationservice.editstorage);
      if (this.authManager.userRole !== 'ADMIN') {
        this.isAdmin = false;
        this.surveyDec.surveyStatus = true;
      }
    } else {
      this.router.navigate(['../dashboard'], { relativeTo: this.route });
    }
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
  createItem(): FormGroup {
    return this.fb.group({
      includeAllFlag: [''],
      sharedListQuestionId: [''],
      questionText: [''],
      inputSheetId: [this.surveyDec.selectedInputSheetId],
      templateId: [this.surveyDec.SelectedTemplateId],
    });
  }
  getData(params: any) {
    // console.log(params);
    this.surveyDec.isLoading = true;
    this.surveyDec.notSaved = true;
    this.surveyDec.surveyId = params.surveyId;
    this.communicationservice.sendCommunication(false);
    this.subscription = this.communicationservice.getCommunication()
      .subscribe(message => { this.surveyDec.communicationData = message; }
      );
    this.surveyDec.firstFormGroup.patchValue({
      inputSheetId: this.surveyDec.selectedInputSheetId,
      templateId: this.surveyDec.SelectedTemplateId
    });
    this.surveyDec.surveyCode = params.surveycode;
    this.surveyDec.surveyStatus = params.status;
    this.surveyDec.submitCreateSurvey = {
      c2301SurveyId: params.surveyId,
      surveyStatus: 'Submitted'
    };
    this.fetchSummaryData(params.surveyId);

  }


  fetchSummaryData(surveyId: any) {
    this.surveyDec.dragdropData = [];
    this.surveyDec.isLoading = true;
    // const optionSurveyId = surveyId ?
    //   { params: new HttpParams().set('surveyId', surveyId) } : {};
    const optionSurveyId = surveyId ?
      { 'surveyId': surveyId } : {};
    // tslint:disable-next-line: max-line-length
    this.awscommonservice.getApiRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/${this.appConstants.surveySummaryParams}`,
      'GET', optionSurveyId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        dataSummary => {
          this.surveyDec.summaryData = dataSummary;
          this.surveyDec.summaryData.map((pitem, pindex) => {
            if (typeof (pitem) !== 'undefined' && pitem != null) {
              this.surveyDec.dragdropData.push({
                c2311SurveyQuestionId: pitem.c2311SurveyQuestionId,
                c2311QuestionLongTxt: (pitem.c2311QuestionLongTxt) ? pitem.c2311QuestionLongTxt : '',
                c2311QuestionSeqNum: pitem.c2311QuestionSeqNum,
                summarySurveyId: surveyId
              });
            }
          });
        },
        err => {
          // console.log(err);
          this.surveyDec.isLoading = false;
          this.notification.warn('Oops!!! The Server was unable to complete your request');
        },
        () => {
          this.surveyDec.isLoading = false;
        }
      );
  }

  openconfirmModal(): void {

    const message = `Are you sure you want to cancel the survey details`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);
    const dialogRef =
      this.dialog.open(ConfirmdialogComponent,
        {
          panelClass: 'confirmModal',
          disableClose: true,
          data: dialogData
        });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        this.router.navigate(['../dashboard'], { relativeTo: this.route });
      }
    });
  }
  drop(event: CdkDragDrop<string[]>, summarySurveyId) {
    if (event.currentIndex !== event.previousIndex) {
      const dragdropSummaryQuestion = this.surveyDec.dragdropData;
      moveItemInArray(this.surveyDec.dragdropData, event.previousIndex, event.currentIndex);
      this.surveyDec.isLoading = true;
      this.awscommonservice.putRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/dragNdrop`,
        'PUT', dragdropSummaryQuestion)
        .subscribe(
          (resultsaveSummaryQuestion) => {
            this.surveyDec.isLoading = false;
            this.communicationservice.sendCommunication(false);
            this.subscription = this.communicationservice.getCommunication()
              .subscribe(message => { this.surveyDec.communicationData = message; }
              );
            this.fetchSummaryData(this.surveyDec.submitCreateSurvey.c2301SurveyId);
            this.notification.success('Questions Rearranged Successfully');
          },
          err => {
            
            this.notification.warn('Oops!!! The Server was unable to complete your request');
            
          }
        );

    } else {
      //this.notification.warn('Survey Summary same postion again you dropped..!');
    }
  }

  saveSurvey() {

    if (!this.surveyDec.surveyId) {
      if (this.surveyDec.firstFormGroup.invalid) {
        return false;
      } else {
        this.surveyDec.isLoading = true;
        // if (this.appConstants.checkServerURL) {
        // tslint:disable-next-line: max-line-length
        this.awscommonservice.postRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/save`,
          'POST', this.surveyDec.firstFormGroup.value)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (result: any) => {
              this.surveyDec.submitCreateSurvey = {
                c2301SurveyId: result.SurveyId,
                surveyStatus: 'Submitted',
                SurveyCode: result.SurveyCode
              };
              this.surveyDec.surveyId = true;
              this.surveyDec.notSaved = true;
              this.fetchSummaryData(result.SurveyId);
              this.notification.success('Saved Successfully');
            },
            error => {
              console.log(error);
              this.surveyDec.isLoading = false;
              this.notification.warn('Oops!!! The Server was unable to complete your request');
            }
          );
        // } else {
        //   // this.notificationService.warn('Please Check Config File & Server is InActive..!');
        // }
      }
    }
  }


  OpenExpansion(data: any, panelOpenState, event: Event): void {
    this.surveyDec.qChoiceDataReorder = [];
    this.surveyDec.dataSource = new MatTableDataSource<any>(this.surveyDec.qChoiceDataReorder);
    this.communicationservice.sendCommunication(false);
    if (panelOpenState._expanded) {
      this.surveyDec.isLoading = true;
      this.summaryFetchAllChoice(data);
    }
  }
  summaryquestionCopy(questiondata: number, questionLong: number, summarySurveyId: number) {

    const copySummaryQuestion = { c2311SurveyQuestionId: questiondata, c2311QuestionLongTxt: questionLong };
    this.communicationservice.sendCommunication(false);
    this.surveyDec.summaryquestionEdit = false;
    this.subscription = this.communicationservice.getCommunication()
      .subscribe(message => { this.surveyDec.communicationData = message.condition; }
      );

    this.surveyDec.isLoading = true;
    this.awscommonservice.postRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/questions`,
      'POST', copySummaryQuestion)
      .subscribe(
        (resultCopySummaryQuestion) => {
          this.surveyDec.isLoading = true;
          this.fetchSummaryData(summarySurveyId);
          this.notification.success('Question Copied Successfully');
        },
        err => {
          this.surveyDec.isLoading = false;
          this.notification.warn('Oops!!! The Server was unable to complete your request');
        }
      );
  }
  summaryquestionEdit(questiondata: any) {
    this.surveyDec.communicationData = questiondata;
    const qId = questiondata;
  }

  summaryChoicesPunchCode(questionChoiceData: any, surveyQuestion: any, e: any, summarySurveyId: any) {

    this.surveyDec.isLoading = true;
    // this.communicationservice.sendCommunication(false);
    // this.surveyDec.summaryquestionEdit = false;
    // this.subscription = this.communicationservice.getCommunication()
    //   .subscribe(message => { this.surveyDec.communicationData = message.condition; }
    //   );
    const summaryPunchCodeUpdate = {
      surveyQuestionId: surveyQuestion,
      choiceLibraryId: questionChoiceData.choiceLibraryId,
      questionChoiceId: questionChoiceData.questionChoiceId,
      choicePunchCode: e.target.value
    };

    this.awscommonservice.postRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/summary/choices`,
      'POST', summaryPunchCodeUpdate)
      .subscribe(
        (resultsaveSummaryQuestion) => {
          // this.surveyDec.isLoading = true;
          this.summaryFetchAllChoice(surveyQuestion);
          this.notification.success('Punch Code Updated Successfully');
        },
        err => {
          this.surveyDec.isLoading = false;
          this.notification.warn('Oops!!! The Server was unable to complete your request');
        }
      );
  }
  summaryquestionSave(questiondata: number, questionLong: number, summarySurveyId: number) {

    this.surveyDec.isLoading = true;
    this.communicationservice.sendCommunication(false);
    this.surveyDec.summaryquestionEdit = false;
    this.subscription = this.communicationservice.getCommunication()
      .subscribe(message => { this.surveyDec.communicationData = message.condition; }
      );

    const saveSummaryQuestion = { c2311SurveyQuestionId: questiondata, c2311QuestionLongTxt: questionLong };

    this.awscommonservice.putRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/edit/summary`,
      'PUT', saveSummaryQuestion)
      .subscribe(
        (resultsaveSummaryQuestion) => {

          this.surveyDec.isLoading = true;
          this.fetchSummaryData(summarySurveyId);
          this.notification.success(' Question Updated Successfully');
        },
        err => {
          this.notification.warn('Oops!!! The Server was unable to complete your request');
        },
        () => {
          this.surveyDec.isLoading = true;
        }
      );
  }
  summaryquestionDelete(surveyQuestionId: any, summarySurveyId: any) {


    const summaryQuectionId = { surveyQuestionId: surveyQuestionId };
    // tslint:disable-next-line: max-line-length
    this.awscommonservice.deleteRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/question`,
      'DELETE', summaryQuectionId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        dataChoiceDelete => {
          this.surveyDec.isLoading = true;
          this.notification.success('Question Deleted Successfully');
          this.fetchSummaryData(summarySurveyId);
        },
        err => {
          this.notification.warn('Oops!!! The Server was unable to complete your request');
        },
        () => {
          this.surveyDec.isLoading = true;
        }
      );
  }
  summaryQuestionChoiceDelete(questionChoiceData: any, surveyQuestionId) {
    this.surveyDec.isLoading = true;

    const summaryQuectionChoiceID = { questionChoiceId: questionChoiceData };
    // tslint:disable-next-line: max-line-length
    this.awscommonservice.deleteRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/choice`,
      'DELETE', summaryQuectionChoiceID)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        dataChoiceDelete => {
          this.notification.success('Choice Deleted Successfully');
          this.summaryFetchAllChoice(surveyQuestionId);
        },
        err => {
          console.log(err);
          this.notification.warn('Oops!!! The Server was unable to complete your request');
        },
        () => {
          this.surveyDec.isLoading = true;
        }
      );
  }
  summaryAddChoice(surveyQuestionId: number) {
    this.surveyDec.dialogRef =
      this.dialog.open((SurveyaddchoiceComponent), { panelClass: 'newQuestion', data: surveyQuestionId, disableClose: true });
    this.surveyDec.dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.surveyDec.qChoiceDataReorder = [];
        this.surveyDec.dataSource = new MatTableDataSource<any>(this.surveyDec.qChoiceDataReorder);
        this.surveyDec.isLoading = true;
        this.notification.success('Choice Added Successfully');
        this.summaryFetchAllChoice(surveyQuestionId);
      }
    },
      err => {
        this.surveyDec.isLoading = false;
        this.notification.warn('Oops!!! The Server was unable to complete your request');
      }
    );
  }

  summaryAddQuestion(surveyQuestionId: number) {
    const surQuestion = surveyQuestionId;
    this.surveyDec.dialogRef =
      this.dialog.open((SurveyaddquestionComponent), { panelClass: 'newQuestion', data: surQuestion, disableClose: true });
    this.surveyDec.dialogRef.afterClosed().subscribe(result => {

      if (result === true) {
        this.notification.success('Question Added Successfully');
        this.surveyDec.qChoiceDataReorder = [];
        this.surveyDec.isLoading = true;
        this.fetchSummaryData(surQuestion);
      }
    },
      err => {
        this.surveyDec.isLoading = false;
        this.notification.warn('Oops!!! The Server was unable to complete your request');
      }
    );
  }

  summaryFetchAllChoice(surveyQuestionId) {
    this.surveyDec.qChoiceDataReorder = [];
    this.surveyDec.isLoading = true;
    this.surveyDec.dataSource = new MatTableDataSource<any>(this.surveyDec.qChoiceDataReorder);
    // tslint:disable-next-line: object-literal-shorthand
    const summaryQuectionChoice = { surveyQuestionId: surveyQuestionId };
    // tslint:disable-next-line: max-line-length
    this.awscommonservice.getApiRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/${this.appConstants.surveySummaryFetchQuestionChoices}`,
      'GET', summaryQuectionChoice)
      // this.appSingleService.getRequest(`${this.surveyDec.summarChoiceLocalURL}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        dataSuveyQuestion => {
          this.surveyDec.isLoading = true;
          this.surveyDec.dataSuveyQuestionChoiceData = dataSuveyQuestion;
          this.surveyDec.dataSuveyQuestionChoiceData = this.surveyDec.dataSuveyQuestionChoiceData;
          this.surveyDec.dataSuveyQuestionChoiceData.map((item, index) => {
            if (typeof (item.choiceLibrary) !== 'undefined' && item.choiceLibrary != null) {
              this.surveyDec.qChoiceDataReorder.push({
                choiceLibraryDesc: item.choiceLibrary.choiceLibraryDesc,
                choiceLibraryId: item.choiceLibrary.choiceLibraryId,
                choicePunchCode: item.choicePunchCode,
                choiceSeqNum: item.choiceSeqNum,
                questionChoiceId: item.questionChoiceId
              });
            }
          });
          this.surveyDec.dataSource = new MatTableDataSource<any>(this.surveyDec.qChoiceDataReorder);
        },
        err => {
          this.surveyDec.isLoading = false;
          this.notification.warn('Oops!!! The Server was unable to complete your request');
        },
        () => {
          this.surveyDec.isLoading = false;
        }
      );
  }
  submitcreateSurvey() {
    this.surveyDec.isLoading = true;
    this.awscommonservice.putRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/status`,
      'PUT', this.surveyDec.submitCreateSurvey)
      .subscribe(
        (resultsaveSummaryQuestion) => {
          this.notification.success('Submitted Successfully');
          this.router.navigate(['../dashboard'], { relativeTo: this.route });
        },
        error => {
          console.log(error);
          this.notification.warn('Oops!!! The Server was unable to complete your request');
        },
        () => {
          this.surveyDec.isLoading = false;
        }
      );
  }
  summaryExport() {
    const editstorage = this.communicationservice.editstorage;
    const localurl = 'assets/json/surveysummaryexport.json';
    const optionSurveyId = editstorage.surveyId ?
      { 'surveyId': editstorage.surveyId } : {};
    // tslint:disable-next-line: max-line-length
    this.awscommonservice.getApiRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/summary/export`,
      'GET', optionSurveyId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        dataSummary => {
          console.log(dataSummary);
          if (dataSummary) {
            this.notification.success('Survey Summary Excel Generated Successfully');
            this.excelService.surveySummaryExportToExcel(dataSummary);
          } else {
            this.notification.warn('Oops!!! The Server was unable to complete your request');
          }

        },
        err => {
          this.notification.warn('Oops!!! The Server was unable to complete your request');
        }
        );


  }
}

