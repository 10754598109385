import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable, MatDialog } from '@angular/material';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAccordion } from '@angular/material';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { DataSource } from '@angular/cdk/table';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { AppConfig } from 'src/app/app.config';
import {
  Inputsheetpromo,
  InputsheetpromoCreateHeader,
  PromoHeaderTable,
  PromoHeader,
  InputsheetPromoCreate,
  InputsheetPromoQuesSection,
  InputSheetPromoAdAttributesSection,
  InputSheetPromoSpotSection,
  InputSheetPromostatIndvSection,
  InputSheetPromostatGrpSection,
  InputSheetPromostatGrpVarSection,
  InputSheetPromoElegrpstdSection,
  InputSheetPromoElegrpVarSection,
  InputSheetPromoQtimeslotSection,
  InputSheetPromoSrAwarenessSection
} from 'src/app/models/inputsheetpromo';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { CommunicatingComponentService } from '../../service/communicating-component.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ConfirmModalComponent } from '../../layout/confirm-modal.component';
import { HttpParams } from '@angular/common/http';
import { ArrayDataSource } from '@angular/cdk/collections';
// import { resolveMx } from 'dns';
import { DatePipe } from '@angular/common';
import data from '../../../../../assets/json/vendorData';
@Component({
  selector: 'app-promo-create',
  templateUrl: './promo-create.component.html',
  styleUrls: ['./promo-create.component.scss'],
  providers: [DatePipe]
})
export class PromoCreateComponent implements OnInit {

  appConstants = AppConfig.settings;
  destroy$: Subject<boolean> = new Subject<boolean>();
  isLoading: boolean = false;
  promoNotSaved: boolean = true;
  spotNotSaved: boolean = true;
  promoData: Inputsheetpromo;
  promoAddTableRow: FormGroup;
  dataSource = new MatTableDataSource<InputsheetpromoCreateHeader>();
  indexpostion: FormGroup;
  createInputPromo: InputsheetPromoCreate;
  section1Form: FormGroup;
  genreList: any = [];
  selected: any = [];
  valueFound: any;
  showTitleList: any[] = [];
  spotList: any = [];
  adTitleSaveEdit: boolean = true;
  sec2SaveEdit: boolean = true;
  sec3SaveEdit: boolean = true
  TotalAds: number = 0;
  showTitleEmpty: boolean = false;

  SpotData = []
  editSpotData = [];
  AddAttrData = []
  statIndivData = [];
  editSpottitleData: any = [];

  sectionData: any[] = [];
  displayedColumns: string[] = [];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  data: any = [];
  dataValue: any = [];
  spotDetailsCount: number;
  inputSheetId: any;
  addAtrributeList: any = [];
  statIndvList: any = [];
  statGrpList: any = [];
  statGrpVarList: any = [];
  elegrpstdList: any = [];
  elegrpvarList: any = [];
  srAwarenessList: any = [{ rowid: null, possraware: 1, srawareness: '' }];
  questionstimList: any = [{ rowid: null, posques: 1, quesnstim: '' }];
  pilotDateVal: string;
  pilotDate = new FormControl();

  @ViewChild('accordion', { static: true }) Accordion: MatAccordion;
  toggle: boolean = true;
  datasection2HeaderList: any[] = [];
  dataset: any[] = [];
  datasettwo: any[] = [];
  spotDetailsData: any[] = [];
  public form: FormGroup;
  public formSpot: FormGroup;
  public formAddAttr: FormGroup;
  public formStatIndiv: FormGroup;

  public formAfterINDIVpAds: FormGroup;
  public formAfterGroupAds: FormGroup;
  public formAfterPromoAds: FormGroup;
  public formTag: FormGroup;
  public formShowDesc: FormGroup;
  public formVideotoShow: FormGroup;

  statgrpstdSave: boolean = false;
  statgrpvarSave: boolean = false;
  elegrpstdSave: boolean = false;
  elegrpvarSave: boolean = false;
  srawarenessSave: boolean = false;
  quessSave: boolean = false;

  submittedForm1: boolean = false;
  submittedForm2: boolean = false;
  submittedForm3: boolean = false;
  submittedForm4: boolean = false;
  submittedForm5: boolean = false;
  submittedForm6: boolean = false;
  submittedForm7: boolean = false;
  submittedForm8: boolean = false;
  submittedForm9: boolean = false;

  // dataSource1;
  // displayedColumns1: string[] = ['posspot', 'showTitle', 'promoSpotPrintTitle', 'promoLength', 'showNoTimes', 'adType', 'programType', 'printQuality', 'splNotes'];

  // section2Form: FormGroup;
  // onAddsec2: boolean = false;
  // dataSource2;
  // displayedColumns2: string[] = ['posadattr', 'showTitle2', 'addattributes'];

  section3Form: FormGroup;
  onAddsec3: boolean = false;
  dataSource3;
  displayedColumns3: string[] = ['posstat', 'statements'];

  section4Form: FormGroup;
  onAddsec4: boolean = false;
  dataSource4;
  displayedColumns4: string[] = ['posstatstd', 'statstd', 'columndelete'];

  section5Form: FormGroup;
  onAddsec5: boolean = false;
  dataSource5;
  displayedColumns5: string[] = ['posstatvar', 'statgrpvar', 'columndelete'];

  section6Form: FormGroup;
  onAddsec6: boolean = false;
  dataSource6;
  displayedColumns6: string[] = ['poselestd', 'elegrpStd', 'columndelete'];

  section7Form: FormGroup;
  onAddsec7: boolean = false;
  dataSource7;
  displayedColumns7: string[] = ['poselegrpVar', 'elegrpVar', 'columndelete'];

  section8Form: FormGroup;
  onAddsec8: boolean = false;
  dataSource8;
  displayedColumns8: string[] = ['possraware', 'srawareness', 'columndelete'];

  section9Form: FormGroup;
  onAddsec9: boolean = false;
  dataSource9;
  displayedColumns9: string[] = ['posques', 'quesnstim', 'columndelete'];
  vendorData: { "vendorId": number; "vendorName": string; }[];
  selectedVendor: any;
  constructor(
    private communicatingComponentService: CommunicatingComponentService,
    private dialog: MatDialog,
    private commonService: AwsCommonService,
    private fb: FormBuilder,
    private router: Router,
    private toastorService: NotificationService,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.spotDetailsCount = 0;
    //console.log(this.adTitleSaveEdit)
    this.form = fb.group({ adTitle_Files: fb.array([]) });
    this.formSpot = fb.group({ adTitle: fb.array([]) });
    this.formAddAttr = fb.group({ inputSheetSection: fb.array([]) });
    this.formStatIndiv = fb.group({ inputSheetSection: fb.array([]) });

    this.formAfterINDIVpAds = fb.group({ afterINDIV_Ads: fb.array([]) });
    this.formAfterGroupAds = fb.group({ afterGroup_Ads: fb.array([]) });
    this.formAfterPromoAds = fb.group({ afterPromo_Ads: fb.array([]) });
    this.formTag = fb.group({ tag: fb.array([]) });
    this.formShowDesc = fb.group({ showDesc: fb.array([]) });
    this.formVideotoShow = fb.group({ videotoshow: fb.array([]) });

    this.promoData = {
      createTitle: this.appConstants.inputsheetHeader.createTitle,
      promoAddHeader: ['genre', 'show Title', 'episode #', 'number of spots'],
      promoAddColumns: ['genreId', 'showTitleID', 'episode', 'noofprintads'],
      promoAddFooter: ['genreId', 'showTitleID', 'episode', 'noofprintads']
    };


    this.section3Form = this.fb.group({
      posstat: new FormControl(null, [Validators.required, validateEmptyField]),
      statements: new FormControl(null, [Validators.required, validateEmptyField]),
    });

    this.section4Form = fb.group({
      section4Array: fb.array([])
    });

    this.section5Form = fb.group({
      section5Array: fb.array([])
    });

    this.section6Form = fb.group({
      section6Array: fb.array([])
    });

    this.section7Form = fb.group({
      section7Array: fb.array([])
    });

    this.section8Form = fb.group({
      section8Array: fb.array([this.fb.group({
        srawareness: new FormControl('', [
          Validators.required,
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
          validateEmptyField
        ])
      })])
    });

    this.section9Form = fb.group({
      section9Array: fb.array([this.fb.group({
        quesnstim: new FormControl('', [
          Validators.required,
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
          validateEmptyField
        ])
      })])
    });

    this.promoData.promoaddData = [{ genreId: '', showTitleID: '', episode: '', noofprintads: '' }];
    // this.dataSource(this.promoData.promoaddData);
    this.dataSource = new MatTableDataSource(this.promoData.promoaddData);

    this.promoData.promoAddCreateForm = this.fb.group({
      inputSheetId: new FormControl(null),
      inputSheetType: this.fb.group({
        inputSheetType: 'Promo',
        activeYn: 'Y'
      }),
      surveyAnalyst: ['',
        [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
      programmerNotes: ['',
        [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
      testDate: [''],
      vendor:['',[Validators.required]],
      inputSheetName: 'name',
      activeYn: 'Y',
      inputSheetStatus: this.fb.group({
        inputSheetStatus: 'DRAFT'
      }),
      inputSheetPrintInfo: this.fb.array([this.createItem()]),
    });

    // this.section1Form = this.fb.group({
    //   inputSheetSpotInfo: this.fb.array([])
    // });

    // this.printAddTableRow = this.fb.group({
    //   rows: this.fb.array([])
    // });
    this.sendMessage();
    this.initGroup();
  }
  createItem(): FormGroup {
    return this.fb.group({
      showTitleSequenceNumber: 1,
      printInfoId: new FormControl(null),
      primeShowTitle: this.fb.group({
        primeShowTitleId: [''],
        projectType: this.fb.group({
          c2027ProjectTypeId: [''],
        }),
      }),
      noOfPrints: [''],
      episodeSerialNumber: ['']
    });
  }



  createSpotTitle(): FormGroup {
    return this.fb.group({
      promoSet: this.fb.group({
        showTitle: [''],
        promoSpotPrintTitle: ['', [
          validateEmptyField,
          Validators.required,
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))
        ]],
        sectionId: '631',
        sectionName: 'SPOT DETAILS',
        promoLength: [''],
        showNoTimes: [''],
        adType: ['',
          [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
        programType: ['',
          [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
        printQuality: ['',
          [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
        splNotes: ['',
          [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]]
      })
    })
  }

  initGroup() {
    this.createItem();
  }

  sendMessage() {
    this.communicatingComponentService.sendCommunication(true);
  }
  clearMessage() {
    this.communicatingComponentService.sendCommunication(false);
    this.router.navigate(['../promo'], { relativeTo: this.route });
  }

  ngOnInit() {
    // this.dataSource2 = new MatTableDataSource<InputSheetPromoAdAttributesSection>(this.addAtrributeList);
    //   this.dataSource3 = new MatTableDataSource<InputSheetPromostatIndvSection>(this.statIndvList);
    this.vendorData=data;
    this.dataSource4 = new MatTableDataSource<InputSheetPromostatGrpSection>(this.statGrpList);
    this.dataSource5 = new MatTableDataSource<InputSheetPromostatGrpVarSection>(this.statGrpVarList);
    this.dataSource6 = new MatTableDataSource<InputSheetPromoElegrpstdSection>(this.elegrpstdList);
    this.dataSource7 = new MatTableDataSource<InputSheetPromoElegrpVarSection>(this.elegrpvarList);
    this.dataSource8 = new MatTableDataSource<InputSheetPromoSrAwarenessSection>(this.srAwarenessList);
    this.dataSource9 = new MatTableDataSource<InputsheetPromoQuesSection>(this.questionstimList);

    this.commonService.getRequest(`
    ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/showprojectgenres`,
      'GET')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          //console.log(data);
          this.genreList = data;
        },
        err => {
          //console.log('show project genres');
          this.toastorService.warn(err);
        }
      );
    // this.getSection1(1829);
    // const localURL = 'assets/json/test1Promosection2.json';
    // this.commonService.getRequest(`${localURL}`, 'GET')
    //   .subscribe( dataSection2 => {
    //     //console.log(dataSection2)
    //     // this.getsection2(dataSection2);
    //   });
  }

  getSection1(inputSheetId) {
    this.isLoading = true;
    const localURL = 'assets/json/promosection.json';
    // const datasection1ColumnsList: any[] = [];
    //  this.datasection1HeaderList = [];
    this.dataset = [];
    // const options = inputSheetId ?
    //   { params: new HttpParams().set('inputSheetId', inputSheetId) } : {};
    const options = inputSheetId ?
      { 'inputSheetId': inputSheetId } : {};
    this.commonService.getApiRequest(`
    ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetprintTitles}`,
      'GET', options)
      .pipe(takeUntil(this.destroy$))
      // this.commonService.getRequest(`${localURL}`, 'GET')
      .subscribe(
        datasection => {
          this.sectionData = datasection;
          //console.log(this.sectionData)
          const inputSheetPrintInfoArray = this.promoData.promoAddCreateForm.get('inputSheetPrintInfo') as FormArray;
          //console.log(inputSheetPrintInfoArray)
          //console.log((this.formSpot.get('adTitle') as FormArray).length);
          while ((this.formSpot.get('adTitle') as FormArray).length !== 0) {
            //console.log( (this.formSpot.get('adTitle') as FormArray));
            (this.formSpot.get('adTitle') as FormArray).removeAt(0);
            //console.log( (this.formSpot.get('adTitle') as FormArray));
          }
          this.spotDetailsCount = 0;
          //console.log((this.formSpot.get('adTitle') as FormArray).length);
          //console.log(datasection);
          if (datasection != "") {
            datasection.map((promoitem, promoindex) => {
              this.dataset.push({
                printInfoId: promoitem.printInfoId,
                showTitle: promoitem.primeShowTitle.showTitle,
                noOfPrints: promoitem.noOfPrints,
                inputsheetId: promoitem.inputsheetId,
                promoSet: []
              });
              //console.log(promoitem)
              inputSheetPrintInfoArray.controls[promoindex].get('printInfoId').setValue(promoitem.printInfoId);
              //console.log(inputSheetPrintInfoArray.controls[promoindex].get('printInfoId'))
              this.section1Generate(this.dataset, promoindex, promoitem);
              this.isLoading = false;
              //console.log(this.promoData.promoAddCreateForm.value)
            });
          } else {
            this.isLoading = false;
          }
        },
        (err) => {
          this.toastorService.warn('Oops!!! The Server was unable to complete your request');
        }
      );

  }

  section1Generate(dataPromo, index, dataArray) {
    //console.log(dataPromo);
    this.addSpotDetails(dataPromo, index, dataArray);
  }

  sections(section) {
    //console.log(this.datasettwo)
    //console.log(userSetData)
    while ((this.formAddAttr.get('inputSheetSection') as FormArray).length !== 0) {
      (this.formAddAttr.get('inputSheetSection') as FormArray).removeAt(0)
    }
    while ((this.formStatIndiv.get('inputSheetSection') as FormArray).length !== 0) {
      (this.formStatIndiv.get('inputSheetSection') as FormArray).removeAt(0)
    }
    if (section) {
      this.datasettwo.forEach(promoData => {
        //console.log(promoData)
        this.addAttributeGroup(promoData);
        this.addAfterStatIndivGroup(promoData);
      });
    } else {
      //this.addAdTitleGroup();
      // this.addAfterINDIVadsGroup();
      // this.addAfterGroupAds();
      // this.addAfterPromoAds();
      // this.addTag();
      // this.addShowDesc();
      // this.addVideotoShow();
    }
    //console.log(this.datasettwo)
  }

  addAttributeGroup(adAttributeData?: any) {
    const adTitleFG = this.fb.group({
      printInfoId: [adAttributeData ? adAttributeData.printInfoId : ''],
      inputSheetId: [adAttributeData ? adAttributeData.inputsheetId : ''],
      inputSheetPromoSpotId: [adAttributeData ? adAttributeData.inputSheetPromoSpotId : ''],
      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 625,
        sequenceNumber: 1,
        sectionName: "ADDITIONAL ATTRIBUTES",
        sectionGroup: ""
      }
    });
    (this.formAddAttr.get('inputSheetSection') as FormArray).push(adTitleFG);
    const adAttributeDataIndex = (this.formAddAttr.get('inputSheetSection') as FormArray).length - 1;
    let attributeDataSet: any = [];
    this.addAtrributeList.map((item, index) => {
      if (item.inputSheetPromoSpotId == adAttributeData.inputSheetPromoSpotId && item.additionalAttributes != null) {
        item.additionalAttributes.forEach(val => {
          attributeDataSet.push(val);
        });
      }
    });
    //console.log(attributeDataSet)
    if (!adAttributeData) {
      this.addAdTitlePromoSet(adAttributeDataIndex);
    } else {
      //console.log(attributeDataSet!="")
      if (attributeDataSet != "") {
        attributeDataSet.forEach((val, index) => {
          this.addAdTitlePromoSet(adAttributeDataIndex, val, attributeDataSet[index]);
        })
      } else {
        adAttributeData.descriptionList.forEach((val, index) => {
          //console.log(val)
          this.addAdTitlePromoSet(adAttributeDataIndex, val);
        });
      }
    }
  }
  addAdTitlePromoSet(adTitleDataIndex: number, data?: any, attrDataSet?: any) {
    //console.log(attrDataSet)
    const descriptionFG = this.fb.group({
      inputSheetUserSectionId: [attrDataSet ? attrDataSet.inputSheetUserSectionId : ''],
      description: [attrDataSet ? attrDataSet.description : '', [
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
    });
    // tslint:disable-next-line: max-line-length
    (((this.formAddAttr.controls.inputSheetSection as FormArray).controls[adTitleDataIndex] as FormGroup).controls.descriptionList as FormArray).push(descriptionFG);

  }
  AddRowAfterAddAttribute() {
    const afterGroupDataIndex = (this.formAddAttr.get('inputSheetSection') as FormArray).length - 1;
    this.formAddAttr.controls.inputSheetSection.value.forEach(
      (addshowData, addIndex) => {
        this.addAdTitlePromoSet(addIndex);
      });
  }
  addAfterStatIndivGroup(adAttributeData?: any) {

    const adTitleFG = this.fb.group({
      printInfoId: [adAttributeData ? adAttributeData.printInfoId : ''],
      inputSheetId: [adAttributeData ? adAttributeData.inputsheetId : ''],
      inputSheetPromoSpotId: [adAttributeData ? adAttributeData.inputSheetPromoSpotId : ''],
      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 626,
        sequenceNumber: 2,
        sectionName: "STATEMENTS INDIV",
        sectionGroup: ""
      }
    });
    (this.formStatIndiv.get('inputSheetSection') as FormArray).push(adTitleFG);
    const adAttributeDataIndex = (this.formStatIndiv.get('inputSheetSection') as FormArray).length - 1;
    let attributeDataSet: any = [];
    this.addAtrributeList.map((item, index) => {
      if (item.inputSheetPromoSpotId == adAttributeData.inputSheetPromoSpotId && item.statementIndiv != null) {
        item.statementIndiv.forEach(val => {
          attributeDataSet.push(val);
        });
      }
    });
    if (!adAttributeData) {
      this.addStatIndivGroupPromoSet(adAttributeDataIndex);
    } else {
      if (attributeDataSet != "") {
        attributeDataSet.forEach((val, index) => {
          this.addStatIndivGroupPromoSet(adAttributeDataIndex, val, attributeDataSet[index]);
        })
      } else {
        adAttributeData.descriptionList.forEach(val => {
          this.addStatIndivGroupPromoSet(adAttributeDataIndex, val);
        });
      }
    }
  }
  addStatIndivGroupPromoSet(adTitleDataIndex: number, data?: any, attrDataSet?: any) {
    const descriptionFG = this.fb.group({
      inputSheetUserSectionId: [attrDataSet ? attrDataSet.inputSheetUserSectionId : ''],
      description: [attrDataSet ? attrDataSet.description : '', [
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
    });
    // tslint:disable-next-line: max-line-length
    (((this.formStatIndiv.controls.inputSheetSection as FormArray).controls[adTitleDataIndex] as FormGroup).controls.descriptionList as FormArray).push(descriptionFG);

  }
  AddRowAfterAddStatIndivGroup() {
    const afterGroupDataIndex = (this.formStatIndiv.get('inputSheetSection') as FormArray).length - 1;
    this.formStatIndiv.controls.inputSheetSection.value.forEach(
      (addshowData, addIndex) => {
        this.addStatIndivGroupPromoSet(addIndex);
      });
  }

  addSpotDetails(spotData?: any, pindex?: any, dataArray?: any) {
    // this.spotDetailsData = [];
    //console.log(spotData);
    // this.sections(dataArray);
    const spotFG = this.fb.group({
      printInfoId: [spotData[pindex] ? spotData[pindex].printInfoId : ''],
      noOfPrints: [spotData[pindex] ? spotData[pindex].noOfPrints : ''],
      inputSheetId: [spotData[pindex] ? spotData[pindex].inputsheetId : ''],
      promoSet: this.fb.array([]),
    });
    this.spotDetailsData.push([
      {
        attributeCheck: 'Y',
        description: 'spot Desc',
        sequence: 1,
        sheetSectionMaster: {
          inputSheetSectionMasterId: 631,
          sequenceNumber: 10,
          sectionName: 'Spot Details',
          sectionGroup: ''
        },
        showTitle: spotData[pindex] ? spotData[pindex].showTitle : '',
        printInfoId: spotData[pindex] ? spotData[pindex].printInfoId : '',
        noOfPrints: spotData[pindex] ? spotData[pindex].noOfPrints : '',
        inputsheetId: spotData[pindex] ? spotData[pindex].inputsheetId : '',
        promoSet: []
      }
    ]);

    (this.formSpot.get('adTitle') as FormArray).push(spotFG);
    const spotDataIndex = (this.formSpot.get('adTitle') as FormArray).length - 1;

    //console.log('form1', this.formSpot);
    if (!spotData) {
      this.addSpotPromoSet(spotDataIndex);
    } else {
      //console.log(spotData[pindex]);
      // spotData[pindex].promoSet.forEach((data) => {
      for (let i = 0; i < spotData[pindex].noOfPrints; i++) {
        // this.spotDetailsData[spotDataIndex].promoSet.push([]);
        this.spotDetailsCount = this.spotDetailsCount + 1;
        let data = '';
        if (dataArray.promoSet != null) {
          data = dataArray.promoSet[i];
        }
        //console.log(data)
        this.addSpotPromoSet(spotDataIndex, spotData[pindex], this.spotDetailsCount, data);
      }
      // });
    }
    // }
  }
  addSpotPromoSet(spotDataIndex: number, data?: any, spotpromosetIndex?: any, dataVal?: any) {
    //console.log(this.spotDetailsData[spotDataIndex].promoSet, '-');
    //console.log(dataVal)
    const promoSetFG = this.fb.group({
      inputSheetPromoSpotId: new FormControl(dataVal ? dataVal.inputSheetPromoSpotId : null),
      spotPosition: spotpromosetIndex,
      showTitle: data ? data.showTitle : '',
      promoSpotPrintTitle: [dataVal ? dataVal.promoSpotPrintTitle : '', [Validators.required,
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
      promoLength: dataVal ? dataVal.promoLength : '',
      showNoTimes: dataVal ? dataVal.showNoTimes : '',
      adType: [dataVal ? dataVal.adType : '',
      [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
      programType: [dataVal ? dataVal.programType : '',
      [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
      printQuality: [dataVal ? dataVal.printQuality : '',
      [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
      splNotes: [dataVal ? dataVal.splNotes : '',
      [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
      sectionId: 631,
      sectionName: 'Spot Details',
      sequenceNumber: 1
    });
    this.spotDetailsData[spotDataIndex].map((item) =>
      item.promoSet.push(
        {
          inputSheetPromoSpotId: dataVal ? dataVal.inputSheetPromoSpotId : null,
          spotPosition: spotpromosetIndex,
          showTitle: data ? data.showTitle : '',
          promoSpotPrintTitle: dataVal ? dataVal.promoSpotPrintTitle : '',
          promoLength: dataVal ? dataVal.promoLength : '',
          showNoTimes: dataVal ? dataVal.showNoTimes : '',
          adType: dataVal ? dataVal.adType : '',
          programType: dataVal ? dataVal.programType : '',
          printQuality: dataVal ? dataVal.printQuality : '',
          splNotes: dataVal ? dataVal.splNotes : ''
        }
      )
    );
    //console.log((((this.formSpot.controls.adTitle as FormArray).controls[spotDataIndex] as FormGroup).controls.promoSet as FormArray).length);
    // tslint:disable-next-line: max-line-length
    (((this.formSpot.controls.adTitle as FormArray).controls[spotDataIndex] as FormGroup).controls.promoSet as FormArray).push(promoSetFG);

  }

  getsection2(data) {
    // test1Promosection2.json
    const localURL = 'assets/json/test1Promosection2.json';
    const datasection2ColumnsList: any[] = [];
    this.datasection2HeaderList = [];
    this.datasettwo = [];
    // this.sectionData = data;
    this.addAtrributeList = data.inputSheetPromoSpot;
    this.addAtrributeList.map((item, index) => {
      //console.log(item.additionalAttributes)
      this.datasection2HeaderList.push({
        inputsheetId: item.inputSheetId,
        printInfoId: item.printInfoId,
        inputSheetPromoSpotId: item.inputSheetPromoSpotId,
        promoSpotPrintTitle: item.promoSpotPrintTitle,
        descriptionList: []
      });
      this.datasettwo.push({
        inputsheetId: item.inputSheetId,
        printInfoId: item.printInfoId,
        inputSheetPromoSpotId: item.inputSheetPromoSpotId,
        promoSpotPrintTitle: item.promoSpotPrintTitle,
        descriptionList: []
      });
      this.datasettwo[index].descriptionList.push('');

    });
    this.displayedColumns = this.datasection2HeaderList.map(c => c.promoSpotPrintTitle);
    this.sections(this.datasettwo);
  }
  spotSave(formValue) {
    //console.log(this.formSpot.value);
    if (formValue.invalid) {
      this.submittedForm1 = true;
      return false;
      //console.log('sdsdihgbshjabohubsahfsabo')
      //console.log('spot details invalid');
    } else {
      this.isLoading = true;
      console.log(this.formSpot);
      if (this.spotNotSaved === true) {
        this.commonService.postRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section/addTitle`, 'POST', this.formSpot.value)
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            this.spotNotSaved = false;
            //console.log(result);
            this.isLoading = false;
            this.putAdTitleGroup(result);
            this.getsection2(result);
            this.toastorService.success('Saved Successfully');
          }, error => {
            this.isLoading = false;
            this.toastorService.warn(error.message);
          });
      } else {
        this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section/addTitle`, 'PUT', this.formSpot.value)
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            //console.log(result);
            this.isLoading = false;
            this.putAdTitleGroup(result);
            this.getsection2(result)
            this.toastorService.success('Updated Successfully');
          }, error => {
            this.isLoading = false;
            this.toastorService.warn(error.message);
          });
      }
    }
  }
  /* section 1 update */
  putAdTitleGroup(putAdTitleGroup: any) {
    (this.formSpot.get('adTitle') as FormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.formSpot.controls.adTitle as FormArray).controls[index] as FormGroup).controls.printInfoId.value;
      (((this.formSpot.controls.adTitle as FormArray).controls[index] as FormGroup).controls.promoSet as FormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.formSpot.controls.adTitle as FormArray).controls[index] as FormGroup).controls.promoSet as FormArray).controls[pindex] as FormGroup).controls.inputSheetPromoSpotId.value;
        const promoSpotPrintTitle = ((((this.formSpot.controls.adTitle as FormArray).controls[index] as FormGroup).controls.promoSet as FormArray).controls[pindex] as FormGroup).controls.promoSpotPrintTitle.value;
        putAdTitleGroup.inputSheetPromoSpot.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetPromoSpotId === '' || editData.promoSpotPrintTitle === promoSpotPrintTitle) {
            ((((this.formSpot.controls.adTitle as FormArray).controls[index] as FormGroup).controls.promoSet as FormArray).controls[pindex] as FormGroup).controls.inputSheetPromoSpotId.patchValue(editData.inputSheetPromoSpotId);
          }
        });
      });
    });
    //console.log(putAdTitleGroup);
    //console.log(this.formSpot.value);
  }

  /* section 2 update */
  putAdditionalAttr(putAdditionalAttr: any) {
    (this.formAddAttr.get('inputSheetSection') as FormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.formAddAttr.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.printInfoId.value;
      (((this.formAddAttr.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.descriptionList as FormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.formAddAttr.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.descriptionList as FormArray).controls[pindex] as FormGroup).controls.inputSheetUserSectionId.value;
        const description = ((((this.formAddAttr.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.descriptionList as FormArray).controls[pindex] as FormGroup).controls.description.value;
        putAdditionalAttr.InputUserSection.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetUserSectionId === '' || editData.description === description) {
            ((((this.formAddAttr.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.descriptionList as FormArray).controls[pindex] as FormGroup).controls.inputSheetUserSectionId.patchValue(editData.inputSheetUserSectionId);
          }
        });
      });
    });
    //console.log(putAdditionalAttr);
    //console.log(this.formAddAttr.value);
  }

  /* section 3 update */
  putStatementIndiv(putStatementIndiv: any) {
    (this.formStatIndiv.get('inputSheetSection') as FormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.formStatIndiv.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.printInfoId.value;
      (((this.formStatIndiv.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.descriptionList as FormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.formStatIndiv.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.descriptionList as FormArray).controls[pindex] as FormGroup).controls.inputSheetUserSectionId.value;
        const description = ((((this.formStatIndiv.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.descriptionList as FormArray).controls[pindex] as FormGroup).controls.description.value;
        putStatementIndiv.InputUserSection.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetUserSectionId === '' || editData.description === description) {
            ((((this.formStatIndiv.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.descriptionList as FormArray).controls[pindex] as FormGroup).controls.inputSheetUserSectionId.patchValue(editData.inputSheetUserSectionId);
          }
        });
      });
    });
    //console.log(putStatementIndiv);
    //console.log(this.formStatIndiv.value);
  }

  deleteAddAttr(element, index) {
    //console.log(element, index);
    // (((this.formAddAttr.controls.inputSheetSection as FormArray).controls[adTitleDataIndex] as FormGroup).controls.descriptionList as FormArray).push(descriptionFG);
    // const delAttr = {
    //   params: new HttpParams()
    //   .set('INPUT_SHEET_USER_SECTION_ID': in)
    // }
  }

  addTag(tagData?: any) {
    const adsData = this.fb.group({
      attributeCheck: 'Y',
      description: 'Tag Line Desc',
      sequence: 5,
      sheetSectionMaster: {
        inputSheetSectionMasterId: this.appConstants.inputSheetSectionMasterId,
        sequenceNumber: 5,
        sectionName: 'Tag Line(S)',
        sectionGroup: ''
      },
      inputSheet: {
        inputSheetId: [tagData ? tagData.inputsheetId : ''],
        inputSheetPrintInfo: [
          {
            printInfoId: (tagData) ? tagData.printInfoId : ''
          }
        ]
      },
      showTitle: [tagData ? tagData.showTitle : ''],
      printInfoId: [tagData ? tagData.printInfoId : ''],
      noOfPrints: [tagData ? tagData.noOfPrints : ''],
      inputsheetId: [tagData ? tagData.inputsheetId : ''],
      promoSet: this.fb.array([]),
    });
    (this.formTag.get('tag') as FormArray).push(adsData);
    const afterGroupDataIndex = (this.formTag.get('tag') as FormArray).length - 1;
    if (!tagData) {
      this.addTagPromoSet(afterGroupDataIndex);
    } else {
      tagData.promoSet.forEach(promoData => {
        this.addTagPromoSet(afterGroupDataIndex, promoData);
      });
    }

  }
  addTagPromoSet(afterGroupDataIndex: number, data?: any) {
    const promoSetFG = this.fb.group({
      promoSpotPrintTitle: [data ? data : ''],
    });
    // tslint:disable-next-line: max-line-length
    (((this.formTag.controls.tag as FormArray).controls[afterGroupDataIndex] as FormGroup).controls.promoSet as FormArray).push(promoSetFG);

  }
  AddRowTag() {
    const afterGroupDataIndex = (this.formTag.get('tag') as FormArray).length - 1;
    this.formTag.controls.tag.value.forEach(
      (addshowData, addIndex) => {
        this.addTagPromoSet(addIndex);
      });
  }
  addShowDesc(showDescData?: any) {
    const adsData = this.fb.group({
      attributeCheck: 'Y',
      description: 'Show Description',
      sequence: 6,
      sheetSectionMaster: {
        inputSheetSectionMasterId: this.appConstants.inputSheetSectionMasterId,
        sequenceNumber: 6,
        sectionName: 'Show Description',
        sectionGroup: ''
      },
      inputSheet: {
        inputSheetId: [showDescData ? showDescData.inputsheetId : ''],
        inputSheetPrintInfo: [
          {
            printInfoId: (showDescData) ? showDescData.printInfoId : ''
          }
        ]
      },
      showTitle: [showDescData ? showDescData.showTitle : ''],
      printInfoId: [showDescData ? showDescData.printInfoId : ''],
      noOfPrints: [showDescData ? showDescData.noOfPrints : ''],
      inputsheetId: [showDescData ? showDescData.inputsheetId : ''],
      promoSet: this.fb.array([]),
    });
    (this.formShowDesc.get('showDesc') as FormArray).push(adsData);
    const afterGroupDataIndex = (this.formShowDesc.get('showDesc') as FormArray).length - 1;
    if (!showDescData) {
      this.addShowDescPromoSet(afterGroupDataIndex);
    } else {
      showDescData.promoSet.forEach((promoData, index) => {
        //console.log(afterGroupDataIndex);
        //console.log(index);
        // if (index === 0) {
        this.addShowDescPromoSet(afterGroupDataIndex, promoData);
        // }

      });
    }

  }
  addShowDescPromoSet(afterGroupDataIndex: number, data?: any) {
    const promoSetFG = this.fb.group({
      promoSpotPrintTitle: [data ? data : ''],
    });
    // tslint:disable-next-line: max-line-length
    (((this.formShowDesc.controls.showDesc as FormArray).controls[afterGroupDataIndex] as FormGroup).controls.promoSet as FormArray).push(promoSetFG);
  }
  AddRowShowDesc() {
    const afterGroupDataIndex = (this.formShowDesc.get('showDesc') as FormArray).length - 1;
    this.formShowDesc.controls.showDesc.value.forEach(
      (addshowData, addIndex) => {
        this.addShowDescPromoSet(addIndex);
      });
  }


  addVideotoShow(videotoshowData?: any) {
    const adsData = this.fb.group({
      attributeCheck: 'Y',
      description: 'Video to Show ',
      sequence: 7,
      sheetSectionMaster: {
        inputSheetSectionMasterId: this.appConstants.inputSheetSectionMasterId,
        sequenceNumber: 7,
        sectionName: 'Video to Show',
        sectionGroup: ''
      },
      inputSheet: {
        inputSheetId: [videotoshowData ? videotoshowData.inputsheetId : ''],
        inputSheetPrintInfo: [
          {
            printInfoId: (videotoshowData) ? videotoshowData.printInfoId : ''
          }
        ]
      },
      showTitle: [videotoshowData ? videotoshowData.showTitle : ''],
      printInfoId: [videotoshowData ? videotoshowData.printInfoId : ''],
      noOfPrints: [videotoshowData ? videotoshowData.noOfPrints : ''],
      inputsheetId: [videotoshowData ? videotoshowData.inputsheetId : ''],
      promoSet: this.fb.array([]),
    });
    (this.formVideotoShow.get('videotoshow') as FormArray).push(adsData);
    const afterGroupDataIndex = (this.formVideotoShow.get('videotoshow') as FormArray).length - 1;
    if (!videotoshowData) {
      this.addVideoShowPromoSet(afterGroupDataIndex);
    } else {
      videotoshowData.promoSet.forEach((promoData, index) => {
        //                 //console.log(afterGroupDataIndex);
        //console.log(index);
        // if (index === 0) {
        this.addVideoShowPromoSet(afterGroupDataIndex, promoData);
        // }

      });
    }

  }
  addVideoShowPromoSet(afterGroupDataIndex: number, data?: any) {
    const promoSetFG = this.fb.group({
      promoSpotPrintTitle: [data ? data : ''],
    });
    // tslint:disable-next-line: max-line-length
    (((this.formVideotoShow.controls.videotoshow as FormArray).controls[afterGroupDataIndex] as FormGroup).controls.promoSet as FormArray).push(promoSetFG);
  }

  AddRowVideoToShow() {
    const afterGroupDataIndex = (this.formVideotoShow.get('videotoshow') as FormArray).length - 1;
    this.formVideotoShow.controls.videotoshow.value.forEach(
      (addshowData, addIndex) => {
        this.addVideoShowPromoSet(addIndex);
      });
  }

  onSection2Submit(formValue) {
    //console.log(formValue);
    if (this.formAddAttr.invalid) {
      this.submittedForm2 = true;
      //console.log('form invalid');
      return false;

    } else {
      //console.log('form valid');
      this.isLoading = true;
      if (this.sec2SaveEdit === true) {
        this.commonService.postRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetSave}/${this.appConstants.inputSheetPrintPromoSection}`, 'POST', formValue)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            result => {
              this.sec2SaveEdit = false;
              this.isLoading = false;
              this.putAdditionalAttr(result);

              this.toastorService.success('Saved Successfully');
            }, err => {
              this.isLoading = false;
              this.toastorService.warn('Oops!!! The Server was unable to complete your request');
            });
      } else {
        this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetUpdate}/${this.appConstants.inputSheetPrintPromoSection}`, 'PUT', formValue)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            result => {
              this.isLoading = false;
              this.putAdditionalAttr(result);

              this.toastorService.success('Updated Successfully');
            }, err => {
              this.isLoading = false;
              this.toastorService.warn('Oops!!! The Server was unable to complete your request');
            });
      }
    }
  }

  onSection3Submit(formValue) {
    //console.log(formValue);
    if (this.formStatIndiv.invalid) {
      this.submittedForm3 = true;
      //console.log('form invalid');
    } else {
      this.isLoading = true;
      //console.log('form valid');
      if (this.sec3SaveEdit === true) {
        this.commonService.postRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetSave}/${this.appConstants.inputSheetPrintPromoSection}`, 'POST', formValue)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            result => {
              this.sec3SaveEdit = false;
              this.isLoading = false;
              this.putStatementIndiv(result);

              this.toastorService.success('Saved Successfully');
            }, err => {
              this.isLoading = false;
              this.toastorService.warn('Oops!!! The Server was unable to complete your request');
            });
      } else {
        this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetUpdate}/${this.appConstants.inputSheetPrintPromoSection}`, 'PUT', formValue)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            result => {
              this.isLoading = false;
              this.putStatementIndiv(result);
              this.toastorService.success('Updated Successfully');
            }, err => {
              this.isLoading = false;
              this.toastorService.warn('Oops!!! The Server was unable to complete your request');
            });
      }
    }
  }

  getTotalAds() {
    // this.TotalAds = this.TotalAds + parseInt(evt.target.value);
    this.TotalAds = 0;
    const inputSheetPrintInfoArray = this.promoData.promoAddCreateForm.get('inputSheetPrintInfo') as FormArray;
    let indexVal: any = 0;
    inputSheetPrintInfoArray.value.map(
      t => {
        if (t.noOfPrints != 'undefined' && t.noOfPrints != '') {
          this.TotalAds = this.TotalAds + parseInt(t.noOfPrints);
        }
        indexVal = parseInt(indexVal + 1)
        t.showTitleSequenceNumber = indexVal;
      }
    )
  }
  promoGenerate(status) {
    //console.log('generate');
    // console.log(this.pilotDate.value)
    // console.log(this.promoData.promoAddCreateForm.value);
    if (this.promoData.promoAddCreateForm.invalid) {
      return false;
    } else {
      this.isLoading = true;
      this.promoData.promoAddCreateForm.get('testDate').setValue(this.datePipe.transform(this.pilotDate.value, 'yyyy/MM/dd'));
      this.promoData.promoAddCreateForm.get('vendor').setValue(this.selectedVendor);
      if (this.promoNotSaved === true) {
        //console.log('POST')
        this.commonService.postRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}`, 'POST', this.promoData.promoAddCreateForm.value)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            result => {
              //console.log(result);
              this.promoNotSaved = false;
              this.promoData.promoAddCreateForm.controls.inputSheetId.setValue(result);
              this.inputSheetId = result;
              this.isLoading = false;
              this.getSection1(result);
              this.getStatementsGrpStd();
              this.getStatementsGroupVar();
              this.getElementsGrpStd();
              this.getElementsGrpVar();
              this.toastorService.success('Generated Successfully');
            },
            err => {
              this.isLoading = false;
              this.toastorService.warn('Oops!!! The Server was unable to complete your request');
            });
      } else {
        //console.log('PUT')
        status == 'DRAFT' ?
          this.promoData.promoAddCreateForm.value.inputSheetStatus.inputSheetStatus = 'DRAFT' :
          this.promoData.promoAddCreateForm.value.inputSheetStatus.inputSheetStatus = 'SUBMITTED';
        this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}`, 'PUT', this.promoData.promoAddCreateForm.value)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (result: any) => {
              this.promoNotSaved = false;
              //console.log(result.inputSheetId);
              if (status == 'DRAFT') {
                this.isLoading = false;
                this.getSection1(result.inputSheetId);
                this.toastorService.success('Updated Successfully');
              } else {
                this.isLoading = false;
                this.toastorService.success('Submitted Successfully');
                this.router.navigate(['../promo'], { relativeTo: this.route });
              }
            },
            err => {
              this.isLoading = false;
              this.toastorService.warn('Oops!!! The Server was unable to complete your request');
            });
      }
    }

  }

  openconfirmModal(type: any, id: any): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, { panelClass: 'confirmModal', disableClose: true, data: { msg: (type === 'cancel') ? 'cancel the changes' : (type == 'Submit') ? 'submit? All the unsaved changes will be lost' : '' } });
    dialogRef.afterClosed().subscribe(dialogResult => {
      //console.log(dialogResult);
      if (dialogResult === 'edit') {
        this.router.navigate(['../promo'], { relativeTo: this.route });
      }
      if (dialogResult === 'Submit') {
        this.promoGenerate('Submit');
      }
      // else {
      //   //console.log(this.clearMessage)
      // }
    });
  }
  onSelectedVendor(e){
     this.selectedVendor=e.value;
        
  }

  onSelectedGenre(e, index) {
    // this.isLoading = true;
    let selectedGenre = e;
    //console.log(selectedGenre);
    //console.log(index);
    this.commonService.getRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/showtitles/?genretype=${selectedGenre}`,
      'GET')
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        //console.log(data);
        // this.isLoading = false;
        this.showTitleList[index] = data;
      }, error => this.toastorService.warn(error)
      );
  }




  promoaddRow() {
    //console.log('Add Promo Row');
    //console.log(this.promoData.promoaddData)

    this.showTitleEmpty = false;
    let rows = this.promoData.promoAddCreateForm.get('inputSheetPrintInfo') as FormArray;
    rows.push(this.createItem());
    this.promoData.promoaddData.push([
      { genreId: '', showTitleID: '', episode: '', noofprintads: '' }
    ]);
    this.dataSource = new MatTableDataSource(this.promoData.promoaddData);

    //console.log(rows.value);
    //console.log(this.promoData.promoAddCreateForm.value);
    //console.log('datasource', this.dataSource);
  }
  removeRow(ele, index) {
    //console.log(ele);
    //console.log(index)
    //console.log(this.promoData.promoaddData)

    //console.log(this.promoData.promoAddCreateForm)
    this.isLoading = true;
    let inputSheetPrintInfo = this.promoData.promoAddCreateForm.get('inputSheetPrintInfo') as FormArray;
    //console.log(this.inputSheetId);
    // let promoDatasec: any;
    //console.log(inputSheetPrintInfo.value[index]);
    if (inputSheetPrintInfo.value[index].printInfoId != null) {
      const printInfoId = inputSheetPrintInfo.value[index].printInfoId;
      // const options = printInfoId ?
      // { params: new HttpParams().set('InputSheetPrintInfoId', printInfoId) } : {};
      const options = printInfoId ?
        { 'InputSheetPrintInfoId': printInfoId } : {};
      //console.log('Array format ',options);
      // return false;
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/header`,
        'DELETE', options)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          // this.getTable();
          //console.log(result);
          //console.log((this.promoData.promoAddCreateForm.get('inputSheetPrintInfo') as FormArray));
          (this.promoData.promoAddCreateForm.get('inputSheetPrintInfo') as FormArray).removeAt(index);
          let promoDatasec: any;
          promoDatasec = this.promoData.promoaddData.filter((item, i) => i != index);
          this.promoData.promoaddData = promoDatasec;
          this.dataSource = new MatTableDataSource(this.promoData.promoaddData);
          this.getTotalAds();
          if ((this.promoData.promoAddCreateForm.get('inputSheetPrintInfo') as FormArray).length == 0) {
            this.showTitleEmpty = true;
          }
          // this.getSection1(this.inputSheetId);
          const inputSheetId = this.inputSheetId ? { 'inputSheetId': this.inputSheetId } : {};
          this.commonService.getApiRequest(`
            ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/getAllPromoSections`,
            'GET', inputSheetId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => {
              //console.log(result);
              this.isLoading = false;
              this.SpotData = result.Section_Data.spotDetails;
              this.AddAttrData = result.Section_Data.additionalAttributes;
              this.statIndivData = result.Section_Data.statementIndiv;
              // this.loadSpotDetails(this.SpotData);
              this.loadSection1(this.inputSheetId);
            })
        },
          error => {
            this.toastorService.warn('Error' + error.message);
          },
          () => {
            this.toastorService.success('Show Title Deleted Successfully!');
          }
        );
    } else {
      let promoDatasec: any;
      promoDatasec = this.promoData.promoaddData.filter((item, i) => i != index);
      this.promoData.promoaddData = promoDatasec;
      this.dataSource = new MatTableDataSource(this.promoData.promoaddData);
      (this.promoData.promoAddCreateForm.get('inputSheetPrintInfo') as FormArray).removeAt(index);
      this.getTotalAds();
      this.isLoading = false;
      if ((this.promoData.promoAddCreateForm.get('inputSheetPrintInfo') as FormArray).length == 0) {
        this.showTitleEmpty = true;
      }
    }
  }


  /* Load section datas after delete*/
  loadSection1(inputSheetId) {
    this.isLoading = true;
    this.editSpottitleData = [];
    while ((this.formSpot.get('adTitle') as FormArray).length !== 0) {
      (this.formSpot.get('adTitle') as FormArray).removeAt(0);
    }
    while ((this.formAddAttr.get('inputSheetSection') as FormArray).length !== 0) {
      (this.formAddAttr.get('inputSheetSection') as FormArray).removeAt(0)
    }
    while ((this.formStatIndiv.get('inputSheetSection') as FormArray).length !== 0) {
      (this.formStatIndiv.get('inputSheetSection') as FormArray).removeAt(0)
    }
    // const options = inputSheetId ?
    //   { params: new HttpParams().set('inputSheetId', inputSheetId) } : {};
    const options = this.inputSheetId ? { 'inputSheetId': inputSheetId } : {};
    this.commonService.getApiRequest(`
    ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetprintTitles}`,
      'GET', options)
      //this.commonService.getRequest(`${localURL}`, 'GET')
      .subscribe(
        datasection => {
          this.isLoading = false;
          this.editSpottitleData = datasection;
          this.sectionData = datasection;
        },
        (err) => {
          this.isLoading = false;
          this.toastorService.warn('Oops!!! The Server was unable to complete your request');
        },
        () => {
          this.promoEditSectionDisplay();
        }
      );
  }
  promoEditSectionDisplay() {
    this.displayedColumns = [];
    // this.datasection2HeaderList = [];
    if (this.editSpottitleData) {
      if (this.SpotData != null) {
        this.SpotData.map(item => {
          //console.log(item.promoSpotPrintTitle)
          this.displayedColumns.push(item.promoSpotPrintTitle)
        })
      }
      //console.log(this.SpotData);
      //console.log(this.editAdTitleGroup);
      this.spotDetailsCount = 0;
      this.editSpottitleData.forEach((promoDataitem, adindex) => {
        //console.log(promoDataitem)
        this.editAdTitleGroup(promoDataitem);
      });
      if (this.SpotData != null) {
        this.SpotData.forEach((promoSpotData) => {
          this.editAddAttribute(promoSpotData);
          this.editStatementIndiv(promoSpotData);
        });
        this.spotNotSaved = false;
      } else {
        this.spotNotSaved = true;
      }
    }
  }

  /* Spot Details Edit Start */
  editAdTitleGroup(spotData?: any) {
    const spotFG = this.fb.group({
      printInfoId: [spotData ? spotData.printInfoId : ''],
      noOfPrints: [spotData ? spotData.noOfPrints : ''],
      inputSheetId: [spotData ? spotData.inputsheetId : ''],
      promoSet: this.fb.array([]),
    });
    this.spotDetailsData.push([
      {
        attributeCheck: 'Y',
        description: 'spot Desc',
        sequence: 1,
        sheetSectionMaster: {
          inputSheetSectionMasterId: 631,
          sequenceNumber: 10,
          sectionName: 'Spot Details',
          sectionGroup: ''
        },
        showTitle: spotData ? spotData.showTitle : '',
        printInfoId: spotData ? spotData.printInfoId : '',
        noOfPrints: spotData ? spotData.noOfPrints : '',
        inputsheetId: spotData ? spotData.inputsheetId : '',
        promoSet: []
      }
    ]);
    (this.formSpot.get('adTitle') as FormArray).push(spotFG);
    const adTitleDataIndex = (this.formSpot.get('adTitle') as FormArray).length - 1;
    if (!spotData) {
      this.editAdTitlePromoSet(adTitleDataIndex);
    } else {
      const editAdTitleData = this.SpotData;
      if (editAdTitleData != null) {
        editAdTitleData.forEach(adTitleItem => {
          if (spotData.printInfoId === adTitleItem.printInfoId) {
            //console.log(spotData.primeShowTitle)
            this.spotDetailsCount = this.spotDetailsCount + 1;
            this.editAdTitlePromoSet(adTitleDataIndex, spotData.primeShowTitle, adTitleItem, this.spotDetailsCount);
          }
        });
      }
    }

  }
  editAdTitlePromoSet(spotDataIndex: number, data?: any, dataVal?: any, spotpromosetIndex?: any) {
    //console.log(dataVal)
    const promoSetFG = this.fb.group({
      inputSheetPromoSpotId: new FormControl(dataVal ? dataVal.inputSheetPromoSpotId : null),
      spotPosition: spotpromosetIndex,
      showTitle: data ? data.showTitle : '',
      promoSpotPrintTitle: [dataVal ? dataVal.promoSpotPrintTitle : '', [Validators.required,
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField]],
      promoLength: dataVal ? dataVal.promoLength : '',
      showNoTimes: dataVal ? dataVal.showNoTimes : '',
      adType: [dataVal ? dataVal.adType : '',
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))],
      programType: [dataVal ? dataVal.programType : '',
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))],
      printQuality: [dataVal ? dataVal.printQuality : '',
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))],
      splNotes: [dataVal ? dataVal.splNotes : '',
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))],
      sectionId: 631,
      sectionName: 'Spot Details',
      sequenceNumber: 1
    });
    this.spotDetailsData[spotDataIndex].map((item) =>
      item.promoSet.push(
        {
          inputSheetPromoSpotId: dataVal ? dataVal.inputSheetPromoSpotId : null,
          spotPosition: spotpromosetIndex,
          showTitle: data ? data.showTitle : '',
          promoSpotPrintTitle: dataVal ? dataVal.promoSpotPrintTitle : '',
          promoLength: dataVal ? dataVal.promoLength : '',
          showNoTimes: dataVal ? dataVal.showNoTimes : '',
          adType: dataVal ? dataVal.adType : '',
          programType: dataVal ? dataVal.programType : '',
          printQuality: dataVal ? dataVal.printQuality : '',
          splNotes: dataVal ? dataVal.splNotes : ''
        }
      )
    );
    // tslint:disable-next-line: max-line-length
    (((this.formSpot.controls.adTitle as FormArray).controls[spotDataIndex] as FormGroup).controls.promoSet as FormArray).push(promoSetFG);

  }
  /* Spot Details Edit section End */

  /* Additional Attribute Edit section Start */
  editAddAttribute(adAttributeData?: any) {
    //console.log(adAttributeData)
    const adTitleFG = this.fb.group({
      printInfoId: [adAttributeData ? adAttributeData.printInfoId : ''],
      inputSheetId: [adAttributeData ? adAttributeData.inputsheetId : ''],
      inputSheetPromoSpotId: [adAttributeData ? adAttributeData.inputSheetPromoSpotId : ''],
      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 625,
        sequenceNumber: 1,
        sectionName: "ADDITIONAL ATTRIBUTES",
        sectionGroup: ""
      }
    });
    (this.formAddAttr.get('inputSheetSection') as FormArray).push(adTitleFG);
    const adAttributeDataIndex = (this.formAddAttr.get('inputSheetSection') as FormArray).length - 1;
    let attributeDataSet: any = [];
    if (this.AddAttrData != null) {
      this.AddAttrData.map((item, index) => {
        if (item.inputSheetPromoSpotId == adAttributeData.inputSheetPromoSpotId) {
          attributeDataSet.push(item);
        }
      });
    }
    //console.log(attributeDataSet)
    // if (adAttributeData != "") {
    if (!adAttributeData) {
      //console.log('if');
      this.addAdTitlePromoSet(adAttributeDataIndex);
    } else {
      //console.log('else if')
      if (attributeDataSet != "") {
        attributeDataSet.forEach((val, index) => {
          this.addAdTitlePromoSet(adAttributeDataIndex, val, attributeDataSet[index]);
        })
      }
    }
  }
  /* Additional Attribute Edit section End */

  /* Statement indiv section start */
  editStatementIndiv(adAttributeData?: any) {
    const adTitleFG = this.fb.group({
      printInfoId: [adAttributeData ? adAttributeData.printInfoId : ''],
      inputSheetId: [adAttributeData ? adAttributeData.inputsheetId : ''],
      inputSheetPromoSpotId: [adAttributeData ? adAttributeData.inputSheetPromoSpotId : ''],
      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 626,
        sequenceNumber: 2,
        sectionName: "STATEMENTS INDIV",
        sectionGroup: ""
      }
    });
    (this.formStatIndiv.get('inputSheetSection') as FormArray).push(adTitleFG);
    const adAttributeDataIndex = (this.formStatIndiv.get('inputSheetSection') as FormArray).length - 1;
    let attributeDataSet: any = [];
    if (this.statIndivData != null) {
      this.statIndivData.map((item, index) => {
        if (item.inputSheetPromoSpotId == adAttributeData.inputSheetPromoSpotId) {
          attributeDataSet.push(item);
        }
      });
    }
    //console.log(adAttributeData)
    // if (adAttributeData != "") {
    if (!adAttributeData) {
      this.addStatIndivGroupPromoSet(adAttributeDataIndex);
    } else {
      if (attributeDataSet != "") {
        attributeDataSet.forEach((val, index) => {
          this.addStatIndivGroupPromoSet(adAttributeDataIndex, val, attributeDataSet[index]);
        })
      }
    }
  }
  /* Statement indiv section end */
  /* Load section datas */

  deleteSections(cellValue) {
    //console.log(cellValue);
    //  //console.log(cellValue.value.inputSheetPromoSpotId);
    cellValue.controls.description.reset();
    if (cellValue.value.inputSheetUserSectionId) {
      this.isLoading = true;
      // const options = cellValue.value.inputSheetUserSectionId ?
      // { params: new HttpParams().set('INPUT_SHEET_USER_SECTION_ID', cellValue.value.inputSheetUserSectionId) } : {};
      const options = cellValue.value.inputSheetUserSectionId ?
        { 'INPUT_SHEET_USER_SECTION_ID': cellValue.value.inputSheetUserSectionId } : {};
      this.commonService.deleteRequest(`
  ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetPrintAdsDashboard}/${this.appConstants.inputSheetSections}/${this.appConstants.inputSheetSectionsDelete}`,
        'DELETE', options)
        .subscribe(
          datasection => {
            //console.log(datasection);
            // if(sectionNumber='section2'){this.putAfterINDIVadsGroup(datasection);}
            // if(sectionNumber='section3'){this.putAfterGroupAds(datasection);}
            // if(sectionNumber='section4'){this.putAfterPromoAds(datasection);}
            // if(sectionNumber='section5'){this.putaddTag(datasection);}
            // if(sectionNumber='section6'){this.putShowDesc(datasection);}
            // if(sectionNumber='section7'){this.putVideotoShow(datasection);}
            cellValue.controls.description.reset();
            this.isLoading = false;
            this.toastorService.success('Deleted Successfully');
          },
          (err) => {
            this.isLoading = false;
            this.toastorService.warn('Oops!!! The Server was unable to complete your request');
          },
        );
    }

  }

  getStatementsGrpStd() {
    this.isLoading = true;
    let statstd = [];
    let data = [];
    //console.log(this.inputSheetId)
    // const getStatGrpStd = {
    //   params: new HttpParams()
    //     .set('inputSheetId', this.inputSheetId)
    //     .append('sectionName', 'STATEMENT GRP_STANDARD')
    //     .append('sectiontype', 'PROMO')
    //   } 
    const getStatGrpStd = { 'inputSheetId': this.inputSheetId, 'sectionName': 'STATEMENT GRP_STANDARD', 'sectiontype': 'PROMO' };
    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/sections`,
      'GET', getStatGrpStd)
      .pipe(takeUntil(this.destroy$))
      .subscribe(chardata => {
        //console.log(chardata);
        statstd = chardata;
        statstd = statstd.map(mapdata => {
          let metaFlag;
          mapdata.createdBy == 'METADATA' ? metaFlag = false : metaFlag = true
          data.push({
            rowid: mapdata.inputSheetUserSectionId,
            posstatstd: mapdata.sheetQuestion.questionSequenceNum,
            statstd: mapdata.sheetQuestion.questionLongText,
            sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
            metaDataFlag: metaFlag,
            upDateFlag: mapdata.upDateFlag
          });
          const ArrayData = this.fb.group({
            statstd: new FormControl(mapdata.sheetQuestion.questionLongText, [
              Validators.required,
              Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
              validateEmptyField
            ])
          });
          (this.section4Form.get('section4Array') as FormArray).push(ArrayData);
        })

        this.statGrpList = data;
        //console.log(this.statGrpList);
        this.dataSource4 = new MatTableDataSource(this.statGrpList);
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        this.toastorService.warn(error.message);
      })
  }


  getStatementsGroupVar() {
    this.isLoading = true;
    let statgrpvar = [];
    let data = [];
    //console.log(this.inputSheetId)
    // const getStatGrpVar = {
    //   params: new HttpParams()
    //     .set('inputSheetId', this.inputSheetId)
    //     .append('sectionName', 'STATEMENT GRP_VARIABLE')
    //     .append('sectiontype', 'PROMO')
    //   } 
    const getStatGrpVar = { 'inputSheetId': this.inputSheetId, 'sectionName': 'STATEMENT GRP_VARIABLE', 'sectiontype': 'PROMO' };
    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/sections`,
      'GET', getStatGrpVar)
      .pipe(takeUntil(this.destroy$))
      .subscribe(chardata => {
        //console.log(chardata);
        this.isLoading = false;
        statgrpvar = chardata;
        statgrpvar = statgrpvar.map(mapdata => {
          let metaFlag;
          mapdata.createdBy == 'METADATA' ? metaFlag = false : metaFlag = true
          data.push({
            rowid: mapdata.inputSheetUserSectionId,
            posstatvar: mapdata.sheetQuestion.questionSequenceNum,
            statgrpvar: mapdata.sheetQuestion.questionLongText,
            sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
            metaDataFlag: metaFlag,
            upDateFlag: mapdata.upDateFlag
          });
          const ArrayData = this.fb.group({
            statgrpvar: new FormControl(mapdata.sheetQuestion.questionLongText, [
              Validators.required,
              Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
              validateEmptyField
            ])
          });
          // const ArrayDataSource = mapdata.questionLongText
          (this.section5Form.get('section5Array') as FormArray).push(ArrayData);
        })

        this.statGrpVarList = data;
        //console.log(this.statGrpVarList);
        this.dataSource5 = new MatTableDataSource(this.statGrpVarList);

      }, error => {
        this.isLoading = false;
        this.toastorService.warn(error.message);
      })
  }

  getElementsGrpStd() {
    this.isLoading = true;
    let elegrpStd = [];
    let data = [];
    //console.log(this.inputSheetId)
    // const getEleGrpStd = {
    //   params: new HttpParams()
    //     .set('inputSheetId', this.inputSheetId)
    //     .append('sectionName', 'ELEMENT GRP_STANDARD')
    //     .append('sectiontype', 'PROMO')
    //   } 
    const getEleGrpStd = { 'inputSheetId': this.inputSheetId, 'sectionName': 'ELEMENT GRP_STANDARD', 'sectiontype': 'PROMO' };
    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/sections`,
      'GET', getEleGrpStd)
      .pipe(takeUntil(this.destroy$))
      .subscribe(chardata => {
        this.isLoading = false;
        //console.log(chardata);
        elegrpStd = chardata;
        elegrpStd = elegrpStd.map(mapdata => {
          let metaFlag;
          mapdata.createdBy == 'METADATA' ? metaFlag = false : metaFlag = true
          data.push({
            rowid: mapdata.inputSheetUserSectionId,
            poselestd: mapdata.sheetQuestion.questionSequenceNum,
            elegrpStd: mapdata.sheetQuestion.questionLongText,
            sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
            metaDataFlag: metaFlag,
            upDateFlag: mapdata.upDateFlag
          });
          const ArrayData = this.fb.group({
            elegrpStd: new FormControl(mapdata.sheetQuestion.questionLongText, [
              Validators.required,
              Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
              validateEmptyField
            ])
          });
          // const ArrayDataSource = mapdata.questionLongText
          (this.section6Form.get('section6Array') as FormArray).push(ArrayData);
        })
        this.elegrpstdList = data;
        //console.log(this.elegrpstdList);
        this.dataSource6 = new MatTableDataSource(this.elegrpstdList);
      }, error => {
        this.isLoading = false;
        this.toastorService.warn(error.message);
      })
  }

  getElementsGrpVar() {
    this.isLoading = true;
    let elegrpVar = [];
    let data = [];
    //console.log(this.inputSheetId)
    // const getEleGrpVar = {
    //   params: new HttpParams()
    //     .set('inputSheetId', this.inputSheetId)
    //     .append('sectionName', 'ELEMENT GRP_VARIABLE')
    //     .append('sectiontype', 'PROMO')
    //   } 
    const getEleGrpVar = { 'inputSheetId': this.inputSheetId, 'sectionName': 'ELEMENT GRP_VARIABLE', 'sectiontype': 'PROMO' };
    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/sections`,
      'GET', getEleGrpVar)
      .pipe(takeUntil(this.destroy$))
      .subscribe(chardata => {
        //console.log(chardata);
        this.isLoading = false;
        elegrpVar = chardata;
        elegrpVar = elegrpVar.map(mapdata => {
          let metaFlag;
          mapdata.createdBy == 'METADATA' ? metaFlag = false : metaFlag = true
          data.push({
            rowid: mapdata.inputSheetUserSectionId,
            poselegrpVar: mapdata.sheetQuestion.questionSequenceNum,
            elegrpVar: mapdata.sheetQuestion.questionLongText,
            sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
            metaDataFlag: metaFlag,
            upDateFlag: mapdata.upDateFlag
          });
          const ArrayData = this.fb.group({
            elegrpVar: new FormControl(mapdata.sheetQuestion.questionLongText, [
              Validators.required,
              Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
              validateEmptyField
            ])
          });
          // const ArrayDataSource = mapdata.questionLongText
          (this.section7Form.get('section7Array') as FormArray).push(ArrayData);
        })

        this.elegrpvarList = data;
        //console.log(this.elegrpvarList);
        this.dataSource7 = new MatTableDataSource(this.elegrpvarList);

      }, error => {
        this.isLoading = false;
        this.toastorService.warn(error.message);
      })
  }


  // getInitStatementsGroupStd() {
  //   this.isLoading = true;
  //   let statstd = [];
  //   let data: any = [];
  //   this.commonService.getRequest(
  //     `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/questions?sectionName=STATEMENT GRP_STANDARD`,
  //     'GET')
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(chardata => {
  //       this.isLoading = false;
  //       //console.log(chardata);
  //       statstd = chardata;
  //       statstd = statstd.map(mapdata => {
  //         data.push({
  //           sheetQuestionId: mapdata.inputSheetQuestionsId,
  //           posstatstd: mapdata.questionSequenceNum,
  //           statstd: mapdata.questionLongText,
  //         });
  //        const ArrayData = this.fb.group({
  //           statstd: new FormControl(mapdata.questionLongText, [
  //             Validators.required, 
  //             Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
  //             validateEmptyField
  //           ])
  //        });
  //       // const ArrayDataSource = mapdata.questionLongText
  //        (this.section4Form.get('section4Array') as FormArray).push(ArrayData);
  //       });
  //       this.statGrpList = data;
  //       //console.log(this.statGrpList);
  //       this.dataSource4 = new MatTableDataSource<InputSheetPromostatGrpSection>(this.statGrpList);

  //     }, error => {
  //       this.isLoading = false;
  //       this.toastorService.warn(error)
  //     }
  //     );
  // }


  // getInitStatementsGroupVar() {
  //   this.isLoading = true;
  //   let statgrpvar = [];
  //   let data = [];
  //   this.commonService.getRequest(
  //     `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/questions?sectionName=STATEMENT GRP_VARIABLE`,
  //     'GET')
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(chardata => {
  //       this.isLoading = false;
  //       //console.log(chardata);
  //       statgrpvar = chardata;
  //       statgrpvar = statgrpvar.map(mapdata => {
  //         data.push({
  //           sheetQuestionId: mapdata.inputSheetQuestionsId,
  //           posstatvar: mapdata.questionSequenceNum,
  //           statgrpvar: mapdata.questionLongText,
  //         });
  //         const ArrayData = this.fb.group({
  //           statgrpvar: new FormControl(mapdata.questionLongText, [
  //             Validators.required, 
  //             Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
  //             validateEmptyField
  //           ])
  //         });
  //       // const ArrayDataSource = mapdata.questionLongText
  //        (this.section5Form.get('section5Array') as FormArray).push(ArrayData);
  //       })
  //       this.statGrpVarList = data;
  //       // this.attributeList = this.isReadonly = true;
  //       //console.log(this.statGrpVarList);
  //       this.dataSource5 = new MatTableDataSource<InputSheetPromostatGrpVarSection>(this.statGrpVarList);

  //     }, error => {
  //       this.isLoading = false;
  //       this.toastorService.warn(error)
  //     }
  //     );
  // }

  // getInitElementsGroupStd() {
  //   this.isLoading = true;
  //   let elegrpStd = [];
  //   let data = [];
  //   this.commonService.getRequest(
  //     `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/questions?sectionName=ELEMENT GRP_STANDARD`,
  //     'GET')
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(chardata => {
  //       this.isLoading = false;
  //       //console.log(chardata);
  //       elegrpStd = chardata;
  //       elegrpStd = elegrpStd.map(mapdata => {
  //         data.push({
  //           sheetQuestionId: mapdata.inputSheetQuestionsId,
  //           poselestd: mapdata.questionSequenceNum,
  //           elegrpStd: mapdata.questionLongText,
  //         });
  //         const ArrayData = this.fb.group({
  //           elegrpStd: new FormControl(mapdata.questionLongText, [
  //             Validators.required, 
  //             Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
  //             validateEmptyField
  //           ])
  //         });
  //       // const ArrayDataSource = mapdata.questionLongText
  //        (this.section6Form.get('section6Array') as FormArray).push(ArrayData);
  //       })
  //       this.elegrpstdList = data;
  //       //console.log(this.elegrpstdList);
  //       this.dataSource6 = new MatTableDataSource<InputSheetPromoElegrpstdSection>(this.elegrpstdList);

  //     }, error => {
  //       this.isLoading = false;
  //       this.toastorService.warn(error)
  //     }
  //     );
  // }

  // getInitElementsGroupVar() {
  //   this.isLoading = false;
  //   let elegrpVar = [];
  //   let data = [];
  //   this.commonService.getRequest(
  //     `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/questions?sectionName=ELEMENT GRP_VARIABLE`,
  //     'GET')
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(chardata => {
  //       this.isLoading = false;
  //       //console.log(chardata);
  //       elegrpVar = chardata;
  //       elegrpVar = elegrpVar.map(mapdata => {
  //         data.push({
  //           sheetQuestionId: mapdata.inputSheetQuestionsId,
  //           poselegrpVar: mapdata.questionSequenceNum,
  //           elegrpVar: mapdata.questionLongText,
  //         });
  //         const ArrayData = this.fb.group({
  //           elegrpVar: new FormControl(mapdata.questionLongText, [
  //             Validators.required, 
  //             Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
  //             validateEmptyField
  //           ])
  //         });
  //       // const ArrayDataSource = mapdata.questionLongText
  //        (this.section7Form.get('section7Array') as FormArray).push(ArrayData);
  //       })
  //       this.elegrpvarList = data;
  //       // this.attributeList = this.isReadonly = true;
  //       //console.log(this.elegrpvarList);
  //       this.dataSource7 = new MatTableDataSource<InputSheetPromoElegrpVarSection>(this.elegrpvarList);

  //     }, error => {
  //       this.isLoading = false;
  //       this.toastorService.warn(error)
  //     }
  //     );
  // }

  toggleAll(selection) {
    if (selection) {
      // this.openall = true;
      this.Accordion.multi = true;
      this.Accordion.openAll();
      this.toggle = false;
    } else {
      // this.openall = false;
      this.Accordion.closeAll();
      this.Accordion.multi = false;
      this.toggle = true;
    }

  }

  updatespotTitleVal(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.promoSpotPrintTitle = event.target.value;
  }

  updatelenVal(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.promoLength = event.target.value;
  }

  updateshowtimesVal(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.showNoTimes = event.target.value;
  }

  updateadTypeVal(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.adType = event.target.value;
  }

  updateshwTypeVal(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.programType = event.target.value;
  }
  updateprintQualVal(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.printQuality = event.target.value;
  }

  updateNotesVal(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.splNotes = event.target.value;
  }


  /** Section 2 - Add, Delete and Save method **/

  onAddattrRow() {
    // this.onAddsec2 = true;
    // let newRow = { rowid: null, posadattr: (this.addAtrributeList.length + 1), showTitle2: '', addattributes: '' };
    // this.addAtrributeList.push(newRow);
    // this.dataSource2 = new MatTableDataSource(this.addAtrributeList);
    //console.log(this.addAtrributeList);
    // this.dataSource2._updateChangeSubscription();
  }


  /** Section 3 - Add, Delete and Save method **/

  onAddstatRow() {
    this.onAddsec3 = true;
    let newRow = { rowid: null, posstat: (this.statIndvList.length + 1), statements: '' };
    this.statIndvList.push(newRow);
    this.dataSource3 = new MatTableDataSource(this.statIndvList);
    //console.log(this.statIndvList);
    this.dataSource3._updateChangeSubscription();
  }

  updatesta(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.statements = event.target.value;
  }

  updateVal(ele, event) {
    // console.log(ele);
    ele.setValue(event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"'));
    // console.log(ele.value);
  }

  // }
  /** Section 4 - Add, Delete and Save method **/

  onAddstatstdRow() {
    this.onAddsec4 = true;
    let newRow = {
      rowid: null, posstatstd: (this.statGrpList.length + 1), statstd: '',
      metaDataFlag: true, upDateFlag: true
    };
    const row = this.fb.group({
      statstd: new FormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField
      ])
    });
    (this.section4Form.get('section4Array') as FormArray).push(row);
    //console.log(this.section4Form.value);
    this.statGrpList.push(newRow);
    this.dataSource4 = new MatTableDataSource(this.statGrpList);
    //console.log(this.statGrpList);
    this.dataSource4._updateChangeSubscription();
  }

  updatestatstd(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.statstd = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  statstdSave(formValue) {
    if (formValue.invalid) {
      //console.log('invalid');
      this.submittedForm4 = true;
      return false;
    } else {
      this.isLoading = true;
      //console.log(formValue);
      //console.log(this.section4Form.value);
      //console.log(this.statGrpList);
      let statStdSave = [];
      //console.log(this.statGrpList);
      //  let stdSave = [];
      //console.log(statStdSave);
      statStdSave = this.statGrpList.map(item => {
        let createdBy;
        item.metaDataFlag == false ? createdBy = 'METADATA' : createdBy = null;
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.statstd,
          sequence: item.posstatstd,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 603,
            sequenceNumber: 3,
            sectionName: 'STATEMENT GRP_STANDARD',
            sectionGroup: ""
          },
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          sheetQuestion: {
            inputSheetQuestionsId: item.sheetQuestionId,
            questionLongText: item.statstd,
            questionSequenceNum: item.posstatstd
          },
          createdBy: createdBy,
          upDateFlag: item.upDateFlag
        }
      })
      // if (!this.statgrpstdSave) {
      //   //console.log(statStdSave);
      //   this.commonService.postRequest(`
      //   ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/promosection`, 'POST', statStdSave)
      //     .pipe(takeUntil(this.destroy$))  
      //     .subscribe(result => {
      //       //console.log(result);
      //   //    stdSave = result;
      //       this.loadStatStd(result);
      //       this.statgrpstdSave = true;
      //   //    this.getStatementsGrpStd();
      //       this.isLoading = false;
      //       this.toastorService.success('Saved Successfully');
      //     }, error => {
      //       this.isLoading = false;
      //       this.toastorService.warn(error.message);
      //     });
      // } else {
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', statStdSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          //   stdSave = result;
          this.isLoading = false;
          //      this.getStatementsGrpStd();
          this.loadStatStd(result);
          this.toastorService.success('Saved Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        });
      // }
    }
  }

  loadStatStd(stdSave) {
    if (stdSave == null) {
      this.onAddstatstdRow();
    } else {
      let data = [];
      (this.section4Form.controls.section4Array as FormArray).clear();
      stdSave = stdSave.map(mapdata => {
        let metaFlag;
        mapdata.createdBy == 'METADATA' ? metaFlag = false : metaFlag = true;
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          posstatstd: mapdata.sheetQuestion.questionSequenceNum,
          statstd: mapdata.sheetQuestion.questionLongText,
          sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
          metaDataFlag: metaFlag,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          statstd: new FormControl(mapdata.sheetQuestion.questionLongText, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section4Form.get('section4Array') as FormArray).push(ArrayData);
        //console.log(this.section4Form.value);
      })

      this.statGrpList = data;
      //console.log(this.statGrpList);
      this.dataSource4 = new MatTableDataSource(this.statGrpList);
    }

  }

  deleteStmtGrp(elm, index) {
    //console.log(elm);
    //console.log(index)
    //console.log(this.statGrpList);
    this.statGrpList.splice(index, 1);
    (this.section4Form.get('section4Array') as FormArray).removeAt(index);
    //console.log(this.section4Form.value);
    this.dataSource4 = new MatTableDataSource(this.statGrpList);

    if (elm.rowid != null || '') {
      this.isLoading = true;
      // const delStmGrp = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      // }
      const delStmGrp = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delStmGrp)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.isLoading = false;
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        })
    }
  }


  /** Section 5 - Add, Delete and Save method **/

  onAddStatgrpVarRow() {
    this.onAddsec5 = true;
    let newRow = {
      rowid: null, posstatvar: (this.statGrpVarList.length + 1), statgrpvar: '',
      metaDataFlag: true, upDateFlag: true
    };
    const row = this.fb.group({
      statgrpvar: new FormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField
      ])
    });
    (this.section5Form.get('section5Array') as FormArray).push(row);
    this.statGrpVarList.push(newRow);
    this.dataSource5 = new MatTableDataSource(this.statGrpVarList);
    //console.log(this.statGrpVarList);
    this.dataSource5._updateChangeSubscription();
  }

  updateStatgrpVar(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.statgrpvar = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  statgrpVarSave(formValue) {
    if (formValue.invalid) {
      //console.log('invalid');
      this.submittedForm5 = true;
      return false;
    } else {
      this.isLoading = true;
      //console.log(this.statGrpVarList);
      let statVarSave = [];
      //console.log(this.statGrpVarList);

      //console.log(statVarSave);
      statVarSave = this.statGrpVarList.map(item => {
        let createdBy;
        item.metaDataFlag == false ? createdBy = 'METADATA' : createdBy = null;
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.statgrpvar,
          sequence: item.posstatvar,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 604,
            sequenceNumber: 4,
            sectionName: 'STATEMENT GRP_VARIABLE',
            sectionGroup: ""
          },
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          sheetQuestion: {
            inputSheetQuestionsId: item.sheetQuestionId,
            questionLongText: item.statgrpvar,
            questionSequenceNum: item.posstatvar
          },
          createdBy: createdBy,
          upDateFlag: item.upDateFlag
        }
      })
      // if (!this.statgrpvarSave) {
      //   //console.log(statVarSave);
      //   this.commonService.postRequest(`
      //     ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/promosection`, 'POST', statVarSave)
      //     .pipe(takeUntil(this.destroy$))  
      //     .subscribe(result => {
      //       //console.log(result);
      //       this.statgrpvarSave = true;
      //       this.loadStatVar(result);
      //       this.isLoading = false;
      //     //  this.getStatementsGroupVar();
      //       this.toastorService.success('Saved Successfully');
      //     }, error => {
      //       this.isLoading = false;
      //       this.toastorService.warn(error.message);
      //     });
      // } else {
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', statVarSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.isLoading = false;
          this.loadStatVar(result);
          //   this.getStatementsGroupVar();
          this.toastorService.success('Saved Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        });
      // }
    }
  }

  loadStatVar(statgrpvar) {
    if (statgrpvar == null) {
      this.onAddStatgrpVarRow();
    } else {
      let data = [];
      (this.section5Form.controls.section5Array as FormArray).clear();
      statgrpvar = statgrpvar.map(mapdata => {
        let metaFlag;
        mapdata.createdBy == 'METADATA' ? metaFlag = false : metaFlag = true;
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          posstatvar: mapdata.sheetQuestion.questionSequenceNum,
          statgrpvar: mapdata.sheetQuestion.questionLongText,
          sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
          metaDataFlag: metaFlag,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          statgrpvar: new FormControl(mapdata.sheetQuestion.questionLongText, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section5Form.get('section5Array') as FormArray).push(ArrayData);
        //console.log(this.section5Form.value);
      })

      this.statGrpVarList = data;
      //console.log(this.statGrpVarList);
      this.dataSource5 = new MatTableDataSource(this.statGrpVarList);
    }
  }

  deleteStatgrpvar(elm, index) {
    //console.log(elm);
    //console.log(this.statGrpVarList);
    this.statGrpVarList.splice(index, 1);
    (this.section5Form.get('section5Array') as FormArray).removeAt(index);
    //console.log(this.statGrpVarList)
    this.dataSource5 = new MatTableDataSource(this.statGrpVarList);

    if (elm.rowid != null || '') {
      this.isLoading = true;
      // const delStmVar = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      // }
      const delStmVar = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delStmVar)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          //this.getStatementsGroupVar();
          this.isLoading = false;
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        })
    }
  }



  /** Section 6 - Add, Delete and Save method **/

  onaddeleGrpStdRow() {
    this.onAddsec6 = true;
    let newRow = {
      rowid: null, poselestd: (this.elegrpstdList.length + 1), elegrpStd: '',
      metaDataFlag: true, upDateFlag: true
    };
    const row = this.fb.group({
      elegrpStd: new FormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField
      ])
    });
    (this.section6Form.get('section6Array') as FormArray).push(row);
    this.elegrpstdList.push(newRow);
    this.dataSource6 = new MatTableDataSource(this.elegrpstdList);
    //console.log(this.elegrpstdList);
    this.dataSource6._updateChangeSubscription();
  }

  updateeleGrpstd(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.elegrpStd = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  eleGrpStdSave(formValue) {
    if (formValue.invalid) {
      //console.log('invalid');
      this.submittedForm6 = true;
      return false;
    } else {
      this.isLoading = true;
      //console.log(this.elegrpstdList);
      let eleStdSave = [];
      //console.log(this.elegrpstdList);

      //console.log(eleStdSave);
      eleStdSave = this.elegrpstdList.map(item => {
        let createdBy;
        item.metaDataFlag == false ? createdBy = 'METADATA' : createdBy = null;
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.elegrpStd,
          sequence: item.poselestd,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 605,
            sequenceNumber: 5,
            sectionName: 'ELEMENT GRP_STANDARD',
            sectionGroup: ""
          },
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          sheetQuestion: {
            inputSheetQuestionsId: item.sheetQuestionId,
            questionLongText: item.elegrpStd,
            questionSequenceNum: item.poselestd,
          },
          createdBy: createdBy,
          upDateFlag: item.upDateFlag
        }
      })
      // if (!this.elegrpstdSave) {
      //   //console.log(eleStdSave);
      //   this.commonService.postRequest(`
      //     ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/promosection`, 'POST', eleStdSave)
      //     .pipe(takeUntil(this.destroy$))
      //     .subscribe(result => {
      //       //console.log(result);
      //       this.elegrpstdSave = true;
      //       this.loadEleStd(result);
      //       this.isLoading = false;
      //   //    this.getElementsGrpStd();
      //       this.toastorService.success('Saved Successfully');
      //     }, error => {
      //       this.isLoading = false;
      //       this.toastorService.warn(error.message);
      //     });
      // } else {

      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', eleStdSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.isLoading = false;
          this.loadEleStd(result);
          //    this.getElementsGrpStd();
          this.toastorService.success('Saved Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        });
      // }
    }
  }

  loadEleStd(elegrpStd) {
    if (elegrpStd == null) {
      this.onaddeleGrpStdRow();
    } else {
      let data = [];
      (this.section6Form.controls.section6Array as FormArray).clear();
      elegrpStd = elegrpStd.map(mapdata => {
        let metaFlag;
        mapdata.createdBy == 'METADATA' ? metaFlag = false : metaFlag = true;
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          poselestd: mapdata.sheetQuestion.questionSequenceNum,
          elegrpStd: mapdata.sheetQuestion.questionLongText,
          sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
          metaDataFlag: metaFlag,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          elegrpStd: new FormControl(mapdata.sheetQuestion.questionLongText, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section6Form.get('section6Array') as FormArray).push(ArrayData);
        //console.log(this.section6Form.value);
      })

      this.elegrpstdList = data;
      //console.log(this.elegrpstdList);
      this.dataSource6 = new MatTableDataSource(this.elegrpstdList);
    }
  }

  deleteeleGrpstd(elm, index) {
    //console.log(elm);
    //console.log(this.elegrpstdList);
    this.elegrpstdList.splice(index, 1);
    (this.section6Form.get('section6Array') as FormArray).removeAt(index);
    this.dataSource6 = new MatTableDataSource(this.elegrpstdList);

    if (elm.rowid != null || '') {
      this.isLoading = true;
      // const delEleGrp = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      // }
      const delEleGrp = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delEleGrp)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          //this.getElementsGrpStd();
          this.isLoading = false;
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        })
    }

  }



  /** Section 7 - Add, Delete and Save method **/

  onAddEleGrpVarRow() {
    this.onAddsec7 = true;
    let newRow = {
      rowid: null, poselegrpVar: (this.elegrpvarList.length + 1), elegrpVar: '',
      metaDataFlag: true, upDateFlag: true
    };
    const row = this.fb.group({
      elegrpVar: new FormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField
      ])
    });
    (this.section7Form.get('section7Array') as FormArray).push(row);
    this.elegrpvarList.push(newRow);
    this.dataSource7 = new MatTableDataSource(this.elegrpvarList);
    //console.log(this.elegrpvarList);
    this.dataSource7._updateChangeSubscription();
  }

  updateElegrpVar(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.elegrpVar = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  deleteEleGrpvar(elm, index) {
    //console.log(elm);
    //console.log(this.elegrpvarList);
    this.elegrpvarList.splice(index, 1);
    (this.section7Form.get('section7Array') as FormArray).removeAt(index);
    this.dataSource7 = new MatTableDataSource(this.elegrpvarList);

    if (elm.rowid != null || '') {
      this.isLoading = true;
      // const delEleVar = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      // }
      const delEleVar = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delEleVar)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.isLoading = false;
          //console.log(result);
          // this.getElementsGrpVar();
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.toastorService.warn(error.message);
        })
    }
  }

  EleGrpVarSave(formValue) {
    if (formValue.invalid) {
      //console.log('invalid');
      this.submittedForm7 = true;
      return false;
    } else {
      this.isLoading = true;
      //console.log(this.elegrpvarList);
      let eleVarSave = [];
      //console.log(this.elegrpvarList);

      //console.log(eleVarSave);
      eleVarSave = this.elegrpvarList.map(item => {
        let createdBy;
        item.metaDataFlag == false ? createdBy = 'METADATA' : createdBy = null;
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.elegrpStd,
          sequence: item.poselestd,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 606,
            sequenceNumber: 6,
            sectionName: 'ELEMENT GRP_VARIABLE',
            sectionGroup: ""
          },
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          sheetQuestion: {
            inputSheetQuestionsId: item.sheetQuestionId,
            questionLongText: item.elegrpVar,
            questionSequenceNum: item.poselegrpVar
          },
          createdBy: createdBy,
          upDateFlag: item.upDateFlag
        }
      })
      // if (!this.elegrpvarSave) {
      //   //console.log(eleVarSave);
      //   this.commonService.postRequest(`
      //     ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/promosection`, 'POST', eleVarSave)
      //     .pipe(takeUntil(this.destroy$))
      //     .subscribe(result => {
      //       //console.log(result);
      //       this.elegrpvarSave = true;
      //       this.loadEleGrpVar(result);
      //       this.isLoading = false;
      //    //   this.getElementsGrpVar();
      //       this.toastorService.success('Saved Successfully');
      //     }, error => {
      //       this.isLoading = false;
      //       this.toastorService.warn(error.message);
      //     });
      // } else {      
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', eleVarSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.isLoading = false;
          this.loadEleGrpVar(result);
          //   this.getElementsGrpVar();
          this.toastorService.success('Saved Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        });
      // }
    }
  }

  loadEleGrpVar(elegrpVar) {
    if (elegrpVar == null) {
      this.onAddEleGrpVarRow();
    } else {
      let data = [];
      (this.section7Form.controls.section7Array as FormArray).clear();
      elegrpVar = elegrpVar.map(mapdata => {
        let metaFlag;
        mapdata.createdBy == 'METADATA' ? metaFlag = false : metaFlag = true;
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          poselegrpVar: mapdata.sheetQuestion.questionSequenceNum,
          elegrpVar: mapdata.sheetQuestion.questionLongText,
          sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
          metaDataFlag: metaFlag,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          elegrpVar: new FormControl(mapdata.sheetQuestion.questionLongText, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section7Form.get('section7Array') as FormArray).push(ArrayData);
        //console.log(this.section7Form.value);
      })
      this.elegrpvarList = data;
      //console.log(this.elegrpvarList);
      this.dataSource7 = new MatTableDataSource(this.elegrpvarList);
    }
  }


  /** Section 8 - Add, Delete and Save method **/


  onAddsrawareRow() {
    this.onAddsec8 = true;
    let newRow = {
      rowid: null, possraware: (this.srAwarenessList.length + 1), srawareness: null,
      upDateFlag: true
    };
    const row = this.fb.group({
      srawareness: new FormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField
      ])
    });
    (this.section8Form.get('section8Array') as FormArray).push(row);
    this.srAwarenessList.push(newRow);
    this.dataSource8 = new MatTableDataSource(this.srAwarenessList);
    //console.log(this.srAwarenessList);
    this.dataSource8._updateChangeSubscription();
  }

  deletesraware(elm, index) {
    //console.log(elm);
    //console.log(this.srAwarenessList);
    this.srAwarenessList.splice(index, 1);
    (this.section8Form.get('section8Array') as FormArray).removeAt(index);
    this.dataSource8 = new MatTableDataSource(this.srAwarenessList);

    if (elm.rowid != null || '') {
      this.isLoading = true;
      // const delSrAware = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      // }
      const delSrAware = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delSrAware)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          // this.getQuestionStim();
          this.isLoading = false;
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        })
    }
  }

  updatesrAware(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.srawareness = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  srawareSave(formValue) {
    if (formValue.invalid) {
      //console.log('invalid');
      this.submittedForm8 = true;
      return false;
    } else {
      this.isLoading = true;
      //console.log(this.srAwarenessList);
      //console.log(this.srAwarenessList)
      let srawareSave = [];
      //console.log(this.srAwarenessList);

      srawareSave = this.srAwarenessList.map(item => {
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.srawareness,
          sequence: item.possraware,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 628,
            sequenceNumber: 8,
            sectionName: 'SR AWARENESS',
            sectionGroup: ""
          },
          sheetQuestion: null,
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          upDateFlag: item.upDateFlag
        }
      });
      if (!this.srawarenessSave) {
        //console.log(srawareSave);
        this.commonService.postRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/promosection`, 'POST', srawareSave)
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            //console.log(result);
            this.srawarenessSave = true;
            this.isLoading = false;
            this.loadSrAware(result);
            //   this.getSrAwareness();
            this.toastorService.success('Saved Successfully');
          }, error => {
            this.isLoading = false;
            this.toastorService.warn(error.message);
          });
      } else {
        this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', srawareSave)
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            //console.log(result);
            this.isLoading = false;
            this.loadSrAware(result);
            //   this.getSrAwareness();
            this.toastorService.success('Saved Successfully');
          }, error => {
            this.isLoading = false;
            this.toastorService.warn(error.message);
          });
      }
    }
  }

  loadSrAware(srAwarenesssec) {
    if (srAwarenesssec == null) {
      this.onAddsrawareRow();
    } else {
      let data = [];
      (this.section8Form.controls.section8Array as FormArray).clear();
      srAwarenesssec = srAwarenesssec.map(mapdata => {
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          srawareness: mapdata.description,
          possraware: mapdata.sequence,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          srawareness: new FormControl(mapdata.description, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section8Form.get('section8Array') as FormArray).push(ArrayData);
        //console.log(this.section8Form.value);
      })

      this.srAwarenessList = data;
      //console.log(this.srAwarenessList);
      this.dataSource8 = new MatTableDataSource<InputSheetPromoSrAwarenessSection>(this.srAwarenessList)
    }
  }

  getSrAwareness() {
    this.isLoading = true;
    let srAwarenesssec = [];
    let data = [];
    // const getSrAware = {
    //   params: new HttpParams()
    //     .set('inputSheetId', this.inputSheetId)
    //     .append('sectionName', 'SR AWARENESS')
    //     .append('sectiontype', 'PROMO')
    //   }
    const getSrAware = { 'inputSheetId': this.inputSheetId, 'sectionName': 'SR AWARENESS', 'sectiontype': 'PROMO' };
    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/sections`,
      'GET', getSrAware)
      .pipe(takeUntil(this.destroy$))
      .subscribe(chardata => {
        this.isLoading = false;
        //console.log(chardata);
        srAwarenesssec = chardata;
        srAwarenesssec = srAwarenesssec.map(mapdata => {
          data.push({
            rowid: mapdata.inputSheetUserSectionId,
            sraware: mapdata.description,
            possraware: mapdata.sequence,
            upDateFlag: mapdata.upDateFlag
          })
        })

        this.srAwarenessList = data;
        //console.log(this.srAwarenessList);
        this.dataSource8 = new MatTableDataSource<InputSheetPromoSrAwarenessSection>(this.srAwarenessList);

      }, error => {
        this.isLoading = false;
        this.toastorService.warn(error.message);
      })
  }


  /** Section 9 - Add, Delete and Save method **/

  onAddQuesRow() {
    this.onAddsec9 = true;
    let newRow = {
      rowid: null, posques: (this.questionstimList.length + 1), quesnstim: null,
      upDateFlag: true
    };
    const row = this.fb.group({
      quesnstim: new FormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField
      ])
    });
    (this.section9Form.get('section9Array') as FormArray).push(row);
    this.questionstimList.push(newRow);
    this.dataSource9 = new MatTableDataSource(this.questionstimList);
    //console.log(this.questionstimList);
    this.dataSource9._updateChangeSubscription();
  }

  deleteQues(elm, index) {
    //console.log(elm);
    //console.log(this.questionstimList);
    this.questionstimList.splice(index, 1);
    (this.section9Form.get('section9Array') as FormArray).removeAt(index);
    this.dataSource9 = new MatTableDataSource(this.questionstimList);

    if (elm.rowid != null || '') {
      this.isLoading = true;
      // const delQues = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      // }
      const delQues = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delQues)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          //  this.getQuestionStim();
          this.isLoading = false;
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        })
    }
  }

  updateQues(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.quesnstim = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  quesSave(formValue) {
    if (formValue.invalid) {
      //console.log('invalid');
      this.submittedForm9 = true;
      return false;
    } else {
      this.isLoading = true;
      //console.log(this.questionstimList);
      //console.log(this.questionstimList)
      let quessSave = [];
      //console.log(this.questionstimList);

      quessSave = this.questionstimList.map(item => {
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.quesnstim,
          sequence: item.posques,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 629,
            sequenceNumber: 9,
            sectionName: 'QUESTION AND STIM CHANGES',
            sectionGroup: ""
          },
          sheetQuestion: null,
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          upDateFlag: item.upDateFlag
        }

      })
      if (!this.quessSave) {
        //console.log(quessSave);
        this.commonService.postRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/promosection`, 'POST', quessSave)
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            //console.log(result);
            this.quessSave = true;
            this.isLoading = false;
            this.loadQuesStim(result);
            //    this.getQuestionStim();
            this.toastorService.success('Saved Successfully');
          }, error => {
            this.isLoading = false;
            this.toastorService.warn(error.message);
          });
      } else {
        this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', quessSave)
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            //console.log(result);
            this.isLoading = false;
            this.loadQuesStim(result);
            //  this.getQuestionStim();
            this.toastorService.success('Saved Successfully');
          }, error => {
            this.isLoading = false;
            this.toastorService.warn(error.message);
          });
      }
    }
  }

  loadQuesStim(questionstim) {
    if (questionstim == null) {
      this.onAddQuesRow();
    } else {
      let data = [];
      (this.section9Form.controls.section9Array as FormArray).clear();
      questionstim = questionstim.map(mapdata => {
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          quesnstim: mapdata.description,
          posques: mapdata.sequence,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          quesnstim: new FormControl(mapdata.description, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section9Form.get('section9Array') as FormArray).push(ArrayData);
        //console.log(this.section9Form.value);
      })

      this.questionstimList = data;
      //console.log(this.questionstimList);
      this.dataSource9 = new MatTableDataSource<InputsheetPromoQuesSection>(this.questionstimList);
    }
  }

  getQuestionStim() {
    this.isLoading = true;
    let questionstim = [];
    let data = [];
    // const getQuesStim = {
    //   params: new HttpParams()
    //     .set('inputSheetId', this.inputSheetId)
    //     .append('sectionName', 'QUESTION AND STIM CHANGES')
    //     .append('sectiontype', 'PROMO')
    //   } 
    const getQuesStim = { 'inputSheetId': this.inputSheetId, 'sectionName': 'QUESTION AND STIM CHANGES', 'sectiontype': 'PROMO' };
    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/sections`,
      'GET', getQuesStim)
      .pipe(takeUntil(this.destroy$))
      .subscribe(chardata => {
        this.isLoading = false;
        //console.log(chardata);
        questionstim = chardata;
        questionstim = questionstim.map(mapdata => {
          data.push({
            rowid: mapdata.inputSheetUserSectionId,
            quesnstim: mapdata.description,
            posques: mapdata.sequence,
            upDateFlag: mapdata.upDateFlag
          })
        })

        this.questionstimList = data;
        //console.log(this.questionstimList);
        this.dataSource9 = new MatTableDataSource<InputsheetPromoQuesSection>(this.questionstimList);

      }, error => {
        this.isLoading = false;
        this.toastorService.warn(error.message);
      })
  }

  public hasErrorsection4Form = (index, errorName: string) => {
    return ((this.section4Form.controls.section4Array as FormArray).controls[index] as FormGroup).controls.statstd.hasError(errorName);
  }
  public hasErrorsection5Form = (index, errorName: string) => {
    return ((this.section5Form.controls.section5Array as FormArray).controls[index] as FormGroup).controls.statgrpvar.hasError(errorName);
  }
  public hasErrorsection6Form = (index, errorName: string) => {
    return ((this.section6Form.controls.section6Array as FormArray).controls[index] as FormGroup).controls.elegrpStd.hasError(errorName);
  }
  public hasErrorsection7Form = (index, errorName: string) => {
    return ((this.section7Form.controls.section7Array as FormArray).controls[index] as FormGroup).controls.elegrpVar.hasError(errorName);
  }
  public hasErrorsection8Form = (index, errorName: string) => {
    return ((this.section8Form.controls.section8Array as FormArray).controls[index] as FormGroup).controls.srawareness.hasError(errorName);
  }
  public hasErrorsection9Form = (index, errorName: string) => {
    return ((this.section9Form.controls.section9Array as FormArray).controls[index] as FormGroup).controls.quesnstim.hasError(errorName);
  }
  spotdetailControls() {
    return (this.formSpot.get('adTitle') as FormArray).controls;
  }
  addAttributesControls() {
    return (this.formAddAttr.get('inputSheetSection') as FormArray).controls;
  }
  statementsIndivControls() {
    return (this.formStatIndiv.get('inputSheetSection') as FormArray).controls;
  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.destroy$.next(true);
  }

}
