import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicatingComponentService {

  private subject = new Subject<any>();
  public storage: any;
  public editstorage: any;
  public storageTotalCount: any;

  sendCommunication(message: any) {
    this.subject.next({ condition: message });
  }

  clearCommunication() {
    this.subject.next();
  }

  getCommunication(): Observable<any> {
    return this.subject.asObservable();
  }

}
