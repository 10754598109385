import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { MatDialogRef, MAT_DIALOG_DATA, MatSelectionList, MatListOption } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';

import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
// import { AwsCommonSprint3Service } from 'src/app/services/aws-commonsprint3.service';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { NotificationService } from 'src/app/services/notification.service';

import { SurveyCreateSummaryAddQuestion, SurveyCreateSummaryQuestionObject } from 'src/app/models/surveycreate';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
@Component({
  selector: 'app-surveyaddquestion',
  templateUrl: './surveyaddquestion.component.html',
  styleUrls: ['./surveyaddquestion.component.scss']
})
export class SurveyaddquestionComponent implements OnInit, OnDestroy {
  appConstants = AppConfig.settings;
  qustDec: SurveyCreateSummaryAddQuestion;
  destroy$: Subject<boolean> = new Subject<boolean>();
  dialog: any;
  searchChoiModel: any;
  @ViewChild('multiSelect', { static: false }) private selectionList: MatSelectionList;

  constructor(
    private createSurveyRef: MatDialogRef<SurveyaddquestionComponent>,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private awsCommonService: AwsCommonService,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: number,
  ) {

    this.qustDec = {
      surveySummaryQuestionId: data,
      surveySummaryAllQuestionListURL: '',
      survetSummaryfetchQuestionList: [],
      survetSummaryFilterQuestionList: [],
      summaryAddQuestionForm: this.formBuilder.group({
        questionSharedListId: ['', Validators.required],
        surveyId: [data, Validators.required]
      }),
      isLoading: true,
      selectedChoices: [],
      selectedOptions: [],
      sharedChoiceList: '',
      choiceLibraryIds: [],
      selectedOption: ''
    };
    if (this.appConstants.checkServerURL) {
      this.qustDec.surveySummaryAllQuestionListURL =
        `${this.appConstants.serverUrl}/${this.appConstants.surveyURL}/sharedQuestions`;
    }
  }

  ngOnInit() {
    this.qustDec.isLoading = true;
    const dataQuestionList: SurveyCreateSummaryQuestionObject[] = [];
    this.awsCommonService.getRequest(`${this.qustDec.surveySummaryAllQuestionListURL}`, 'GET')
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        resultData => {

          this.qustDec.sharedChoiceList = resultData;
          this.qustDec.sharedChoiceList.forEach(element => {
            element.isselected = false;
          });
          this.qustDec.survetSummaryfetchQuestionList = resultData;
          if (this.qustDec.survetSummaryfetchQuestionList) {
            this.qustDec.survetSummaryfetchQuestionList.map((item, index) => {
              if (item.activeYn === 'Y') {
                dataQuestionList.push({
                  sharedListName: item.sharedListName,
                  questionSharedListId: item.questionSharedListId
                });
              }
            });
            this.qustDec.survetSummaryfetchQuestionList = dataQuestionList;
            this.qustDec.survetSummaryFilterQuestionList = this.qustDec.survetSummaryfetchQuestionList.slice();
          } else {
            //this.notificationService.warn('Empty Object..!');
          }
        },
        error => {
          this.qustDec.isLoading = false;
          //this.notificationService.warn('Wait..!');
        },
        () => {
          this.qustDec.isLoading = false;
        }
      );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  surveySummaryAddQuestion() {
    this.qustDec.isLoading = true;
    if (this.qustDec.summaryAddQuestionForm.invalid) {
      this.qustDec.isLoading = false;
      return false;

    } else {
      if (this.appConstants.checkServerURL) {
        this.awsCommonService.postRequest(
          `${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/addQuestions`,
          'POST', this.qustDec.summaryAddQuestionForm.value)
          .subscribe(
            (result) => {
              this.qustDec.isLoading = false;
              this.createSurveyRef.close(true);
              //this.notificationService.success('Submitted Successfully..!');
            },
            error => {
              console.log(error);
              this.notificationService.warn('Oops!!! The Server was unable to complete your request');
              this.createSurveyRef.close(this.qustDec.summaryAddQuestionForm.value);
            }
          );

      } else {
       // this.notificationService.warn('Please Check Config File & Server is InActive..!');
      }
    }
  }
  onAreaListControlChanged(option) {

    this.selectionList.selectedOptions = new SelectionModel<MatListOption>(true);
    const found = this.qustDec.selectedChoices.some(el => el.questionSharedListId === option.questionSharedListId);
    if (!found) {

      this.qustDec.selectedChoices.push(option);
      this.qustDec.choiceLibraryIds.push(option.questionSharedListId);
      this.qustDec.summaryAddQuestionForm.patchValue({ questionSharedListId: this.qustDec.choiceLibraryIds });

    } else {
      this.onRemove(option);
    }

    return this.qustDec.sharedChoiceList;

  }
  getSomeClass(option) {

    const found = this.qustDec.selectedChoices.some(el => el.questionSharedListId === option.questionSharedListId);
    if (found) {
      return { createQuesselected: found };
    } else {
      return { removeBg: !found };
    }

  }
  onRemove(item) {


    const index = this.qustDec.selectedChoices.findIndex(x => x.questionSharedListId === item.questionSharedListId);
    this.qustDec.selectedChoices.splice(index, 1);

    const ChoiceIds = this.qustDec.selectedChoices.map(x => x.questionSharedListId);
    this.qustDec.choiceLibraryIds = ChoiceIds;

    this.qustDec.summaryAddQuestionForm.patchValue({ questionSharedListId: this.qustDec.choiceLibraryIds });
  }

  surveyAddQuectionCancel(data) {
    this.createSurveyRef.close(false);
  }

  onNgModelChange($event) {


  }
  // public hasError = (controlName: string, errorName: string) => {
  //   return this.qustDec.summaryAddQuestionForm.controls[controlName].hasError(errorName);
  // }


}
