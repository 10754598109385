import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { Workbook } from 'exceljs';
import { NotificationService } from './notification.service';
import { DatePipe } from '@angular/common';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor(
    private toastorService: NotificationService, private datePipe: DatePipe) { }
  generateExcelChoice(tableData) {
    const header = ['CHOICE DESCRIPTION', 'FLAG', 'STATUS', 'CREATED BY', 'CREATED DATE',
      'MODIFIED DATE'];
    const data = tableData;
    const newArray = data.map(f => ({
      choiceLibraryDesc: f.choiceLibraryDesc,
      posNegFlag: f.posNegFlag,
      activeYn: f.activeYn,
      createdBy: f.createdBy,
      createdDt: this.datePipe.transform(f.createdDt, 'MM/dd/yyyy'),
      lastUpdatedDt: this.datePipe.transform(f.lastUpdatedDt, 'MM/dd/yyyy')
    }));
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Choices');
    const headerRow = worksheet.addRow(header);
    // tslint:disable-next-line: variable-name
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    newArray.forEach(d => {
      d.activeYn === 'Y' ? d.activeYn = 'Active' : d.activeYn === 'N' ? d.activeYn = 'InActive' : d.activeYn = '';
      d.posNegFlag === 'Y' ? d.posNegFlag = 'Positive' : d.posNegFlag === 'N' ? d.posNegFlag = 'Negative' : d.posNegFlag = 'Neutral';
      const result = Object.keys(d).map((key) => {
        return d[key];
      });
      const row = worksheet.addRow(result);
    });
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    // tslint:disable-next-line: no-shadowed-variable
    workbook.xlsx.writeBuffer().then((newArray: any) => {
      const blob = new Blob([newArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, 'Prime_Survey_Choices.xlsx');
      this.toastorService.success('Prime Survey Choice Library Excel generated  successfully');
    });
  }
  generateExcelQuestions(tableData) {
    const header = ['QUESTION CATEGORY', 'GENERIC QUESTION', 'CONNOTATION', 'PERCENTILE',
      'SUMMARY REPORT', 'STATUS', 'CREATED DATE', 'MODIFIED DATE'];
    const data = tableData;
    const newArray = data.map(f => ({
      questionCategoryDesc: f.questionCategoryDesc,
      genericText: f.genericText,
      questionPosNegFlag: f.questionPosNegFlag,
      percentileYn: f.percentileYn,
      summRptYn: f.summRptYn,
      activeYn: f.activeYn,
      createdDt: this.datePipe.transform(f.createdDt, 'MM/dd/yyyy'),
      lastUpdatedDt: this.datePipe.transform(f.lastUpdatedDt, 'MM/dd/yyyy')
    }));
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Questions');
    const headerRow = worksheet.addRow(header);
    // tslint:disable-next-line: variable-name
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    newArray.forEach(d => {
      d.activeYn === 'Y' ? d.activeYn = 'Active' : d.activeYn === 'N' ? d.activeYn = 'InActive' : d.activeYn = '';
      d.questionPosNegFlag === 'Y' ? d.questionPosNegFlag = 'Positive' : d.questionPosNegFlag === 'N' ?
        d.questionPosNegFlag = 'Negative' : d.questionPosNegFlag = 'Neutral';
      d.percentileYn === 'Y' ? d.percentileYn = 'Yes' : d.percentileYn === 'N' ? d.percentileYn = 'No' : d.percentileYn = '';
      d.summRptYn === 'Y' ? d.summRptYn = 'Yes' : d.summRptYn === 'N' ? d.summRptYn = 'No' : d.summRptYn = '';
      const result = Object.keys(d).map((key) => {
        return d[key];
      });
      const row = worksheet.addRow(result);
    });
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 15;
    // tslint:disable-next-line: no-shadowed-variable
    workbook.xlsx.writeBuffer().then((newArray: any) => {
      const blob = new Blob([newArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, 'Prime_Survey_Questions.xlsx');
      this.toastorService.success('Prime Survey Question Library Excel generated  successfully');
    });
  }
  surveyToExcel(tableData) {
    const header = ['SURVEY CODE', 'SHOW TITLE', 'SHOW GENRE', 'EPISODE #', 'TESTDATE', 'SURVEY TYPE', 'VERSION',
      'INPUT SHEET', 'MODIFIED DATE', 'STATUS'];
    const data = tableData;
    const newArray = data.map(f => ({
      surveyCode: f.surveyCode,
      showTitle: f.showTitle,
      showGenre: f.showGenre,
      episodeNumber: f.episodeNumber,
      testDt: this.datePipe.transform(f.testDt, 'MM/dd/yyyy'),
      surveyTypeDesc: f.surveyTypeDesc,
      titleVersion: f.titleVersion,
      inputsheet: f.inputsheet,
      LastUpdatedDt: this.datePipe.transform(f.LastUpdatedDt, 'MM/dd/yyyy'),
      status: f.status
    }));
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Survey');
    const headerRow = worksheet.addRow(header);
    // tslint:disable-next-line: variable-name
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    newArray.forEach(d => {
      const result = Object.keys(d).map((key) => {
        return d[key];
      });
      const row = worksheet.addRow(result);
    }
    );
    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(8).width = 25;
    worksheet.getColumn(9).width = 15;
    // tslint:disable-next-line: no-shadowed-variable
    workbook.xlsx.writeBuffer().then((newArray: any) => {
      const blob = new Blob([newArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, 'Prime_Survey_Survey.xlsx');
    });
  }
  surveySummaryExportToExcel(tableData) {
    const header = ['GENERIC QUESTION', 'SEQUENCE NUMBER', 'QUESTION TEXT', 'CHOICES'];
    const data = tableData;
    const newArray = data.map(f => ({
      genericQuestion: f.genericQuestion,
      questionSequenceNo: f.questionSequenceNo,
      question: f.question,
      choices: f.choices
    }));
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Survey Summary');
    const headerRow = worksheet.addRow(header);
    // tslint:disable-next-line: variable-name
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    newArray.forEach(d => {
      const result = Object.keys(d).map((key) => {
        return d[key];
      });
      const row = worksheet.addRow(result);
    }
    );
    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 5;
    worksheet.getColumn(3).width = 25;
    worksheet.getColumn(4).width = 50;
    //worksheet.getColumn(9).width = 15;
    // tslint:disable-next-line: no-shadowed-variable
    workbook.xlsx.writeBuffer().then((newArray: any) => {
      const blob = new Blob([newArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, 'Prime_Survey_Survey_summary_Export.xlsx');
    });
  }
  inputsheetPilotExport(tableData) {
    const header = ['INPUT SHEET NAME', 'GENRE', 'SHOW TITLE', 'EPISODE#', 'ANALYST', 'ROUGHT CUT',
      'VERSION', 'CREATED DATE', 'MODIFIED DATE', 'STATUS'];
    const data = tableData;
    const newArray = data.map(f => ({
      inputSheetName: f.inputSheetName.toString(),
      genreDesc: f.genreDesc.toString(),
      showTitle: f.showTitle.toString(),
      episodenumber: f.episode.toString(),
      surveyAnalyst: f.surveyAnalyst.toString(),
      roughCut: f.roughCut.toString(),
      titleVersionName: f.titleVersionName.toString(),
      createdDt: this.datePipe.transform(f.createdDt, 'MM/dd/yyyy'),
      lastUpdatedDt: this.datePipe.transform(f.lastUpdatedDt, 'MM/dd/yyyy'),
      inputSheetStatus: f.inputSheetStatus.toString().toUpperCase()
    }));
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Pilot');
    const headerRow = worksheet.addRow(header);
    // tslint:disable-next-line: variable-name
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    newArray.forEach(d => {
      d.activeYn === 'Y' ? d.activeYn = 'Active' : d.activeYn === 'N' ? d.activeYn = 'InActive' : d.activeYn = '';
      const result = Object.keys(d).map((key) => {
        return d[key];
      });
      const row = worksheet.addRow(result);
    }
    );
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 15;
    // tslint:disable-next-line: no-shadowed-variable
    workbook.xlsx.writeBuffer().then((newArray: any) => {
      const blob = new Blob([newArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, 'Prime_Survey_Pilot.xlsx');
    });
  }

  inputsheetPrintExport(tableData) {
    const header = ['INPUT SHEET NAME', 'GENRE', 'SHOW TITLE', 'ANALYST', 'EPISODE#',
      'NO. OF PRINT ADS', 'NOTES TO PROGRAMMER', 'CREATED DATE', 'MODIFIED DATE', 'STATUS'];
    const data = tableData;
    const newArray = data.map(f => ({
      inputsheetName: f.inputsheetName,
      genreDesc: f.genreDesc.toString(),
      showTitle: f.showTitle.toString(),
      surveyAnalyst: f.surveyAnalyst,
      episode: f.episode,
      noOfPrints: f.noOfPrints,
      programmerNotes: f.programmerNotes,
      createdDt: this.datePipe.transform(f.createdDt, 'MM/dd/yyyy'),
      lastUpdatedDt: this.datePipe.transform(f.lastUpdatedDt, 'MM/dd/yyyy'),
      inputSheetStatus: f.inputSheetStatus.toUpperCase()
    }));
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Print');
    const headerRow = worksheet.addRow(header);
    // tslint:disable-next-line: variable-name
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    newArray.forEach(d => {
      d.activeYn === 'Y' ? d.activeYn = 'Active' : d.activeYn === 'N' ? d.activeYn = 'InActive' : d.activeYn = '';
      const result = Object.keys(d).map((key) => {
        return d[key];
      });
      const row = worksheet.addRow(result);
    }
    );
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 15;
    // tslint:disable-next-line: no-shadowed-variable
    workbook.xlsx.writeBuffer().then((newArray: any) => {
      const blob = new Blob([newArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, 'Prime_Survey_Inputsheet_Print.xlsx');
    });
  }

  inputsheetPromoExport(tableData) {
    const header = ['INPUT SHEET NAME', 'GENRE', 'SHOW TITLE', 'ANALYST', 'EPISODE #',
      'NUMBER OF PROMOS', 'NOTES TO PROGRAMMER', 'CREATED DATE', 'CREATED DATE', 'STATUS'];
    const data = tableData;
    const newArray = data.map((f, i) => ({
      inputsheetName: f.inputsheetName,
      genreDesc: f.genreDesc.toString(),
      showTitle: f.showTitle.toString(),
      surveyAnalyst: f.surveyAnalyst,
      episode: f.episode,
      noOfPrints: f.noOfPrints,
      programmerNotes: f.programmerNotes,
      createdDt: this.datePipe.transform(f.createdDt, 'MM/dd/yyyy'),
      lastUpdatedDt: this.datePipe.transform(f.lastUpdatedDt, 'MM/dd/yyyy'),
      inputSheetStatus: f.inputSheetStatus.toUpperCase(),
    }));
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Promo');
    const headerRow = worksheet.addRow(header);
    // tslint:disable-next-line: variable-name
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    newArray.forEach(d => {
      const result = Object.keys(d).map((key) => {
        return d[key];
      });
      const row = worksheet.addRow(result);
    }
    );
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 15;
    // tslint:disable-next-line: no-shadowed-variable
    workbook.xlsx.writeBuffer().then((newArray) => {
      const blob = new Blob([newArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, 'Prime_Survey_InputSheet_Promo.xlsx');
    });
  }
}
