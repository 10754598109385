import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MaterialModule } from 'src/app/app.material.module';
import { ChoiceRoutingModule } from './choice.routing.module';
import { ChoiceLayoutComponent } from './layout/choice-layout.component';
import { ChoiceDashboardComponent } from './components/choice-dashboard/choice-dashboard.component';
import { ChoiceCreateComponent } from './components/choice-create/choice-create.component';
import { ChoiceEditComponent } from './components/choice-edit/choice-edit.component';
import { ChoiceSurveydialogComponent } from './components/choice-dashboard/choice-surveydialog.component';
import { SharedModule } from 'src/app/shared';
import { MatVsTableModule } from 'mat-vs-table';

@NgModule({
  declarations: [
    ChoiceLayoutComponent,
    ChoiceDashboardComponent,
    ChoiceCreateComponent,
    ChoiceEditComponent,
    ChoiceSurveydialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    ChoiceRoutingModule,
    SharedModule,
    MatVsTableModule
  ],
  providers: [ChoiceDashboardComponent],
  entryComponents: [
    ChoiceDashboardComponent,
    ChoiceCreateComponent,
    ChoiceEditComponent,
    ChoiceLayoutComponent,
    ChoiceSurveydialogComponent
  ]
})
export class ChoiceModule {
 }
