import { Injectable, EventEmitter } from '@angular/core';
import { HttpErrorHandlerService, HandleError } from '../shared/services/httperrorhandler.service';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { List, questionSharedList, choiceSharedList } from '../models/list.model';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../app.config';
//const url = 'shared';
@Injectable({
  providedIn: 'root'
})
export class SharedlistService {
  public apiUrl = AppConfig.settings.serverUrl;
  checkServerURL: any;
  SERVER_URL: any;
  tooltip = new EventEmitter();
  appConstants = AppConfig.settings;
  private subject = new Subject<any>();
  public handleError: HandleError;

  constructor(
    private http: HttpClient,
    private httpErrorHandler: HttpErrorHandlerService,
  ) {
    this.handleError = httpErrorHandler.createHandleError('SharedlistService'),
      this.checkServerURL = this.appConstants.checkServerURL;
    this.SERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedListParam}`;
  }

  getSharedQuestions(url): Observable<any[]> {
    // const url="assets/json/sharedQuestion.json";
    //  const url = `${this.apiUrl}/sharedlist/questions`
    return this.http.get<questionSharedList[]>(url)
      .pipe(
        (map(res => res)),
        catchError(this.handleError('getSharedQuestions', []))
      );
  }

  getSharedChoice(url): Observable<any[]> {
    // const url="assets/json/shared.json";
    // const url = `${this.apiUrl}/sharedlist/choices`
    return this.http.get<choiceSharedList[]>(url)
      .pipe(
        (map(res => res),
          catchError(this.handleError('getSharedChoice', []))
        ))
  }

  getSharedVariable(url): Observable<any[]> {
    //   const url="assets/json/shared.json";
    // const url = `${this.apiUrl}/sharedlist/variable`
    return this.http.get<any[]>(url)
      .pipe(
        (map(res => res),
          catchError(this.handleError('getSharedVariable', []))
        ))
  }

  getQuestionCategory(url): Observable<any> {
    // const url="assets/json/shared.json";
    return this.http.get<any[]>(url)
      .pipe(
        (map(res => res),
          catchError(this.handleError('getQuestionCategory', []))
        )
      )
  }

  getQuestionList(url, ids): Observable<any> {
    // const url ="assets/json/choicecategory.json";
    url = `${url}/${ids}/question`;
    // return this.http.get<any[]>(url)
    return this.http.get<any[]>(`${url}`)
      .pipe(
        (map(res => res)),
        catchError(this.handleError('getQuestionList', []))
      )
  }

  getQuestionSharedListById(url) {

    return this.http.get<any[]>(`${url}`)
      .pipe(
        (map(res => res)),
        catchError(this.handleError('getQuestionSharedListById', []))
      )
  }
  getSharedVariablebyId(url) {
    const geturl = `${this.apiUrl}${url}`;
    return this.http.get<any[]>(geturl)
      .pipe(
        (map(res => res)),
        catchError(this.handleError('getSharedVariableById', []))
      )
  }
  getSharedChoicebyId(url) {
    const geturl = `${this.apiUrl}${url}`;
    return this.http.get(geturl)
      .pipe(
        (map(res => res)),
        catchError(this.handleError('getSharedVariableById', []))
      );
  }
  getSharedChoiceList(url) {

    return this.http.get<[]>(url)
      .pipe(
        (map(res => res),
          catchError(this.handleError('getSharedChoiceList', []))
        ));
  }
}
