import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ChoiceDashboardComponent } from '../choice-dashboard/choice-dashboard.component';
import { NotificationService } from 'src/app/services/notification.service';
import { CommonService } from 'src/app/services/common.service';
// import { ChoiceService } from 'src/app/services/choice.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppConfig } from '../../../../app.config';
import { Observable, Subject } from 'rxjs';
import { List, sharedList } from 'src/app/models/list.model';
import { startWith, map, debounceTime } from 'rxjs/operators';
import { ChoicedashboardDatatable } from 'src/app/models/choicedashboard-datatable';
import { catchError, retry } from 'rxjs/operators';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { SharedlistService } from 'src/app/services/sharedlist.service';
import { QuestionService } from 'src/app/services/question.service';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-choice-create',
  templateUrl: './choice-create.component.html',
  styleUrls: ['./choice-create.component.scss']
})
export class ChoiceCreateComponent implements OnInit {
  sharedChoiceList: List[];
  appConstants = AppConfig.settings;
  choiceCreateLabel: any;
  validationMsg: any;
  createChoiceForm: FormGroup;
  public globalResponse: any;
  public url = 'choices';
  checkServerURL: any;
  choiceParam: any;
  filteredChoice = [];
  filteredSharedList;
  choiceList;
  dialogRef: any;
  getSharedChoiceList_URL: any;
  commanPattern: string;
  SERVER_URL: any;
  isLoadingSave = false;
  isLoadingSharedQuestion = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild(ChoiceDashboardComponent, { static: false }) private choicedashboard: ChoiceDashboardComponent;
  constructor(
    private createChoiceRef: MatDialogRef<ChoiceDashboardComponent>,
    // private choiceService: ChoiceService,
    private toastorService: NotificationService,
    private commonService: CommonService,
    private sharedlistService: SharedlistService,
    private appconfig: AppConfig, 
    private quesService: QuestionService,
    private awsCommonService: AwsCommonService
  ) {
    this.commanPattern = '^([a-zA-Z0-9 _-]{10,255})$';
    this.choiceCreateLabel = this.appConstants.choiceModel;
    this.validationMsg = this.appConstants.validationMsg;
    this.checkServerURL = this.appConstants.checkServerURL;
    this.choiceParam = this.appConstants.choiceDashboardParam;
    this.SERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedListParam}`;
    this.getSharedChoiceList_URL = `${this.SERVER_URL}/${this.appConstants.getSharedChoiceList}`;
  }
  sharedList: sharedList[] = [
    { sharedListId: 1, sharedListDesc: 'Value' },
    { sharedListId: 2, sharedListDesc: 'sdfsd' }
  ];
  radioItem = [
    { id: 1, value: 'POSITIVE', data: 'Y', color: 'green' },
    { id: 2, value: 'NEGATIVE', data: 'N', color: 'red' },
    { id: 3, value: 'NEUTRAL', data: 'U', color: '' }
  ];
  ngOnInit() {
    this.getSharedList();
    this.createChoiceForm = new FormGroup({
      choiceLibraryDesc: new FormControl('',
        Validators.compose([
          Validators.required,
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
          Validators.maxLength(255),
          validateEmptyField
        ])),
      posNegFlag: new FormControl(''),
      activeYn: new FormControl(''),
      sharedList: new FormControl(''),
      choicesharedListId: new FormControl(''),
      questionSharedList: new FormControl('')
    });
    this.initializeFormGroup();
  }

  // Validation Error Message
  public hasError = (controlName: string, errorName: string) => {
    return this.createChoiceForm.controls[controlName].hasError(errorName);
  }

  // Set Slider field value
  onChange(controlName: string, e) {
    if (e.checked === 'Y') {
      this.createChoiceForm.controls[controlName].setValue('Y');
    } else {
      this.createChoiceForm.controls[controlName].setValue('N');
    }
  }

  //Close Popup
  close(event: MouseEvent): void {
    this.createChoiceRef.close('cancel');
    event.preventDefault();
  }

  initializeFormGroup() {
    this.createChoiceForm.setValue({
      choiceLibraryDesc: '',
      posNegFlag: this.radioItem[0].data,
      activeYn: 'Y',
      sharedList: '',
      choicesharedListId: '',
      questionSharedList: ''
    });
  }

  // Shared List API
  getSharedList() {
    this.isLoadingSharedQuestion = true;
    // this.quesService.getChoiceList().subscribe(
    this.awsCommonService.getRequest(`${this.appConstants.serverUrl}/sharedlist/choices`,'GET').pipe(takeUntil(this.destroy$)).subscribe(
      data => {
        this.sharedList = data;
        //console.log(this.sharedList);
        this.isLoadingSharedQuestion = false;
        this.filteredSharedList = this.createChoiceForm.controls.questionSharedList.valueChanges
          .pipe(
            startWith(''),
            map(shared => shared ? this._filterValue(shared, this.sharedList, 'sharedList') : this.sharedList.slice())
          );
      },
      error => {
       this.isLoadingSharedQuestion = false,
        //console.log(error),
        this.toastorService.warn('Oops!!! The Server was unable to complete your request');
      },
      () => { }
    );
  }
  _filterValue(val: any, ArrList: any, Type: any) {
    const name = val.sharedListName || val; // val can be List or string
    const list = ArrList.filter(
      option => option.sharedListName.toLowerCase().includes(name.toLowerCase()));
    // /return (list.length > 0 ? list : [{ questionCategoryId: null, questionCategoryDesc: 'No record found' } as any]);
    return (list.length > 0 ? list : [{ choiceSharedListId: null, sharedListName: 'No record found' } as any]);
  }
  sharedDisplayFn(filterBy): string {
    return filterBy ? filterBy.sharedListName : filterBy;
  }

  // Insert Question Data
  createChoice() {
    if (this.createChoiceForm.invalid) {
      return;
    } else {
      // this.createChoiceForm.removeControl('choiceName');
      this.isLoadingSave = true;
      this.createChoiceForm.patchValue({
        choiceLibraryDesc: this.createChoiceForm.controls.choiceLibraryDesc.value,
        posNegFlag: this.createChoiceForm.controls.posNegFlag.value,
        activeYn: this.createChoiceForm.controls.activeYn.value,
        sharedList: this.createChoiceForm.controls.sharedList.value,
        choicesharedListId: (this.createChoiceForm.controls.questionSharedList.value) ?
        this.createChoiceForm.controls.questionSharedList.value.choiceSharedListId : '',
      });
      // this.createChoiceForm.removeControl('choicesharedList');
       // //console.log(this.createChoiceForm.value); return false;
      // this.commonService.insert(this.choiceParam, this.createChoiceForm.value)
      this.awsCommonService.postRequest(`${this.appConstants.serverUrl}/${this.choiceParam}`, 'POST', this.createChoiceForm.value)
        .subscribe((result) => {
          this.globalResponse = result;
        },
          error => {
            // //console.log(error);
            // this.toastorService.warn('Error' + error.message);
            if (error.status === 409) {
              this.createChoiceForm.controls.choiceLibraryDesc.setErrors({
                notUnique: true
              });
              this.isLoadingSave = false;
              // this.createQuesForm.controls.genericText.setValue('');
              // this.toastorService.warn('already exists in our database');
            } else {
              this.isLoadingSave = false;
              this.toastorService.warn('Oops!!! The Server was unable to complete your request');
              this.createChoiceRef.close('cancel');
            }
          },
          () => {
            this.isLoadingSave = false;
            this.createChoiceRef.close('success');
            this.toastorService.success('Submitted Successfully');
          }
        );
      // tslint:disable-next-line:deprecation
      event.preventDefault();
    }
  }
}
