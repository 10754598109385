
import { BrowserModule } from '@angular/platform-browser';
import { ModuleWithProviders, NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MatNativeDateModule } from '@angular/material/core';
import { AppConfig } from './app.config';


import { AppComponent } from './app.component';
import {
  FooterComponent,
  HeaderComponent,
  MenuComponent,
  SharedModule
} from './shared';
import { QuestionModule } from './modules/question/question.module';
import { ChoiceModule } from './modules/choice/choice.module';
import { MaterialModule } from './app.material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedlistModule } from './modules/sharedlist/sharedlist.module';
import { AppRoutingModule } from './app-routing.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
// import { LoginComponent } from './login/login.component';
import { TemplateModule } from './modules/template/template.module';
import { SurveyModule } from './modules/survey/survey.module';
import { InputsheetModule } from './modules/inputsheet/inputsheet.module';
import { DatePipe } from '@angular/common';
import { LandingComponent } from './landing.component';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    // LoginComponent,
    LandingComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    MatNativeDateModule,
    AppRoutingModule,
    SharedModule,
    QuestionModule,
    ChoiceModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedlistModule,
    DashboardModule,
    TemplateModule,
    SurveyModule,
    InputsheetModule
  ],
  providers: [
    DatePipe,	
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig], multi: true
    },
  ],
  exports: [  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
