import { SharedChoiceViewComponent } from './components/shared-choice-view/shared-choice-view.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedListComponent } from './layout/shared-list.component';
import { SharedListDashboardComponent } from './components/shared-list-dashboard/shared-list-dashboard.component';
import { SharedlistRoutingModule } from './sharedlist.routing.module';
import { MaterialModule } from 'src/app/app.material.module';
import { SharedListSidenavComponent } from './components/shared-list-sidenav/shared-list-sidenav.component';
import { SharedModule } from 'src/app/shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateSharedChoiceComponent } from './components/create-shared-choice/create-shared-choice.component';
import { SingleConfirmModalComponent } from '../sharedlist/components/simple-question/confirm-modal'
import { SharedQuestionCreateComponent } from './components/shared-question-create/shared-question-create.component';
import { SharedQuestionEditViewComponent } from './components/shared-question-edit-view/shared-question-edit-view.component';
import { AddQuestionComponent } from './components/add-question/add-question.component';
import { AddChoiceComponent } from './components/add-choice/add-choice.component';
import { ConfirmModalComponent } from './components/shared-question-edit-view/confirm-modal'
import { SharedVariableViewComponent } from './components/shared-variable-view/shared-variable-view.component';
import { SimpleQuestionComponent } from './components/simple-question/simple-question.component';
import { MatInputModule } from '@angular/material';
import { ChoiceConfirmModalComponent } from '../sharedlist/components/shared-choice-view/confirm-modal';
import { CancelModalComponent } from './layout/confirm-modal';
import { NumberOnlyDirective } from 'src/app/shared/validators/directives/number-only.directive';
import { NgForm } from '@angular/forms';
import { MatSelectFilterModule } from 'mat-select-filter';
@NgModule({
  declarations: [
    SharedListComponent,
    SharedListDashboardComponent,
    SharedListSidenavComponent,
    CreateSharedChoiceComponent,
    SharedQuestionCreateComponent,
    SharedQuestionEditViewComponent,
    AddQuestionComponent,
    AddChoiceComponent,
    ConfirmModalComponent,
    CancelModalComponent,
    SharedChoiceViewComponent,
    SharedVariableViewComponent,
    SimpleQuestionComponent, ChoiceConfirmModalComponent, SingleConfirmModalComponent
  ],
  imports: [
    CommonModule,
    SharedlistRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    SharedlistRoutingModule,
    SharedModule,
    MatInputModule,
    MatSelectFilterModule
  ],
  entryComponents: [SharedListComponent,
    SharedListDashboardComponent,
    SharedListSidenavComponent,
    CreateSharedChoiceComponent,
    SharedQuestionCreateComponent,
    SharedQuestionEditViewComponent,
    AddQuestionComponent,
    SimpleQuestionComponent,
    AddChoiceComponent,
    CancelModalComponent,
    ConfirmModalComponent, ChoiceConfirmModalComponent, SingleConfirmModalComponent
  ]
})
export class SharedlistModule { }
