import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/app.material.module';
import { SharedModule } from 'src/app/shared';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatStepperModule } from '@angular/material/stepper';
import { MatVsTableModule } from 'mat-vs-table';
import { MatSelectFilterModule } from 'mat-select-filter';

import { SurveyroutingModule } from './surveyrouting.module';
import { SurveylayoutComponent } from './layout/surveylayout.component';
import { CreateSurveyComponent } from './components/create-survey/create-survey.component';
import { SurveyDashboardComponent } from './components/survey-dashboard/survey-dashboard.component';
import { SurveyStepComponent } from './components/survey-step/survey-step.component';
import { SurveyeditComponent } from './components/surveyedit/surveyedit.component';
import { SurveyaddchoiceComponent } from './components/surveyaddchoice/surveyaddchoice.component';
import { ConfirmdialogComponent } from 'src/app/shared/components/confirmdialog/confirmdialog.component';
import { SurveyaddquestionComponent } from 'src/app/modules/survey/components/surveyaddquestion/surveyaddquestion.component';
import { ImportSurveyComponent } from 'src/app/modules/survey/components/import-survey/import-survey.component';
import { QuestionModule } from '../question/question.module';

@NgModule({
  declarations: [
    SurveylayoutComponent,
    CreateSurveyComponent,
    SurveyDashboardComponent,
    SurveyStepComponent,
    SurveyeditComponent,
    SurveyaddchoiceComponent,
    SurveyaddquestionComponent,
    ImportSurveyComponent
  ],
  imports: [
    CommonModule,
    SurveyroutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    SatDatepickerModule,
    SatNativeDateModule,
    CdkStepperModule,
    MatStepperModule,
    MatVsTableModule,
    MatSelectFilterModule,
    
  ],
  providers: [],
  entryComponents: [
    SurveyStepComponent,
    CreateSurveyComponent,
    SurveyaddchoiceComponent,
    SurveyaddquestionComponent,
    ConfirmdialogComponent,
    ImportSurveyComponent]
})
export class SurveyModule { }

