import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable, fadeInItems, MatDialog } from '@angular/material';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAccordion } from '@angular/material';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { DataSource } from '@angular/cdk/table';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { AppConfig } from 'src/app/app.config';
import { Inputsheetpromo, InputsheetpromoCreateHeader, PromoHeaderTable, PromoHeader, InputsheetPromoCreate, InputsheetPromoQuesSection, InputSheetPromoAdAttributesSection, InputSheetPromoSpotSection, InputSheetPromostatIndvSection, InputSheetPromostatGrpSection, InputSheetPromostatGrpVarSection, InputSheetPromoElegrpstdSection, InputSheetPromoElegrpVarSection, InputSheetPromoQtimeslotSection, InputSheetPromoSrAwarenessSection } from 'src/app/models/inputsheetpromo';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { CommunicatingComponentService } from '../../service/communicating-component.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ConfirmModalComponent } from '../../layout/confirm-modal.component';
import { HttpParams } from '@angular/common/http';
import { InputService } from 'src/app/services/inputsheet.service';
import { InputsheetprintEditHeader } from 'src/app/models/inputsheetprintads';
import { isObject } from 'util';
import { DatePipe } from '@angular/common';
import { AuthManager } from 'src/app/helpers/auth-manager';
import data from '../../../../../assets/json/vendorData';

@Component({
  selector: 'app-promo-edit',
  templateUrl: './promo-edit.component.html',
  styleUrls: ['./promo-edit.component.scss'],
  providers: [DatePipe]
})
export class PromoEditComponent implements OnInit {

  appConstants = AppConfig.settings;
  destroy$: Subject<boolean> = new Subject<boolean>();
  promoData: Inputsheetpromo;
  promoAddTableRow: FormGroup;
  dataSource = new MatTableDataSource<InputsheetpromoCreateHeader>();
  indexpostion: FormGroup;
  createInputPromo: InputsheetPromoCreate;
  section1Form: FormGroup;
  genreList: any = [];
  selected: any = [];
  valueFound: any;
  showTitleList: any[] = [];
  spotList: any = [];
  editSpottitleData: any = [];
  adTitleSaveEdit: boolean = true;
  sec2SaveEdit: boolean = true;
  sec3SaveEdit: boolean = true
  TotalAds: number = 0;
  isLoading: boolean = false;
  promoNotSaved: boolean = true;
  spotNotSaved: boolean = true;
  disButtons: boolean = false;
  showTitleEmpty: boolean = false;
  pilotDateVal: string;
  pilotDate = new FormControl();
  vendor=new FormControl();
  notSaved: boolean;
  readonlyFlag: boolean = false;
  disableSelect: boolean = false;
  sectionData: any[] = [];
  displayedColumns: string[] = [];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  inputSheetId: any;
  data: any = [];
  editData: any;
  dataValue: any = [];
  typeid: any;
  @ViewChild('accordion', { static: true }) Accordion: MatAccordion;
  toggle: boolean = true;
  datasection1HeaderList: any[] = [];
  dataset: any[] = [];
  datasection2HeaderList: any = [];
  datasettwo: any[] = [];
  spotDetailsData: any[] = [];
  spotDetailsCount: number;
  public form: FormGroup;
  public formSpot: FormGroup;
  public formAddAttr: FormGroup;
  public formStatIndiv: FormGroup;
  isAdmin:boolean = true;

  // public formAfterINDIVpAds: FormGroup;
  // public formAfterGroupAds: FormGroup;
  // public formAfterPromoAds: FormGroup;
  // public formTag: FormGroup;
  // public formShowDesc: FormGroup;
  // public formVideotoShow: FormGroup;

  addAtrributeList: any = [];
  statIndvList: any = [];
  statGrpList: any = [];
  statGrpVarList: any = [];
  elegrpstdList: any = [];
  elegrpvarList: any = [];
  srAwarenessList: any = [];
  questionstimList: any = [];

  statgrpstdSave: boolean = false;
  statgrpvarSave: boolean = false;
  elegrpstdSave: boolean = false;
  elegrpvarSave: boolean = false;
  srawarenessSave: boolean = false;
  quessSave: boolean = false;

  SpotData = []
  editSpotData = [];
  AddAttrData = []
  statIndivData = []
  statGrpStdData = []
  statGrpVarData = []
  eleGrpStdData = []
  eleGrpVarData = []
  srAwareData = []
  quesStimData = []

  submittedForm1: boolean = false;
  submittedForm2: boolean = false;
  submittedForm3: boolean = false;
  submittedForm4: boolean = false;
  submittedForm5: boolean = false;
  submittedForm6: boolean = false;
  submittedForm7: boolean = false;
  submittedForm8: boolean = false;
  submittedForm9: boolean = false;
  vendorData: { "vendorId": number; "vendorName": string; }[];
  onAddsec1: boolean = false;
  dataSource1;
  displayedColumns1: string[] = ['posspot', 'showTitle', 'promoSpotPrintTitle', 'promoLength', 'showNoTimes', 'adType', 'programType', 'printQuality', 'splNotes'];

  section4Form: FormGroup;
  onAddsec4: boolean = false;
  dataSource4;
  displayedColumns4: string[] = ['posstatstd', 'statstd', 'columndelete'];

  section5Form: FormGroup;
  onAddsec5: boolean = false;
  dataSource5;
  displayedColumns5: string[] = ['posstatvar', 'statgrpvar', 'columndelete'];

  section6Form: FormGroup;
  onAddsec6: boolean = false;
  dataSource6;
  displayedColumns6: string[] = ['poselestd', 'elegrpStd', 'columndelete'];

  section7Form: FormGroup;
  onAddsec7: boolean = false;
  dataSource7;
  displayedColumns7: string[] = ['poselegrpVar', 'elegrpVar', 'columndelete'];

  section8Form: FormGroup;
  onAddsec8: boolean = false;
  dataSource8;
  displayedColumns8: string[] = ['possraware', 'srawareness', 'columndelete'];

  section9Form: FormGroup;
  onAddsec9: boolean = false;
  dataSource9;
  displayedColumns9: string[] = ['posques', 'quesnstim', 'columndelete'];
  vendorSelected: string;
  selectedVendor: any;

  constructor(
    private communicatingComponentService: CommunicatingComponentService,
    private dialog: MatDialog,
    private commonService: AwsCommonService,
    private fb: FormBuilder,
    private router: Router,
    private toastorService: NotificationService,
    private route: ActivatedRoute,
    private inputService: InputService,
    private datePipe: DatePipe,
    private authManager: AuthManager
  ) {

    this.spotDetailsCount = 0;
    this.formSpot = fb.group({ adTitle: fb.array([]) });
    this.formAddAttr = fb.group({ inputSheetSection: fb.array([]) });
    this.formStatIndiv = fb.group({ inputSheetSection: fb.array([]) });

    this.section4Form = fb.group({
      section4Array: fb.array([])
    });

    this.section5Form = fb.group({
      section5Array: fb.array([])
    });

    this.section6Form = fb.group({
      section6Array: fb.array([])
    });

    this.section7Form = fb.group({
      section7Array: fb.array([])
    });

    this.section8Form = fb.group({
      section8Array: fb.array([])
    });

    this.section9Form = fb.group({
      section9Array: fb.array([])
    });


    this.promoData = {
      editTitle: this.appConstants.inputsheetHeader.editTitle,
      promoAddHeader: ['genre', 'show Title', 'episode #', 'number of spots'],
      promoAddColumns: ['genreDesc', 'showTitle', 'episode', 'noOfPrints'],
      promoAddFooter: ['genreDesc', 'showTitle', 'episode', 'noOfPrints']
    };
    this.promoData.promoaddData = [];
    // this.dataSource(this.promoData.promoaddData);


    // this.printAddTableRow = this.fb.group({
    //   rows: this.fb.array([])
    // });
    this.promoData.promoAddCreateForm = this.fb.group({
      inputSheetId: new FormControl(null),
      inputSheetType: this.fb.group({
        inputSheetType: 'Print',
        activeYn: 'Y'
      }),
      surveyAnalyst: ['', [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
      programmerNotes: ['', [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
      testDate: [''],
      vendor:[''],
      activeYn: 'Y',
      inputSheetStatus: this.fb.group({
        inputSheetStatus: 'DRAFT'
      }),
      inputSheetPrintInfo: this.fb.array([this.createItem()]),
    });

    if (this.commonService.editPromoData) {
      this.editData = this.commonService.editPromoData;
      if(this.authManager.userRole != 'ADMIN'){
        this.isAdmin = false;
        this.disButtons = true;
        this.readonlyFlag = true;
        this.disableSelect = true;
      }
      //console.log(editstorage);

    } else {
      this.router.navigate(['../promo'], { relativeTo: this.route });
    }

    this.sendMessage();
    this.initGroup();
  }
  createItem(): FormGroup {
    return this.fb.group({
      showTitleSequenceNumber: 1,
      printInfoId: new FormControl(null),
      primeShowTitle: this.fb.group({
        primeShowTitleId: [''],
        projectType: this.fb.group({
          c2027ProjectTypeId: [''],
        }),
      }),
      noOfPrints: ['', Validators.required],
      episodeSerialNumber: ['', Validators.required]
    });
  }
  createSpotTitle(): FormGroup {
    return this.fb.group({
      promoSet: this.fb.group({
        showTitle: [''],
        promoSpotPrintTitle: ['', Validators.required,
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
          validateEmptyField],
        promoLength: [''],
        showNoTimes: [''],
        adType: ['',
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))],
        programType: ['',
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))],
        printQuality: ['',
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))],
        splNotes: ['',
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]
      })
    })
  }
  createItemSection(): FormGroup {
    return this.fb.group({
      printInfoId: [],
      showTitle: [],
      noOfPrints: [],
      inputsheetId: [],
      promoSet: this.fb.array([this.section1()])
    })

  }

  section1(): FormGroup {
    return this.fb.group({
      promoSpotPrintTitle: [''],
    });
  }
  initGroup() {
    this.createItem();
  }

  sendMessage() {
    this.communicatingComponentService.sendCommunication(true);
  }
  clearMessage() {
    this.communicatingComponentService.sendCommunication(false);
    this.router.navigate(['../promo'], { relativeTo: this.route });
  }

  ngOnInit() {
    this.isLoading = true;
    this.vendorData=data;
    this.commonService.getRequest(`
    ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/showprojectgenres`,
      'GET')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          //console.log(data);
          this.genreList = data;
        },
        err => {
          //console.log('show project genres');
          this.toastorService.warn(err.message);
        }
      );

    this.dataSource4 = new MatTableDataSource<InputSheetPromostatGrpSection>(this.statGrpList);
    this.dataSource5 = new MatTableDataSource<InputSheetPromostatGrpVarSection>(this.statGrpVarList);
    this.dataSource6 = new MatTableDataSource<InputSheetPromoElegrpstdSection>(this.elegrpstdList);
    this.dataSource7 = new MatTableDataSource<InputSheetPromoElegrpVarSection>(this.elegrpvarList);
    this.dataSource8 = new MatTableDataSource<InputSheetPromoSrAwarenessSection>(this.srAwarenessList);
    this.dataSource9 = new MatTableDataSource<InputsheetPromoQuesSection>(this.questionstimList);

    this.editData = this.commonService.editPromoData;
    console.log(this.editData)
    this.inputSheetId = this.editData.inputsheetId;
    //  this.getSection1(this.editData.inputsheetId);

    // this.inputSheetId = 3271;

    // const options = this.inputSheetId ?
    //   {
    //     params: new HttpParams()
    //       .set('inputsheetId', this.inputSheetId)
    //       .append('inputsheetType', 'PROMO')
    //   } : {};
    const options = this.inputSheetId ? { 'inputsheetId': this.inputSheetId, 'inputsheetType': 'PROMO' } : {};
    //const localURL = 'assets/json/printads.json';

    this.commonService.getApiRequest(`
    ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.editInputSheetParams}`,
      'GET', options)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          // if(data[0].status==='SUBMITTED'){
          //   //console.log('submitted pilot');
          //   this.disButtons=true;
          // }
          //console.log(data);
          if (data.inputSheetStatus.inputSheetStatus === 'SUBMITTED') {
            //console.log('submitted promo');
            this.disButtons = true;
            this.readonlyFlag = true;
            this.disableSelect = true;
          }
          //console.log(data);
          this.pilotDate.setValue(data.testDate);
          if(data.vendorId){
            const index=this.vendorData.findIndex(item=>item.vendorId===data.vendorId);
            if(index>-1){
              this.vendorSelected=this.vendorData[index].vendorName;             
              this.vendor.setValue(this.vendorData[index].vendorName);
            }
          }
          this.promoData.promoAddCreateForm = this.fb.group({
            inputSheetId: data.inputSheetId,
            inputSheetType: this.fb.group({
              inputSheetType: data.inputSheetType.inputSheetType,
              activeYn: data.inputSheetType.activeYn
            }),
            surveyAnalyst: [data.surveyAnalyst],
            programmerNotes: [data.programmerNotes],
            testDate: [data.testDate],
            vendor:[this.vendorSelected],
            activeYn: 'Y',
            inputSheetStatus: this.fb.group({
              inputSheetStatus: 'DRAFT'
            }),
            inputSheetPrintInfo: this.fb.array([])
          });
          this.TotalAds = 0;
          data.inputSheetPrintInfoTemp.forEach((itemPE, indexPE) => {
            this.promoData.promoaddData.push([
              {
                genreId: itemPE.primeShowTitle.projectType.c2027ProjectTypeId,
                showTitleID: itemPE.primeShowTitle.primeShowTitleId,
                episode: itemPE.episodeSerialNumber,
                noofprintads: itemPE.noOfPrints
              }
            ]);
            const dataHeader = this.fb.group({
              showTitleSequenceNumber: parseInt(indexPE + 1),
              printInfoId: itemPE.printInfoId,
              primeShowTitle: this.fb.group({
                primeShowTitleId: [itemPE.primeShowTitle.primeShowTitleId],
                showTitle: [itemPE.primeShowTitle.showTitle],
                projectType: this.fb.group({
                  c2027ProjectTypeId: [itemPE.primeShowTitle.projectType.c2027ProjectTypeId],

                  c2027ProjectTypeDesc: [itemPE.primeShowTitle.projectType.c2027ProjectTypeDesc]
                }),
              }),
              noOfPrints: [itemPE.noOfPrints],
              episodeSerialNumber: [itemPE.episodeSerialNumber]
            });
            this.TotalAds = this.TotalAds + itemPE.noOfPrints;
            (this.promoData.promoAddCreateForm.controls.inputSheetPrintInfo as FormArray).push(dataHeader);
            this.typeid = itemPE.primeShowTitle.showTitle
            //console.log('@+!+@+',itemPE);
            //console.log('!!!!!!!!!!!!!!!',this.typeid)
          });
          this.dataSource = new MatTableDataSource<InputsheetprintEditHeader>(this.promoData.promoaddData);

          //console.log('---------------------');
          //console.log(this.promoData.promoAddCreateForm.value);
          //console.log('---------------------');          
        },
        err => {
          //console.log('show project genres');
          this.toastorService.warn(err.message);
        }
      )


    // const inputSheetId = this.inputSheetId ?
    //   {
    //     params: new HttpParams()
    //       .set('inputSheetId', this.inputSheetId)
    //   } : {};
    const inputSheetId = this.inputSheetId ? { 'inputSheetId': this.inputSheetId } : {};
    this.commonService.getApiRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/getAllPromoSections`,
      'GET', inputSheetId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        //console.log(result);
        this.isLoading = false;
        this.SpotData = result.Section_Data.spotDetails;
        this.AddAttrData = result.Section_Data.additionalAttributes;
        this.statIndivData = result.Section_Data.statementIndiv;
        this.statGrpStdData = result.Section_Data.statementGrpStandard;
        this.statGrpVarData = result.Section_Data.statementGrpVariable;
        this.eleGrpStdData = result.Section_Data.elementGrpStandard;
        this.eleGrpVarData = result.Section_Data.elementGrpVariable;
        this.srAwareData = result.Section_Data.srAwareness;
        this.quesStimData = result.Section_Data.questionStimChanges;
        // this.loadSpotDetails(this.SpotData);
        this.loadSection1(this.inputSheetId);
        // this.loadAttributeData(this.AddAttrData);
        // this.getSection2(result.Section_Data);
        this.loadStatementsGrpStd(this.statGrpStdData);
        this.loadStatementsGrpVar(this.statGrpVarData);
        this.loadElementsGrpStd(this.eleGrpStdData);
        this.loadElementsGrpVar(this.eleGrpVarData);
        this.loadSrAware(this.srAwareData);
        this.loadQuesStim(this.quesStimData);

      })
    // this.loadSection1(this.inputSheetId);
  }

  getSection1(inputSheetId) {
    this.isLoading = true;
    const localURL = 'assets/json/promosection.json';
    // const datasection1ColumnsList: any[] = [];
    //  this.datasection1HeaderList = [];
    this.dataset = [];
    // const options = inputSheetId ?
    //   { params: new HttpParams().set('inputSheetId', inputSheetId) } : {};

    const options = this.inputSheetId ? { 'inputSheetId': inputSheetId } : {};
    this.commonService.getApiRequest(`
    ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetprintTitles}`,
      'GET', options)
      .pipe(takeUntil(this.destroy$))
      // this.commonService.getRequest(`${localURL}`, 'GET')
      .subscribe(
        datasection => {
          this.isLoading = false;
          this.sectionData = datasection;
          const inputSheetPrintInfoArray = this.promoData.promoAddCreateForm.get('inputSheetPrintInfo') as FormArray;
          //console.log(inputSheetPrintInfoArray)
          //console.log((this.formSpot.get('adTitle') as FormArray).length);
          while ((this.formSpot.get('adTitle') as FormArray).length !== 0) {
            //console.log( (this.formSpot.get('adTitle') as FormArray));
            (this.formSpot.get('adTitle') as FormArray).removeAt(0);
            //console.log( (this.formSpot.get('adTitle') as FormArray));
          }
          this.spotDetailsCount = 0;
          //console.log((this.formSpot.get('adTitle') as FormArray).length);
          //console.log(datasection);
          datasection.map((promoitem, promoindex) => {
            this.dataset.push({
              printInfoId: promoitem.printInfoId,
              showTitle: promoitem.primeShowTitle.showTitle,
              noOfPrints: promoitem.noOfPrints,
              inputsheetId: promoitem.inputsheetId,
              promoSet: []
            });
            //console.log(promoitem)
            inputSheetPrintInfoArray.controls[promoindex].get('printInfoId').setValue(promoitem.printInfoId);
            //console.log(inputSheetPrintInfoArray.controls[promoindex].get('printInfoId'))
            this.section1Generate(this.dataset, promoindex, promoitem);

            //console.log(this.promoData.promoAddCreateForm.value)
          });
        },
        (err) => {
          this.toastorService.warn('Oops!!! The Server was unable to complete your request');
        }
      );

  }

  section1Generate(dataPromo, index, dataArray) {
    //console.log(dataPromo);
    this.addSpotDetails(dataPromo, index, dataArray);
  }

  addSpotDetails(spotData?: any, pindex?: any, dataArray?: any) {
    // this.spotDetailsData = [];
    //console.log(spotData);
    // this.sections(dataArray);
    const spotFG = this.fb.group({
      printInfoId: [spotData[pindex] ? spotData[pindex].printInfoId : ''],
      noOfPrints: [spotData[pindex] ? spotData[pindex].noOfPrints : ''],
      inputSheetId: [spotData[pindex] ? spotData[pindex].inputsheetId : ''],
      promoSet: this.fb.array([]),
    });
    this.spotDetailsData.push([
      {
        attributeCheck: 'Y',
        description: 'spot Desc',
        sequence: 1,
        sheetSectionMaster: {
          inputSheetSectionMasterId: 631,
          sequenceNumber: 10,
          sectionName: 'Spot Details',
          sectionGroup: ''
        },
        showTitle: spotData[pindex] ? spotData[pindex].showTitle : '',
        printInfoId: spotData[pindex] ? spotData[pindex].printInfoId : '',
        noOfPrints: spotData[pindex] ? spotData[pindex].noOfPrints : '',
        inputsheetId: spotData[pindex] ? spotData[pindex].inputsheetId : '',
        promoSet: []
      }
    ]);

    (this.formSpot.get('adTitle') as FormArray).push(spotFG);
    const spotDataIndex = (this.formSpot.get('adTitle') as FormArray).length - 1;

    if (!spotData) {
      this.addSpotPromoSet(spotDataIndex);
    } else {
      //console.log(spotData[pindex]);
      // spotData[pindex].promoSet.forEach((data) => {
      for (let i = 0; i < spotData[pindex].noOfPrints; i++) {
        // this.spotDetailsData[spotDataIndex].promoSet.push([]);
        this.spotDetailsCount = this.spotDetailsCount + 1;
        let data = '';
        if (dataArray.promoSet != null) {
          data = dataArray.promoSet[i];
        }
        //console.log(data)
        this.addSpotPromoSet(spotDataIndex, spotData[pindex], this.spotDetailsCount, data);
      }
      // });
    }
    // }
  }

  addSpotPromoSet(spotDataIndex: number, data?: any, spotpromosetIndex?: any, dataVal?: any) {
    //console.log(this.spotDetailsData[spotDataIndex].promoSet, '-');
    //console.log(dataVal)
    const promoSetFG = this.fb.group({
      inputSheetPromoSpotId: new FormControl(dataVal ? dataVal.inputSheetPromoSpotId : null),
      spotPosition: spotpromosetIndex,
      showTitle: data ? data.showTitle : '',
      promoSpotPrintTitle: [dataVal ? dataVal.promoSpotPrintTitle : '', [Validators.required,
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField]],
      promoLength: dataVal ? dataVal.promoLength : '',
      showNoTimes: dataVal ? dataVal.showNoTimes : '',
      adType: [dataVal ? dataVal.adType : '',
      [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
      programType: [dataVal ? dataVal.programType : '',
      [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
      printQuality: [dataVal ? dataVal.printQuality : '',
      [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
      splNotes: [dataVal ? dataVal.splNotes : '',
      [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
      sectionId: 631,
      sectionName: 'Spot Details',
      sequenceNumber: 1
    });
    this.spotDetailsData[spotDataIndex].map((item) =>
      item.promoSet.push(
        {
          inputSheetPromoSpotId: dataVal ? dataVal.inputSheetPromoSpotId : null,
          spotPosition: spotpromosetIndex,
          showTitle: data ? data.showTitle : '',
          promoSpotPrintTitle: dataVal ? dataVal.promoSpotPrintTitle : '',
          promoLength: dataVal ? dataVal.promoLength : '',
          showNoTimes: dataVal ? dataVal.showNoTimes : '',
          adType: dataVal ? dataVal.adType : '',
          programType: dataVal ? dataVal.programType : '',
          printQuality: dataVal ? dataVal.printQuality : '',
          splNotes: dataVal ? dataVal.splNotes : ''
        }
      )
    );
    //console.log((((this.formSpot.controls.adTitle as FormArray).controls[spotDataIndex] as FormGroup).controls.promoSet as FormArray).length);
    // tslint:disable-next-line: max-line-length
    (((this.formSpot.controls.adTitle as FormArray).controls[spotDataIndex] as FormGroup).controls.promoSet as FormArray).push(promoSetFG);

  }
  /* Section wise save */
  /* --SPOT Details Save-- */
  spotSave(formValue) {
    //console.log(this.formSpot.value);
    if (formValue.invalid) {
      this.submittedForm1 = true;
      //console.log('sdsdihgbshjabohubsahfsabo')
      return false;
    } else {
      this.isLoading = true;
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section/addTitle`, 'PUT', this.formSpot.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.isLoading = false;
          this.putAdTitleGroup(result);
          this.getsection2(result)
          this.toastorService.success('Updated Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        });
    }
  }
  /* Additional Attribute Save */
  onSection2Submit(formValue) {
    //console.log(formValue);
    if (this.formAddAttr.invalid) {
      this.submittedForm2 = true;
      return false;
      //console.log('form invalid');
    } else {
      // console.log(formValue);
      this.isLoading = true;
      // if (this.sec2SaveEdit === true) {
      //   this.commonService.insertRequest(`
      //     ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetSave}/${this.appConstants.inputSheetPrintPromoSection}`, 'POST', formValue)
      //     .pipe(takeUntil(this.destroy$))
      //     .subscribe(
      //       result => {              
      //         this.isLoading = false;
      //         this.sec2SaveEdit = false;
      //         this.putAdditionalAttr(result);
      //         this.toastorService.success('Saved Successfully');
      //       }, err => {
      //         this.isLoading = false;
      //         this.toastorService.warn('Oops!!! The Server was unable to complete your request');
      //       });
      // } else {
      this.commonService.putRequest(`
          ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetUpdate}/${this.appConstants.inputSheetPrintPromoSection}`, 'PUT', formValue)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          result => {
            this.isLoading = false;
            this.putAdditionalAttr(result);
            this.toastorService.success('Saved Successfully');
          }, err => {
            this.isLoading = false;
            this.toastorService.warn('Oops!!! The Server was unable to complete your request');
          });
      //}
    }
  }

  /* statement indiv save */
  onSection3Submit(formValue) {
    //console.log(formValue);
    if (this.formStatIndiv.invalid) {
      this.submittedForm3 = true;
      return false;
      //console.log('form invalid');
    } else {
      this.isLoading = true;
      //console.log('form valid');
      // if (this.sec3SaveEdit === true) {
      //   this.commonService.insertRequest(`
      //   ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetSave}/${this.appConstants.inputSheetPrintPromoSection}`, 'POST', formValue)
      //     .pipe(takeUntil(this.destroy$))  
      //     .subscribe(
      //       result => {
      //         this.isLoading = false;
      //         this.sec3SaveEdit = false;
      //         this.putStatementIndiv(result);
      //         this.toastorService.success('Saved Successfully');
      //       }, err => {
      //         this.isLoading = false;
      //         this.toastorService.warn('Oops!!! The Server was unable to complete your request');
      //       });
      // }else{
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetUpdate}/${this.appConstants.inputSheetPrintPromoSection}`, 'PUT', formValue)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          result => {
            this.isLoading = false;
            this.putStatementIndiv(result);
            this.toastorService.success('Saved Successfully');
          }, err => {
            this.isLoading = false;
            this.toastorService.warn('Oops!!! The Server was unable to complete your request');
          });
      // }
    }
  }


  getsection2(data) {
    this.datasection2HeaderList = [];
    this.datasettwo = [];
    // this.sectionData = data;
    // console.log(data)
    this.addAtrributeList = data.inputSheetPromoSpot;
    this.addAtrributeList.map((item, index) => {
      //console.log(item.additionalAttributes)
      this.datasection2HeaderList.push({
        inputsheetId: item.inputSheetId,
        printInfoId: item.printInfoId,
        inputSheetPromoSpotId: item.inputSheetPromoSpotId,
        promoSpotPrintTitle: item.promoSpotPrintTitle,
        descriptionList: []
      });
      this.datasettwo.push({
        inputsheetId: item.inputSheetId,
        printInfoId: item.printInfoId,
        inputSheetPromoSpotId: item.inputSheetPromoSpotId,
        promoSpotPrintTitle: item.promoSpotPrintTitle,
        descriptionList: []
      });
      this.datasettwo[index].descriptionList.push('');
    });
    //console.log(this.datasettwo)
    this.displayedColumns = this.datasection2HeaderList.map(c => c.promoSpotPrintTitle);
    this.sections(this.datasettwo);
  }

  /* Mapping Id's on a POST/PUT Method Start*/
  /* section 1 update */
  putAdTitleGroup(putAdTitleGroup: any) {
    (this.formSpot.get('adTitle') as FormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.formSpot.controls.adTitle as FormArray).controls[index] as FormGroup).controls.printInfoId.value;
      (((this.formSpot.controls.adTitle as FormArray).controls[index] as FormGroup).controls.promoSet as FormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.formSpot.controls.adTitle as FormArray).controls[index] as FormGroup).controls.promoSet as FormArray).controls[pindex] as FormGroup).controls.inputSheetPromoSpotId.value;
        const promoSpotPrintTitle = ((((this.formSpot.controls.adTitle as FormArray).controls[index] as FormGroup).controls.promoSet as FormArray).controls[pindex] as FormGroup).controls.promoSpotPrintTitle.value;
        putAdTitleGroup.inputSheetPromoSpot.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetPromoSpotId === '' || editData.promoSpotPrintTitle === promoSpotPrintTitle) {
            ((((this.formSpot.controls.adTitle as FormArray).controls[index] as FormGroup).controls.promoSet as FormArray).controls[pindex] as FormGroup).controls.inputSheetPromoSpotId.patchValue(editData.inputSheetPromoSpotId);
          }
        });
      });
    });
    //console.log(putAdTitleGroup);
    //console.log(this.formSpot.value);
  }

  /* section 2 update */
  putAdditionalAttr(putAdditionalAttr: any) {
    (this.formAddAttr.get('inputSheetSection') as FormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.formAddAttr.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.printInfoId.value;
      (((this.formAddAttr.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.descriptionList as FormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.formAddAttr.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.descriptionList as FormArray).controls[pindex] as FormGroup).controls.inputSheetUserSectionId.value;
        const description = ((((this.formAddAttr.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.descriptionList as FormArray).controls[pindex] as FormGroup).controls.description.value;
        putAdditionalAttr.InputUserSection.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetUserSectionId === '' || editData.description === description) {
            ((((this.formAddAttr.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.descriptionList as FormArray).controls[pindex] as FormGroup).controls.inputSheetUserSectionId.patchValue(editData.inputSheetUserSectionId);
          }
        });
      });
    });
    //console.log(putAdditionalAttr);
    //console.log(this.formAddAttr.value);
  }

  /* section 3 update */
  putStatementIndiv(putStatementIndiv: any) {
    (this.formStatIndiv.get('inputSheetSection') as FormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.formStatIndiv.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.printInfoId.value;
      (((this.formStatIndiv.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.descriptionList as FormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.formStatIndiv.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.descriptionList as FormArray).controls[pindex] as FormGroup).controls.inputSheetUserSectionId.value;
        const description = ((((this.formStatIndiv.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.descriptionList as FormArray).controls[pindex] as FormGroup).controls.description.value;
        putStatementIndiv.InputUserSection.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetUserSectionId === '' || editData.description === description) {
            ((((this.formStatIndiv.controls.inputSheetSection as FormArray).controls[index] as FormGroup).controls.descriptionList as FormArray).controls[pindex] as FormGroup).controls.inputSheetUserSectionId.patchValue(editData.inputSheetUserSectionId);
          }
        });
      });
    });
    //console.log(putStatementIndiv);
    //console.log(this.formStatIndiv.value);
  }
  /* Mapping Id's on a POST/PUT Method End*/

  /* Load Section 1 datas */
  loadSection1(inputSheetId) {
    this.isLoading = true;
    this.datasection1HeaderList = [];
    this.editSpottitleData = [];
    while ((this.formSpot.get('adTitle') as FormArray).length !== 0) {
      (this.formSpot.get('adTitle') as FormArray).removeAt(0);
    }
    while ((this.formAddAttr.get('inputSheetSection') as FormArray).length !== 0) {
      (this.formAddAttr.get('inputSheetSection') as FormArray).removeAt(0)
    }
    while ((this.formStatIndiv.get('inputSheetSection') as FormArray).length !== 0) {
      (this.formStatIndiv.get('inputSheetSection') as FormArray).removeAt(0)
    }
    // const options = inputSheetId ?
    //   { params: new HttpParams().set('inputSheetId', inputSheetId) } : {};
    const options = this.inputSheetId ? { 'inputSheetId': inputSheetId } : {};
    this.commonService.getApiRequest(`
    ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetprintTitles}`,
      'GET', options)
      //this.commonService.getRequest(`${localURL}`, 'GET')
      .subscribe(
        datasection => {
          this.isLoading = false;
          this.editSpottitleData = datasection;
          this.sectionData = datasection;
        },
        (err) => {
          this.isLoading = false;
          this.toastorService.warn('Oops!!! The Server was unable to complete your request');
        },
        () => {
          this.promoEditSectionDisplay();
        }
      );
  }

  promoEditSectionDisplay() {
    this.displayedColumns = [];
    // this.datasection2HeaderList = [];
    if (this.editSpottitleData) {
      if (this.SpotData != null) {
        this.SpotData.map(item => {
          //console.log(item.promoSpotPrintTitle)
          this.displayedColumns.push(item.promoSpotPrintTitle)
        })
      }

      this.spotDetailsCount = 0;
      //console.log(this.SpotData);
      //console.log(this.editAdTitleGroup);
      // this.displayedColumns = this.datasection2HeaderList.map(c => c.promoSpotPrintTitle);
      this.editSpottitleData.forEach((promoDataitem, adindex) => {
        //console.log(promoDataitem)
        this.editAdTitleGroup(promoDataitem);
      });
      //console.log(this.SpotData);
      if (this.SpotData != null) {
        this.SpotData.forEach((promoSpotData) => {
          this.editAddAttribute(promoSpotData);
          this.editStatementIndiv(promoSpotData);
        });
        this.spotNotSaved = false;
      } else {
        this.spotNotSaved = true;
      }
    }
  }

  /* Spot Details Edit Start */
  editAdTitleGroup(spotData?: any) {
    // console.log(spotData)
    const spotFG = this.fb.group({
      printInfoId: [spotData ? spotData.printInfoId : ''],
      noOfPrints: [spotData ? spotData.noOfPrints : ''],
      inputSheetId: [spotData ? spotData.inputsheetId : ''],
      promoSet: this.fb.array([]),
    });
    this.spotDetailsData.push([
      {
        attributeCheck: 'Y',
        description: 'spot Desc',
        sequence: 1,
        sheetSectionMaster: {
          inputSheetSectionMasterId: 631,
          sequenceNumber: 10,
          sectionName: 'Spot Details',
          sectionGroup: ''
        },
        showTitle: spotData ? spotData.showTitle : '',
        printInfoId: spotData ? spotData.printInfoId : '',
        noOfPrints: spotData ? spotData.noOfPrints : '',
        inputsheetId: spotData ? spotData.inputsheetId : '',
        promoSet: []
      }
    ]);
    (this.formSpot.get('adTitle') as FormArray).push(spotFG);
    const adTitleDataIndex = (this.formSpot.get('adTitle') as FormArray).length - 1;
    if (!spotData) {
      this.editAdTitlePromoSet(adTitleDataIndex);
    } else {
      const editAdTitleData = this.SpotData;
      if (editAdTitleData != null) {
        editAdTitleData.forEach(adTitleItem => {
          if (spotData.printInfoId === adTitleItem.printInfoId) {
            //console.log(spotData)
            this.spotDetailsCount = this.spotDetailsCount + 1;
            this.editAdTitlePromoSet(adTitleDataIndex, spotData.primeShowTitle, adTitleItem, this.spotDetailsCount);
          }
        });
      }
      const promoSetLength = (((this.formSpot.controls.adTitle as FormArray).controls[adTitleDataIndex] as FormGroup).controls.promoSet as FormArray).length;
      for (let i = promoSetLength; i < spotData.noOfPrints; i++) {
        this.spotDetailsCount = this.spotDetailsCount + 1;
        this.editAdTitlePromoSet(adTitleDataIndex, spotData.primeShowTitle, '', this.spotDetailsCount);
      }
    }
  }
  editAdTitlePromoSet(spotDataIndex: number, data?: any, dataVal?: any, spotpromosetIndex?: any) {
    //console.log(dataVal)
    const promoSetFG = this.fb.group({
      inputSheetPromoSpotId: new FormControl(dataVal ? dataVal.inputSheetPromoSpotId : null),
      spotPosition: spotpromosetIndex,
      showTitle: data ? data.showTitle : '',
      promoSpotPrintTitle: [dataVal ? dataVal.promoSpotPrintTitle : '', [Validators.required,
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField]],
      promoLength: dataVal ? dataVal.promoLength : '',
      showNoTimes: dataVal ? dataVal.showNoTimes : '',
      adType: [dataVal ? dataVal.adType : '',
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))],
      programType: [dataVal ? dataVal.programType : '',
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))],
      printQuality: [dataVal ? dataVal.printQuality : '',
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))],
      splNotes: [dataVal ? dataVal.splNotes : '',
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))],
      sectionId: 631,
      sectionName: 'Spot Details',
      sequenceNumber: 1
    });
    this.spotDetailsData[spotDataIndex].map((item) =>
      item.promoSet.push(
        {
          inputSheetPromoSpotId: dataVal ? dataVal.inputSheetPromoSpotId : null,
          spotPosition: spotpromosetIndex,
          showTitle: data ? data.showTitle : '',
          promoSpotPrintTitle: dataVal ? dataVal.promoSpotPrintTitle : '',
          promoLength: dataVal ? dataVal.promoLength : '',
          showNoTimes: dataVal ? dataVal.showNoTimes : '',
          adType: dataVal ? dataVal.adType : '',
          programType: dataVal ? dataVal.programType : '',
          printQuality: dataVal ? dataVal.printQuality : '',
          splNotes: dataVal ? dataVal.splNotes : ''
        }
      )
    );
    // tslint:disable-next-line: max-line-length
    (((this.formSpot.controls.adTitle as FormArray).controls[spotDataIndex] as FormGroup).controls.promoSet as FormArray).push(promoSetFG);

  }
  /* Spot Details Edit section End */

  /* Additional Attribute Edit section Start */
  editAddAttribute(adAttributeData?: any) {
    // console.log(adAttributeData)
    const adTitleFG = this.fb.group({
      printInfoId: [adAttributeData ? adAttributeData.printInfoId : ''],
      inputSheetId: [adAttributeData ? adAttributeData.inputsheetId : ''],
      inputSheetPromoSpotId: [adAttributeData ? adAttributeData.inputSheetPromoSpotId : ''],
      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 625,
        sequenceNumber: 1,
        sectionName: "ADDITIONAL ATTRIBUTES",
        sectionGroup: ""
      }
    });
    (this.formAddAttr.get('inputSheetSection') as FormArray).push(adTitleFG);
    const adAttributeDataIndex = (this.formAddAttr.get('inputSheetSection') as FormArray).length - 1;
    let attributeDataSet: any = [];
    // console.log(this.AddAttrData)
    if (this.AddAttrData != null) {
      this.AddAttrData.map((item, index) => {
        if (item.inputSheetPromoSpotId == adAttributeData.inputSheetPromoSpotId) {
          attributeDataSet.push(item);
        }
      });
    }
    // console.log(attributeDataSet)
    // if (adAttributeData != "") {
    if (!adAttributeData) {
      //console.log('if');
      this.addAdTitlePromoSet(adAttributeDataIndex);
    } else {
      //console.log('else if')
      if (attributeDataSet != "") {
        attributeDataSet.forEach((val, index) => {
          this.addAdTitlePromoSet(adAttributeDataIndex, val, attributeDataSet[index]);
        })
      } else {
        this.addAdTitlePromoSet(adAttributeDataIndex);
      }
    }
  }
  /* Additional Attribute Edit section End */

  /* Statement indiv section start */
  editStatementIndiv(adAttributeData?: any) {
    const adTitleFG = this.fb.group({
      printInfoId: [adAttributeData ? adAttributeData.printInfoId : ''],
      inputSheetId: [adAttributeData ? adAttributeData.inputsheetId : ''],
      inputSheetPromoSpotId: [adAttributeData ? adAttributeData.inputSheetPromoSpotId : ''],
      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 626,
        sequenceNumber: 2,
        sectionName: "STATEMENTS INDIV",
        sectionGroup: ""
      }
    });
    (this.formStatIndiv.get('inputSheetSection') as FormArray).push(adTitleFG);
    const adAttributeDataIndex = (this.formStatIndiv.get('inputSheetSection') as FormArray).length - 1;
    let attributeDataSet: any = [];
    if (this.statIndivData != null) {
      this.statIndivData.map((item, index) => {
        if (item.inputSheetPromoSpotId == adAttributeData.inputSheetPromoSpotId) {
          attributeDataSet.push(item);
        }
      });
    }
    //console.log(adAttributeData)
    // if (adAttributeData != "") {
    if (!adAttributeData) {
      this.addStatIndivGroupPromoSet(adAttributeDataIndex);
    } else {
      if (attributeDataSet != "") {
        attributeDataSet.forEach((val, index) => {
          this.addStatIndivGroupPromoSet(adAttributeDataIndex, val, attributeDataSet[index]);
        })
      } else {
        this.addStatIndivGroupPromoSet(adAttributeDataIndex);
      }
    }
  }
  /* Statement indiv section end */



  /* Additional Attribute and Statement indiv */
  // getSection2(data) {
  //   // test1Promosection2.json
  //   const datasection2ColumnsList: any[] = [];
  //   this.datasection2HeaderList = [];
  //   this.datasettwo = [];
  //   // this.sectionData = data;
  //   this.addAtrributeList = data.inputSheetPromoSpot;
  //   this.addAtrributeList.map((item, index) => {
  //     //console.log(item.additionalAttributes)
  //     this.datasection2HeaderList.push({
  //       inputsheetId: item.inputSheetId,
  //       printInfoId: item.printInfoId,
  //       inputSheetPromoSpotId: item.inputSheetPromoSpotId,
  //       promoSpotPrintTitle: item.promoSpotPrintTitle,
  //       descriptionList: []
  //     });
  //     this.datasettwo.push({
  //       inputsheetId: item.inputSheetId,
  //       printInfoId: item.printInfoId,
  //       inputSheetPromoSpotId: item.inputSheetPromoSpotId,
  //       promoSpotPrintTitle: item.promoSpotPrintTitle,
  //       descriptionList: []
  //     });
  //     this.datasettwo[index].descriptionList.push('');

  //   });
  //   this.displayedColumns = this.datasection2HeaderList.map(c => c.promoSpotPrintTitle);
  //   this.sections(this.datasettwo);
  // }

  deleteSections(cellValue) {
    //console.log(cellValue);
    //  //console.log(cellValue.value.inputSheetPromoSpotId);
    cellValue.controls.description.reset();
    if (cellValue.value.inputSheetUserSectionId) {
      this.isLoading = true;
      // const options = cellValue.value.inputSheetUserSectionId ?
      // { params: new HttpParams().set('INPUT_SHEET_USER_SECTION_ID', cellValue.value.inputSheetUserSectionId) } : {};
      const options = cellValue.value.inputSheetUserSectionId ?
        { 'INPUT_SHEET_USER_SECTION_ID': cellValue.value.inputSheetUserSectionId } : {};

      this.commonService.deleteRequest(`
  ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetPrintAdsDashboard}/${this.appConstants.inputSheetSections}/${this.appConstants.inputSheetSectionsDelete}`,
        'DELETE', options)
        .subscribe(
          datasection => {
            //console.log(datasection);
            cellValue.controls.description.reset();
            this.isLoading = false;
            this.toastorService.success('Deleted Successfully');
          },
          (err) => {
            this.isLoading = false;
            this.toastorService.warn('Oops!!! The Server was unable to complete your request');
          },
        );
    }

  }

  loadStatementsGrpStd(statstd) {
    if (statstd == null) {
      this.onAddStatgrpVarRow();
    } else {
      let data = [];
      (this.section4Form.controls.section4Array as FormArray).clear();
      //console.log(statstd);
      statstd = statstd.map(mapdata => {
        let metaFlag;
        mapdata.createdBy == 'METADATA' ? metaFlag = false : metaFlag = true;
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          posstatstd: mapdata.sheetQuestion.questionSequenceNum,
          statstd: mapdata.sheetQuestion.questionLongText,
          sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
          metaDataFlag: metaFlag,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          statstd: new FormControl(mapdata.sheetQuestion.questionLongText, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section4Form.get('section4Array') as FormArray).push(ArrayData);
      })

      this.statGrpList = data;
      //console.log(this.statGrpList);
      this.dataSource4 = new MatTableDataSource(this.statGrpList);
    }

  }

  loadStatementsGrpVar(statvar) {
    if (statvar == null) {
      this.onAddStatgrpVarRow();
    } else {
      let data = [];
      (this.section5Form.controls.section5Array as FormArray).clear();
      statvar = statvar.map(mapdata => {
        let metaFlag;
        mapdata.createdBy == 'METADATA' ? metaFlag = false : metaFlag = true;
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          posstatvar: mapdata.sheetQuestion.questionSequenceNum,
          statgrpvar: mapdata.sheetQuestion.questionLongText,
          sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
          metaDataFlag: metaFlag,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          statgrpvar: new FormControl(mapdata.sheetQuestion.questionLongText, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section5Form.get('section5Array') as FormArray).push(ArrayData);
      })

      this.statGrpVarList = data;
      //console.log(this.statGrpVarList);
      this.dataSource5 = new MatTableDataSource(this.statGrpVarList);
    }

  }

  loadElementsGrpStd(elestd) {
    if (elestd == null) {
      this.onaddeleGrpStdRow();
    } else {
      let data = [];
      (this.section6Form.controls.section6Array as FormArray).clear();
      elestd = elestd.map(mapdata => {
        let metaFlag;
        mapdata.createdBy == 'METADATA' ? metaFlag = false : metaFlag = true;
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          poselestd: mapdata.sheetQuestion.questionSequenceNum,
          elegrpStd: mapdata.sheetQuestion.questionLongText,
          sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
          metaDataFlag: metaFlag,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          elegrpStd: new FormControl(mapdata.sheetQuestion.questionLongText, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section6Form.get('section6Array') as FormArray).push(ArrayData);
      })

      this.elegrpstdList = data;
      //console.log(this.elegrpstdList);
      this.dataSource6 = new MatTableDataSource(this.elegrpstdList);
    }

  }

  loadElementsGrpVar(elevar) {
    if (elevar == null) {
      this.onAddEleGrpVarRow();
    } else {
      let data = [];
      (this.section7Form.controls.section7Array as FormArray).clear();
      elevar = elevar.map(mapdata => {
        let metaFlag;
        mapdata.createdBy == 'METADATA' ? metaFlag = false : metaFlag = true;
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          poselegrpVar: mapdata.sheetQuestion.questionSequenceNum,
          elegrpVar: mapdata.sheetQuestion.questionLongText,
          sheetQuestionId: mapdata.sheetQuestion.inputSheetQuestionsId,
          metaDataFlag: metaFlag,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          elegrpVar: new FormControl(mapdata.sheetQuestion.questionLongText, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section7Form.get('section7Array') as FormArray).push(ArrayData);
      })

      this.elegrpvarList = data;
      //console.log(this.elegrpvarList);
      this.dataSource7 = new MatTableDataSource(this.elegrpvarList);
    }

  }

  loadSrAware(sraware) {
    if (sraware == null) {
      this.onAddsrawareRow();
    } else {
      let data = [];
      (this.section8Form.controls.section8Array as FormArray).clear();
      sraware = sraware.map(mapdata => {
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          srawareness: mapdata.description,
          possraware: mapdata.sequence,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          srawareness: new FormControl(mapdata.description, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section8Form.get('section8Array') as FormArray).push(ArrayData);
      })

      this.srAwarenessList = data;
      //console.log(this.srAwarenessList);
      this.dataSource8 = new MatTableDataSource(this.srAwarenessList);
    }

  }

  loadQuesStim(quesstim) {
    if (quesstim == null) {
      this.onAddQuesRow();
    } else {
      let data = [];
      (this.section9Form.controls.section9Array as FormArray).clear();
      quesstim = quesstim.map(mapdata => {
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          quesnstim: mapdata.description,
          posques: mapdata.sequence,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          quesnstim: new FormControl(mapdata.description, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section9Form.get('section9Array') as FormArray).push(ArrayData);
      })

      this.questionstimList = data;
      //console.log(this.questionstimList);
      this.dataSource9 = new MatTableDataSource(this.questionstimList);
    }

  }

  sections(section) {
    while ((this.formAddAttr.get('inputSheetSection') as FormArray).length !== 0) {
      (this.formAddAttr.get('inputSheetSection') as FormArray).removeAt(0)
    }
    while ((this.formStatIndiv.get('inputSheetSection') as FormArray).length !== 0) {
      (this.formStatIndiv.get('inputSheetSection') as FormArray).removeAt(0)
    }
    if (section) {
      this.datasettwo.forEach(promoData => {
        this.addAttributeGroup(promoData);
        this.addAfterStatIndivGroup(promoData);
      });
    } else {

    }
  }


  loadAttributeData(adAttributeData?: any) {
    //console.log(adAttributeData)
    const adTitleFG = this.fb.group({
      printInfoId: [adAttributeData ? adAttributeData.printInfoId : ''],
      inputSheetId: [adAttributeData ? adAttributeData.inputsheetId : ''],
      inputSheetPromoSpotId: [adAttributeData ? adAttributeData.inputSheetPromoSpotId : ''],
      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 625,
        sequenceNumber: 1,
        sectionName: "ADDITIONAL ATTRIBUTES",
        sectionGroup: ""
      }
    });
    (this.formAddAttr.get('inputSheetSection') as FormArray).push(adTitleFG);
    const adAttributeDataIndex = (this.formAddAttr.get('inputSheetSection') as FormArray).length - 1;
    let attributeDataSet: any = [];
    this.AddAttrData.map((item, index) => {
      if (item.inputSheetPromoSpotId == adAttributeData.inputSheetPromoSpotId && this.AddAttrData != null) {
        attributeDataSet.push(item);
      }
    });
    //console.log(attributeDataSet)
    if (!adAttributeData) {
      this.addAdTitlePromoSet(adAttributeDataIndex);
    } else {
      //console.log(attributeDataSet != "")
      if (attributeDataSet != "") {
        attributeDataSet.forEach((val, index) => {
          this.addAdTitlePromoSet(adAttributeDataIndex, val, attributeDataSet[index]);
        })
      } else {
        adAttributeData.descriptionList.forEach((val, index) => {
          //console.log(val)
          this.addAdTitlePromoSet(adAttributeDataIndex, val);
        });
      }
    }
  }

  addAttributeGroup(adAttributeData?: any) {
    const adTitleFG = this.fb.group({
      printInfoId: [adAttributeData ? adAttributeData.printInfoId : ''],
      inputSheetId: [adAttributeData ? adAttributeData.inputsheetId : ''],
      inputSheetPromoSpotId: [adAttributeData ? adAttributeData.inputSheetPromoSpotId : ''],
      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 625,
        sequenceNumber: 1,
        sectionName: "ADDITIONAL ATTRIBUTES",
        sectionGroup: ""
      }
    });
    (this.formAddAttr.get('inputSheetSection') as FormArray).push(adTitleFG);
    const adAttributeDataIndex = (this.formAddAttr.get('inputSheetSection') as FormArray).length - 1;
    let attributeDataSet: any = [];
    this.addAtrributeList.map((item, index) => {
      if (item.inputSheetPromoSpotId == adAttributeData.inputSheetPromoSpotId && item.additionalAttributes != null) {
        item.additionalAttributes.forEach(val => {
          attributeDataSet.push(val);
        });
      }
    });
    //console.log(attributeDataSet)
    if (!adAttributeData) {
      this.addAdTitlePromoSet(adAttributeDataIndex);
    } else {
      //console.log(attributeDataSet != "")
      if (attributeDataSet != "") {
        attributeDataSet.forEach((val, index) => {
          this.addAdTitlePromoSet(adAttributeDataIndex, val, attributeDataSet[index]);
        })
      } else {
        adAttributeData.descriptionList.forEach((val, index) => {
          //console.log(val)
          this.addAdTitlePromoSet(adAttributeDataIndex, val);
        });
      }
    }
  }
  addAdTitlePromoSet(adTitleDataIndex: number, data?: any, attrDataSet?: any) {
    //console.log(attrDataSet)
    const descriptionFG = this.fb.group({
      inputSheetUserSectionId: [attrDataSet ? attrDataSet.inputSheetUserSectionId : ''],
      description: [attrDataSet ? attrDataSet.description : '', [
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
    });
    // tslint:disable-next-line: max-line-length
    (((this.formAddAttr.controls.inputSheetSection as FormArray).controls[adTitleDataIndex] as FormGroup).controls.descriptionList as FormArray).push(descriptionFG);
    //console.log(this.formAddAttr);

  }
  AddRowAfterAddAttribute() {
    const afterGroupDataIndex = (this.formAddAttr.get('inputSheetSection') as FormArray).length - 1;
    this.formAddAttr.controls.inputSheetSection.value.forEach(
      (addshowData, addIndex) => {
        this.addAdTitlePromoSet(addIndex);
      });
  }
  addAfterStatIndivGroup(adAttributeData?: any) {

    const adTitleFG = this.fb.group({
      printInfoId: [adAttributeData ? adAttributeData.printInfoId : ''],
      inputSheetId: [adAttributeData ? adAttributeData.inputsheetId : ''],
      inputSheetPromoSpotId: [adAttributeData ? adAttributeData.inputSheetPromoSpotId : ''],
      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 626,
        sequenceNumber: 2,
        sectionName: "STATEMENTS INDIV",
        sectionGroup: ""
      }
    });
    (this.formStatIndiv.get('inputSheetSection') as FormArray).push(adTitleFG);
    const adAttributeDataIndex = (this.formStatIndiv.get('inputSheetSection') as FormArray).length - 1;
    let attributeDataSet: any = [];
    this.addAtrributeList.map((item, index) => {
      if (item.inputSheetPromoSpotId == adAttributeData.inputSheetPromoSpotId && item.statementIndiv != null) {
        item.statementIndiv.forEach(val => {
          attributeDataSet.push(val);
        });
      }
    });
    if (!adAttributeData) {
      this.addStatIndivGroupPromoSet(adAttributeDataIndex);
    } else {
      if (attributeDataSet != "") {
        attributeDataSet.forEach((val, index) => {
          this.addStatIndivGroupPromoSet(adAttributeDataIndex, val, attributeDataSet[index]);
        })
      } else {
        adAttributeData.descriptionList.forEach(val => {
          this.addStatIndivGroupPromoSet(adAttributeDataIndex, val);
        });
      }
    }
  }
  addStatIndivGroupPromoSet(adTitleDataIndex: number, data?: any, attrDataSet?: any) {
    const descriptionFG = this.fb.group({
      inputSheetUserSectionId: [attrDataSet ? attrDataSet.inputSheetUserSectionId : ''],
      description: [attrDataSet ? attrDataSet.description : '', [
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/))]],
    });
    // tslint:disable-next-line: max-line-length
    (((this.formStatIndiv.controls.inputSheetSection as FormArray).controls[adTitleDataIndex] as FormGroup).controls.descriptionList as FormArray).push(descriptionFG);

  }
  AddRowAfterAddStatIndivGroup() {
    const afterGroupDataIndex = (this.formStatIndiv.get('inputSheetSection') as FormArray).length - 1;
    this.formStatIndiv.controls.inputSheetSection.value.forEach(
      (addshowData, addIndex) => {
        this.addStatIndivGroupPromoSet(addIndex);
      });
  }

  getTotalAds() {
    this.TotalAds = 0;
    const inputSheetPrintInfoArray = this.promoData.promoAddCreateForm.get('inputSheetPrintInfo') as FormArray;
    let indexVal: any = 0;
    inputSheetPrintInfoArray.value.map(
      t => {
        if (t.noOfPrints != 'undefined' && t.noOfPrints != '') {
          this.TotalAds = this.TotalAds + parseInt(t.noOfPrints);
        }
        indexVal = parseInt(indexVal + 1)
        t.showTitleSequenceNumber = indexVal;
      }
    )
  }

  /* Remove Row */
  removeRow(ele, index) {
    //console.log(ele);
    //console.log(index)
    //console.log(this.promoData.promoaddData)

    //console.log(this.promoData.promoAddCreateForm)
    let inputSheetPrintInfo = this.promoData.promoAddCreateForm.get('inputSheetPrintInfo') as FormArray;
    //console.log(this.inputSheetId);
    // let promoDatasec: any;
    //console.log(inputSheetPrintInfo.value[index]);
    if (inputSheetPrintInfo.value[index].printInfoId != null) {
      this.isLoading = true;
      const printInfoId = inputSheetPrintInfo.value[index].printInfoId;
      // const options = printInfoId ?
      // { params: new HttpParams().set('InputSheetPrintInfoId', printInfoId) } : {};
      const options = printInfoId ?
        { 'InputSheetPrintInfoId': printInfoId } : {};
      //console.log('Array format ',options);
      // return false;
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/header`,
        'DELETE', options)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          // this.getTable();
          // this.isLoading = false;
          (this.promoData.promoAddCreateForm.get('inputSheetPrintInfo') as FormArray).removeAt(index);
          let promoDatasec: any;
          promoDatasec = this.promoData.promoaddData.filter((item, i) => i != index);
          this.promoData.promoaddData = promoDatasec;
          this.dataSource = new MatTableDataSource(this.promoData.promoaddData);
          this.getTotalAds();
          if ((this.promoData.promoAddCreateForm.get('inputSheetPrintInfo') as FormArray).length == 0) {
            this.showTitleEmpty = true;
          }
          // this.getSection1(this.inputSheetId);
          const inputSheetId = this.inputSheetId ? { 'inputSheetId': this.inputSheetId } : {};
          this.commonService.getApiRequest(`
            ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/getAllPromoSections`,
            'GET', inputSheetId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => {
              //console.log(result);
              this.isLoading = false;
              this.SpotData = result.Section_Data.spotDetails;
              this.AddAttrData = result.Section_Data.additionalAttributes;
              this.statIndivData = result.Section_Data.statementIndiv;
              // this.loadSpotDetails(this.SpotData);
              this.loadSection1(this.inputSheetId);
            })
        },
          error => {
            this.isLoading = false;
            this.toastorService.warn('Error' + error.message);
          },
          () => {

            this.toastorService.success('Show Title Deleted Successfully!');
          }
        );
    } else {
      (this.promoData.promoAddCreateForm.get('inputSheetPrintInfo') as FormArray).removeAt(index);
      if ((this.promoData.promoAddCreateForm.get('inputSheetPrintInfo') as FormArray).length == 0) {
        this.showTitleEmpty = true;
      }
      let promoDatasec: any;
      promoDatasec = this.promoData.promoaddData.filter((item, i) => i != index);
      this.promoData.promoaddData = promoDatasec;
      this.dataSource = new MatTableDataSource(this.promoData.promoaddData);
      this.getTotalAds();
      this.isLoading = false;
    }

  }

  promoGenerate(status) {
    //console.log('generate');
    //console.log(this.promoData.promoAddCreateForm.value);

    if (this.promoData.promoAddCreateForm.invalid) {
      return false;
    } else {
      console.log(this.promoData.promoAddCreateForm);
      console.log(this.selectedVendor);
      this.isLoading = true;
      this.promoData.promoAddCreateForm.get('testDate').setValue(this.datePipe.transform(this.pilotDate.value, 'yyyy/MM/dd'));
      this.promoData.promoAddCreateForm.get('vendor').setValue(this.vendor.value);

      status == 'DRAFT' ?
        this.promoData.promoAddCreateForm.value.inputSheetStatus.inputSheetStatus = 'DRAFT' :
        this.promoData.promoAddCreateForm.value.inputSheetStatus.inputSheetStatus = 'SUBMITTED';
      //console.log(this.promoData.promoAddCreateForm.value);
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}`, 'PUT', this.promoData.promoAddCreateForm.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (result: any) => {
            //console.log(result);
            this.isLoading = false;
            //console.log(status);
            if (status == 'DRAFT') {
              // this.loadSection1(result.inputSheetId);
              this.getSection1(result.inputSheetId);
              this.toastorService.success('Generated Successfully');
            } else {
              this.toastorService.success('Submitted Successfully');
              this.router.navigate(['../promo'], { relativeTo: this.route });
            }
            // //  //console.log(result);
            //  this.getSection1(result);
            // this.toastorService.success('Submitted Successfully');
          },
          err => {
            this.isLoading = false;
            this.toastorService.warn('Oops!!! The Server was unable to complete your request');
          });
    }

  }

  openconfirmModal(type: any, id: any): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, { panelClass: 'confirmModal', disableClose: true, data: { msg: (type === 'cancel') ? 'cancel the changes' : (type == 'Submit') ? 'submit? All the unsaved changes will be lost' : '' } });
    dialogRef.afterClosed().subscribe(dialogResult => {
      //console.log(dialogResult);
      if (dialogResult === 'edit') {
        this.router.navigate(['../promo'], { relativeTo: this.route });
      }
      if (dialogResult === 'Submit') {
        this.promoGenerate('Submit');
      }
      // else {
      //   //console.log(this.clearMessage)
      // }
    });
  }
  onSelectedVendor(e){
    this.selectedVendor=e.value;
  
  }

  onSelectedGenre(e, index) {
    let selectedGenre = e;
    //console.log(selectedGenre);
    //console.log(index);
    this.commonService.getRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/showtitles/?genretype=${selectedGenre}`,
      'GET')
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        //console.log(data);
        this.showTitleList[index] = data;
      }, error => this.toastorService.warn(error.message)
      );
  }

  promoaddRow() {
    //console.log('Add Promo Row');
    //console.log(this.promoData.promoaddData)
    this.showTitleEmpty = false;
    let rows = this.promoData.promoAddCreateForm.get('inputSheetPrintInfo') as FormArray;
    rows.push(this.createItem());
    this.promoData.promoaddData.push([
      { genreId: '', showTitleID: '', episode: '', noofprintads: '' }
    ]);
    this.dataSource = new MatTableDataSource(this.promoData.promoaddData);
    //console.log(rows.value);
    //console.log(this.promoData.promoAddCreateForm.value);
    //console.log('datasource', this.dataSource);
  }

  toggleAll(selection) {
    if (selection) {
      // this.openall = true;
      this.Accordion.multi = true;
      this.Accordion.openAll();
      this.toggle = false;
    } else {
      // this.openall = false;
      this.Accordion.closeAll();
      this.Accordion.multi = false;
      this.toggle = true;
    }
  }

  updatespotTitleVal(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.promoSpotPrintTitle = event.target.value;
  }

  updatelenVal(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.promoLength = event.target.value;
  }

  updateshowtimesVal(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.showNoTimes = event.target.value;
  }

  updateadTypeVal(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.adType = event.target.value;
  }

  updateshwTypeVal(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.programType = event.target.value;
  }
  updateprintQualVal(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.printQuality = event.target.value;
  }

  updateNotesVal(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.splNotes = event.target.value;
  }

  updateVal(ele, event) {
    // console.log(ele);
    ele.setValue(event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"'));
    // console.log(ele.value);
  }

  /** Section 4 - Add, Delete and Save method **/

  onAddstatstdRow() {
    let newRow = {
      rowid: null, posstatstd: (this.statGrpList.length + 1), statstd: '',
      metaDataFlag: true, upDateFlag: true
    };
    const row = this.fb.group({
      statstd: new FormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField
      ])
    });
    (this.section4Form.get('section4Array') as FormArray).push(row);
    this.statGrpList.push(newRow);
    this.dataSource4 = new MatTableDataSource(this.statGrpList);
    //console.log(this.statGrpList);
    this.dataSource4._updateChangeSubscription();
  }

  updatestatstd(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.statstd = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  statstdSave(formValue) {
    if (formValue.invalid) {
      //console.log('invalid');
      this.submittedForm4 = true;
      return false;
    } else {
      this.isLoading = true;
      //console.log(this.statGrpList);
      let statStdSave = [];
      //console.log(this.statGrpList);
      //console.log(statStdSave);

      statStdSave = this.statGrpList.map(item => {
        let createdBy;
        item.metaDataFlag == false ? createdBy = 'METADATA' : createdBy = null;
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.statstd,
          sequence: item.posstatstd,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 603,
            sequenceNumber: 3,
            sectionName: 'STATEMENT GRP_STANDARD',
            sectionGroup: ""
          },
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          sheetQuestion: {
            inputSheetQuestionsId: item.sheetQuestionId,
            questionLongText: item.statstd,
            questionSequenceNum: item.posstatstd
          },
          createdBy: createdBy,
          upDateFlag: item.upDateFlag
        }
      })
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', statStdSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.isLoading = false;
          this.loadStatementsGrpStd(result);
          //    this.getStatementsGrpStd();
          this.toastorService.success('Saved Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        });
    }

  }

  deleteStmtGrp(elm, index) {
    //console.log(elm);
    //console.log(this.statGrpList);
    this.statGrpList.splice(index, 1);
    (this.section4Form.get('section4Array') as FormArray).removeAt(index);
    //console.log(this.section4Form.value);
    this.dataSource4 = new MatTableDataSource(this.statGrpList);

    if (elm.rowid != null || '') {
      this.isLoading = true;
      // const delStmGrp = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      // }
      const delStmGrp = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delStmGrp)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.isLoading = false;
          //console.log(result);
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        })
    }
  }



  /** Section 5 - Add, Delete and Save method **/

  onAddStatgrpVarRow() {
    this.onAddsec5 = true;
    let newRow = {
      rowid: null, posstatvar: (this.statGrpVarList.length + 1), statgrpvar: '',
      metaDataFlag: true, upDateFlag: true
    };
    const row = this.fb.group({
      statgrpvar: new FormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField
      ])
    });
    (this.section5Form.get('section5Array') as FormArray).push(row);
    this.statGrpVarList.push(newRow);
    this.dataSource5 = new MatTableDataSource(this.statGrpVarList);
    //console.log(this.statGrpVarList);
    // this.dataSource5._updateChangeSubscription();
  }

  updateStatgrpVar(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.statgrpvar = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  statgrpVarSave(formValue) {
    if (formValue.invalid) {
      //console.log('invalid');
      this.submittedForm5 = true;
      return false;
    } else {
      this.isLoading = true;
      //console.log(this.statGrpVarList);
      let statgrpVarSave = [];
      //console.log(this.statGrpVarList);
      //console.log(statgrpVarSave);

      statgrpVarSave = this.statGrpVarList.map(item => {
        let createdBy;
        item.metaDataFlag == false ? createdBy = 'METADATA' : createdBy = null;
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.statgrpvar,
          sequence: item.posstatvar,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 604,
            sequenceNumber: 4,
            sectionName: 'STATEMENT GRP_VARIABLE',
            sectionGroup: ""
          },
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          sheetQuestion: {
            inputSheetQuestionsId: item.sheetQuestionId,
            questionLongText: item.statgrpvar,
            questionSequenceNum: item.posstatvar
          },
          createdBy: createdBy,
          upDateFlag: item.upDateFlag
        }
      })
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', statgrpVarSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.isLoading = false;
          this.loadStatementsGrpVar(result);
          //   this.getStatementsGrpVar();
          this.toastorService.success('Saved Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        });
    }
  }

  deleteStatgrpvar(elm, index) {
    //console.log(elm);
    //console.log(this.statGrpVarList);
    this.statGrpVarList.splice(index, 1);
    (this.section5Form.get('section5Array') as FormArray).removeAt(index);
    //console.log(this.statGrpVarList)
    this.dataSource5 = new MatTableDataSource(this.statGrpVarList);

    if (elm.rowid != null || '') {
      this.isLoading = true;
      // const delStmVar = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      // }
      const delStmVar = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delStmVar)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.isLoading = false;
          //console.log(result);
          //this.getStatementsGroupVar();
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        })
    }
  }




  /** Section 6 - Add, Delete and Save method **/

  onaddeleGrpStdRow() {
    this.onAddsec6 = true;
    let newRow = {
      rowid: null, poselestd: (this.elegrpstdList.length + 1), elegrpStd: '',
      metaDataFlag: true, upDateFlag: true
    };
    const row = this.fb.group({
      elegrpStd: new FormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField
      ])
    });
    (this.section6Form.get('section6Array') as FormArray).push(row);
    this.elegrpstdList.push(newRow);
    this.dataSource6 = new MatTableDataSource(this.elegrpstdList);
    //console.log(this.elegrpstdList);
    this.dataSource6._updateChangeSubscription();
  }

  updateeleGrpstd(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.elegrpStd = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  eleStdSave(formValue) {
    if (formValue.invalid) {
      //console.log('invalid');
      this.submittedForm6 = true;
      return false;
    } else {
      this.isLoading = true;
      //console.log(this.elegrpstdList);
      let eleStdSave = [];
      //console.log(this.elegrpstdList);
      //console.log(eleStdSave);

      eleStdSave = this.elegrpstdList.map(item => {
        let createdBy;
        item.metaDataFlag == false ? createdBy = 'METADATA' : createdBy = null;
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.elegrpStd,
          sequence: item.poselestd,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 605,
            sequenceNumber: 5,
            sectionName: 'ELEMENT GRP_STANDARD',
            sectionGroup: ""
          },
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          sheetQuestion: {
            inputSheetQuestionsId: item.sheetQuestionId,
            questionLongText: item.elegrpStd,
            questionSequenceNum: item.poselestd
          },
          createdBy: createdBy,
          upDateFlag: item.upDateFlag
        }
      })
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', eleStdSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.isLoading = false;
          this.loadElementsGrpStd(result);
          // this.getElementsGrpStd();
          this.toastorService.success('Saved Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        });
    }
  }

  deleteeleGrpstd(elm, index) {
    //console.log(elm);
    //console.log(this.elegrpstdList);
    this.elegrpstdList.splice(index, 1);
    (this.section6Form.get('section6Array') as FormArray).removeAt(index);
    this.dataSource6 = new MatTableDataSource(this.elegrpstdList);

    if (elm.rowid != null || '') {
      this.isLoading = true;
      // const delEleGrp = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      // }
      const delEleGrp = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delEleGrp)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.isLoading = false;
          //console.log(result);
          //this.getElementsGrpStd();
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        })
    }
  }


  /** Section 7 - Add, Delete and Save method **/

  onAddEleGrpVarRow() {
    this.onAddsec7 = true;
    let newRow = {
      rowid: null, poselegrpVar: (this.elegrpvarList.length + 1), elegrpVar: '',
      metaDataFlag: true, upDateFlag: true
    };
    const row = this.fb.group({
      elegrpVar: new FormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField
      ])
    });
    (this.section7Form.get('section7Array') as FormArray).push(row);
    this.elegrpvarList.push(newRow);
    this.dataSource7 = new MatTableDataSource(this.elegrpvarList);
    //console.log(this.elegrpvarList);
    this.dataSource7._updateChangeSubscription();
  }

  updateElegrpVar(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.elegrpVar = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  EleGrpVarSave(formValue) {
    if (formValue.invalid) {
      //console.log('invalid', formValue);
      this.submittedForm7 = true;
      return false;
    } else {
      this.isLoading = true;
      //console.log(this.elegrpvarList);
      let eleVarSave = [];
      //console.log(this.elegrpvarList);
      //console.log(eleVarSave);

      eleVarSave = this.elegrpvarList.map(item => {
        let createdBy;
        item.metaDataFlag == false ? createdBy = 'METADATA' : createdBy = null;
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.elegrpVar,
          sequence: item.poselegrpVar,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 606,
            sequenceNumber: 6,
            sectionName: 'ELEMENT GRP_VARIABLE',
            sectionGroup: ""
          },
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          sheetQuestion: {
            inputSheetQuestionsId: item.sheetQuestionId,
            questionLongText: item.elegrpVar,
            questionSequenceNum: item.poselegrpVar
          },
          createdBy: createdBy,
          upDateFlag: item.upDateFlag
        }
      })
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', eleVarSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.isLoading = false;
          this.loadElementsGrpVar(result);
          // this.getElementsGrpStd();
          this.toastorService.success('Saved Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        });
    }
  }

  deleteEleGrpvar(elm, index) {
    //console.log(elm);
    //console.log(this.elegrpvarList);
    this.elegrpvarList.splice(index, 1);
    (this.section7Form.get('section7Array') as FormArray).removeAt(index);
    this.dataSource7 = new MatTableDataSource(this.elegrpvarList);

    if (elm.rowid != null || '') {
      this.isLoading = true;
      // const delEleVar = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      // }
      const delEleVar = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delEleVar)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.isLoading = false;
          //console.log(result);
          // this.getElementsGrpVar();
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        })
    }
  }


  /** Section 8 - Add, Delete and Save method **/

  onAddsrawareRow() {
    this.onAddsec8 = true;
    let newRow = {
      rowid: null, possraware: (this.srAwarenessList.length + 1), srawareness: null,
      upDateFlag: true
    };
    const row = this.fb.group({
      srawareness: new FormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField
      ])
    });
    (this.section8Form.get('section8Array') as FormArray).push(row);
    this.srAwarenessList.push(newRow);
    this.dataSource8 = new MatTableDataSource(this.srAwarenessList);
    //console.log(this.srAwarenessList);
    // this.dataSource8._updateChangeSubscription();
  }

  deletesraware(elm, index) {
    //console.log(elm);
    //console.log(this.srAwarenessList);
    this.srAwarenessList.splice(index, 1);
    (this.section8Form.get('section8Array') as FormArray).removeAt(index);
    this.dataSource8 = new MatTableDataSource(this.srAwarenessList);

    if (elm.rowid != null || '') {
      this.isLoading = true;
      // const delSrAware = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      // }
      const delSrAware = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delSrAware)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.isLoading = false;
          //console.log(result);
          // this.getQuestionStim();
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        })
    }
  }

  updatesrAware(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.srawareness = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
  }

  srawareSave(formValue) {
    if (formValue.invalid) {
      //console.log('invalid');
      this.submittedForm8 = true;
      return false;
    } else {
      this.isLoading = true;
      //console.log(this.srAwarenessList);
      //console.log(this.srAwarenessList)
      let srawarenessSave = [];
      //console.log(this.srAwarenessList);

      srawarenessSave = this.srAwarenessList.map(item => {
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.srawareness,
          sequence: item.possraware,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 628,
            sequenceNumber: 8,
            sectionName: 'SR AWARENESS',
            sectionGroup: ""
          },
          sheetQuestion: null,
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          upDateFlag: item.upDateFlag
        }

      })
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', srawarenessSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.isLoading = false;
          this.loadSrAware(result);
          // this.getSrAwareness();
          this.toastorService.success('Saved Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        });
    }
  }


  /** Section 9 - Add, Delete and Save method **/

  onAddQuesRow() {
    this.onAddsec9 = true;
    let newRow = {
      rowid: null, posques: (this.questionstimList.length + 1), quesnstim: null,
      upDateFlag: true
    };
    const row = this.fb.group({
      quesnstim: new FormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"‘“’”?+-=]{1,255})$/)),
        validateEmptyField
      ])
    });
    (this.section9Form.get('section9Array') as FormArray).push(row);
    this.questionstimList.push(newRow);
    this.dataSource9 = new MatTableDataSource(this.questionstimList);
    //console.log(this.questionstimList);
    this.dataSource9._updateChangeSubscription();
  }

  deleteQues(elm, index) {
    //console.log(elm);
    //console.log(this.questionstimList);
    this.questionstimList.splice(index, 1);
    (this.section9Form.get('section9Array') as FormArray).removeAt(index);
    this.dataSource9 = new MatTableDataSource(this.questionstimList);

    if (elm.rowid != null || '') {
      this.isLoading = true;
      // const delQues = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      // }
      const delQues = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delQues)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.isLoading = false;
          //console.log(result);
          //  this.getQuestionStim();
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        })
    }
  }

  updateQues(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.quesnstim = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
  }

  quesSave(formValue) {
    if (formValue.invalid) {
      //console.log('invalid');
      this.submittedForm9 = true;
      return false;
    } else {
      this.isLoading = true;
      //console.log(this.questionstimList);
      //console.log(this.questionstimList)
      let quessSave = [];
      //console.log(this.questionstimList);

      quessSave = this.questionstimList.map(item => {
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.quesnstim,
          sequence: item.posques,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 629,
            sequenceNumber: 9,
            sectionName: 'QUESTION AND STIM CHANGES',
            sectionGroup: ""
          },
          sheetQuestion: null,
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          upDateFlag: item.upDateFlag
        }

      })
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', quessSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.isLoading = false;
          this.loadQuesStim(result);
          // this.getQuestionStim();
          this.toastorService.success('Saved Successfully');
        }, error => {
          this.isLoading = false;
          this.toastorService.warn(error.message);
        });
    }
  }
  public hasErrorsection4Form = (index, errorName: string) => {
    return ((this.section4Form.controls.section4Array as FormArray).controls[index] as FormGroup).controls.statstd.hasError(errorName);
  }
  public hasErrorsection5Form = (index, errorName: string) => {
    return ((this.section5Form.controls.section5Array as FormArray).controls[index] as FormGroup).controls.statgrpvar.hasError(errorName);
  }
  public hasErrorsection6Form = (index, errorName: string) => {
    return ((this.section6Form.controls.section6Array as FormArray).controls[index] as FormGroup).controls.elegrpStd.hasError(errorName);
  }
  public hasErrorsection7Form = (index, errorName: string) => {
    return ((this.section7Form.controls.section7Array as FormArray).controls[index] as FormGroup).controls.elegrpVar.hasError(errorName);
  }
  public hasErrorsection8Form = (index, errorName: string) => {
    return ((this.section8Form.controls.section8Array as FormArray).controls[index] as FormGroup).controls.srawareness.hasError(errorName);
  }
  public hasErrorsection9Form = (index, errorName: string) => {
    return ((this.section9Form.controls.section9Array as FormArray).controls[index] as FormGroup).controls.quesnstim.hasError(errorName);
  }
  spotdetailControls() {
    return (this.formSpot.get('adTitle') as FormArray).controls;
  }
  addAttributesControls() {
    return (this.formAddAttr.get('inputSheetSection') as FormArray).controls;
  }
  statementsIndivControls() {
    return (this.formStatIndiv.get('inputSheetSection') as FormArray).controls;
  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.destroy$.next(true);
  }

}
