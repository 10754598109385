import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { SatDatepicker } from 'saturn-datepicker';
import { FormControl } from '@angular/forms';
import { ChoicedashboardDatatableService } from 'src/app/services/choicedashboard-datatable.service';
import { QuestiondashboardDatatableService } from 'src/app/services/questiondashboard-datatable.service';

@Component({
  selector: 'app-pilot-datepicker',
  template: `
  <mat-form-field appearance="outline">
  <input matInput
    placeholder="Select Date"
    [satDatepicker]="picker" 
    (dateChange)="emitDates()" readonly 
    [formControl]="date"
    >
  <sat-datepicker #picker [rangeMode]="true">
  </sat-datepicker>
  <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
</mat-form-field>
  `,
  styles: []
})
export class PilotDatepickerComponent implements OnInit {

  @ViewChild('picker', { static: true }) dateInput: SatDatepicker<any>;
  @Output() emitRange: EventEmitter<any> = new EventEmitter<any>();
  now: Date = new Date();
  date = new FormControl('');
  delay;

  constructor(
    private questionservice: QuestiondashboardDatatableService,
    private choiceservice: ChoicedashboardDatatableService) { }

  ngOnInit() {
    this.questionservice.clearDate
      .subscribe(data =>
        this.date.setValue(''));

    this.choiceservice.clearDate
      .subscribe(data =>
        this.date.setValue(''));
  }

  emitDates() {
    const start = this.dateInput.beginDate;
    const end = this.dateInput.endDate;
    const daterange = { 'start': start, 'end': end };

    this.emitRange.emit(daterange);
  }

}
