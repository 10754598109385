import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, tap, map, retry } from 'rxjs/operators';
import { List } from '../models/list.model';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class ChoiceService {
  appConstants = AppConfig.settings;
  public apiUrl = this.appConstants.serverUrl;
  tooltip = new EventEmitter;
  private subject = new Subject<any>();
  httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json')
  };

  constructor(private http: HttpClient, private appconfig: AppConfig) { }

  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse instanceof ErrorEvent) {
      console.error('Client side error', errorResponse.error.message);
    } else {
      console.error('Server side error', errorResponse);
    }
    console.error('error', errorResponse);

    return throwError(errorResponse);
  }


  getSharedChoice(url){
    // const url="assets/json/shared.json";
    // const url = `${this.apiUrl}/sharedlist/choices`
    return this.http.get(url)
      .pipe(
        (map(res => res),
          catchError(this.handleError)
        ));
  }


}
