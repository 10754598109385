import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listFilter'
})
export class ListFilterPipe implements PipeTransform {

  transform(list: any[], filterText: string): any {
    if(filterText){
     // list =  list ? list.filter(item => item.value.search(new RegExp(filterText, 'i')) > -1) : [];
      list =  list ? list.filter(item => item.value.toLowerCase().indexOf(filterText.toLowerCase()) > -1) : [];
    } 
    return list;
  }
}


