import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicatingComponentService {

  private subject = new Subject<any>();

  sendCommunication(message: boolean) {
      this.subject.next({ condition: message });
  }

  clearCommunication() {
      this.subject.next();
  }

  getCommunication(): Observable<any> {
      return this.subject.asObservable();
  }
}
