import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-add-choice',
  templateUrl: './confirm-modal.html'
})
export class CancelModalComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<CancelModalComponent>) { }

  ngOnInit() {
  }

  onConfirm(): void{
    this.dialogRef.close(true);
  }
}
