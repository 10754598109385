import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';



@Component({
  selector: 'app-surveylayout',
  templateUrl: './surveylayout.component.html',
  styleUrls: ['./surveylayout.component.scss']
})
export class SurveylayoutComponent implements OnInit {
  constructor(
    private commonService:CommonService
  ) { 

  }

  ngOnInit() {

  }

}
