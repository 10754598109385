import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray, NgForm } from '@angular/forms';
import { Observable, from, Subscription, Subject, interval } from 'rxjs';
import { map, startWith, tap, debounceTime, switchMap, finalize, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SelectionModel } from '@angular/cdk/collections';
import { Router, ActivatedRoute } from '@angular/router';
/* Component Section   */
import { CreateSurveyComponent } from '../create-survey/create-survey.component';
import { SurveyaddchoiceComponent } from '../surveyaddchoice/surveyaddchoice.component';
import { SurveyaddquestionComponent } from '../surveyaddquestion/surveyaddquestion.component';
import { ConfirmdialogComponent, ConfirmDialogModel } from 'src/app/shared/components/confirmdialog/confirmdialog.component';
/*  Service Section  */

import { NotificationService } from 'src/app/services/notification.service';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { CommunicatingComponentService } from 'src/app/modules/survey/service/communicating-component.service';

/*  Interface Section  */
import { Surveycreate, SurveyCreateInputSheet, SurveyCreateTemplate } from 'src/app/models/surveycreate';
import { HttpParams } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material';


@Component({
  selector: 'app-survey-step',
  templateUrl: './survey-step.component.html',
  styleUrls: ['./survey-step.component.scss']
})
export class SurveyStepComponent implements OnInit, OnDestroy {
  appConstants = AppConfig.settings;
  surveyDec: Surveycreate;
  subscription: Subscription;
  subscriptionData: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  surveyCode: any;
  intervalCount=0;
  count=0;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private awscommonservice: AwsCommonService,
    private notification: NotificationService,
    private communicationservice: CommunicatingComponentService
  ) {

    this.surveyDec = {
      SelectedInputSheet: '',
      SelectedTemplate: '',
      StatementList: '',
      isLinear: false,
      dialogRef: '',
      submitCreateSurvey: FormGroup,
      secondFormGroup: FormGroup,
      summaryFormGroup: FormGroup,
      SelectedInputSheetValue: '',
      SelectedTemplateValue: '',
      selectedInputSheetId: '',
      SelectedTemplateId: '',
      inputTemplateData: [],
      inputSheetData: [],
      isEdit: false,
      selectedAll: true,
      tselectedAll: true,
      totalSelected: '',
      checkedInputquestion: '',
      checkedTemplateQuestion: '',
      dragdropData: [],
      summarChoiceLocalURL: 'assets/json/summarySurveyQuestionChoiceList.json',
      dataSuveyQuestionChoiceData: [],
      qChoiceDataReorder: [],
      choiceColumns: ['choiceLibraryDesc', 'choicePunchCode'],
      dataSource: [],
      summaryquestionEdit: false,
      communicationData: false,
      summaryQuestionName: new FormControl(''),
      summaryQuestionChoicePunchCode: new FormControl(''),

      isLoading: true,
      summaryData: [],
      displayedColumnsInputSheet: ['questionText', 'includeAllFlag'],
      rowsInputSheet: [],
      rowsTemplate: [],
      surveyId: false,
      notSaved: false,
      notSubmitted: false,
      selectionTemplate: ''

    };
    this.surveyDec.dragdropData = [];
    this.surveyDec.SelectedInputSheet = this.appConstants.surveysteps.selectedInputSheet;
    this.surveyDec.SelectedTemplate = this.appConstants.surveysteps.selectedTemplate;
    this.surveyDec.StatementList = this.appConstants.surveysteps.statementList;
    // this.openCreateSurvey();
    this.surveyDec.firstFormGroup = this.fb.group({
      inputSheetId: [this.surveyDec.selectedInputSheetId, Validators.required],
      templateId: [this.surveyDec.SelectedTemplateId, Validators.required],
      surveyDescription:[this.surveyDec.surveyDescription,Validators.required],
      status: 'Draft',
      sharedListQustionids: this.fb.array([]),
      inputSheetQuestions: this.fb.array([]),

      // sharedListQustionids: this.fb.array([this.createItem()])
    });
  }

  ngOnInit() {
    this.surveyDec.secondFormGroup = this.fb.group({
      secondCtrl: ['', Validators.required]
    });
    this.surveyDec.summaryFormGroup = this.fb.group({
      summaryCtrl: ['', Validators.required]
    });
    this.surveyDec.rowsInputSheet = this.surveyDec.firstFormGroup.get('inputSheetQuestions') as FormArray;
    this.surveyDec.rowsTemplate = this.surveyDec.firstFormGroup.get('sharedListQustionids') as FormArray;
    if (this.communicationservice.storage) {

      this.getData(this.communicationservice.storage);
    } else {
      this.router.navigate(['../dashboard'], { relativeTo: this.route });
    }
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }


  isAllSelected() {
    const numSelected = this.surveyDec.selection.selected.length;
    const numRows = this.surveyDec.dataSourceInputSheet.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    if (this.isAllSelected()) {
      this.surveyDec.selection.clear();
      this.surveyDec.dataSourceInputSheet.data.forEach((row, iptindex) => {
        row.select = false;
        this.surveyDec.rowsInputSheet.controls[iptindex].controls.includeAllFlag.setValue('N');
      }
      );
    } else {
      this.surveyDec.dataSourceInputSheet.data.forEach((row, iptindex) => {
        this.surveyDec.selection.select(row);
        row.select = true;
        this.surveyDec.rowsInputSheet.controls[iptindex].controls.includeAllFlag.setValue('Y');
      }
      );
    }
  }

  checkboxLabel(row?: SurveyCreateInputSheet): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.surveyDec.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.select + 1}`;
  }

  isCheckSelected(event, row, cindex, surveyId?: any) {
    if (surveyId) {
      this.surveyDec.selection.toggle(row);
      if (event.checked) {
        this.surveyDec.rowsInputSheet.controls[cindex].controls.includeAllFlag.setValue('Y');
        row.select = true;
      } else {
        this.surveyDec.rowsInputSheet.controls[cindex].controls.includeAllFlag.setValue('N');
        row.select = false;
      }
      this.surveyDec.isLoading = true;
      this.communicationservice.sendCommunication(false);
      this.surveyDec.summaryquestionEdit = false;
      this.subscription = this.communicationservice.getCommunication()
        .subscribe(message => { this.surveyDec.communicationData = message.condition; }
        );
      const updateIncludeFlag = [{
        c2311SurveyQuestionId: row.sharedListQuestionId,
        c2311ScoreCode: this.surveyDec.rowsInputSheet.controls[cindex].controls.includeAllFlag.value
      }];
      this.awscommonservice.putRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/surveyQuestions/includeFlag`,
        'PUT', updateIncludeFlag)
        .subscribe(
          (resultsaveSummaryQuestion) => {
            this.surveyDec.isLoading = false;
            this.fetchSummaryData(surveyId);
            this.notification.success('update include flag Successfully..!');
          },
          err => {
            this.notification.warn('Oops!!! The Server was unable to complete your request');
          },
          () => {
            this.surveyDec.isLoading = false;
          }
        );
    } else {
      this.surveyDec.selection.toggle(row);
      if (event.checked) {
        this.surveyDec.rowsInputSheet.controls[cindex].controls.includeAllFlag.setValue('Y');
        row.select = true;
      } else {
        this.surveyDec.rowsInputSheet.controls[cindex].controls.includeAllFlag.setValue('N');
        row.select = false;
      }
    }
  }

  isAllSelectedTemplate() {
    const numSelected = this.surveyDec.selectionTemplate.selected.length;
    const numRows = this.surveyDec.dataSourceTempalte.data.length;
    return numSelected === numRows;
  }
  masterToggleTemplate() {
    if (this.isAllSelectedTemplate()) {
      this.surveyDec.selectionTemplate.clear();
      this.surveyDec.dataSourceTempalte.data.forEach(
        (row, tmpInput) => {
          row.select = false;
          this.surveyDec.rowsTemplate.controls[tmpInput].controls.includeAllFlag.setValue('N');
        }
      );
    } else {
      this.surveyDec.dataSourceTempalte.data.forEach((row, tmpInput) => {
        this.surveyDec.selectionTemplate.select(row);
        row.select = true;
        this.surveyDec.rowsTemplate.controls[tmpInput].controls.includeAllFlag.setValue('Y');
      }
      );
    }
  }

  checkboxLabelTemplate(row?: SurveyCreateInputSheet): string {
    if (!row) {
      return `${this.isAllSelectedTemplate() ? 'select' : 'deselect'} all`;
    }
    return `${this.surveyDec.selectionTemplate.isSelected(row) ? 'deselect' : 'select'} row ${row.select + 1}`;
  }

  isCheckSelectedTemplate(event, row, cindex, surveyId?: any) {
    if (surveyId) {
      this.surveyDec.selectionTemplate.toggle(row);
      if (event.checked) {
        this.surveyDec.rowsTemplate.controls[cindex].controls.includeAllFlag.setValue('Y');
        row.select = true;
      } else {
        this.surveyDec.rowsTemplate.controls[cindex].controls.includeAllFlag.setValue('N');
        row.select = false;
      }
      this.surveyDec.isLoading = true;
      this.communicationservice.sendCommunication(false);
      this.surveyDec.summaryquestionEdit = false;
      this.subscription = this.communicationservice.getCommunication()
        .subscribe(message => { this.surveyDec.communicationData = message.condition; }
        );

      const updateIncludeFlag = [{
        c2311SurveyQuestionId: row.sharedListQuestionId,
        c2311ScoreCode: this.surveyDec.rowsTemplate.controls[cindex].controls.includeAllFlag.value
      }];

      this.awscommonservice.putRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/surveyQuestions/includeFlag`,
        'PUT', updateIncludeFlag)
        .subscribe(
          (resultsaveSummaryQuestion) => {

            this.surveyDec.isLoading = false;
            this.fetchSummaryData(surveyId);
            this.notification.success('update include flag Successfully..!');
          },
          err => {
            this.notification.warn('Oops!!! The Server was unable to complete your request');
          },
          () => {
            this.surveyDec.isLoading = false;
          }
        );
    } else {
      this.surveyDec.selectionTemplate.toggle(row);
      if (event.checked) {
        this.surveyDec.rowsTemplate.controls[cindex].controls.includeAllFlag.setValue('Y');
        row.select = true;
      } else {
        this.surveyDec.rowsTemplate.controls[cindex].controls.includeAllFlag.setValue('N');
        row.select = false;
      }
    }
  }

  createItem(): FormGroup {
    return this.fb.group({
      includeAllFlag: [''],
      sharedListQuestionId: [''],
      questionText: [''],
      inputSheetId: [this.surveyDec.selectedInputSheetId],
      templateId: [this.surveyDec.SelectedTemplateId],
    });
  }

  openCreateSurvey(): void {
    this.surveyDec.dialogRef = this.dialog.open((CreateSurveyComponent), { panelClass: 'newQuestion', disableClose: true });
    this.surveyDec.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData(result);
      } else {
        this.router.navigate(['../dashboard'], { relativeTo: this.route });
      }
    });
  }
  getData(params: any) {
    this.communicationservice.sendCommunication(false);
    this.subscription = this.communicationservice.getCommunication()
      .subscribe(message => { this.surveyDec.communicationData = message; }
      );
    this.surveyDec.SelectedInputSheetValue = params.inputSheetName;
    this.surveyDec.SelectedTemplateValue = params.templateDescription;
    this.surveyDec.selectedInputSheetId = params.inputSheetId;
    this.surveyDec.SelectedTemplateId = params.surveyTemplateId;
    this.surveyDec.surveyDescription=params.surveyDescription;
    this.surveyDec.firstFormGroup.patchValue({
      inputSheetId: this.surveyDec.selectedInputSheetId,
      templateId: this.surveyDec.SelectedTemplateId,
      surveyDescription:this.surveyDec.surveyDescription
    });
    /* InputSheet API  */
    // const optionsInputSheet = { inputSheetId: this.surveyDec.selectedInputSheetId };
    // this.surveyDec.isLoading = true;
    // this.awscommonservice.getApiRequest(
    //   `${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/${this.appConstants.surveyCreatestepperInputSheet}`,
    //   'GET', optionsInputSheet)
    //   // this.appSingleService.getRequest(`${this.appConstants.stepperInputSheetLocalURL}`)
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe(
    //     dataInputsheet => {
    //       dataInputsheet.map((item, index) => {
    //         if (typeof (item) !== 'undefined' && item != null) {
    //           this.surveyDec.inputSheetData.push({
    //             inputSheetQuestionsId: item.inputSheetQuestionsId,
    //             questionText: (item.questionLongText) ? item.questionLongText : 'NO DATA',
    //             includeAllFlag: 'Y',
    //             select: true
    //           });
    //           this.surveyDec.rowsInputSheet.push(this.fb.group({
    //             includeAllFlag: 'Y',
    //             inputSheetQuestionsId: item.inputSheetQuestionsId,
    //             questionText: (item.questionLongText) ? item.questionLongText : 'NO DATA'
    //           }));
    //           this.surveyDec.rowsInputSheet.patchValue(this.surveyDec.inputSheetData);
    //         }
    //       });
    //       this.surveyDec.dataSourceInputSheet = new MatTableDataSource<SurveyCreateInputSheet>(this.surveyDec.inputSheetData);
    //       this.surveyDec.selection = new SelectionModel<SurveyCreateInputSheet>(true, (this.surveyDec.inputSheetData));
    //     },
    //     error => {
    //       this.surveyDec.isLoading = false;
    //     },
    //     () => {
    //       this.surveyDec.isLoading = true;

    //     }
    //   );

    /* Template API  */
    this.surveyDec.isLoading = true;
    const optionsTemplate = { surveyTemplateId: this.surveyDec.SelectedTemplateId };
    this.awscommonservice.getApiRequest(`
       ${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/${this.appConstants.surveyCreatestepperTemplate}`,
      'GET', optionsTemplate)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        dataTemplate => {
          dataTemplate.map((pitem, pindex) => {
            this.surveyDec.inputTemplateData.push({
              includeAllFlag: 'Y',
              sharedListQuestionId: pitem.sharedListQuestionId,
              questionText: (pitem.questionLongText) ? pitem.questionLongText : 'NO DATA',
              inputSheetId: this.surveyDec.selectedInputSheetId,
              templateId: this.surveyDec.SelectedTemplateId,
              surveyQuestionId: '',
              select: true,
              sharedQuestionChoiceId: pitem.sharedQuestionChoiceId
            });
            this.surveyDec.rowsTemplate.push(this.fb.group({
              includeAllFlag: 'Y',
              sharedListQuestionId: pitem.sharedListQuestionId,
              questionText: (pitem.questionLongText) ? pitem.questionLongText : 'NO DATA',
              sharedQuestionChoiceId: pitem.sharedQuestionChoiceId
            }));
            // this.surveyDec.rowsTemplate.patchValue(this.surveyDec.inputTemplateData);
          });
          this.surveyDec.dataSourceTempalte = new MatTableDataSource<SurveyCreateTemplate>(this.surveyDec.inputTemplateData);
          this.surveyDec.selectionTemplate = new SelectionModel<SurveyCreateTemplate>(true, (this.surveyDec.inputTemplateData));
        },
        err => {
          this.surveyDec.isLoading = false;
        },
        () => {
          this.surveyDec.isLoading = false;
        }
      );
    // this.fetchSummaryData('11332');
  }
  fetchSummaryData(surveyIdData: any) {


    this.surveyDec.dragdropData = [];
    this.surveyDec.isLoading = true;
    // const optionSurveyId = surveyId ?
    //   { params: new HttpParams().set('surveyId', surveyId) } : {};
    const optionSurveyId = { surveyId: surveyIdData };
    // tslint:disable-next-line: max-line-length
    this.awscommonservice.getApiRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/${this.appConstants.surveySummaryParams}`,
      'GET', optionSurveyId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        dataSummary => {
          this.surveyDec.summaryData = dataSummary;
          this.surveyDec.summaryData.map((pitem, pindex) => {
            if (typeof (pitem) !== 'undefined' && pitem != null) {
              this.surveyDec.dragdropData.push({
                c2311SurveyQuestionId: pitem.c2311SurveyQuestionId,
                c2311QuestionLongTxt: (pitem.c2311QuestionLongTxt) ? pitem.c2311QuestionLongTxt : 'NO DATA',
                c2311QuestionSeqNum: pitem.c2311QuestionSeqNum,
                summarySurveyId: surveyIdData
              });
            }
          });
        },
        err => {
          console.log(err);
          this.surveyDec.isLoading = false;
          this.notification.warn('Oops!!! The Server was unable to complete your request');
        },
        () => {
          this.surveyDec.isLoading = false;
        }
      );


  }

  openconfirmModal(): void {

    const message = `Are you sure you want to cancel the survey details`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);
    const dialogRef =
      this.dialog.open(ConfirmdialogComponent,
        {
          panelClass: 'confirmModal',
          disableClose: true,
          data: dialogData
        });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        this.router.navigate(['../dashboard'], { relativeTo: this.route });
      }
    });

  }

  drop(event: CdkDragDrop<string[]>, summarySurveyId) {
    if (event.currentIndex !== event.previousIndex) {
      const dragdropSummaryQuestion = this.surveyDec.dragdropData;
      moveItemInArray(this.surveyDec.dragdropData, event.previousIndex, event.currentIndex);
      this.surveyDec.isLoading = true;
      this.awscommonservice.putRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/dragNdrop`,
        'PUT', dragdropSummaryQuestion)
        .subscribe(
          (resultsaveSummaryQuestion) => {
            this.surveyDec.isLoading = false;
            this.communicationservice.sendCommunication(false);
            this.subscription = this.communicationservice.getCommunication()
              .subscribe(message => { this.surveyDec.communicationData = message; }
              );
            this.fetchSummaryData(this.surveyDec.submitCreateSurvey.c2301SurveyId);
            this.notification.success('Rearrange Survey Question Successfully..!');
          },
          err => {
            this.notification.warn('Oops!!! The Server was unable to complete your request');
          }
        );

    } else {
      this.notification.warn('Survey Summary same postion again you dropped..!');
    }
  }
  getMaxSurveyId(surveyId)
  {
    const optionSurveyId = { authorName: surveyId };
    console.log('calling maxid function');
    this.awscommonservice.getApiRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/getMaxSurveyId`,
    'GET',optionSurveyId)
    .subscribe(result=>{
     if(result){
        this.subscription.unsubscribe();
       const source = interval(1000);
               this.subscription=source.subscribe(val => this.afterSave(result));  
     }
    },
    err=>{
      console.log(err);
      this.surveyDec.isLoading = false;
      this.notification.warn('Oops!!! The Server was unable to complete your request');
      this.subscription.unsubscribe();
    },
    ()=>{
    //  this.surveyDec.isLoading = false;
    })

  }
  afterSave(surveyId){
    this.surveyDec.submitCreateSurvey = {
      c2301SurveyId: surveyId,
      surveyStatus: 'Submitted'
    };
    this.intervalCount++;
    this.surveyDec.isLoading = true;
    const optionSurveyId = { surveyId: surveyId };
    this.awscommonservice.getApiRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/isSurveyCreated`,
    'GET', optionSurveyId)
    .subscribe(result=>{
     if(result){
      this.surveyDec.isLoading = false;
      this.subscription.unsubscribe();
      this.notification.success('Saved Successfully..');
      this.surveyDec.notSubmitted = true;
      this.fetchSummaryData(surveyId);    
     }
     else{
      this.surveyDec.isLoading = true;
       if (this.intervalCount == 700)
       {
        this.subscription.unsubscribe();
        this.surveyDec.isLoading = false;
       }
     // this.router.navigate(['../dashboard'], { relativeTo: this.route });
     }
     
    },
    err=>{
      console.log(err);
      this.surveyDec.isLoading = false;
      this.notification.warn('Oops!!! The Server was unable to complete your request');
      this.subscription.unsubscribe();
    },
    ()=>{
    //  this.surveyDec.isLoading = false;
    })

  }

  saveSurvey() {

    if (!this.surveyDec.surveyId) {
      if (this.surveyDec.firstFormGroup.invalid) {
        return false;
      } else {
        console.log(this.surveyDec.firstFormGroup);
        this.surveyDec.isLoading = true;
        // if (this.appConstants.checkServerURL) {
        this.surveyDec.firstFormGroup.removeControl('inputSheetQuestions');
        // tslint:disable-next-line: max-line-length
        this.awscommonservice.postRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/save`,
          'POST', this.surveyDec.firstFormGroup.value)
      //  var x = this.awscommonservice.invokeLambda('prime-survey-lambda-sprin-LambdaPrimeSprintFunctio-1TUQF37LOG5IJ',this.surveyDec.firstFormGroup.value,`${this.appConstants.surveyDashboardParamsURL}/save`);
      //  console.log(x);
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (resultSave: any) => {
              console.log(resultSave);
              if (resultSave) {
                this.surveyCode = (resultSave.SurveyCode) ? resultSave.SurveyCode : 'No Data';
                this.surveyDec.submitCreateSurvey = {
                  c2301SurveyId: resultSave.SurveyId,
                  surveyStatus: 'Submitted'
                };
                this.surveyDec.surveyId = true;
                this.surveyDec.notSaved = true;
                this.surveyDec.notSubmitted=false;
               // this.afterSave(resultSave.SurveyId);
               
                // this.fetchSummaryData(resultSave.SurveyId);
                const source = interval(1000);
                this.subscription=source.subscribe(val => this.getMaxSurveyId(resultSave));  
                
              } else {
                this.notification.warn('survey Response data is null');
                this.router.navigate(['../dashboard'], { relativeTo: this.route });
              }
            },
            error => {
              console.log(error);
              this.surveyDec.isLoading = false;
              this.notification.warn('Oops!!! The Server was unable to complete your request');
              this.router.navigate(['../dashboard'], { relativeTo: this.route });
            }
          );
          
        // } else {
        //   // this.notificationService.warn('Please Check Config File & Server is InActive..!');
        // }
      }
    }

  }


  OpenExpansion(data: any, panelOpenState, event: Event): void {

    this.surveyDec.qChoiceDataReorder = [];
    this.surveyDec.dataSource = new MatTableDataSource<any>(this.surveyDec.qChoiceDataReorder);
    this.communicationservice.sendCommunication(false);
    if (panelOpenState._expanded) {
      this.surveyDec.isLoading = true;
      this.summaryFetchAllChoice(data);
    }
  }
  summaryquestionCopy(questiondata: number, questionLong: number, summarySurveyId: number) {
    this.communicationservice.sendCommunication(false);
    this.surveyDec.summaryquestionEdit = false;
    this.subscription = this.communicationservice.getCommunication()
      .subscribe(message => { this.surveyDec.communicationData = message.condition; }
      );
    const copySummaryQuestion = { c2311SurveyQuestionId: questiondata, c2311QuestionLongTxt: questionLong };
    this.surveyDec.isLoading = true;
    this.awscommonservice.postRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/questions`,
      'POST', copySummaryQuestion)
      .subscribe(
        (resultCopySummaryQuestion) => {

          this.surveyDec.isLoading = true;
          this.fetchSummaryData(summarySurveyId);
          this.notification.success('summary Question Copy Successfully..!');
        },
        err => {
          this.surveyDec.isLoading = false;
          this.notification.warn('Oops!!! The Server was unable to complete your request');
        }
      );

  }
  summaryquestionEdit(questiondata: any) {

    this.surveyDec.communicationData = questiondata;
    const qId = questiondata;
  }

  summaryChoicesPunchCode(questionChoiceData: any, surveyQuestion: any, e: any, summarySurveyId: any) {

    this.surveyDec.isLoading = true;
    // this.communicationservice.sendCommunication(false);
    // this.surveyDec.summaryquestionEdit = false;
    // this.subscription = this.communicationservice.getCommunication()
    //   .subscribe(message => { this.surveyDec.communicationData = message.condition; }
    //   );
    const summaryPunchCodeUpdate = {
      surveyQuestionId: surveyQuestion,
      choiceLibraryId: questionChoiceData.choiceLibraryId,
      questionChoiceId: questionChoiceData.questionChoiceId,
      choicePunchCode: e.target.value
    };
    this.awscommonservice.postRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/summary/choices`,
      'POST', summaryPunchCodeUpdate)
      .subscribe(
        (resultsaveSummaryQuestion) => {
          // this.surveyDec.isLoading = true;
          this.summaryFetchAllChoice(surveyQuestion);
          this.notification.success('PunchCode Updated Successfully..');
        },
        err => {
          this.surveyDec.isLoading = false;
          this.notification.warn('Oops!!! The Server was unable to complete your request');
        }
      );
  }
  summaryquestionSave(questiondata: number, questionLong: number, summarySurveyId: number) {

    this.surveyDec.isLoading = true;
    this.communicationservice.sendCommunication(false);
    this.surveyDec.summaryquestionEdit = false;
    this.subscription = this.communicationservice.getCommunication()
      .subscribe(message => { this.surveyDec.communicationData = message.condition; }
      );
    const saveSummaryQuestion = { c2311SurveyQuestionId: questiondata, c2311QuestionLongTxt: questionLong };
    this.awscommonservice.putRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/edit/summary`,
      'PUT', saveSummaryQuestion)
      .subscribe(
        (resultsaveSummaryQuestion) => {
          this.surveyDec.isLoading = true;
          this.fetchSummaryData(summarySurveyId);
          this.notification.success('Question Updated Successfully');
        },
        err => {
          this.notification.warn('Oops!!! The Server was unable to complete your request');
        },
        () => {
          this.surveyDec.isLoading = true;
        }
      );
  }
  summaryquestionDelete(surveyQuestId: any, summarySurveyId: any) {
    // const summaryQuectionId = surveyQuestionId ?
    //   { params: new HttpParams().set('surveyQuestionId', surveyQuestionId) } : {};
    const summaryQuectionId = { surveyQuestionId: surveyQuestId };
    // tslint:disable-next-line: max-line-length
    this.awscommonservice.deleteRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/question`,
      'DELETE', summaryQuectionId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        dataChoiceDelete => {
          this.surveyDec.isLoading = true;
          this.notification.success('Survey Summary Choice Deleted Successfully');
          this.fetchSummaryData(summarySurveyId);
        },
        err => {
          this.notification.warn('Oops!!! The Server was unable to complete your request');
        },
        () => {
          this.surveyDec.isLoading = true;
        }
      );

  }
  summaryQuestionChoiceDelete(questionChoiceData: any, surveyQuestionId) {
    this.surveyDec.isLoading = true;
    // const summaryQuectionChoiceID = questionChoiceData ?
    //   { params: new HttpParams().set('questionChoiceId', questionChoiceData) } : {};
    const summaryQuectionChoiceID = { questionChoiceId: questionChoiceData };
    // tslint:disable-next-line: max-line-length
    this.awscommonservice.deleteRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/choice`,
      'DELETE', summaryQuectionChoiceID)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        dataChoiceDelete => {
          this.notification.success('Survey Summary Question Delete Successfully');
          this.summaryFetchAllChoice(surveyQuestionId);
        },
        err => {
          console.log(err);
          this.notification.warn('Oops!!! The Server was unable to complete your request');
        },
        () => {
          this.surveyDec.isLoading = true;
        }
      );
  }
  summaryAddChoice(surveyQuestionId: number) {
    this.surveyDec.dialogRef =
      this.dialog.open((SurveyaddchoiceComponent), { panelClass: 'newQuestion', data: surveyQuestionId, disableClose: true });
    this.surveyDec.dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.notification.success('Survey Summary Choice Created Successfully');
        this.surveyDec.qChoiceDataReorder = [];
        this.surveyDec.dataSource = new MatTableDataSource<any>(this.surveyDec.qChoiceDataReorder);
        this.surveyDec.isLoading = true;
        this.summaryFetchAllChoice(surveyQuestionId);
      }
    },
      err => {
        this.surveyDec.isLoading = false;
        this.notification.warn('Oops!!! The Server was unable to complete your request');
      }
    );
  }

  summaryAddQuestion(surveyQuestionId: number) {
    const surQuestion = surveyQuestionId;
    this.surveyDec.dialogRef =
      this.dialog.open((SurveyaddquestionComponent), { panelClass: 'newQuestion', data: surQuestion, disableClose: true });
    this.surveyDec.dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.notification.success('Survey Summary Question Created Successfully');
        this.surveyDec.qChoiceDataReorder = [];
        this.surveyDec.isLoading = true;
        this.fetchSummaryData(surQuestion);
      }
    },
      err => {
        this.surveyDec.isLoading = false;
        this.notification.warn('Oops!!! The Server was unable to complete your request');
      }
    );
  }

  summaryFetchAllChoice(surveyQuestionID) {
    this.surveyDec.qChoiceDataReorder = [];
    this.surveyDec.isLoading = true;
    this.surveyDec.dataSource = new MatTableDataSource<any>(this.surveyDec.qChoiceDataReorder);
    // const summaryQuectionChoice = surveyQuestionId ?
    //   { params: new HttpParams().set('surveyQuestionId', surveyQuestionId) } : {};
    const summaryQuectionChoice = surveyQuestionID ? { surveyQuestionId: surveyQuestionID } : {};
    // tslint:disable-next-line: max-line-length
    this.awscommonservice.getApiRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/${this.appConstants.surveySummaryFetchQuestionChoices}`,
      'GET', summaryQuectionChoice)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        dataSuveyQuestion => {
          this.surveyDec.isLoading = true;
          this.surveyDec.dataSuveyQuestionChoiceData = dataSuveyQuestion;
          this.surveyDec.dataSuveyQuestionChoiceData = this.surveyDec.dataSuveyQuestionChoiceData;
          this.surveyDec.dataSuveyQuestionChoiceData.map((item, index) => {
            if (typeof (item.choiceLibrary) !== 'undefined' && item.choiceLibrary != null) {
              this.surveyDec.qChoiceDataReorder.push({
                choiceLibraryDesc: item.choiceLibrary.choiceLibraryDesc,
                choiceLibraryId: item.choiceLibrary.choiceLibraryId,
                choicePunchCode: item.choicePunchCode,
                choiceSeqNum: item.choiceSeqNum,
                questionChoiceId: item.questionChoiceId
              });
            }
          });
          this.surveyDec.dataSource = new MatTableDataSource<any>(this.surveyDec.qChoiceDataReorder);
        },
        err => {
          this.surveyDec.isLoading = false;
          this.notification.warn('Oops!!! The Server was unable to complete your request');
        },
        () => {
          this.surveyDec.isLoading = false;
        }
      );
  }
  submitcreateSurvey() {
    this.surveyDec.isLoading = true;
    this.awscommonservice.putRequest(`${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/status`,
      'PUT', this.surveyDec.submitCreateSurvey)
      .subscribe(
        (resultsaveSummaryQuestion) => {
          this.notification.success('Submitted Successfully..!');
          this.router.navigate(['../dashboard'], { relativeTo: this.route });
        },
        error => {
          console.log(error);
          this.notification.warn('Oops!!! The Server was unable to complete your request');
        },
        () => {
          this.surveyDec.isLoading = false;
        }
      );
  }
}
