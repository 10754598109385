import { Injectable, EventEmitter } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { AppConfig } from '../app.config';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpErrorHandlerService } from '../shared/services/httperrorhandler.service';
import { HandleError } from '../shared/services/httperrorhandler.service';
import { AwsSignonService } from './aws-signon.service';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AwsCommonService {

  public appConstants: any;
  public SERVER_URL: any;
  public handleError: HandleError;
  public httpOptions: any;
  public editPilotData: any;
  public editPrintData: any;
  public editPromoData: any;  
  public clearDate = new EventEmitter();

  constructor(
    private appconfig: AppConfig,
    private httpClient: HttpClient,
    private httpErrorHandler: HttpErrorHandlerService,
    private awsSignonService: AwsSignonService
  ) {

    this.appConstants = AppConfig.settings;
    this.handleError = httpErrorHandler.createHandleError('');
    this.httpOptions = {
      headers: new HttpHeaders().
        set('content-type', 'application/json').
        set('Accept', 'application/json').
        set('Access-Control-Allow-Origin', '*')
    };
  }

  /*Common Simple Get HTTP Method for all Modules*/
  public getRequest(url: any , method: any, options?): Observable<any> {
    // debugger;
    return this.awsSignonService.apiRequest(url, method, options).pipe(
      retry(3)
    )
  }
  public getApiRequest(url: any , method: any, params?,options?): Observable<any> {
    // debugger;
    return this.awsSignonService.apiGetRequest(url, method, params,options).pipe(
      retry(3)
    )
  }
  public postRequest(url: any , method: any, options ) {
    return this.awsSignonService.apiRequest(url, method,options).pipe(
      // retry(3)
    );
  }
  public deleteRequest(url: any , method: any, params, options? ) {
    return this.awsSignonService.apiGetRequest(url, method,params,options).pipe(
      retry(3)
    )
  }
  // public invokeLambda(functionName:string, body:string,path:string) {
  //   return this.awsSignonService.invokeLambda(functionName, body,path)
  // }
  public putRequest(url: any , method: any, options ) {
    return this.awsSignonService.apiRequest(url, method,options).pipe(
      // retry(3)
    )
  }
  public getRequestParam(url: any, params: any) {
    params = params;
    // Add safe, URL encoded search parameter if there is a search term
    const options = params ? params : {};
    return this.httpClient.get(url, this.httpOptions)
      .pipe(
        retry(3),
      );

  }
}
