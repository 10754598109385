import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SurveyCreateSummaryAddChoice, SurveyCreateSummaryChoiceObject } from 'src/app/models/surveycreate';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// import { AwsCommonSprint3Service } from 'src/app/services/aws-commonsprint3.service';

@Component({
  selector: 'app-surveyaddchoice',
  templateUrl: './surveyaddchoice.component.html',
  styleUrls: ['./surveyaddchoice.component.scss']
})
export class SurveyaddchoiceComponent implements OnInit, OnDestroy {
  appConstants = AppConfig.settings;
  choiceDec: SurveyCreateSummaryAddChoice;
  destroy$: Subject<boolean> = new Subject<boolean>();
  dialog: any;
  constructor(
    private createSurveyRef: MatDialogRef<SurveyaddchoiceComponent>,
    private formBuilder: FormBuilder,
    private awsCommonService: AwsCommonService,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: number,
  ) {

    this.choiceDec = {
      surveySummaryQuestionId: data,
      surveySummaryAllChoiceListURL: '',
      survetSummaryfetchChoiceList: [],
      survetSummaryFilterChoiceList: [],
      summaryAddChoiceForm: this.formBuilder.group({
        choiceLibraryId: new FormControl('', [Validators.required]),
        surveyQuestionId: new FormControl(data, [Validators.required])
      }),
      isLoading: true
    };
    if (this.appConstants.checkServerURL) {
      this.choiceDec.surveySummaryAllChoiceListURL =
        `${this.appConstants.serverUrl}/${this.appConstants.surveyURL}/${this.appConstants.surveySummaryAddChoiceFetchURL}`;
    } else {
      this.choiceDec.surveySummaryAllChoiceListURL =
        `${this.appConstants.surveySummaryAddChoiceFetchLocalURL}`;
    }

    const dataChoiceList: SurveyCreateSummaryChoiceObject[] = [];
    this.awsCommonService.getRequest(`${this.choiceDec.surveySummaryAllChoiceListURL}`, 'GET')
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        resultData => {

          this.choiceDec.survetSummaryfetchChoiceList = resultData;
          if (this.choiceDec.survetSummaryfetchChoiceList) {
            this.choiceDec.survetSummaryfetchChoiceList.map((item, index) => {
              // if (item.activeYn === 'Y') {
              dataChoiceList.push({
                surveychoiceLibraryDesc: item.choiceLibraryDesc,
                surveychoiceLibraryId: item.choiceLibraryId
              });
              // }
            });
            this.choiceDec.survetSummaryfetchChoiceList = dataChoiceList;
            this.choiceDec.survetSummaryFilterChoiceList = dataChoiceList.slice();

          } else {
            //this.notificationService.warn('Empty Object..!');
          }
        },
        error => {
          this.choiceDec.isLoading = false;
          //this.notificationService.warn('Wait..!');
        },
        () => {
          this.choiceDec.isLoading = false;
        }
      );
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  surveySummaryAddChoice() {

    this.choiceDec.isLoading = true;
    if (this.choiceDec.summaryAddChoiceForm.invalid) {
      this.choiceDec.isLoading = false;
      return false;

    } else {
      if (this.appConstants.checkServerURL) {
        // tslint:disable-next-line: max-line-length
        this.awsCommonService.postRequest(
          `${this.appConstants.serverUrl}/${this.appConstants.surveyDashboardParamsURL}/${this.appConstants.surveySummaryParams}/${this.appConstants.surveySummaryAddChoicePost}`,
          'POST', this.choiceDec.summaryAddChoiceForm.value)
          .subscribe(
            (result) => {
              // this.choiceDec.isLoading = false;
              this.createSurveyRef.close(true);
              //this.notificationService.success('Submitted Successfully..!');
            },
            error => {
              console.log(error);
              this.notificationService.warn('Oops!!! The Server was unable to complete your request');
              this.createSurveyRef.close(false);
            }
          );

      } else {
       // this.notificationService.warn('Please Check Config File & Server is InActive..!');
      }
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.choiceDec.summaryAddChoiceForm.controls[controlName].hasError(errorName);
  }

  surveyAddChoiceCancel(data) {
    this.createSurveyRef.close(false);
  }
}
