import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuestionComponent } from './layout/question.component';
import { SurveyeditComponent } from '../survey/components/surveyedit/surveyedit.component';

const questionRouting: Routes  = [
  // { path: 'question', component: QuestionComponent  },
  // { path: '', redirectTo: 'question', pathMatch: 'full' }
  { path: '', component: QuestionComponent , pathMatch: 'full' },
  { path: 'surveyedit', component: SurveyeditComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(questionRouting)  ],
  exports: [RouterModule]
})
export class QuestionRoutingModule { }
