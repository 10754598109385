import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthManager } from './helpers/auth-manager';
import { AuthService } from './services/auth.service';
import { AppConfig } from './app.config';

@Component({
    template:'<div></div>'
})

export class LandingComponent implements OnInit {
    state: string;
    selectedMenuItem: string;
    SSO: any;
    UserRoleID: any;
    userId: any;
    responseDetails: any = [];
    role: any;
    userstatus: any;
    Tocheck : any;
    constructor(
        private router: Router, private AuthManager: AuthManager, private authService:AuthService
    ){
        // debugger;
    }
    ngOnInit(){
        // this.awsService.authenticatefss();
        // debugger;
        this.SSO = Number(this.AuthManager.dynSSOId);
        //console.log(this.SSO)
        // debugger;
        if ((this.SSO) >= 0) {
            // AppConfig.settings.appUrl="http://localhost:4200"
            // window.location.href = AppConfig.settings.appUrl + '/dashboard';
            // this.router.navigate(['dashboard']);
        }

        // if ((this.SSO) >= 0) {
        //     // if (sessionStorage.getItem('referenceUrl')) {
        //     //     window.location.href = sessionStorage.getItem('referenceUrl');
        //     //     sessionStorage.removeItem('referenceUrl');
        //     // }else{
        //     //     this.authService.getUserRoleID(this.SSO).subscribe(
        //     //     p => {
        //     //         // debugger;
        //     //         this.responseDetails = p;
        //     //         this.UserRoleID = this.responseDetails[0].roles.roleID;
        //     //         this.userId = this.responseDetails[0].userID;
        //     //         sessionStorage.setItem('userid', this.userId);
        //     //         this.Tocheck = sessionStorage.getItem('userid');

        //     //         // if (this.UserRoleID === 1) {
        //     //         //   window.location.href = environment.appurl + '/#/Admin';
        //     //         // } else if (this.UserRoleID === 2) {
        //     //         //   window.location.href = environment.appurl + '/#/Admin';
        //     //         // } else if (this.UserRoleID === 3 && (this.Tocheck != null || this.Tocheck != undefined || this.Tocheck != ""))
        //     //         // {
        //     //         //   window.location.href = environment.appurl + '/#/TransactionRefund';
        //     //         // } else if (this.UserRoleID === 4) {
        //     //         //   window.location.href = environment.appurl + '/#/RefundApprove';
        //     //         // } else {
        //     //         //   window.location.href = environment.appurl + '/#/Login';
        //     //         // }
        //     //     });
        //     // }
        // }else{
        //     if (sessionStorage.getItem('referenceUrl')) {
        //         window.location.href = sessionStorage.getItem('referenceUrl');
        //         sessionStorage.removeItem('referenceUrl');
        //     } else {
        //         window.location.href = AppConfig.settings.appUrl + '/#/Login';
        //     }
        // }
    }
}
