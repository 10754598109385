import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

export class ConfirmDialogModel {

  constructor(public title: string, public message: string) {
  }
}

@Component({
  selector: 'app-confirmdialog',
  template: `
<div class="confirm-modal">
    <p> {{message}}? </p>
    <button mat-raised-button color="primary" class="mat-raised-button mat-button-base mat-primary" (click)="onConfirm()">YES</button>
    <button type="button" mat-raised-button color="warn" class="mat-raised-button mat-button-base mat-warn" (click)="onDismiss()" mat-dialog-close>CANCEL</button>
</div>
  `,
  styles: []
})
export class ConfirmdialogComponent implements OnInit {
  title: string;
  message: string;
  constructor(
    public dialogRef: MatDialogRef<ConfirmdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
  ) {
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit() {
  }
  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
    
  }
}

/*
<div class="confirm-modal templateSaveAs">
   <form [formGroup]="templateSaveName"  class="example-form">
        <mat-form-field class="example-full-width float-left" floatLabel=never>
            <label class="font-13 required">Save Tamplate as</label>
            <input matInput class="form-control" placeholder="Template Name" formControlName="name">
            <mat-error *ngIf="hasError('name', 'required')">Please enter Template Name
                </mat-error>
        </mat-form-field>

   <div class="saveAsAction">
        <button type="button" mat-raised-button color="warn" mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" (click)="onConfirm()" >Save</button>
</div>
</form>
</div>
*/
